import React, { useEffect, useRef, useState } from 'react'
import { Button, Stack, FormControlLabel, Checkbox } from '@mui/material'
import SaveIcon from '@mui/icons-material/Save'
import { FormattedMessage } from 'react-intl'
import { Map as ImmutableMap } from 'immutable'
import pick from 'lodash/pick'
import { startTransaction } from '../../utils/apm'
import { messageKeys as globalMessageKeys } from '../../globalMessages'
import { useSnackbar } from '../../utils/snackBar'
import Section from '../../components/Section'
import ValidationPhoneField from '../../components/Fields/ValidationPhoneField'
import ValidationEmailField from '../../components/Fields/ValidationEmailField'
import { messageKeys } from './messages'
import Analytics from '../../utils/analytics'
import { CUSTOMER_SERVICE_SAVE } from '../../utils/analytics/events'

const CustomerSerivcePage = props => {
  const { showSuccessSnackbar, showErrorSnackbar } = useSnackbar(
    <FormattedMessage {...messageKeys.Title} />
  )
  const [hasChanges, setHasChanges] = useState(false)
  const [data, setData] = useState({})
  const [errors, setErrors] = useState({})
  const prevData = useRef({})
  const { updateCustomerServiceData } = props

  useEffect(() => {
    if (props.selectedProvider) {
      const providersConfigData = props.providersConfig
        .get(props.selectedProvider, new ImmutableMap())
        .toJSON()
      const providerData = props.providers
        .get(props.selectedProvider, new ImmutableMap())
        .toJSON()

      const nextData = {
        ...pick(providerData, [
          'sendConfirmationEmailToProvider',
          'bookingNotificationEmail'
        ]),
        ...pick(providersConfigData, [
          'customerServiceEmail',
          'customerServicePhone'
        ])
      }
      prevData.current = {
        ...nextData
      }
      setData(nextData)
      setErrors({})
      setHasChanges(false)
    }
  }, [props.selectedProvider, props.providersConfig, props.providers]) //eslint-disable-line

  const onSubmit = () => {
    Analytics.trackEvent(CUSTOMER_SERVICE_SAVE)
    if (!allMandatoryFieldsAreFilled()) {
      showErrorSnackbar(
        <FormattedMessage {...globalMessageKeys.ErrorDoNotDeleteMandatory} />
      )
      return
    }
    if (!allFilledFieldsAreValid()) {
      showErrorSnackbar(
        <FormattedMessage {...globalMessageKeys.ErrorInvalidDataInForm} />
      )
      return
    }

    const providersConfigData = props.providersConfig
      .get(props.selectedProvider, new ImmutableMap())
      .toJSON()
    const providerData = props.providers
      .get(props.selectedProvider, new ImmutableMap())
      .toJSON()
    const transaction = startTransaction('CustomerServiceDetailsPage#Save')

    updateCustomerServiceData(
      {
        provider: {
          ...providerData,
          ...pick(data, [
            'sendConfirmationEmailToProvider',
            'bookingNotificationEmail'
          ])
        },
        configuration: {
          ...providersConfigData,
          ...pick(data, ['customerServiceEmail', 'customerServicePhone'])
        }
      },
      () => {
        showSuccessSnackbar()
        setHasChanges(false)
        transaction.end()
      },
      error => {
        showErrorSnackbar(error.text)
        transaction.end()
      }
    )
  }

  const allMandatoryFieldsAreFilled = () => {
    let allGood = true
    Object.keys(errors).forEach(field => {
      const validationFailReason = errors[field]
      if (
        prevData.current &&
        prevData.current[field] &&
        validationFailReason === 'required'
      ) {
        allGood = false
      }
    })

    return allGood
  }

  const allFilledFieldsAreValid = () => {
    let allGood = true
    Object.keys(errors).forEach(field => {
      const validationFailReason = errors[field]
      if (validationFailReason && validationFailReason !== 'required') {
        allGood = false
      }
    })
    return allGood
  }

  const handleTextFieldChange = (e, errorReason) => {
    const { name, value } = e.target
    setData({
      ...data,
      [name]: value
    })
    setErrors({
      ...errors,
      [name]: errorReason || null
    })
    setHasChanges(true)
  }

  const handleCheckBoxChange = e => {
    const { checked, name } = e.target
    setData({
      ...data,
      [name]: checked
    })
    setHasChanges(true)
  }

  return (
    <form autoComplete="off">
      <Stack spacing={4}>
        <Section
          title={<FormattedMessage {...messageKeys.Header} />}
          subtitle={<FormattedMessage {...messageKeys.SubHeader} />}
        >
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={4}>
            <ValidationEmailField
              name="customerServiceEmail"
              error={errors.customerServiceEmail}
              label={<FormattedMessage {...messageKeys.CustomerServiceEmail} />}
              value={data.customerServiceEmail || ''}
              fullWidth
              required
              onChange={handleTextFieldChange}
            />
            <ValidationPhoneField
              name="customerServicePhone"
              error={errors.customerServicePhone}
              label={<FormattedMessage {...messageKeys.CustomerServicePhone} />}
              value={data.customerServicePhone}
              fullWidth
              required
              onChange={handleTextFieldChange}
            />
          </Stack>
        </Section>
        <Section
          title={
            <FormattedMessage {...messageKeys.BookingNotificationHeader} />
          }
          subtitle={
            <FormattedMessage {...messageKeys.BookingNotificationSubHeader} />
          }
        >
          <Stack spacing={2}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={data.sendConfirmationEmailToProvider || false}
                  name="sendConfirmationEmailToProvider"
                  inputProps={{
                    'aria-label': 'Send confirmation to provider'
                  }}
                  onChange={handleCheckBoxChange}
                />
              }
              label={
                <FormattedMessage
                  {...messageKeys.SendBookingNotificationEmail}
                />
              }
            />

            {/* Booking Notification */}
            <ValidationEmailField
              name="bookingNotificationEmail"
              error={errors.bookingNotificationEmail}
              label={
                <FormattedMessage {...messageKeys.BookingNotificationEmail} />
              }
              value={data.bookingNotificationEmail || ''}
              fullWidth
              required
              onChange={handleTextFieldChange}
            />
          </Stack>
        </Section>
        <Button
          size="large"
          color="primary"
          disabled={!hasChanges}
          startIcon={<SaveIcon />}
          variant="contained"
          fullWidth
          onClick={onSubmit}
        >
          <FormattedMessage {...globalMessageKeys.SaveButton} />
        </Button>
      </Stack>
    </form>
  )
}

export default CustomerSerivcePage
