export const messageKeys = {
  Title: {
    id: 'FinalizeRegistration:TITLE',
    defaultMessage: 'FinalizeRegistration:TITLE'
  },
  SubTitle: {
    id: 'FinalizeRegistration:SUB_TITLE',
    defaultMessage: 'FinalizeRegistration:SUB_TITLE'
  },
  SaveButton: {
    id: 'FinalizeRegistration:SAVE_BUTTON',
    defaultMessage: 'FinalizeRegistration:SAVE_BUTTON'
  },
  ApiLabel: {
    id: 'FinalizeRegistration:API_LABEL',
    defaultMessage: 'FinalizeRegistration:API_LABEL'
  },
  PrapiLabel: {
    id: 'FinalizeRegistration:PRAPI_LABEL',
    defaultMessage: 'FinalizeRegistration:PRAPI_LABEL'
  },
  PersonalDetailsFormTitle: {
    id: 'FinalizeRegistration:PERSONAL_DETAILS_FORM_TITLE',
    defaultMessage: 'FinalizeRegistration:PERSONAL_DETAILS_FORM_TITLE'
  },
  RegisteredEmailTitle: {
    id: 'FinalizeRegistration:REGISTERED_EMAIL_TITLE',
    defaultMessage: 'FinalizeRegistration:REGISTERED_EMAIL_TITLE'
  },
  IntegrationDetailsFormTitle: {
    id: 'FinalizeRegistration:INTEGRATION_DETAILS_FORM_TITLE',
    defaultMessage: 'FinalizeRegistration:INTEGRATION_DETAILS_FORM_TITLE'
  },
  AddressDetailsFormTitle: {
    id: 'FinalizeRegistration:ADDRESS_DETAILS_FORM_TITLE',
    defaultMessage: 'FinalizeRegistration:ADDRESS_DETAILS_FORM_TITLE'
  },
  PropertiesDetailsFormTitle: {
    id: 'FinalizeRegistration:PROPERTIES_DETAILS_FORM_TITLE',
    defaultMessage: 'FinalizeRegistration:PROPERTIES_DETAILS_FORM_TITLE'
  },
  ConnectionQuestion: {
    id: 'FinalizeRegistration:CONNECTION_QUESTION',
    defaultMessage: 'FinalizeRegistration:CONNECTION_QUESTION'
  },
  ChannelManagerQuestion: {
    id: 'FinalizeRegistration:CHANNEL_MANAGER_QUESTION',
    defaultMessage: 'FinalizeRegistration:CHANNEL_MANAGER_QUESTION'
  },
  CompanyLocationQuestion: {
    id: 'FinalizeRegistration:COMPANY_LOCATION_QUESTION',
    defaultMessage: 'FinalizeRegistration:COMPANY_LOCATION_QUESTION'
  },
  ThankYouTitle: {
    id: 'FinalizeRegistration:THANK_YOU_TITLE',
    defaultMessage: 'FinalizeRegistration:THANK_YOU_TITLE'
  },
  ThankYouMessage: {
    id: 'FinalizeRegistration:THANK_YOU_MESSAGE',
    defaultMessage: 'FinalizeRegistration:THANK_YOU_MESSAGE'
  },
  InfoForConnected: {
    id: 'FinalizeRegistration:INFO_FOR_CONNECTED',
    defaultMessage: 'FinalizeRegistration:INFO_FOR_CONNECTED'
  },
  InfoForNotConnected: {
    id: 'FinalizeRegistration:INFO_FOR_NOT_CONNECTED',
    defaultMessage: 'FinalizeRegistration:INFO_FOR_NOT_CONNECTED'
  },
  STEP_USER_DETAILS: {
    id: 'FinalizeRegistration:STEP_USER_DETAILS',
    defaultMessage: 'FinalizeRegistration:STEP_USER_DETAILS'
  },
  STEP_INTEGRATION_TYPE: {
    id: 'FinalizeRegistration:STEP_INTEGRATION_TYPE',
    defaultMessage: 'FinalizeRegistration:STEP_INTEGRATION_TYPE'
  },
  STEP_INTEGRATION_DETAILS: {
    id: 'FinalizeRegistration:STEP_INTEGRATION_DETAILS',
    defaultMessage: 'FinalizeRegistration:STEP_INTEGRATION_DETAILS'
  },
  OPTION_CONNECT_CHANNEL_MANAGER: {
    id: 'FinalizeRegistration:OPTION_CONNECT_CHANNEL_MANAGER',
    defaultMessage: 'FinalizeRegistration:OPTION_CONNECT_CHANNEL_MANAGER'
  },
  OPTION_CONNECT_CHANNEL_MANAGER_HELP: {
    id: 'FinalizeRegistration:OPTION_CONNECT_CHANNEL_MANAGER_HELP',
    defaultMessage: 'FinalizeRegistration:OPTION_CONNECT_CHANNEL_MANAGER_HELP'
  },
  OPTION_CONNECT_HAPI: {
    id: 'FinalizeRegistration:OPTION_CONNECT_HAPI',
    defaultMessage: 'FinalizeRegistration:OPTION_CONNECT_HAPI'
  },
  OPTION_CONNECT_HAPI_HELP: {
    id: 'FinalizeRegistration:OPTION_CONNECT_HAPI_HELP',
    defaultMessage: 'FinalizeRegistration:OPTION_CONNECT_HAPI_HELP'
  },
  OPTION_CONNECT_CONTRACT_SIGNED: {
    id: 'FinalizeRegistration:OPTION_CONNECT_CONTRACT_SIGNED',
    defaultMessage: 'FinalizeRegistration:OPTION_CONNECT_CONTRACT_SIGNED'
  },
  OPTION_CONNECT_CONTRACT_SIGNED_HELP: {
    id: 'FinalizeRegistration:OPTION_CONNECT_CONTRACT_SIGNED_HELP',
    defaultMessage: 'FinalizeRegistration:OPTION_CONNECT_CONTRACT_SIGNED_HELP'
  },
  SELECT_CHANNEL_MANAGER: {
    id: 'FinalizeRegistration:SELECT_CHANNEL_MANAGER',
    defaultMessage: 'FinalizeRegistration:SELECT_CHANNEL_MANAGER'
  },
  VALIDATING_VAT: {
    id: 'FinalizeRegistration:VALIDATING_VAT',
    defaultMessage: 'FinalizeRegistration:VALIDATING_VAT'
  },
  WITHOUT_VAT: {
    id: 'FinalizeRegistration:WITHOUT_VAT',
    defaultMessage: 'FinalizeRegistration:WITHOUT_VAT'
  },
  HoliduPaymentEnabled: {
    id: 'FinalizeRegistration:HOLIDU_PAYMENT_DESC',
    defaultMessage: 'FinalizeRegistration:HOLIDU_PAYMENT_DESC'
  },
  HoliduPaymentConsent: {
    id: 'FinalizeRegistration:HOLIDU_PAYMENT_CONSENT',
    defaultMessage: 'FinalizeRegistration:HOLIDU_PAYMENT_CONSENT'
  },
  HoliduPaymentInfoLink: {
    id: 'FinalizeRegistration:HOLIDU_PAYMENT_INFO_LINK',
    defaultMessage: 'FinalizeRegistration:HOLIDU_PAYMENT_INFO_LINK'
  },
  HoliduPaymentRefusal: {
    id: 'FinalizeRegistration:HOLIDU_PAYMENT_REFUSAL',
    defaultMessage: 'FinalizeRegistration:HOLIDU_PAYMENT_REFUSAL'
  },
  VerificationErrorHeader: {
    id: 'Auth:VERIFICATION_ERROR_HEADER',
    defaultMessage: 'Auth:VERIFICATION_ERROR_HEADER'
  },
  ExpirationCodeExceptionMessage: {
    id: 'Auth:CODE_DEPRECATED',
    defaultMessage: 'Auth:CODE_DEPRECATED'
  },
  ResendVerificationButton: {
    id: 'Auth:RESEND_VERIFICATION',
    defaultMessage: 'Auth:RESEND_VERIFICATION'
  },
  NotAuthorizedExceptionMessage: {
    id: 'Auth:ALREADY_EXISTING',
    defaultMessage: 'Auth:ALREADY_EXISTING'
  },
  LoginButton: {
    id: 'Auth:LOGIN_BTN',
    defaultMessage: 'Auth:LOGIN_BTN'
  }
}
