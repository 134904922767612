import React from 'react'
import { Box, Grid, LinearProgress, Typography } from '@mui/material'

const SubScore = (props: {
  label: string
  secondaryLabel?: string
  value: JSX.Element | string | number
  progressValue?: number
}): JSX.Element => {
  const { label, secondaryLabel, value, progressValue } = props
  return (
    <Box m={1}>
      <Grid container spacing={1} alignItems="center">
        <Grid item xs={6} lg={6}>
          <Typography variant="subtitle2">{label}:</Typography>
          {secondaryLabel && (
            <Typography variant="caption" component="div" noWrap>
              {secondaryLabel}
            </Typography>
          )}
        </Grid>
        <Grid item xs={6} lg={typeof progressValue === 'number' ? 2 : 6}>
          <Box textAlign="right">
            <Typography component="div">{value}</Typography>
          </Box>
        </Grid>
        {typeof progressValue === 'number' && (
          <Grid item xs={12} lg={4}>
            <Box width="100%">
              <LinearProgress
                sx={theme => ({
                  height: theme.spacing(2)
                })}
                variant="determinate"
                value={progressValue > 100 ? 100 : progressValue}
              />
            </Box>
          </Grid>
        )}
      </Grid>
    </Box>
  )
}

export default SubScore
