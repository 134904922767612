import React from 'react'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import ListItemButton from '@mui/material/ListItemButton'
import { NavLink as RouterNavLink, useRouteMatch } from 'react-router-dom'
import { Chip, Stack, Box } from '@mui/material'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { connectRequest } from 'redux-query-immutable-react'
import APP_ROUTES from '../routes'
import LanguageMenu from '../containers/Header/LanguageMenu'
import UserMenu from '../containers/Header/UserMenu'
import { getSelectedProvider } from '../store/providerSelection/providerSelector'
import { getGeneralConfigSelector } from '../store/providerConfiguration/providerConfigurationSelector'
import { getCompanySelector } from '../store/company/companySelector'
import { getProviderConfigurationRequest } from '../store/providerConfiguration/providerConfigurationRequest'
import { getCompanyRequest } from '../store/company/companyRequest'
import {
  PATH_BILLING_INFORMATION,
  PATH_CUSTOMER_PAYMENT,
  PATH_CUSTOMER_SERVICE,
  PATH_INTEGRATION_DETAILS,
  PATH_PAYOUTS,
  PATH_INVOICES,
  PATH_KNOW_YOUR_CUSTOMER_VERIFICATION,
  PATH_CANCELLATION_POLICY,
  PATH_REGULATORY_REPORTING,
  PATH_COMMERCIAL_AGREEMENT,
  PATH_INVOICES_NON_HOPA
} from '../routes/PathConstants'
import {
  checkKycApproved,
  countMissingFieldsForBillingInformation,
  countMissingFieldsForCancellationPolicy,
  countMissingFieldsForCommercialAgreement,
  countMissingFieldsForCustomerPaymentOptions,
  countMissingFieldsForCustomerService,
  countMissingFieldsForIntegrationBasics,
  countMissingFieldsForRegulatoryReporting
} from '../containers/Dashboard/OnBoarding/RequiredFieldsFetcher'
import { getFetchKycStatusRequest } from '../store/kyc/kycRequests'
import { getKycStatusSelector } from '../store/kyc/kycSelectors'
import { getBookingConfigurationDetailsSelector } from '../store/bookingConfigurationDetails/bookingDetailsSelector'
import { getBookingConfigurationDetailsRequest } from '../store/bookingConfigurationDetails/bookingDetailsRequest'
import { getProviderSelector } from '../store/provider/providerSelector'
import { Map } from 'immutable'

const NavLink = React.forwardRef((props, ref) => (
  <RouterNavLink innerRef={ref} {...props} />
))

const identifyBadgeForPath = (path, props) => {
  const { companies, providers, providersConfig, selectedProvider, kycStatus } =
    props

  const provider = providers.get(selectedProvider)
  const providerConfig = providersConfig.get(selectedProvider)
  const company = companies.get(selectedProvider)

  switch (path) {
    case PATH_INTEGRATION_DETAILS:
      return countMissingFieldsForIntegrationBasics(provider, providerConfig)
    case PATH_CUSTOMER_SERVICE:
      return countMissingFieldsForCustomerService(provider, providerConfig)
    case PATH_CUSTOMER_PAYMENT:
      return countMissingFieldsForCustomerPaymentOptions(providerConfig)
    case PATH_CANCELLATION_POLICY:
      return countMissingFieldsForCancellationPolicy(providerConfig)
    case PATH_BILLING_INFORMATION:
      return countMissingFieldsForBillingInformation(company)
    case PATH_REGULATORY_REPORTING:
      return countMissingFieldsForRegulatoryReporting(company)
    case PATH_KNOW_YOUR_CUSTOMER_VERIFICATION:
      return checkKycApproved(kycStatus)
    case PATH_COMMERCIAL_AGREEMENT:
      return countMissingFieldsForCommercialAgreement(company, provider)
    default:
      return null
  }
}

const NavItem = props => {
  const selected = useRouteMatch({
    path: props.to,
    strict: true,
    sensitive: true
  })
  return (
    <ListItemButton
      sx={{
        m: 1,
        borderRadius: '10px',
        background: theme =>
          selected ? theme.palette.primary.main : theme.palette.common.white,
        color: theme =>
          selected ? theme.palette.common.white : theme.palette.common.black,
        '&:hover': {
          background: theme =>
            selected ? theme.palette.primary.main : theme.palette.grey[200]
        }
      }}
      {...props}
    />
  )
}

const NavList = props => {
  const { companies, providersConfig, selectedProvider } = props

  const company = companies.get(selectedProvider)
  const providerConfig = providersConfig.get(selectedProvider)

  const isPlutusProvider = Boolean(
    company && company.get('holiduPaymentsEnabled')
  )

  const isHopa = Boolean(
    providerConfig && providerConfig.get('holiduHandlesPayments')
  )

  const showNonHopaInvoices = Boolean(
    !isHopa ||
      (isHopa &&
        props.bookingsDetail
          .get(props.selectedProvider, Map({}))
          .get('showNonHopaInvoicesTab'))
  )

  const isValidPath = path => {
    const plutusPaths = [
      PATH_PAYOUTS,
      PATH_INVOICES,
      PATH_KNOW_YOUR_CUSTOMER_VERIFICATION
    ]

    if (path === PATH_CUSTOMER_PAYMENT) {
      return !isHopa
    }
    if (plutusPaths.includes(path)) {
      return isPlutusProvider
    }
    if (path === PATH_COMMERCIAL_AGREEMENT) {
      return props.bookingsDetail
        .get(props.selectedProvider, Map({}))
        .get('showCommercialAgreementTab')
    }
    if (path === PATH_INVOICES_NON_HOPA) {
      return showNonHopaInvoices
    }
    return true
  }

  return (
    <Stack>
      <List dense component="nav">
        {APP_ROUTES.map((category, index) => {
          if (category.children) {
            return (
              <React.Fragment key={index}>
                <ListItem
                  sx={{ color: theme => theme.palette.text.secondary, mx: 1 }}
                >
                  {category.icon && (
                    <ListItemIcon sx={{ minWidth: 36, color: 'inherit' }}>
                      {category.icon}
                    </ListItemIcon>
                  )}
                  <ListItemText
                    primaryTypographyProps={{
                      color: 'inherit',
                      variant: 'caption'
                    }}
                    primary={category.id}
                  />
                </ListItem>
                {category.children
                  .filter(route => isValidPath(route.path))
                  .map((child, index) => {
                    const badge = identifyBadgeForPath(child.path, props)
                    return (
                      <NavItem
                        component={NavLink}
                        to={child.path}
                        key={index}
                        inset="true"
                      >
                        {child.icon && (
                          <ListItemIcon sx={{ minWidth: 36, color: 'inherit' }}>
                            {child.icon}
                          </ListItemIcon>
                        )}
                        <ListItemText primary={child.name} />
                        {Boolean(badge) && (
                          <Chip color="error" size="small" label={badge} />
                        )}
                      </NavItem>
                    )
                  })}
                {/* <Divider /> */}
              </React.Fragment>
            )
          } else {
            return (
              <React.Fragment key={index}>
                <NavItem component={NavLink} to={category.path}>
                  {category.icon && (
                    <ListItemIcon
                      sx={{
                        minWidth: 36,
                        color: 'inherit'
                      }}
                    >
                      {category.icon}
                    </ListItemIcon>
                  )}
                  <ListItemText primary={category.id} />
                </NavItem>
                {/* <Divider /> */}
              </React.Fragment>
            )
          }
        })}
      </List>
      <Box sx={{ display: { xs: 'block', lg: 'none' } }}>
        <Stack>
          <LanguageMenu />
          <UserMenu />
        </Stack>
      </Box>
    </Stack>
  )
}

export default compose(
  connect(getSelectedProvider),
  connect(getGeneralConfigSelector),
  connect(getCompanySelector),
  connect(getProviderSelector),
  connect(getKycStatusSelector),
  connect(getBookingConfigurationDetailsSelector),
  connectRequest(getBookingConfigurationDetailsRequest),
  connectRequest(getProviderConfigurationRequest),
  connectRequest(getCompanyRequest),
  connectRequest(getFetchKycStatusRequest)
)(NavList)
