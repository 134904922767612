import { useLayoutEffect, useRef, useState } from 'react'
import get from 'lodash/get'

const useResponsiveWidth = (defaultWidth: number) => {
  const [width, setWidth] = useState<number>(0)
  const containerRef = useRef<unknown>()

  useLayoutEffect(() => {
    function handleResize() {
      if (containerRef.current) {
        const containerWidth = get(
          containerRef.current,
          'offsetWidth',
          defaultWidth
        )
        setWidth(containerWidth)
      } else {
        setWidth(defaultWidth)
      }
    }
    window.addEventListener('resize', handleResize)

    handleResize()

    return () => window.removeEventListener('resize', handleResize)
  }, [defaultWidth])

  return { containerRef, width }
}

export default useResponsiveWidth
