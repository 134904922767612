import React from 'react'
import {
  Stack,
  Box,
  Typography,
  TableRow,
  TableCell,
  Table,
  TableBody
} from '@mui/material'
import { useIntl } from 'react-intl'
import messageKeys from './messages'
import { CommissionBreakdown } from './types'
import { getTextFromPrice } from './util'
import { Section, Tray } from '@holidu/pui-components/dist'

type CommissionItemProps = {
  label: string
  value: string
}

const CommissionItem = (props: CommissionItemProps) => {
  const { label, value } = props
  return (
    <TableRow>
      <TableCell>{label}</TableCell>

      <TableCell align="right">
        <Typography variant="subtitle2">{value}</Typography>
      </TableCell>
    </TableRow>
  )
}

type CommisionBreakdownSectionProps = {
  commissionBreakdown: CommissionBreakdown
  isHoliduPayment?: boolean
}
const CommissionBreakdownSection = (props: CommisionBreakdownSectionProps) => {
  const { commissionBreakdown, isHoliduPayment = false } = props
  const intl = useIntl()

  return (
    <Section title={intl.formatMessage(messageKeys.CommissionBreakdown)}>
      <Table>
        <TableBody>
          {isHoliduPayment && (
            <CommissionItem
              label={intl.formatMessage(messageKeys.TotalPricePaidToHolidu)}
              value={getTextFromPrice(commissionBreakdown?.totalAmount)}
            />
          )}
          <CommissionItem
            label={intl.formatMessage(messageKeys.CommissionablePrice)}
            value={getTextFromPrice(commissionBreakdown?.commissionableAmount)}
          />
          {isHoliduPayment && (
            <CommissionItem
              label={intl.formatMessage(messageKeys.CommissionPercent)}
              value={`${commissionBreakdown?.commissionPercent || 0}%`}
            />
          )}
          <CommissionItem
            label={intl.formatMessage(messageKeys.Commission)}
            value={getTextFromPrice(commissionBreakdown?.commission)}
          />
          {isHoliduPayment && (
            <CommissionItem
              label={intl.formatMessage(messageKeys.VAT)}
              value={getTextFromPrice(commissionBreakdown?.vat)}
            />
          )}
        </TableBody>
      </Table>
      {isHoliduPayment && (
        <Box pt={2}>
          <Tray color="primary">
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography variant="subtitle2">
                {intl.formatMessage(messageKeys.TotalPayout)}
              </Typography>
              {commissionBreakdown?.netPayout !== undefined && (
                <Typography variant="subtitle2">
                  {getTextFromPrice(commissionBreakdown.netPayout)}
                </Typography>
              )}
            </Stack>
          </Tray>
        </Box>
      )}
    </Section>
  )
}

export default CommissionBreakdownSection
