export const messageKeys = {
  Title: {
    id: 'RegulatoryReporting:TITLE',
    defaultMessage: 'RegulatoryReporting:TITLE'
  },
  ShowReportingDataButton: {
    id: 'RegulatoryReporting:SHOW_REPORTING_DATA_BUTTON',
    defaultMessage: 'RegulatoryReporting:SHOW_REPORTING_DATA_BUTTON'
  },
  RegistrationNumber: {
    id: 'RegulatoryReporting:REGISTRATION_NUMBER',
    defaultMessage: 'RegulatoryReporting:REGISTRATION_NUMBER'
  },
  BusinessType: {
    id: 'RegulatoryReporting:BUSINESS_TYPE',
    defaultMessage: 'RegulatoryReporting:BUSINESS_TYPE'
  },
  TaxId: {
    id: 'RegulatoryReporting:TAX_ID',
    defaultMessage: 'RegulatoryReporting:TAX_ID'
  },
  Header: {
    id: 'RegulatoryReporting:HEADER',
    defaultMessage: 'RegulatoryReporting:HEADER'
  },
  SubHeader: {
    id: 'RegulatoryReporting:SUBHEADER',
    defaultMessage: 'RegulatoryReporting:SUBHEADER'
  },
  TaxIdHint: {
    id: 'RegulatoryReporting:TAX_ID_HINT',
    defaultMessage: 'RegulatoryReporting:TAX_ID_HINT'
  },
  RegistrationNumberHint: {
    id: 'RegulatoryReporting:REGISTRATION_NUMBER_HINT',
    defaultMessage: 'RegulatoryReporting:REGISTRATION_NUMBER_HINT'
  },
  IndividualDesc: {
    id: 'RegulatoryReporting:INDIVIDUAL_ENTITY_DESC',
    defaultMessage: 'RegulatoryReporting:INDIVIDUAL_ENTITY_DESC'
  },
  LegalBusinessDesc: {
    id: 'RegulatoryReporting:LEGAL_BUSINESS_ENTITY_DESC',
    defaultMessage: 'RegulatoryReporting:LEGAL_BUSINESS_ENTITY_DESC'
  },
  StateOrPublicDesc: {
    id: 'RegulatoryReporting:STATE_OR_PUBLIC_ENTITY_DESC',
    defaultMessage: 'RegulatoryReporting:STATE_OR_PUBLIC_ENTITY_DESC'
  },
  BirthDate: {
    id: 'RegulatoryReporting:BIRTH_DATE',
    defaultMessage: 'RegulatoryReporting:BIRTH_DATE'
  },
  BirthDateHint: {
    id: 'RegulatoryReporting:BIRTH_DATE_HINT',
    defaultMessage: 'RegulatoryReporting:BIRTH_DATE_HINT'
  },
  SelectEntityType: {
    id: 'RegulatoryReporting:SELECT_ENTITY_TYPE',
    defaultMessage: 'RegulatoryReporting:SELECT_ENTITY_TYPE'
  },
  DataHeader: {
    id: 'RegulatoryReporting:SHOW_DATA_HEADER',
    defaultMessage: 'RegulatoryReporting:SHOW_DATA_HEADER'
  },
  DataSubHeader: {
    id: 'RegulatoryReporting:SHOW_DATA_SUB_HEADER',
    defaultMessage: 'RegulatoryReporting:SHOW_DATA_SUB_HEADER'
  },
  ReportingYear: {
    id: 'RegulatoryReporting:REPORTING_YEAR',
    defaultMessage: 'RegulatoryReporting:REPORTING_YEAR'
  },
  NoDataFound: {
    id: 'RegulatoryReporting:NO_DATA_FOUND',
    defaultMessage: 'RegulatoryReporting:NO_DATA_FOUND'
  },
  CompanyName: {
    id: 'RegulatoryReporting:COMPANY_NAME',
    defaultMessage: 'RegulatoryReporting:COMPANY_NAME'
  },
  TinCountry: {
    id: 'RegulatoryReporting:TIN_COUNTRY',
    defaultMessage: 'RegulatoryReporting:TIN_COUNTRY'
  },
  PrivateTaxNumber: {
    id: 'RegulatoryReporting:PRIVATE_TAX_NUMBER',
    defaultMessage: 'RegulatoryReporting:PRIVATE_TAX_NUMBER'
  },
  Address: {
    id: 'RegulatoryReporting:ADDRESS',
    defaultMessage: 'RegulatoryReporting:ADDRESS'
  },
  DateOfBirth: {
    id: 'RegulatoryReporting:DATE_OF_BIRTH',
    defaultMessage: 'RegulatoryReporting:DATE_OF_BIRTH'
  },
  CommercialRegistrationNumber: {
    id: 'RegulatoryReporting:COMMERCIAL_REGISTRATION_NUMBER',
    defaultMessage: 'RegulatoryReporting:COMMERCIAL_REGISTRATION_NUMBER'
  },
  FirstLastName: {
    id: 'RegulatoryReporting:FIRST_LAST_NAME',
    defaultMessage: 'RegulatoryReporting:FIRST_LAST_NAME'
  },
  Surname: {
    id: 'RegulatoryReporting:SURNAME',
    defaultMessage: 'RegulatoryReporting:SURNAME'
  },
  ApartmentId: {
    id: 'RegulatoryReporting:APARTMENT_ID',
    defaultMessage: 'RegulatoryReporting:APARTMENT_ID'
  },
  Country: {
    id: 'RegulatoryReporting:COUNTRY',
    defaultMessage: 'RegulatoryReporting:COUNTRY'
  },
  TotalPayouts: {
    id: 'RegulatoryReporting:TOTAL_PAYOUTS',
    defaultMessage: 'RegulatoryReporting:TOTAL_PAYOUTS'
  },
  NumberOfBookings: {
    id: 'RegulatoryReporting:NUMBER_OF_BOOKINGS',
    defaultMessage: 'RegulatoryReporting:NUMBER_OF_BOOKINGS'
  },
  TotalRevenue: {
    id: 'RegulatoryReporting:TOTAL_REVENUE',
    defaultMessage: 'RegulatoryReporting:TOTAL_REVENUE'
  },
  AdditionalTax: {
    id: 'RegulatoryReporting:ADDITIONAL_TAX',
    defaultMessage: 'RegulatoryReporting:ADDITIONAL_TAX'
  },
  InfoByQuarter: {
    id: 'RegulatoryReporting:INFO_BY_QUARTER',
    defaultMessage: 'RegulatoryReporting:INFO_BY_QUARTER'
  },
  Quarter: {
    id: 'RegulatoryReporting:QUARTER',
    defaultMessage: 'RegulatoryReporting:QUARTER'
  }
}
