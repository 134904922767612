import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import Drawer from '@mui/material/Drawer'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import { styled } from '@mui/material/styles'
import { useLocation } from 'react-router'
import Header from '../containers/Header'
import NavList from './NavList'

const drawerWidth = 300

const DrawerToolbar = styled(Toolbar)(({ theme }) => ({
  background: theme.palette.primary.main
}))

function MainLayout(props) {
  const { children, window } = props
  const location = useLocation()
  const [mobileOpen, setMobileOpen] = useState(false)

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen)
  }

  useEffect(() => {
    setMobileOpen(false)
  }, [location])

  const drawer = (
    <Box>
      <DrawerToolbar>
        <Box display="flex" alignItems="center">
          <Box mr={2} display="flex" alignItems="center">
            <img
              height={26}
              alt="Holidu Partner Portal"
              src="/static/img/WhiteLogo.png"
            />
          </Box>
          <Divider orientation="vertical" flexItem />
          <Typography ml={2} variant="subtitle2" color="white">
            Partner Portal
          </Typography>
        </Box>
      </DrawerToolbar>
      <Divider />
      <Box
        sx={{
          // borderRight: '1px solid rgba(0, 0, 0, 0.12)',
          height: 'calc(100vh - 65px)',
          overflowY: 'auto'
        }}
      >
        <NavList />
      </Box>
    </Box>
  )

  const container =
    window !== undefined ? () => window().document.body : undefined

  return (
    <Box sx={{ display: 'flex' }}>
      <Header
        drawerWidth={drawerWidth}
        onMenuButtonClick={handleDrawerToggle}
      />
      <Box
        component="nav"
        sx={{ width: { lg: drawerWidth }, flexShrink: { lg: 0 } }}
        aria-label="navigation"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', lg: 'none' },
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: drawerWidth,
              borderRight: '0px'
              // backgroundColor: theme => theme.palette.grey[600],
              // color: theme => theme.palette.grey[50],
            }
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: 'none', lg: 'block' },
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: drawerWidth,
              borderRight: '0px'
              // backgroundColor: theme => theme.palette.grey[600],
              // color: theme => theme.palette.grey[50],
            }
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box component="main" sx={{ flexGrow: 1 }}>
        <Toolbar />
        {children}
      </Box>
    </Box>
  )
}

MainLayout.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func
}

export default MainLayout
