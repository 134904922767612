import env from '../../env'
import { currentUserIdToken } from '../../utils/awsCognitoClient'
import sanitizeData from '../../utils/sanitizeData'

export const registerNew = payload => ({
  url: `${env.BACKEND}/registerWithNewProvider`,
  body: sanitizeData(payload),
  transform: response => ({
    user: response.user,
    provider: response.provider,
    providerConfig: response.configuration,
    providers: response.provider
  }),
  update: {
    user: (prev, next) => next,
    provider: (prev, next) => next,
    providerConfig: (prev, next) => next,
    providers: (prev, next) => {
      if (!prev) {
        return Map({ [next.get('identifier')]: next })
      } else {
        return prev.set(next.get('identifier'), next)
      }
    }
  },
  options: {
    headers: {
      Authorization: 'Bearer ' + currentUserIdToken()
    }
  }
})

export const connectToExisting = payload => ({
  url: `${env.BACKEND}/registerWithExistingProvider`,
  body: sanitizeData(payload),
  options: {
    headers: {
      Authorization: 'Bearer ' + currentUserIdToken()
    }
  }
})
