const messageKeys = {
  Title: {
    id: 'BookingsKeys:TITLE',
    defaultMessage: 'BookingsKeys:TITLE'
  },
  BookedOn: {
    id: 'BookingsKeys:BOOKED_ON',
    defaultMessage: 'BookingsKeys:BOOKED_ON'
  },
  Status: {
    id: 'BookingsKeys:STATUS',
    defaultMessage: 'BookingsKeys:STATUS'
  },
  EmptyBookingSubtitle: {
    id: 'BookingsKeys:EMPTY_BOOKING_SUBTITLE',
    defaultMessage: 'BookingsKeys:EMPTY_BOOKING_SUBTITLE'
  },
  TestBooking: {
    id: 'BookingsKeys:TEST_BOOKING',
    defaultMessage: 'BookingsKeys:TEST_BOOKING'
  },
  FilterBigSearch: {
    id: 'Filter:BIG_SEARCH',
    defaultMessage: 'Filter:BIG_SEARCH'
  },
  FilterTransactionId: {
    id: 'Filter:TRANSACTION_ID',
    defaultMessage: 'Filter:TRANSACTION_ID'
  },
  FilterBookingStatus: {
    id: 'Filter:BOOKING_STATUS',
    defaultMessage: 'Filter:BOOKING_STATUS'
  },
  FilterBookingStatusAll: {
    id: 'Filter:BOOKING_STATUS_ALL',
    defaultMessage: 'Filter:BOOKING_STATUS_ALL'
  },
  FilterArrivalDate: {
    id: 'Filter:ARRIVAL_DATE',
    defaultMessage: 'Filter:ARRIVAL_DATE'
  },
  FilterArrivalDateAll: {
    id: 'Filter:ARRIVAL_DATE_ALL',
    defaultMessage: 'Filter:ARRIVAL_DATE_ALL'
  },
  FilterArrivalDateFuture: {
    id: 'Filter:ARRIVAL_DATE_FUTURE',
    defaultMessage: 'Filter:ARRIVAL_DATE_FUTURE'
  },
  FilterArrivalDatePast: {
    id: 'Filter:ARRIVAL_DATE_PAST',
    defaultMessage: 'Filter:ARRIVAL_DATE_PAST'
  },
  FilterBookingDateRange: {
    id: 'Filter:BOOKING_DATE_RANGE',
    defaultMessage: 'Filter:BOOKING_DATE_RANGE'
  },
  FilterSort: {
    id: 'Filter:SORT',
    defaultMessage: 'Filter:SORT'
  },
  FilterSortBookingDateAsc: {
    id: 'Filter:SORT_BOOKING_DATE_ASC',
    defaultMessage: 'Filter:SORT_BOOKING_DATE_ASC'
  },
  FilterSortBookingDateDesc: {
    id: 'Filter:SORT_BOOKING_DATE_DESC',
    defaultMessage: 'Filter:SORT_BOOKING_DATE_DESC'
  },
  FilterSortArrivalDateAsc: {
    id: 'Filter:SORT_ARRIVAL_DATE_ASC',
    defaultMessage: 'Filter:SORT_ARRIVAL_DATE_ASC'
  },
  FilterSortArrivalDateDesc: {
    id: 'Filter:SORT_ARRIVAL_DATE_DESC',
    defaultMessage: 'Filter:SORT_ARRIVAL_DATE_DESC'
  },
  DownloadCsv: {
    id: 'BookingsKeys:DOWNLOAD_CSV',
    defaultMessage: 'BookingsKeys:DOWNLOAD_CSV'
  },
  DownloadCsvSuccess: {
    id: 'BookingsKeys:DOWNLOAD_CSV_SUCCESS',
    defaultMessage: 'BookingsKeys:DOWNLOAD_CSV_SUCCESS'
  },
  DownloadCsvError: {
    id: 'BookingsKeys:DOWNLOAD_CSV_ERROR',
    defaultMessage: 'BookingsKeys:DOWNLOAD_CSV_ERROR: {message}'
  },
  DownloadCsvLimit: {
    id: 'BookingsKeys:DOWNLOAD_CSV_LIMIT',
    defaultMessage: 'BookingsKeys:DOWNLOAD_CSV_LIMIT'
  },
  DisplayLimit: {
    id: 'BookingsKeys:DISPLAY_LIMIT',
    defaultMessage: 'BookingsKeys:DISPLAY_LIMIT'
  },
}

export default messageKeys
