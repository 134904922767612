import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Box, Pagination, Typography } from '@mui/material'
import { getSelectedProvider } from '../../store/providerSelection/providerSelector'
import ErrorPageContent from '../../components/ErrorPage/ErrorPageContent'
import Loader from '../../components/Loader'
import NonHopaInvoicesContent from './NonHopaInvoicesContent'
import { Page, PageContent, PageHeader } from '@holidu/pui-components/dist'
import { FormattedMessage } from 'react-intl'
import { messageKeys } from './messages'
import useGetNonHopaInvoiceAggregations from './useGetNonHopaInvoiceAggregations'
import EmptyInvoices from '../HoliduPaymentsInvoices/EmptyInvoices'

const NonHopaInvoicesContainer = () => {
  const { selectedProvider } = useSelector(getSelectedProvider)

  const [currentPage, setCurrentPage] = useState(0)

  const { data, status, error, isLoading, isFetching } =
    useGetNonHopaInvoiceAggregations(selectedProvider)

  const pageSize = 20
  const paginatedData = data?.slice(
    pageSize * currentPage,
    pageSize * (currentPage + 1)
  )

  return (
    <Page>
      <PageHeader
        sx={{
          borderBottom: 'none',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}
      >
        <Typography variant="h6">
          <FormattedMessage {...messageKeys.TITLE} />
        </Typography>
        {data && data?.length ? (
          <Pagination
            count={Math.ceil(data.length / pageSize)}
            onChange={(_, page) => setCurrentPage(page - 1)}
          />
        ) : null}
      </PageHeader>
      <PageContent px={3} pb={8}>
        {(isLoading || isFetching) && <Loader />}
        <Box>
          {status === 'error' ? (
            <ErrorPageContent status={error.message} />
          ) : (
            <>
              {paginatedData && paginatedData?.length ? (
                <NonHopaInvoicesContent data={paginatedData} />
              ) : (
                !(isLoading || isFetching) && <EmptyInvoices />
              )}
            </>
          )}
        </Box>
      </PageContent>
    </Page>
  )
}

export default NonHopaInvoicesContainer
