import {
  Stack,
  InputAdornment,
  Popover,
  TextField,
  Button,
  FormLabel,
  Box,
  TextFieldProps
} from '@mui/material'
import { CalendarPicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import DateRangeIcon from '@mui/icons-material/DateRange'
import moment from 'moment'
import React, { useEffect } from 'react'
import {
  DATE_FORMAT,
  getDateFromDateString,
  getDateStringFromDate
} from '../utils/dateUtil'

export type DateRange = {
  from: string | null
  to: string | null
}

export type DateRangeChange = DateRange & { triggeredBy: string }

type BeforeDay = {
  key: string
  label: string
  subtract: number
}

type DateRangeFilterProps = DateRange & {
  label?: string
  allowBeforeDays?: BeforeDay[]
  fromDateText?: string
  toDateText?: string
  clearText?: string
  applyText?: string
  textFieldProps?: TextFieldProps
  onChange: (dateRangeChange: DateRangeChange) => void
}

const getDisplayTextKey = (dateRange: DateRange) => {
  if (dateRange.from) {
    if (dateRange.to) {
      return (
        moment(dateRange.from).format(DATE_FORMAT) +
        ' ~ ' +
        moment(dateRange.to).format(DATE_FORMAT)
      )
    } else {
      return moment(dateRange.from).format(DATE_FORMAT) + ' ~ '
    }
  } else if (dateRange.to) {
    return ' ~ ' + moment(dateRange.to).format(DATE_FORMAT)
  }
  return ''
}

const DateRangeFilter = (props: DateRangeFilterProps): JSX.Element => {
  const {
    from,
    to,
    allowBeforeDays,
    fromDateText = 'From date',
    toDateText = 'To date',
    clearText = 'Clear',
    applyText = 'Apply',
    label,
    textFieldProps,
    onChange
  } = props
  const [anchorEl, setAnchorEl] = React.useState<HTMLInputElement | null>(null)
  const [state, setState] = React.useState<DateRange>({
    from: null,
    to: null
  })

  useEffect(() => {
    setState({
      from: from || null,
      to: to || null
    })
  }, [from, to])

  const handleClick = (event: React.MouseEvent<HTMLInputElement>) => {
    setAnchorEl(event.currentTarget)
  }


  const handleDateChange = (name: 'from' | 'to') => (value: any) => {
    let nextValue: {
      from: string | null
      to: string | null
      [key: string]: string | null
    } = {
      ...state
    }

    if (value) {
      const dateString = getDateStringFromDate(value)
      if (dateString) {
        nextValue[name] = dateString
      }
    } else {
      nextValue[name] = null
    }

    setState(nextValue)
  }

  const handleQuickSelection = (beforeDay: any) => () => {
    setAnchorEl(null)
    onChange(
      {
        from: getDateStringFromDate(
          moment().subtract(beforeDay.subtract, 'days')
        ),
        to: getDateStringFromDate(moment()),
        triggeredBy: beforeDay.key
      }
    )
  }

  const handleApply = () => {
    onChange({
      from: state.from || null,
      to: state.to || null,
      triggeredBy: 'APPLY'
    })
    setAnchorEl(null)
  }

  const handleClear = () => {
    onChange({
      from: null,
      to: null,
      triggeredBy: 'CLEAR'
    })
    handleClose()
  }

  const handleClose = () => {
    setState({
      from: from || null,
      to: to || null
    })
    setAnchorEl(null)
  }

  const fromDate = getDateFromDateString(state.from)
  const toDate = getDateFromDateString(state.to)

  const toDateProps: any = {}
  const fromDateProps: any = {}

  if (fromDate) {
    toDateProps.minDate = fromDate
  } else {
    toDateProps.minDate = undefined
  }
  toDateProps.maxDate = moment()

  if (toDate) {
    fromDateProps.maxDate = toDate
  } else {
    fromDateProps.maxDate = moment()
  }

  const displayTextKey = getDisplayTextKey({ from, to })

  return (
    <>
      <TextField
        label={label}
        variant="standard"
        sx={{
          minWidth: 260
        }}
        margin="dense"
        InputProps={{
          readOnly: true,
          endAdornment: (
            <InputAdornment position="end">
              <DateRangeIcon />
            </InputAdornment>
          )
        }}
        InputLabelProps={{
          shrink: Boolean(anchorEl || displayTextKey)
        }}
        value={displayTextKey}
        onClick={handleClick}
        {...textFieldProps}
      />
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
      >
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <Stack direction="row" spacing={3} mt={2}>
            <Box>
              <FormLabel sx={{ ml: 3 }}>{fromDateText}</FormLabel>
              <CalendarPicker
                disableHighlightToday
                date={fromDate}
                onChange={handleDateChange('from')}
                {...fromDateProps}
              />
            </Box>
            <Box>
              <FormLabel sx={{ ml: 3 }}>{toDateText}</FormLabel>
              <CalendarPicker
                disableHighlightToday
                date={toDate}
                onChange={handleDateChange('to')}
                {...toDateProps}
              />
            </Box>
          </Stack>
        </LocalizationProvider>
        <Stack
          direction="row"
          spacing={4}
          alignItems="center"
          pl={3}
          pb={2}
          pr={2}
        >
          {Boolean(allowBeforeDays?.length) && (
            <Stack direction="row" spacing={2}>
              {allowBeforeDays &&
                allowBeforeDays.map(beforeDay => {
                  return (
                    <Button
                      key={beforeDay.key}
                      size="small"
                      sx={{
                        textTransform: 'none'
                      }}
                      variant="outlined"
                      onClick={handleQuickSelection(beforeDay)}
                    >
                      {beforeDay.label}
                    </Button>
                  )
                })}
            </Stack>
          )}

          <Box flexGrow={1} />
          <Stack direction="row" spacing={2}>
            <Button
              size="small"
              disabled={!(state.from || state.to)}
              onClick={handleClear}
            >
              {clearText}
            </Button>
            <Button
              size="small"
              disabled={!(state.from || state.to)}
              variant="contained"
              onClick={handleApply}
            >
              {applyText}
            </Button>
          </Stack>
        </Stack>
      </Popover>
    </>
  )
}

export default DateRangeFilter
