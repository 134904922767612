import React, { useEffect, useRef, useState } from 'react'
import SaveIcon from '@mui/icons-material/Save'
import { FormattedMessage } from 'react-intl'
import { Button, Grid, Stack, MenuItem } from '@mui/material'
import pick from 'lodash/pick'
import { useSnackbar } from '../../utils/snackBar'
import { startTransaction } from '../../utils/apm'
import { messageKeys as globalMessageKeys } from '../../globalMessages'
import Section from '../../components/Section'
import ValidationTextField from '../../components/Fields/ValidationTextField'
import ValidationPhoneField from '../../components/Fields/ValidationPhoneField'
import { SUPPORTED_LANGUAGES } from '../../../supportedLanguages'
import { messageKeys } from './messages'
import Analytics from '../../utils/analytics'
import { USER_PROFILE_SAVE } from '../../utils/analytics/events'

const UserDetailsPage = props => {
  const { showSuccessSnackbar, showErrorSnackbar } = useSnackbar(
    <FormattedMessage {...messageKeys.Title} />
  )
  const [hasChanges, setHasChanges] = useState(false)
  const [data, setData] = useState({})
  const [errors, setErrors] = useState({})
  const prevData = useRef({})

  const { updateUser } = props

  useEffect(() => {
    if (props.user) {
      const nextData = pick(props.user.toJSON(), [
        'email',
        'firstName',
        'lastName',
        'phoneNumber',
        'roleInCompany',
        'contactLanguage'
      ])
      prevData.current = {
        ...nextData
      }
      setData(nextData)
      setErrors({})
      setHasChanges(false)
    }
  }, [props.user]) //eslint-disable-line

  const allMandatoryFieldsAreFilled = () => {
    let allGood = true
    Object.keys(errors).forEach(field => {
      const validationFailReason = errors[field]
      if (
        prevData.current &&
        prevData.current[field] &&
        validationFailReason === 'required'
      ) {
        allGood = false
      }
    })

    return allGood
  }

  const allFilledFieldsAreValid = () => {
    let allGood = true
    Object.keys(errors).forEach(field => {
      const validationFailReason = errors[field]
      if (validationFailReason && validationFailReason !== 'required') {
        allGood = false
      }
    })
    return allGood
  }

  const onSubmit = () => {
    Analytics.trackEvent(USER_PROFILE_SAVE)
    const transaction = startTransaction('UserDetailsPage#Save')
    const userData = props.user.toJSON()
    updateUser(
      {
        ...userData,
        ...data
      },
      () => {
        showSuccessSnackbar()
        setHasChanges(false)
        transaction.end()
      },
      error => {
        showErrorSnackbar(error.text)
        transaction.end()
      }
    )
  }

  const handleTextFieldChange = (e, errorReason) => {
    const { name, value } = e.target
    setData({
      ...data,
      [name]: value
    })
    setErrors({
      ...errors,
      [name]: errorReason
    })
    setHasChanges(true)
  }

  const disabledSave =
    !hasChanges || !(allMandatoryFieldsAreFilled() && allFilledFieldsAreValid())

  return (
    <Stack spacing={4}>
      <Section>
        <Grid spacing={4} container>
          <Grid xs={12} sm={6} item>
            <ValidationTextField
              name="lastName"
              error={errors.lastName}
              label={<FormattedMessage {...messageKeys.Surname} />}
              value={data.lastName || ''}
              fullWidth
              required
              onChange={handleTextFieldChange}
            />
          </Grid>
          <Grid xs={12} sm={6} item>
            <ValidationTextField
              name="firstName"
              error={errors.firstName}
              label={<FormattedMessage {...messageKeys.Name} />}
              value={data.firstName || ''}
              fullWidth
              required
              onChange={handleTextFieldChange}
            />
          </Grid>
          <Grid xs={12} item>
            <ValidationPhoneField
              name="phoneNumber"
              error={errors.phoneNumber}
              label={<FormattedMessage {...messageKeys.Phone} />}
              value={data.phoneNumber || ''}
              fullWidth
              required
              onChange={handleTextFieldChange}
            />
          </Grid>
          <Grid xs={12} item>
            <ValidationTextField
              name="contactLanguage"
              error={errors.contactLanguage}
              label={<FormattedMessage {...messageKeys.ContactLanguage} />}
              value={data.contactLanguage || ''}
              fullWidth
              required
              select
              onChange={handleTextFieldChange}
            >
              <MenuItem value="" disabled>
                <FormattedMessage {...globalMessageKeys.Select} />
              </MenuItem>
              {SUPPORTED_LANGUAGES.map(lang => {
                return (
                  <MenuItem value={lang.flagCountryCode} key={lang.value}>
                    {lang.label}
                  </MenuItem>
                )
              })}
            </ValidationTextField>
          </Grid>
          <Grid xs={12} item>
            <ValidationTextField
              name="roleInCompany"
              error={errors.roleInCompany}
              label={<FormattedMessage {...messageKeys.RoleInCompany} />}
              value={data.roleInCompany || ''}
              fullWidth
              required
              select
              onChange={handleTextFieldChange}
            >
              <MenuItem value="" disabled>
                <FormattedMessage {...globalMessageKeys.Select} />
              </MenuItem>
              <MenuItem value="DEV">
                <FormattedMessage {...globalMessageKeys.Developer} />
              </MenuItem>
              <MenuItem value="BD">
                <FormattedMessage {...globalMessageKeys.BizDevOrTam} />
              </MenuItem>
              <MenuItem value="PM">
                <FormattedMessage {...globalMessageKeys.PM} />
              </MenuItem>
              <MenuItem value="OWNER">
                <FormattedMessage {...globalMessageKeys.OWNER} />
              </MenuItem>
            </ValidationTextField>
          </Grid>
        </Grid>
      </Section>
      <Button
        color="primary"
        disabled={disabledSave}
        startIcon={<SaveIcon />}
        variant="contained"
        fullWidth
        onClick={onSubmit}
      >
        <FormattedMessage {...globalMessageKeys.SaveButton} />
      </Button>
    </Stack>
  )
}

export default UserDetailsPage
