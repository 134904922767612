import { Map } from 'immutable'
import env from '../../env'
import { currentUserIdToken } from '../../utils/awsCognitoClient'
import sanitizeData from '../../utils/sanitizeData'

export const updateCompanyRequest = payload => {
  return {
    url: `${env.BACKEND}/provider/company?provider=${payload.selectedProvider}`,
    body: sanitizeData(payload),
    transform: response => ({
      companies: response
    }),
    update: {
      companies: (prev, next) => prev.set(payload.selectedProvider, next)
    },
    options: {
      method: 'PUT',
      headers: {
        Authorization: 'Bearer ' + currentUserIdToken()
      }
    }
  }
}

export const getCompanyRequest = props => {
  return {
    url: `${env.BACKEND}/provider/company?provider=${props.selectedProvider}`,
    transform: response => ({
      companies: response
    }),
    update: {
      companies: (prev, next) => {
        if (!prev) {
          return Map({ [props.selectedProvider]: next })
        } else {
          return prev.set(props.selectedProvider, next)
        }
      }
    },
    options: {
      headers: {
        Authorization: 'Bearer ' + currentUserIdToken()
      }
    }
  }
}
