import { mutateAsync } from 'redux-query-immutable'
import { updateBookingConfigurationDetailsRequest } from './bookingDetailsRequest'

export const updateBookingConfigurationDetails = (
  data,
  onSuccess = () => {},
  onError = () => {}
) => {
  return dispatch => {
    return dispatch(
      mutateAsync(updateBookingConfigurationDetailsRequest(data))
    ).then(response => {
      if (response.status === 200) {
        onSuccess(response)
      } else {
        onError()
      }
    })
  }
}
