import React from 'react'
import { connect } from 'react-redux'
import { IntlProvider } from 'react-intl'

export function LanguageProvider(props) {
  return (
    <IntlProvider
      key={props.locale}
      locale={props.locale}
      messages={props.messages[props.locale]}
    >
      {React.Children.only(props.children)}
    </IntlProvider>
  )
}

const mapStateToProps = state => {
  return {
    locale: state.get('locale')
  }
}

export default connect(mapStateToProps)(LanguageProvider)
