import {
  AVERAGE_COLOR,
  AVERAGE_COLOR_LIGHT,
  BAD_COLOR,
  BAD_COLOR_LIGHT,
  GOOD_COLOR,
  GOOD_COLOR_LIGHT
} from './colors'

const LOWER_THRESHOLD = 30
const UPPER_THRESHOLD = 70

export const getScoreColor = (value: number) => {
  if (typeof value === 'number') {
    if (value > UPPER_THRESHOLD) {
      return GOOD_COLOR
    } else if (value > LOWER_THRESHOLD) {
      return AVERAGE_COLOR
    }
  }
  return BAD_COLOR
}

export const getScoreColorLight = (value: number) => {
  if (typeof value === 'number') {
    if (value > UPPER_THRESHOLD) {
      return GOOD_COLOR_LIGHT
    } else if (value > LOWER_THRESHOLD) {
      return AVERAGE_COLOR_LIGHT
    }
  }
  return BAD_COLOR_LIGHT
}

export const getScoreText = (value: number) => {
  if (typeof value === 'number') {
    return value.toFixed(0) + '%'
  }
  return '0%'
}
