export const messageKeys = {
  Title: {
    id: 'InvoicingDetails:TITLE',
    defaultMessage: 'InvoicingDetails:TITLE'
  },
  Header: {
    id: 'InvoicingDetails:HEADER',
    defaultMessage: 'InvoicingDetails:HEADER'
  },
  SubHeader: {
    id: 'InvoicingDetails:SUB_HEADER',
    defaultMessage: 'InvoicingDetails:SUB_HEADER'
  },
  LegalName: {
    id: 'InvoicingDetails:LEGAL_NAME',
    defaultMessage: 'InvoicingDetails:LEGAL_NAME'
  },
  BillingAddress: {
    id: 'InvoicingDetails:BILLING_ADDRESS',
    defaultMessage: 'InvoicingDetails:BILLING_ADDRESS'
  },
  BillingAddressHouseNumber: {
    id: 'InvoicingDetails:BILLING_ADDRESS_HOUSE_NUMBER',
    defaultMessage: 'InvoicingDetails:BILLING_ADDRESS_HOUSE_NUMBER'
  },
  BillingAddressZip: {
    id: 'InvoicingDetails:BILLING_ADDRESS_ZIP',
    defaultMessage: 'InvoicingDetails:BILLING_ADDRESS_ZIP'
  },
  BillingAddressCity: {
    id: 'InvoicingDetails:BILLING_ADDRESS_CITY',
    defaultMessage: 'InvoicingDetails:BILLING_ADDRESS_CITY'
  },
  BillingAddressCountry: {
    id: 'InvoicingDetails:BILLING_ADDRESS_COUNTRY',
    defaultMessage: 'InvoicingDetails:BILLING_ADDRESS_COUNTRY'
  },
  BillingNumberType: {
    id: 'InvoicingDetails:BILLING_NUMBER_TYPE',
    defaultMessage: 'InvoicingDetails:BILLING_NUMBER_TYPE'
  },
  BillingVatNumber: {
    id: 'InvoicingDetails:BILLING_VAT_NUMBER',
    defaultMessage: 'InvoicingDetails:BILLING_VAT_NUMBER'
  },
  InvoiceEmail: {
    id: 'InvoicingDetails:INVOICE_EMAIL',
    defaultMessage: 'InvoicingDetails:INVOICE_EMAIL'
  },
  IBAN: {
    id: 'InvoicingDetails:IBAN',
    defaultMessage: 'InvoicingDetails:IBAN'
  },
  BIC: {
    id: 'InvoicingDetails:BIC',
    defaultMessage: 'InvoicingDetails:BIC'
  },
  InvoiceRecipients: {
    id: 'InvoicingDetails:INVOICE_RECIPIENTS',
    defaultMessage: 'InvoicingDetails:INVOICE_RECIPIENTS'
  },
  InvoiceCopyRecipients: {
    id: 'InvoicingDetails:INVOICE_COPY_RECIPIENTS',
    defaultMessage: 'InvoicingDetails:INVOICE_COPY_RECIPIENTS'
  },
  InvoiceCopyRecipientsPlaceholder: {
    id: 'InvoicingDetails:INVOICE_COPY_RECIPIENTS_PLACEHOLDER',
    defaultMessage: 'InvoicingDetails:INVOICE_COPY_RECIPIENTS_PLACEHOLDER'
  },
  ContactLanguage: {
    id: 'InvoicingDetails:CONTACT_LANGUAGE',
    defaultMessage: 'InvoicingDetails:CONTACT_LANGUAGE'
  }
}
