import { useIntl } from 'react-intl'
import moment from 'moment'
import { messageKeys as globalMessageKeys } from '../../globalMessages'
import { CancellationFee, Fee } from './types'
import get from 'lodash/get'
import sortBy from 'lodash/sortBy'
import { DATE_FORMAT } from '../../utils/dateUtil'
import { CancellationPolicyProps } from '@holidu/pui-components/dist'
import { getAmountCurrencyText } from '../../utils/currencyUtil'

const getCancellationAmount = (fee: Fee) => {
  if (fee.type === 'PERCENTAGE') {
    const percentage = get(fee, ['percentage', 'percentage'], 0)
    if (percentage) {
      return `${percentage}%`
    }
  } else if (fee.type === 'FIXED_AMOUNT') {
    const value = get(fee, ['fixedAmount', 'value'], 0)
    if (value) {
      return getAmountCurrencyText(
        value,
        get(fee, ['fixedAmount', 'currency'], 'EUR')
      )
    }
  }
  return 0
}

const useCancellationPolicyProps = (
  bookingDate?: string,
  checkinDate?: string,
  cancellationFees?: CancellationFee[]
): CancellationPolicyProps | null => {
  const intl = useIntl()

  if (!bookingDate || !checkinDate || !cancellationFees) {
    return null
  }

  let data = cancellationFees.map((cFee, index: number) => {
    const daysBeforeArrival = moment(checkinDate).diff(
      moment(cFee.validUntil),
      'days'
    )
    const value = cFee.fee ? getCancellationAmount(cFee.fee) : 0

    return {
      daysBeforeArrival,
      value
    }
  })
  data = sortBy(data, ['daysBeforeArrival'])
  const cancellationPolicyProps: CancellationPolicyProps = {
    data,
    renderLabel: (daysBeforeArrival: number) => {
      if (daysBeforeArrival === 0) {
        return intl.formatMessage(globalMessageKeys.CHECK_IN)
      }
      const dateString = moment(checkinDate)
        .subtract(daysBeforeArrival, 'days')
        .format(DATE_FORMAT)
      return dateString
    },
    renderValue: value => value || intl.formatMessage(globalMessageKeys.FREE),
    width: 600
  }
  return cancellationPolicyProps
}
export default useCancellationPolicyProps
