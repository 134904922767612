import React from 'react'
import FilterListIcon from '@mui/icons-material/FilterList'
import { useIntl } from 'react-intl'
import { Badge, Button, ButtonProps } from '@mui/material'
import { messageKeys } from '../globalMessages'

type FilterButtonProps = {
  open: boolean
  showBadge?: boolean
} & ButtonProps

const FilterButton = (props: FilterButtonProps): JSX.Element => {
  const intl = useIntl()
  const { open, showBadge = false, ...restProps } = props

  return (
    <Button
      color="inherit"
      startIcon={
        <Badge color="secondary" variant="dot" invisible={!showBadge}>
          <FilterListIcon
            sx={{
              transform: open ? 'rotate(180deg)' : 'rotate(0deg)'
            }}
          />
        </Badge>
      }
      {...restProps}
    >
      {intl.formatMessage(
        open ? messageKeys.HIDE_FILTERS : messageKeys.SHOW_FILTERS
      )}
    </Button>
  )
}

export default FilterButton
