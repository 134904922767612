import { Typography } from '@mui/material'
import React from 'react'
import { useIntl } from 'react-intl'
import messageKeys from './messages'

type GuestValueProps = {
  numberOfGuests?: number | null
  numberOfAdults?: number | null
  numberOfChildren?: number | null
  numberOfBabies?: number | null
}

const GuestsValue = (props: GuestValueProps): JSX.Element => {
  const { numberOfGuests, numberOfAdults, numberOfChildren, numberOfBabies } =
    props
  const intl = useIntl()

  const subtextTokens = []
  let subtext = ''
  if (numberOfAdults) {
    subtextTokens.push(
      `${intl.formatMessage(messageKeys.Adults)}: ${numberOfAdults}`
    )
  }
  if (numberOfChildren) {
    subtextTokens.push(
      `${intl.formatMessage(messageKeys.Children)}: ${numberOfChildren}`
    )
  }
  if (numberOfBabies) {
    subtextTokens.push(
      `${intl.formatMessage(messageKeys.Babies)}: ${numberOfBabies}`
    )
  }
  if (subtextTokens.length) {
    subtext = `(${subtextTokens.join(', ')})`
  }
  return (
    <Typography component="span">{`${numberOfGuests} ${subtext}`}</Typography>
  )
}

export default GuestsValue
