import React from 'react'
import { Box, Grid, Paper, Stack, Typography } from '@mui/material'
import { useIntl } from 'react-intl'
import messageKeys from '../messages'
import { default as properiesMessageKeys } from '../../Properties/messages'

import PropertyImage from './PropertyImage'
import Price from '../../Properties/components/Price'
import PhotoTags from './PhotoTags'
import { PropertyDetail } from '../types'
import Analytics from '../../../utils/analytics'
import { APARTMENT_DETAIL_CLICK_HOLIDU_SCORE_LEARN_MORE } from '../../../utils/analytics/events'
import CircularScore from '../../../components/Score/CicularScore'
import LearnMoreLink from '../../../components/LearnMoreLink'
import { LabelValue } from '@holidu/pui-components/dist'
import CancellationPolicy from './CancellationPolicy'

const LEARN_MORE_LINK =
  'https://holidu4.zendesk.com/hc/en-us/articles/8979193621533'

type ApartmentSectionProps = {
  data: PropertyDetail
}

const getTitle = (data: PropertyDetail) => {
  if (data.title) {
    return data.title
  }
  let str = ''
  if (data.apartmentType) {
    str += `${data.apartmentType}`
  }
  if (data.persons) {
    str += ` for ${data.persons} persons`
  }
  return str
}

const ApartmentSection = (props: ApartmentSectionProps): JSX.Element => {
  const { data } = props
  const intl = useIntl()

  const handleLearnMoreClick = () => {
    Analytics.trackEvent(APARTMENT_DETAIL_CLICK_HOLIDU_SCORE_LEARN_MORE)
  }

  return (
    <Paper
      variant="outlined"
      sx={{
        display: 'flex'
      }}
    >
      <Grid container spacing={4}>
        <Grid item xs={12} lg={4}>
          <PropertyImage
            alt="apartment pic"
            src={data.thumbnailUrl || '/static/img/empty.jpg'}
          />
        </Grid>
        <Grid item xs={12} lg={8}>
          <Grid container p={2} spacing={2}>
            <Grid item xs={12} lg={8} container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h6">{getTitle(data)}</Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <LabelValue
                  label={intl.formatMessage(properiesMessageKeys.ApartmentId)}
                  value={data.externalId}
                  valueProps={{
                    noWrap: true
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <LabelValue
                  label={intl.formatMessage(
                    properiesMessageKeys.ApartmentHoliduId
                  )}
                  value={data.apartmentId}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <LabelValue
                  label={intl.formatMessage(properiesMessageKeys.ApartmentSize)}
                  value={`${data.apartmentSize || 0} sqmt`}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <LabelValue
                  label={intl.formatMessage(properiesMessageKeys.ApartmentType)}
                  value={data.apartmentType}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <LabelValue
                  label={intl.formatMessage(
                    properiesMessageKeys.ApartmentPersons
                  )}
                  value={data.persons}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <LabelValue
                  label={intl.formatMessage(
                    properiesMessageKeys.ApartmentPrice
                  )}
                  value={<Price value={data.referencePrice} />}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} lg={4}>
              <Stack alignItems="center" justifyContent="center" spacing={2}>
                <CircularScore
                  label={intl.formatMessage(messageKeys.APARTMENT_HOLIDU_SCORE)}
                  value={data.holiduScore || 0}
                  thickness={4}
                  size={200}
                  labelProps={{
                    sx: {
                      width: 140
                    }
                  }}
                />
                <LearnMoreLink
                  href={LEARN_MORE_LINK}
                  onClick={handleLearnMoreClick}
                />
              </Stack>
            </Grid>
            {Boolean(data.mainPhotoTags?.length) && (
              <Grid item xs={12}>
                <PhotoTags values={data.mainPhotoTags} />
              </Grid>
            )}
            <Grid item xs={12}>
              <Box mt={2}>
                <LabelValue
                  label={intl.formatMessage(messageKeys.CANCELLATION_POLICY)}
                  value={
                    <CancellationPolicy
                      provider={data.provider}
                      apartmentId={data.apartmentId}
                    />
                  }
                />
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  )
}

export default ApartmentSection
