import axios from 'axios'
import { useQuery } from '@tanstack/react-query'
import { ProviderConfiguration } from './types/ProviderConfiguration'
import QueryKeys from '../utils/queryKeys'

const useGetProviderConfiguration = (provider: string | null) => {
  return useQuery<any, Error, ProviderConfiguration>(
    [QueryKeys.ProviderConfiguration, { query: { provider } }],
    async () => {
      const { data } = await axios.get(
        `/provider/configuration?provider=${provider}`
      )
      return data
    },
    {
      keepPreviousData: false,
      retry: false,
      staleTime: 5 * (60 * 1000), // 5 mins
      cacheTime: 10 * (60 * 1000), // 10 mins
      enabled: Boolean(provider)
    }
  )
}

export default useGetProviderConfiguration
