import React, { useState } from 'react'
import {
  Button,
  TextField,
  Typography,
  Alert,
  Stack,
  TextFieldProps
} from '@mui/material'
import { useIntl } from 'react-intl'
import { resetPassword, signIn } from '../../utils/awsCognitoClient'
import { PATH_DASHBOARD } from '../../routes/PathConstants'
import { messageKeys } from './messages'
import { useHistory, useParams } from 'react-router'

const CommonTextFieldProps: TextFieldProps = {
  margin: 'dense',
  size: 'small',
  fullWidth: true,
  required: true
}

type ParamTypes = {
  email: string
}

const ChangePasswordPage = () => {
  const intl = useIntl()
  const history = useHistory()
  let { email } = useParams<ParamTypes>()

  const [state, setState] = useState({
    verificationCode: '',
    password1: '',
    password2: '',
    error: ''
  })

  const onClickReset = async () => {
    const { password1, password2, verificationCode } = state
    if (password1 !== password2) {
      setState({
        ...state,
        error: intl.formatMessage(messageKeys.PasswordsDontMatch)
      })
      return
    }
    try {
      await resetPassword(email, verificationCode, password1)
      await signIn(email, password1)
      history.replace(PATH_DASHBOARD)
    } catch (err) {
      setState({
        ...state,
        error: (err as any)?.message as string
      })
    }
  }

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target
    setState({
      ...state,
      error: '',
      [name]: value
    })
  }

  const { error, password1, password2, verificationCode } = state

  return (
    <form>
      <Stack spacing={2} alignItems="center">
        <Typography variant="h6">
          {intl.formatMessage(messageKeys.ChangePassHeader)}
        </Typography>
        <TextField
          name="password1"
          label={intl.formatMessage(messageKeys.NewPassword)}
          type="password"
          value={password1}
          onChange={onChange}
          {...CommonTextFieldProps}
        />
        <TextField
          name="password2"
          label={intl.formatMessage(messageKeys.RepeatPassword)}
          type="password"
          value={password2}
          onChange={onChange}
          {...CommonTextFieldProps}
        />
        <TextField
          name="verificationCode"
          label="Verification Code"
          value={verificationCode}
          onChange={onChange}
          {...CommonTextFieldProps}
        />
        {error ? <Alert severity="error">{error}</Alert> : null}

        <Button
          color="primary"
          variant="contained"
          fullWidth
          onClick={onClickReset}
        >
          {intl.formatMessage(messageKeys.ChangePassButton)}
        </Button>
      </Stack>
    </form>
  )
}

export default ChangePasswordPage
