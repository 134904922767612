import { PAID, SCHEDULED } from './constants'

export const messageKeys = {
  Title: {
    id: 'Payouts:TITLE',
    defaultMessage: 'Payouts:TITLE'
  },
  PAYOUT_ID: {
    id: 'Payouts:PAYOUT_ID',
    defaultMessage: 'Payouts:PAYOUT_ID'
  },
  [`PAYOUT_TAB_${PAID}`]: {
    id: 'Payouts:PAID',
    defaultMessage: 'Payouts:PAID'
  },
  [`PAYOUT_TAB_${SCHEDULED}`]: {
    id: 'Payouts:SCHEDULED',
    defaultMessage: 'Payouts:SCHEDULED'
  },
  BOOKINGS: {
    id: 'Payouts:BOOKINGS',
    defaultMessage: 'Payouts:BOOKINGS'
  },
  AMOUNT: {
    id: 'Payouts:AMOUNT',
    defaultMessage: 'Payouts:AMOUNT'
  },
  DATE: {
    id: 'Payouts:DATE',
    defaultMessage: 'Payouts:DATE'
  },
  EXPECTED_DATE: {
    id: 'Payouts:EXPECTED_DATE',
    defaultMessage: 'Payouts:EXPECTED_DATE'
  },
  STATUS: {
    id: 'Payouts:STATUS',
    defaultMessage: 'Payouts:STATUS'
  },
  BLOCKED: {
    id: 'Payouts:BLOCKED',
    defaultMessage: 'Payouts:BLOCKED'
  },
  DOWNLOAD_CSV: {
    id: 'Payouts:DOWNLOAD_CSV',
    defaultMessage: 'Payouts:DOWNLOAD_CSV'
  },
  NO_DATA_AVAILABLE: {
    id: 'Payouts:NO_DATA_AVAILABLE',
    defaultMessage: 'Payouts:NO_DATA_AVAILABLE'
  },
  SHOW_ALL_BOOKINGS: {
    id: 'Payouts:SHOW_ALL_BOOKINGS',
    defaultMessage: 'Payouts:SHOW_ALL_BOOKINGS'
  },
  HIDE_ALL_BOOKINGS: {
    id: 'Payouts:HIDE_ALL_BOOKINGS',
    defaultMessage: 'Payouts:HIDE_ALL_BOOKINGS'
  },
  ALL_BOOKINGS: {
    id: 'Payouts:ALL_BOOKINGS',
    defaultMessage: 'Payouts:ALL_BOOKINGS'
  },
  COUNT: {
    id: 'Payouts:COUNT',
    defaultMessage: 'Payouts:COUNT'
  },
  TOTAL_AMOUNT: {
    id: 'Payouts:TOTAL_AMOUNT',
    defaultMessage: 'Payouts:TOTAL_AMOUNT'
  },
  FROM_DATE: {
    id: 'Payouts:FROM_DATE',
    defaultMessage: 'Payouts:FROM_DATE'
  },
  UNTIL_DATE: {
    id: 'Payouts:UNTIL_DATE',
    defaultMessage: 'Payouts:UNTIL_DATE'
  },
  BOOKING_ID: {
    id: 'Payouts:BOOKING_ID',
    defaultMessage: 'Payouts:BOOKING_ID'
  }
}
