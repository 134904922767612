import { useEffect, useRef } from 'react'
import { useHistory, useLocation } from 'react-router'
import get from 'lodash/get'
import Analytics from '.'
import {
  MARK_INTIAL_FETCHES_END,
  MARK_PAGE_VIEW_START,
  MEASURE_INITIAL_PAGE_LOAD
} from './constants'
import { useIsFetching } from '@tanstack/react-query'
import { Location } from 'history'

const usePageView = (excludedQueryKeys: string[] = []) => {
  const history = useHistory()
  const location = useLocation()
  const prevPathname = useRef<string>('')
  const currentPathname = useRef<string>('')

  const prevFetchCountRef = useRef<number>(0)
  const fetchCount = useIsFetching({
    predicate: query => !excludedQueryKeys.includes(query.queryKey[0] as string)
  })
  const renderedRef = useRef<boolean>(false)

  const handleLocation = (location: Location) => {
    performance.mark(MARK_PAGE_VIEW_START)
    prevPathname.current = currentPathname.current
    const nextPathName = get(location, 'pathname', window.location.pathname)
    currentPathname.current = nextPathName
    renderedRef.current = false
    prevFetchCountRef.current = 0
  }

  useEffect(() => {
    handleLocation(location)
    let unlisten = history.listen(handleLocation)
    return unlisten
  }, []) // eslint-disable-line

  useEffect(() => {
    if (!renderedRef.current) {
      if (prevFetchCountRef.current && !fetchCount) {
        // All initial fetches done
        performance.mark(MARK_INTIAL_FETCHES_END)
        performance.measure(
          MEASURE_INITIAL_PAGE_LOAD,
          MARK_PAGE_VIEW_START,
          MARK_INTIAL_FETCHES_END
        )
        performance.clearMarks(MARK_PAGE_VIEW_START)
        performance.clearMarks(MARK_INTIAL_FETCHES_END)
        const measurement = performance.getEntriesByName(
          MEASURE_INITIAL_PAGE_LOAD
        )
        const duration = get(measurement, '0.duration')
        Analytics.pageView(
          currentPathname.current,
          duration,
          prevPathname.current
        )
        performance.clearMeasures(MEASURE_INITIAL_PAGE_LOAD)

        renderedRef.current = true
      }
      prevFetchCountRef.current = fetchCount
    }
  }, [fetchCount]) // eslint-disable-line

  return null
}

export default usePageView
