import React, { useEffect, useMemo, useState } from 'react'
import { Link as RouterLink, useLocation } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'
import {
  Typography,
  Box,
  Button,
  ListItem,
  ListItemIcon,
  ListItemText,
  Collapse,
  List,
  ListItemButton,
  Stack,
  Link,
  Grid
} from '@mui/material'
import { ExpandLess, ExpandMore } from '@mui/icons-material'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import { useRequest } from 'redux-query-immutable-react'
import { useSelector } from 'react-redux'

import queryString from 'query-string'

import { messageKeys } from '../messages'
import { messageKeys as integrationDetailsKeys } from '../../IntegrationBasics/messages'
import { messageKeys as billingDetailsKeys } from '../../BillingInformation/messages'
import { messageKeys as customerServiceDetailsKeys } from '../../CustomerService/messages'
import { messageKeys as customerPaymentKeys } from '../../CustomerPayments/messages'
import { messageKeys as cancellationPolicyKeys } from '../../CancellationPolicy/messages'
import { messageKeys as commercialAgreementKeys } from '../../CommercialAgreement/messages'

import InformationMissingError from '../../../components/InformationMissingError'
import { getBizDevsRequest } from '../../../store/bizDev/requests'
import { getBizDevsSelector } from '../../../store/bizDev/selectors'
import Section from '../../../components/Section'
import SubSection from '../../../components/Section/SubSection'
import {
  getMissingFieldsForBillingInformation,
  getMissingFieldsForCancellationPolicy,
  getMissingFieldsForCommercialAgreement,
  getMissingFieldsForCustomerPaymentOptions,
  getMissingFieldsForCustomerService,
  getMissingFieldsForIntegrationBasics
} from './RequiredFieldsFetcher'
import OnboardingGuideDialog from './OnboardingGuideDialog'
import OnboardingStep, { OnbordingStepStatus } from './OnboardingStep'
import { OnboardingContents } from './utils'
import ApartmentStats from './ApartmentStats'
import useGetProviderStartingChecklist from './useGetProviderStartingChecklist'
import { getContactLink } from '../../../utils/support'
import Analytics from '../../../utils/analytics'
import {
  DASHBOARD_CLICK_CONTACT_ME,
  DASHBOARD_CLICK_MISSING_FIELD
} from '../../../utils/analytics/events'
import { Map } from 'immutable'

const IntegrationChannel = ({ channelManagerConfigs, provider }) => {
  const channelManagerIdentifier = provider && provider.get('channelManager')
  if (channelManagerIdentifier) {
    const channelManager = channelManagerConfigs.find(
      cM => cM.get('identifier') === channelManagerIdentifier
    )
    if (channelManager) {
      const nameToDisplay =
        channelManager.get('name') || channelManagerIdentifier
      return (
        <Stack direction="row" spacing={1} alignItems="center">
          <Box display="flex" alignItems="cneter" minHeight={48} minWidth={160}>
            <img
              style={{
                maxHeight: '48px',
                maxWidth: '160px'
              }}
              alt={nameToDisplay}
              src={channelManager.get('logoPath') || '/static/img/empty.jpg'}
            />
          </Box>
          <Typography variant="body1">{nameToDisplay}</Typography>
        </Stack>
      )
    }
    return <Typography variant="body1">{channelManagerIdentifier}</Typography>
  } else if (provider && provider.get('isApi')) {
    return (
      <Typography variant="body1">
        <FormattedMessage {...messageKeys.integrationChannelApi} />
      </Typography>
    )
  }
  return (
    <Typography variant="body1">
      <FormattedMessage {...messageKeys.integrationChannelHoliduWillContact} />
    </Typography>
  )
}

const KYC_ONBOARDING_STATUS = {
  OPEN: OnbordingStepStatus.ACTION_REQUIRED,
  APPROVED: OnbordingStepStatus.GOOD_TO_GO,
  CLOSED: OnbordingStepStatus.ACTION_REQUIRED
}

const OnboardingPage = props => {
  const {
    channelManagerConfigs,
    companies,
    providers,
    providersConfig,
    kycStatus,
    selectedProvider,
    providersFrontendConfig
  } = props

  const provider = providers.get(selectedProvider)
  const providerConfig = providersConfig.get(selectedProvider)
  const company = companies.get(selectedProvider)
  const providerStatus = provider && provider.get('status')
  const [listState, setListState] = useState({
    integrationBasics: false,
    customerService: false,
    customerPayment: false,
    cancellationPolicy: false,
    billingInformation: false,
    commercialAgreement: false
  })

  const location = useLocation()
  const locationSearch = location.search
  const query = useMemo(
    () => queryString.parse(locationSearch, { parseBooleans: true }),
    [locationSearch]
  )
  const { guide } = query
  const [guideOpen, setGuideOpen] = useState(false)

  useEffect(() => {
    setGuideOpen(guide)
  }, [guide])

  const { data: providerStartingChecklist } = useGetProviderStartingChecklist(
    selectedProvider,
    providerStatus
  )

  const ibFields = getMissingFieldsForIntegrationBasics(
    provider,
    providerConfig
  )
  const csFields = getMissingFieldsForCustomerService(provider, providerConfig)
  const poFields = getMissingFieldsForCustomerPaymentOptions(providerConfig)
  const cpFields = getMissingFieldsForCancellationPolicy(providerConfig)
  const biFields = getMissingFieldsForBillingInformation(company)

  const includeCaFields = providersFrontendConfig
    .get(props.selectedProvider, Map({}))
    .get('showCommercialAgreementTab')
  const caFields = includeCaFields
    ? getMissingFieldsForCommercialAgreement(company, provider)
    : []

  const contractOrCommercialAgreementSigned =
    (!includeCaFields && providerStartingChecklist?.hasSignedContracts) ||
    (includeCaFields &&
      provider?.get('commercialAgreementSignature')?.get('signed'))

  useRequest(getBizDevsRequest())
  const { bizDevs } = useSelector(getBizDevsSelector)

  const bizDevContact = provider && provider.get('buzDevResponsible')
  const bizDev = useMemo(() => {
    if (bizDevContact) {
      const foundBizDev = bizDevs.find(b => b.get('email') === bizDevContact)
      if (foundBizDev && foundBizDev.size) {
        return foundBizDev
      }
    }
    return bizDevs.find(b => b.get('isDefault'))
  }, [bizDevContact, bizDevs])

  const handleGuideClose = () => {
    setGuideOpen(false)
  }

  return (
    <>
      <Box mt={1}>
        {providerStatus === 'NEW' &&
          (ibFields.length ||
          csFields.length ||
          poFields.length ||
          cpFields.length ||
          biFields.length ||
          caFields.length ? (
            <InformationMissingError />
          ) : null)}
        <Grid container spacing={2}>
          {/* Getting Started */}
          <Grid item xs={12} lg={12} xl={12}>
            {providerStatus === 'NEW' ? (
              <Section
                title={<FormattedMessage {...messageKeys.Header} />}
                subtitle={<FormattedMessage {...messageKeys.SubHeader} />}
              >
                <List dense disablePadding>
                  <OnboardingStep
                    label={
                      Boolean(includeCaFields) ? (
                        <FormattedMessage
                          {...OnboardingContents.SIGN_COMMERCIAL_AGREEMENT
                            .label}
                        />
                      ) : (
                        <FormattedMessage {...OnboardingContents.SIGN.label} />
                      )
                    }
                    desc={
                      Boolean(includeCaFields) ? (
                        <FormattedMessage
                          {...OnboardingContents.SIGN_COMMERCIAL_AGREEMENT.desc}
                          values={{
                            link: (
                              <Link
                                component={RouterLink}
                                to={'/configuration/commercialAgreement'}
                              >
                                <FormattedMessage
                                  {...commercialAgreementKeys.CommercialAgreement}
                                />
                              </Link>
                            )
                          }}
                        />
                      ) : (
                        <FormattedMessage {...OnboardingContents.SIGN.desc} />
                      )
                    }
                    status={
                      contractOrCommercialAgreementSigned
                        ? OnbordingStepStatus.GOOD_TO_GO
                        : OnbordingStepStatus.ACTION_REQUIRED
                    }
                  />
                  <OnboardingStep
                    label={
                      <FormattedMessage
                        {...OnboardingContents.ENABLE_CM.label}
                      />
                    }
                    desc={
                      <FormattedMessage
                        {...OnboardingContents.ENABLE_CM.desc}
                      />
                    }
                    status={OnbordingStepStatus.UNKNOWN}
                  />
                  {Boolean(
                    company && company.get('holiduPaymentsEnabled') && kycStatus
                  ) && (
                    <OnboardingStep
                      label={
                        <FormattedMessage {...OnboardingContents.KYC.label} />
                      }
                      desc={
                        <FormattedMessage {...OnboardingContents.KYC.desc} />
                      }
                      status={
                        KYC_ONBOARDING_STATUS[
                          kycStatus.get('adyenOverallStatus')
                        ] || OnbordingStepStatus.ACTION_REQUIRED
                      }
                    >
                      {kycStatus?.adyenOverallStatus !== 'APPROVED' && (
                        <Link
                          sx={{ ml: 1 }}
                          component={RouterLink}
                          to="/financials/kycVerification"
                        >
                          <FormattedMessage
                            {...messageKeys.OnboardingStepHoliduPaymentLink}
                          />
                        </Link>
                      )}
                    </OnboardingStep>
                  )}
                  <OnboardingStep
                    label={
                      <FormattedMessage
                        {...OnboardingContents.CONFIGURATION.label}
                      />
                    }
                    desc={
                      <FormattedMessage
                        {...OnboardingContents.CONFIGURATION.desc}
                      />
                    }
                    status={
                      ibFields.length ||
                      csFields.length ||
                      poFields.length ||
                      cpFields.length ||
                      biFields.length ||
                      caFields.length
                        ? OnbordingStepStatus.ACTION_REQUIRED
                        : OnbordingStepStatus.GOOD_TO_GO
                    }
                  >
                    {Boolean(
                      ibFields.length ||
                        csFields.length ||
                        poFields.length ||
                        cpFields.length ||
                        biFields.length ||
                        caFields.length
                    ) && (
                      <List dense>
                        <OnboardingItem
                          expanded={listState.integrationBasics}
                          fields={ibFields}
                          incompleteRedirectLink="/configuration/integrationDetails"
                          label={
                            <FormattedMessage
                              {...integrationDetailsKeys.Title}
                            />
                          }
                          onClick={e =>
                            setListState({
                              ...listState,
                              integrationBasics: !listState.integrationBasics
                            })
                          }
                        />
                        <OnboardingItem
                          expanded={listState.customerService}
                          fields={csFields}
                          incompleteRedirectLink="/configuration/customerSerivce"
                          label={
                            <FormattedMessage
                              {...customerServiceDetailsKeys.Title}
                            />
                          }
                          onClick={e =>
                            setListState({
                              ...listState,
                              customerService: !listState.customerService
                            })
                          }
                        />
                        <OnboardingItem
                          expanded={listState.customerPayment}
                          fields={poFields}
                          incompleteRedirectLink="/configuration/customerPayment"
                          label={
                            <FormattedMessage {...customerPaymentKeys.Title} />
                          }
                          onClick={e =>
                            setListState({
                              ...listState,
                              customerPayment: !listState.customerPayment
                            })
                          }
                        />
                        <OnboardingItem
                          expanded={listState.cancellationPolicy}
                          fields={cpFields}
                          incompleteRedirectLink="/configuration/cancellationPolicy"
                          label={
                            <FormattedMessage
                              {...cancellationPolicyKeys.Title}
                            />
                          }
                          onClick={e =>
                            setListState({
                              ...listState,
                              cancellationPolicy: !listState.cancellationPolicy
                            })
                          }
                        />
                        <OnboardingItem
                          expanded={listState.billingInformation}
                          fields={biFields}
                          incompleteRedirectLink="/invoices/invoicingDetails"
                          label={
                            <FormattedMessage {...billingDetailsKeys.Title} />
                          }
                          onClick={e =>
                            setListState({
                              ...listState,
                              billingInformation: !listState.billingInformation
                            })
                          }
                        />
                        {Boolean(includeCaFields) && (
                          <OnboardingItem
                            expanded={listState.commercialAgreement}
                            fields={caFields}
                            incompleteRedirectLink="/configuration/commercialAgreement"
                            label={
                              <FormattedMessage
                                {...commercialAgreementKeys.CommercialAgreement}
                              />
                            }
                            onClick={e =>
                              setListState({
                                ...listState,
                                commercialAgreement:
                                  !listState.commercialAgreement
                              })
                            }
                          />
                        )}
                      </List>
                    )}
                  </OnboardingStep>
                  <OnboardingStep
                    label={
                      <FormattedMessage
                        {...OnboardingContents.TEST_BOOKING.label}
                      />
                    }
                    desc={
                      <FormattedMessage
                        {...OnboardingContents.TEST_BOOKING.desc}
                      />
                    }
                    status={OnbordingStepStatus.PENDING}
                  />
                </List>
              </Section>
            ) : (
              <ApartmentStats provider={selectedProvider} />
            )}
          </Grid>

          {/* Connection Details */}
          <Grid item xs={12} lg={12} xl={12}>
            <Section
              title={
                <FormattedMessage {...messageKeys.connectionDetailsHeading} />
              }
            >
              <Stack direction="column" spacing={4}>
                <SubSection
                  title={
                    <FormattedMessage
                      {...messageKeys.integrationChannelHeading}
                    />
                  }
                >
                  <IntegrationChannel
                    provider={provider}
                    channelManagerConfigs={channelManagerConfigs}
                  />
                </SubSection>
                <SubSection
                  title={
                    <FormattedMessage {...messageKeys.yourIdentifierHeading} />
                  }
                >
                  <Typography margintop="1rem" variant="body1">
                    {provider && provider.get('identifier')}
                  </Typography>
                </SubSection>

                {Boolean(provider && bizDev && bizDev.size) && (
                  <SubSection
                    title={
                      <FormattedMessage {...messageKeys.contactHoliduHeading} />
                    }
                  >
                    <Button
                      onClick={() =>
                        Analytics.trackEvent(DASHBOARD_CLICK_CONTACT_ME)
                      }
                      sx={{ whiteSpace: 'nowrap' }}
                      href={getContactLink(
                        selectedProvider,
                        bizDev.get('email')
                      )}
                      target="_blank"
                      color="primary"
                      variant="contained"
                    >
                      <FormattedMessage {...messageKeys.contactMe} />
                    </Button>
                  </SubSection>
                )}
              </Stack>
            </Section>
          </Grid>
        </Grid>
      </Box>
      <OnboardingGuideDialog
        showKycStep={Boolean(
          company && company.get('holiduPaymentsEnabled') && kycStatus
        )}
        open={guideOpen}
        onClose={handleGuideClose}
        showSignCommercialAgreement={includeCaFields}
      />
    </>
  )
}

const OnboardingItem = props => {
  const { expanded, fields, incompleteRedirectLink, label, onClick } = props

  const handleClick = () => {
    Analytics.trackEvent(DASHBOARD_CLICK_MISSING_FIELD)
  }

  return (
    <>
      <ListItemButton disableGutters onClick={onClick}>
        <ListItemIcon sx={{ minWidth: 40 }}>
          {fields.length === 0 ? null : expanded ? (
            <ExpandLess />
          ) : (
            <ExpandMore />
          )}
        </ListItemIcon>
        <ListItemText sx={{ flexGrow: 'initial', mr: 2 }} primary={label} />
        {fields.length === 0 ? (
          <CheckCircleOutlineIcon color="success" />
        ) : (
          <ErrorOutlineIcon color="error" />
        )}
      </ListItemButton>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <List component="div" disablePadding dense>
          {fields.map((field, index) => (
            <ListItem key={index}>
              <Link
                sx={{ ml: 5 }}
                component={RouterLink}
                onClick={() => handleClick}
                to={incompleteRedirectLink}
              >
                <FormattedMessage
                  {...messageKeys.FieldMissing}
                  values={{ field: field }}
                />
              </Link>
            </ListItem>
          ))}
        </List>
      </Collapse>
    </>
  )
}
export default OnboardingPage
