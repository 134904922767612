import React from 'react'
import MenuIcon from '@mui/icons-material/Menu'
import {
  AppBar,
  IconButton,
  Stack,
  Toolbar,
  useMediaQuery,
  useTheme,
  Box
} from '@mui/material'
import LanguageMenu from './LanguageMenu'
import ProviderSelectionMenu from './ProviderSelectionMenu'
import UserMenu from './UserMenu'
import ContactUs from './ContactUs'

const Header = props => {
  const { drawerWidth, onMenuButtonClick } = props
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <AppBar
      position="fixed"
      elevation={0}
      sx={{
        width: { lg: `calc(100% - ${drawerWidth}px)` },
        ml: { lg: `${drawerWidth}px` }
      }}
    >
      <Toolbar>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={onMenuButtonClick}
          sx={{ mr: 2, display: { lg: 'none' } }}
        >
          <MenuIcon />
        </IconButton>
        <Box flexGrow={1} />
        <Stack direction="row" alignItems="center" spacing={4}>
          <ContactUs />
          <ProviderSelectionMenu size={isMobile ? 'small' : 'medium'} />
          <Stack
            direction="row"
            spacing={2}
            alignItems="cneter"
            sx={{ display: { xs: 'none', lg: 'block' } }}
          >
            <LanguageMenu />
            <UserMenu />
          </Stack>
        </Stack>
      </Toolbar>
    </AppBar>
  )
}

export default Header
