import React from 'react'
import { Alert, AlertTitle, Typography } from '@mui/material'
import { useIntl } from 'react-intl'

import { messageKeys } from '../containers/Dashboard/messages'

const InformationMissingError = (): JSX.Element => {
  const intl = useIntl()
  return (
    <Alert variant="outlined" severity="error" sx={{ mb: 2 }}>
      <AlertTitle>
        {intl.formatMessage(messageKeys.InformationMissingError)}
      </AlertTitle>
      <Typography component="span" variant="subtitle2" color="error">
        {intl.formatMessage(messageKeys.InformationMissingTitle)}
      </Typography>
    </Alert>
  )
}

export default InformationMissingError
