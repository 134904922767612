import React from 'react'
import {
  Box,
  Checkbox,
  FormControlLabel,
  TextField,
  Typography
} from '@mui/material'
import { FormattedMessage } from 'react-intl'
import { messageKeys } from '../messages'

const GuestCategoriesBaby = props => {
  const { disableAgesFields, disableAgesRequiredFields, dispatch, errors } =
    props
  return (
    <>
      <Box display="flex" alignItems="center">
        <FormControlLabel
          data-ga-label="ALLOW_BABY"
          control={
            <Checkbox
              checked={props.babiesSupported}
              disabled={disableAgesFields}
              id="BABY"
              onChange={e =>
                dispatch({
                  type: 'toggleBabiesSupport',
                  payload: e.target.checked
                })
              }
            />
          }
          label={<FormattedMessage {...messageKeys.Baby} />}
        />
        <Typography variant="body2" sx={{ ml: 2 }}>
          <FormattedMessage {...messageKeys.BabyUpToAge} />
        </Typography>
        <TextField
          size="small"
          sx={{ ml: 2 }}
          disabled={disableAgesFields || !props.babiesSupported}
          error={!!errors.babiesUpToAge}
          id="adultAge"
          InputProps={{
            min: '1',
            max: '10',
            step: '1'
          }}
          required={props.babiesSupported}
          type="number"
          value={props.babiesUpToAge || ''}
          variant="outlined"
          onChange={e =>
            dispatch({
              type: 'field',
              field: 'babiesUpToAge',
              value: e.target.value
            })
          }
          helperText={errors.babiesUpToAge || ''}
        />
      </Box>
      <Box sx={{ pl: 10 }}>
        {/* Babies Age Requirement */}
        <FormControlLabel
          data-ga-label="REQUIRE_BABY_AGE"
          control={
            <Checkbox
              checked={props.babiesAgeRequired}
              disabled={disableAgesRequiredFields || !props.babiesSupported}
              id="BABY_AGE"
              onChange={e =>
                dispatch({
                  type: 'field',
                  field: 'babiesAgeRequired',
                  value: e.target.checked
                })
              }
            />
          }
          label={<FormattedMessage {...messageKeys.BabiesAgeRequired} />}
        />
        {/* Maximum allowed person */}
        <FormControlLabel
          data-ga-label="ALLOW_BABY_UNLIMITED"
          control={
            <Checkbox
              checked={props.babiesUnlimited}
              disabled={disableAgesFields || !props.babiesSupported}
              id="BABY_AGE"
              onChange={e =>
                dispatch({
                  type: 'field',
                  field: 'babiesUnlimited',
                  value: e.target.checked
                })
              }
            />
          }
          label={<FormattedMessage {...messageKeys.BabiesUnlimited} />}
        />
      </Box>
    </>
  )
}

export default GuestCategoriesBaby
