import React from 'react'
import {
  Box,
  Checkbox,
  FormControlLabel,
  TextField,
  Typography
} from '@mui/material'
import { FormattedMessage } from 'react-intl'
import { messageKeys } from '../messages'

const GuestCategoriesChild = props => {
  const { disableAgesFields, disableAgesRequiredFields, dispatch } = props

  return (
    <>
      <Box display="flex" alignItems="center">
        {/* Child Checkbox */}
        <FormControlLabel
          data-ga-label="ALLOW_CHILD"
          control={
            <Checkbox
              checked={props.kidsSupported}
              disabled={disableAgesFields}
              name="toggleChildSupport"
              inputProps={{
                'aria-label': 'primary checkbox'
              }}
              onChange={e =>
                dispatch({
                  type: 'toggleChildSupport',
                  payload: e.target.checked
                })
              }
            />
          }
          label={<FormattedMessage {...messageKeys.Child} />}
        />
        <Typography variant="body2" sx={{ ml: 2 }}>
          <FormattedMessage {...messageKeys.ChildUpToAge} />
        </Typography>
        {/* Child age input */}
        <TextField
          sx={{ ml: 2 }}
          disabled={disableAgesFields || !props.kidsSupported}
          error={!!props.errors.kidsUpToAge}
          id="kidsAge"
          name="kidsUpToAge"
          InputProps={{
            min: '1',
            max: '25',
            step: '1'
          }}
          required={props.kidsSupported}
          type="number"
          value={props.kidsUpToAge || ''}
          onChange={e =>
            dispatch({
              type: 'field',
              field: 'kidsUpToAge',
              value: e.target.value
            })
          }
          helperText={props.errors.kidsUpToAge || ''}
        />
      </Box>
      {/* Children age requirement */}
      <Box pl={10}>
        <FormControlLabel
          data-ga-label="REQUIRE_CHILD_AGE"
          control={
            <Checkbox
              checked={props.kidsAgeRequired}
              disabled={disableAgesRequiredFields || !props.kidsSupported}
              id="CHILD_AGE"
              name="kidsAgeRequired"
              onChange={e =>
                dispatch({
                  type: 'field',
                  field: 'kidsAgeRequired',
                  value: e.target.checked
                })
              }
            />
          }
          label={<FormattedMessage {...messageKeys.ChildAgeRequired} />}
        />
      </Box>
    </>
  )
}

export default GuestCategoriesChild
