export const messageKeys = {
  SaveButton: {
    id: 'Global:SAVE_BUTTON',
    defaultMessage: 'Global:SAVE_BUTTON'
  },
  SubmitButton: {
    id: 'Global:SUBMIT_BUTTON',
    defaultMessage: 'Global:SUBMIT_BUTTON'
  },
  CloseButton: {
    id: 'Global:CLOSE_BUTTON',
    defaultMessage: 'Global:CLOSE_BUTTON'
  },
  SHOW_FILTERS: {
    id: 'Global:SHOW_FILTERS',
    defaultMessage: 'Global:SHOW_FILTERS'
  },
  HIDE_FILTERS: {
    id: 'Global:HIDE_FILTERS',
    defaultMessage: 'Global:HIDE_FILTERS'
  },
  CLEAR_FILTERS: {
    id: 'Global:CLEAR_FILTERS',
    defaultMessage: 'Global:CLEAR_FILTERS'
  },
  CLEAR: {
    id: 'Global:CLEAR',
    defaultMessage: 'Global:CLEAR'
  },
  APPLY: {
    id: 'Global:APPLY',
    defaultMessage: 'Global:APPLY'
  },
  FROM_DATE: {
    id: 'Global:FROM_DATE',
    defaultMessage: 'Global:FROM_DATE'
  },
  TO_DATE: {
    id: 'Global:TO_DATE',
    defaultMessage: 'Global:TO_DATE'
  },
  TODAY: {
    id: 'Global:TODAY',
    defaultMessage: 'Global:TODAY'
  },
  N_DAYS: {
    id: 'Global:N_DAYS',
    defaultMessage: 'Global:{n}_DAYS'
  },
  CHECK_IN: {
    id: 'Global:CHECK_IN',
    defaultMessage: 'Global:CHECK_IN'
  },
  LAST_N_DAYS: {
    id: 'Global:LAST_N_DAYS',
    defaultMessage: 'Global:LAST_{n}_DAYS'
  },
  TOTAL_COUNT: {
    id: 'Global:TOTAL_COUNT',
    defaultMessage: 'Global:TOTAL_COUNT'
  },
  FREE: {
    id: 'Global:FREE',
    defaultMessage: 'Global:FREE'
  },
  ROWS_PER_PAGE: {
    id: 'Global:ROWS_PER_PAGE',
    defaultMessage: 'Global:ROWS_PER_PAGE'
  },
  Success: {
    id: 'Global:SUCCESS',
    defaultMessage: 'Global:SUCCESS {page}'
  },
  Error: {
    id: 'Global:ERROR',
    defaultMessage: 'Global:ERROR {error}'
  },
  Select: {
    id: 'Global:PLEASE_SELECT',
    defaultMessage: 'Global:PLEASE_SELECT'
  },
  Developer: {
    id: 'Global:DEVELOPER',
    defaultMessage: 'Global:DEVELOPER'
  },
  BizDevOrTam: {
    id: 'Global:BIZDEV_OR_TAM',
    defaultMessage: 'Global:BIZDEV_OR_TAM'
  },
  PM: {
    id: 'Global:PM',
    defaultMessage: 'Global:PM'
  },
  OWNER: {
    id: 'Global:OWNER',
    defaultMessage: 'Global:OWNER'
  },
  ErrorIsMandatory: {
    id: 'Global:FIELD_IS_MANDATORY',
    defaultMessage: 'Global:FIELD_IS_MANDATORY'
  },
  ErrorInvalidEmailId: {
    id: 'Global:INVALID_EMAIL_ID',
    defaultMessage: 'Global:INVALID_EMAIL_ID'
  },
  ErrorInvalidVat: {
    id: 'Global:INVALID_VAT',
    defaultMessage: 'Global:INVALID_VAT'
  },
  ErrorInvalidVatNumber: {
    id: 'Global:INVALID_VAT_NUMBER',
    defaultMessage: 'Global:INVALID_VAT_NUMBER'
  },
  ErrorInvalidVatLocation: {
    id: 'Global:INVALID_VAT_LOCATION',
    defaultMessage: 'Global:INVALID_VAT_LOCATION'
  },
  ErrorInvalidVatName: {
    id: 'Global:INVALID_VAT_NAME',
    defaultMessage: 'Global:INVALID_VAT_NAME'
  },
  ErrorInvalidZipCode: {
    id: 'Global:INVALID_ZIP_CODE',
    defaultMessage: 'Global:INVALID_ZIP_CODE'
  },
  ErrorInvalidIban: {
    id: 'Global:INVALID_IBAN',
    defaultMessage: 'Global:INVALID_IBAN'
  },
  ErrorInvalidBic: {
    id: 'Global:INVALID_BIC',
    defaultMessage: 'Global:INVALID_BIC'
  },
  ErrorDoNotDeleteMandatory: {
    id: 'Global:DO_NOT_DELETE_MANDATORY',
    defaultMessage: 'Global:DO_NOT_DELETE_MANDATORY'
  },
  ErrorInvalidDataInForm: {
    id: 'Global:INVALID_DATA_IN_FORM',
    defaultMessage: 'Global:INVALID_DATA_IN_FORM'
  },
  ConfirmSuccess: {
    id: 'UserConfirmKeys:SUCCESS',
    defaultMessage: 'UserConfirmKeys:SUCCESS'
  },
  SignOut: {
    id: 'Global:SIGN_OUT',
    defaultMessage: 'Global:SIGN_OUT'
  },
  Yes: {
    id: 'Global:YES',
    defaultMessage: 'Global:YES'
  },
  No: {
    id: 'Global:NO',
    defaultMessage: 'Global:NO'
  },
  Back: {
    id: 'Global:BACK',
    defaultMessage: 'Global:BACK'
  },
  Next: {
    id: 'Global:NEXT',
    defaultMessage: 'Global:NEXT'
  },
  Finish: {
    id: 'Global:FINISH',
    defaultMessage: 'Global:FINISH'
  },
  ForExample: {
    id: 'Global:FOR_EAMPLE',
    defaultMessage: 'Global:FOR_EAMPLE'
  },
  ShowMore: {
    id: 'Global:SHOW_MORE',
    defaultMessage: 'Global:SHOW_MORE'
  },
  ShowLess: {
    id: 'Global:SHOW_LESS',
    defaultMessage: 'Global:SHOW_LESS'
  },
  ErrorInvalidPhone: {
    INVALID: {
      id: 'Global:PHONE_INVALID',
      defaultMessage: 'Global:PHONE_INVALID'
    },
    NOT_A_NUMBER: {
      id: 'Global:PHONE_NOT_A_NUMBER',
      defaultMessage: 'Global:PHONE_NOT_A_NUMBER'
    },
    INVALID_COUNTRY: {
      id: 'Global:PHONE_INVALID_COUNTRY',
      defaultMessage: 'Global:PHONE_INVALID_COUNTRY'
    },
    TOO_SHORT: {
      id: 'Global:PHONE_TOO_SHORT',
      defaultMessage: 'Global:PHONE_TOO_SHORT'
    },
    TOO_LONG: {
      id: 'Global:PHONE_TOO_LONG',
      defaultMessage: 'Global:PHONE_TOO_LONG'
    },
    INVALID_LENGTH: {
      id: 'Global:PHONE_INVALID_LENGTH',
      defaultMessage: 'Global:PHONE_INVALID_LENGTH'
    }
  },
  LearnMore: {
    id: 'Global:LEARN_MORE',
    defaultMessage: 'Global:LEARN_MORE'
  },
  BetaPage: {
    id: 'Global:BETA_PAGE',
    defaultMessage: 'Global:BETA_PAGE'
  },
  GiveFeedback: {
    id: 'Global:TO_GIVE_FEEDBACK',
    defaultMessage: 'Global:TO_GIVE_FEEDBACK'
  },
  ClickHere: {
    id: 'Global:CLICK_HERE',
    defaultMessage: 'Global:CLICK_HERE'
  },
  RECOMMENDED: {
    id: 'Global:RECOMMENDED',
    defaultMessage: 'Global:RECOMMENDED'
  }
}
