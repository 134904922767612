import React from 'react'
import {
  Box,
  CircularProgress,
  CircularProgressProps,
  Skeleton,
  Typography,
  TypographyProps
} from '@mui/material'
import { getScoreColor, getScoreText } from '../../utils/scoreUtil'
import grey from '@mui/material/colors/grey'

type ScoreCircularProgressProps = CircularProgressProps & {
  isLoading?: boolean
}

const ScoreCircularProgress = (props: ScoreCircularProgressProps) => {
  const { value, size = 40, thickness = 4, isLoading = false, ...restProps } = props
  return (
    <Box sx={{ position: 'relative' }}>
      <CircularProgress
        variant="determinate"
        sx={{
          color: grey[300]
        }}
        size={size}
        thickness={thickness}
        value={100}
        {...restProps}
      />
      <CircularProgress
        variant={isLoading ? 'indeterminate' : 'determinate'}
        disableShrink={isLoading}
        sx={{
          color: isLoading ? grey[500] : getScoreColor(value || 0),
          position: 'absolute',
          left: 0
        }}
        size={size}
        thickness={thickness}
        value={isLoading ? 50 : value}
        {...restProps}
      />
    </Box>
  )
}

const CircularScore = (
  props: CircularProgressProps & {
    label: string
    value?: number
    isLoading?: boolean
    labelProps?: TypographyProps
    valueProps?: TypographyProps
  }
) => {
  const {
    label,
    value = 0,
    isLoading = false,
    labelProps,
    valueProps,
    ...restProps
  } = props
  return (
    <Box
      sx={{
        position: 'relative',
        display: 'inline-flex',
        justifyContent: 'center',
        textAlign: 'center'
      }}
    >
      <ScoreCircularProgress
        value={value}
        isLoading={isLoading}
        {...restProps}
      />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <Typography variant="subtitle1" color="text.secondary" {...labelProps}>
          {isLoading ? <Skeleton /> : label}
        </Typography>
        <Typography variant="h4" {...valueProps}>
          {isLoading ? <Skeleton /> : getScoreText(value)}
        </Typography>
      </Box>
    </Box>
  )
}
export default CircularScore
