import * as AmazonCognitoIdentity from 'amazon-cognito-identity-js'
import { clearLocalStorage } from '../localStorage'
import env from '../env'
import Analytics from './analytics'

const POOL_DATA = {
  UserPoolId: env.AWS_COGNITO_POOL_ID,
  ClientId: env.AWS_COGNITO_CLIENT_ID
}
const AWS_USER_POOL = new AmazonCognitoIdentity.CognitoUserPool(POOL_DATA)

export function signUp(email, password) {
  return new Promise((resolve, reject) => {
    const attributeList = [
      new AmazonCognitoIdentity.CognitoUserAttribute({
        Name: 'email',
        Value: email
      })
    ]
    AWS_USER_POOL.signUp(
      email,
      password,
      attributeList,
      null,
      function (err, result) {
        if (err) {
          reject(err)
          return
        }
        resolve(result.user)
      }
    )
  })
}

export function signIn(email, password, onRequireNewPassword, newPassword) {
  const cognitoUser = new AmazonCognitoIdentity.CognitoUser({
    Username: email,
    Pool: AWS_USER_POOL
  })
  const authenticationDetails = new AmazonCognitoIdentity.AuthenticationDetails(
    {
      Username: email,
      Password: password
    }
  )
  return new Promise((resolve, reject) => {
    const onSuccess = function (result) {
      const user = AWS_USER_POOL.getCurrentUser()
      Analytics.setUser(user.getUsername(), email)
      resolve()
    }
    const onFailure = function (err) {
      reject(err)
    }
    cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess,
      onFailure,
      newPasswordRequired: userAttributes => {
        if (!newPassword) {
          onRequireNewPassword()
        } else {
          cognitoUser.completeNewPasswordChallenge(
            newPassword,
            userAttributes,
            { onSuccess, onFailure }
          )
        }
      }
    })
  })
}

export function sendVerificationCode(email) {
  const cognitoUser = new AmazonCognitoIdentity.CognitoUser({
    Username: email,
    Pool: AWS_USER_POOL
  })
  return new Promise((resolve, reject) => {
    cognitoUser.forgotPassword({
      onSuccess: function () {
        resolve()
      },
      onFailure: function (err) {
        reject(err)
      }
    })
  })
}

export function resendVerification(email, password) {
  const cognitoUser = new AmazonCognitoIdentity.CognitoUser({
    Username: email,
    Pool: AWS_USER_POOL
  })
  return new Promise((resolve, reject) => {
    cognitoUser.resendConfirmationCode((err, result) => {
      if (err) {
        reject(err)
        return
      }
      resolve()
    })
  })
}

export function resetPassword(email, verificationCode, newPassword) {
  const cognitoUser = new AmazonCognitoIdentity.CognitoUser({
    Username: email,
    Pool: AWS_USER_POOL
  })

  return new Promise((resolve, reject) => {
    cognitoUser.confirmPassword(verificationCode, newPassword, {
      onFailure(err) {
        reject(err)
      },
      onSuccess() {
        resolve()
      }
    })
  })
}

export function currentUserIdToken() {
  const cognitoUser = AWS_USER_POOL.getCurrentUser()
  let jwtToken = null
  if (cognitoUser != null) {
    cognitoUser.getSession(function (err, session) {
      if (err) {
        return
      }
      jwtToken = session.getIdToken().getJwtToken()
    })
  }
  return jwtToken
}

export function isUserAuthenticated() {
  const cognitoUser = AWS_USER_POOL.getCurrentUser()
  let isAuthenticated = false
  if (cognitoUser != null) {
    cognitoUser.getSession(function (err, session) {
      if (err) {
        return
      }
      isAuthenticated = session.isValid()
    })
  }
  return isAuthenticated
}

export function signOut() {
  clearLocalStorage()
  Analytics.reset()
  AWS_USER_POOL.getCurrentUser().signOut()
}

export function confirmUserSignUp(username, code, onSuccess, onError) {
  const userData = {
    Username: username,
    Pool: AWS_USER_POOL
  }

  const cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData)
  cognitoUser.confirmRegistration(code, true, (err, result) => {
    if (err) {
      onError(err)
    }

    if (result === 'SUCCESS') {
      onSuccess()
    }
  })
}
