import { Map as ImmutableMap } from 'immutable'
import { apm } from '../../utils/apm'

export const getUserSelector = state => {
  let user = state.get('entities').get('user', new ImmutableMap())
  apm.setUserContext({ email: user.get('email'), id: user.get('cognitoId') })
  return {
    user
  }
}
