import React, { useMemo, useReducer } from 'react'
import {
  Box,
  Button,
  Paper,
  Typography,
  styled,
  Stepper,
  Step,
  StepLabel,
  AppBar,
  Toolbar,
  useTheme,
  useMediaQuery,
  Stack,
  StepContent
} from '@mui/material'
import { FormattedMessage } from 'react-intl'
import { withTransaction } from '@elastic/apm-rum-react'
import ExitToAppIcon from '@mui/icons-material/ExitToApp'
import isEmpty from 'lodash/isEmpty'
import { messageKeys as globalMessageKeys } from '../../globalMessages'
import { useSnackbar } from '../../utils/snackBar'
import LanguageMenu from '../Header/LanguageMenu'
import {
  PATH_DASHBOARD,
  PATH_FINALIZE_REGISTRATION_THANK_YOU
} from '../../routes/PathConstants'
import { startTransaction } from '../../utils/apm'
import { signOut } from '../../utils/awsCognitoClient'
import { messageKeys } from './messages'
import { messageKeys as commercialAgreementKeys } from '../CommercialAgreement/messages'
import StepIntegrationType from './Form/StepIntegrationType'
import StepUserDetails from './Form/StepUserDetails'
import StepIntegrationDetailsSigned from './Form/StepIntegrationDetailsSigned'
import StepIntegrationDetailsNotSigned from './Form/StepIntegrationDetailsNotSigned'
import StepCommercialAgreement from './Form/StepCommercialAgreement'
import useGetValidateVat from '../../hooks/useGetValidateVat'
import Loader from '../../components/Loader'
import Analytics from '../../utils/analytics'
import {
  FINALIZE_REGISTRATION_SAVE,
  SIGN_OUT
} from '../../utils/analytics/events'

const FinalizeRegistrationPage = ({
  channelManagerConfigs,
  connectToExisting,
  history,
  registerNew,
  user
}) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const {
    isLoading: isVatValidationLoading,
    mutateAsync: mutateVatValidation
  } = useGetValidateVat()

  // eslint-disable-next-line
  const { showSuccessSnackbar, showErrorSnackbar } = useSnackbar(
    <FormattedMessage {...messageKeys.Title} />
  )

  const [activeStep, setActiveStep] = React.useState(0)
  const [vatValidationError, setVatValidationError] = React.useState(null)

  const [state, dispatch] = useReducer(reducer, {
    // Personal Details
    firstName: user.get('firstName'),
    lastName: user.get('lastName'),
    phoneNumber: user.get('phoneNumber'),
    contactLanguage: user.get('contactLanguage'),
    roleInCompany: user.get('roleInCompany'),
    // Integration Details
    integrationType: null, // new
    integrationChannel: '',
    companyName: '',
    providerName: '',
    legalName: '',
    // Address Details
    street: '',
    number: '',
    zip: '',
    city: '',
    country: '',
    vatNumber: '',
    withoutVat: false,
    // Properties Details
    propertiesCount: '',
    locationOfProperties: '',
    errors: {},
    // Holidu Payment
    isHoliduPaymentEnabled: false,
    selectedChannelManager: null,
    // validActiveStep
    // commercialAgreement
    commercialAgreementSigned: false,
    commission: 0
  })

  const {
    channelManager,
    city,
    companyName,
    contactLanguage,
    country,
    errors,
    firstName,
    integrationChannel,
    integrationType,
    lastName,
    legalName,
    locationOfProperties,
    number,
    phoneNumber,
    propertiesCount,
    providerName,
    roleInCompany,
    street,
    zip,
    vatNumber,
    withoutVat,
    isHoliduPaymentEnabled,
    commercialAgreementSigned,
    commission
  } = state

  const handleFinish = () => {
    Analytics.trackEvent(FINALIZE_REGISTRATION_SAVE)
    const transaction = startTransaction('FinalizeRegistration#Finalize')
    if (integrationType === 'signed') {
      const data = {
        firstName,
        lastName,
        phoneNumber,
        contactLanguage,
        roleInCompany,
        providerName,
        integrationChannel,
        country
      }
      connectToExisting(
        data,
        _ => {
          transaction.end()
          history.push(PATH_FINALIZE_REGISTRATION_THANK_YOU)
        },
        error => {
          showErrorSnackbar(error.text)
          transaction.end()
        }
      )
    } else {
      let data = {
        firstName,
        lastName,
        phoneNumber,
        contactLanguage,
        roleInCompany,

        companyName,
        legalName,
        street,
        number,
        zip,
        city,
        country,
        vatNumber,

        locationOfProperties,
        propertiesCount,
        isApi: integrationType === 'hapi',
        channelManager: integrationType === 'cm' ? channelManager : null,
        showCommercialAgreement: integrationType === 'cm'
      }

      if (integrationType === 'cm') {
        data = {
          ...data,
          commercialAgreementSigned,
          commission,
          isHoliduPaymentEnabled:
            isHoliduPaymentEnabled ||
            state.selectedChannelManager.get('paymentType') ===
              'HOLIDU_PAYMENTS_ONLY'
        }
      }

      registerNew(
        data,
        _ => {
          transaction.end()
          history.replace(`${PATH_DASHBOARD}?guide=true`)

          // to reload user and provider data
          window.location.reload()
        },
        error => {
          showErrorSnackbar(error.text)
          transaction.end()
        }
      )
    }
  }

  const handleSignOutClick = () => {
    Analytics.trackEvent(SIGN_OUT, 'From FinalizeRegistrationPage')
    signOut()
    window.location.replace('/')
  }

  const isLastStep = () => {
    return (
      (integrationType === 'cm' && activeStep === 3) ||
      (integrationType !== 'cm' && activeStep === 2)
    )
  }

  const handleNext = async () => {
    const isValidStep = validateStep()
    if (isValidStep) {
      if (isLastStep()) {
        handleFinish()
      } else {
        if (
          activeStep === 1 &&
          integrationType !== 'signed' &&
          !withoutVat &&
          country !== 'DE'
        ) {
          const response = await mutateVatValidation({
            vatNumber,
            legalCompanyName: legalName,
            city,
            zip,
            country
          })
          if (response.data?.valid === true) {
            setActiveStep(prevActiveStep => prevActiveStep + 1)
            setVatValidationError(null)
          } else {
            dispatch({
              type: 'error',
              field: 'vatNumber',
              value: 'vatValidation'
            })
            setVatValidationError(response.data)
          }
        } else {
          setActiveStep(prevActiveStep => prevActiveStep + 1)
          setVatValidationError(null)
        }
      }
    }
  }

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1)
  }

  const handleVatValidationDialogClose = () => {
    setVatValidationError(null)
  }

  const validateStep = () => {
    const errors = {}

    // Fields to be validated based on current step selection
    const stepFields = [
      ['integrationType'],
      [],
      [
        'firstName',
        'lastName',
        'phoneNumber',
        'contactLanguage',
        'roleInCompany'
      ],
      ['commercialAgreementSigned']
    ]

    if (state.integrationType === 'signed') {
      stepFields[1] = ['integrationChannel', 'providerName', 'country']
    } else {
      stepFields[1] = [
        'legalName',
        'street',
        'number',
        'zip',
        'city',
        'country',
        'companyName',
        'locationOfProperties',
        'propertiesCount'
      ]
      if (!withoutVat) {
        stepFields[1].push('vatNumber')
      }
    }

    // Validation
    stepFields[activeStep] &&
      stepFields[activeStep].forEach(field => {
        if (!state[field]) {
          errors[field] = 'required'
        }
        if (field === 'phoneNumber' && state[field] === '+') {
          errors[field] = 'required'
        }
      })

    if (activeStep === 0) {
      if (state.integrationType === 'cm' && !state.channelManager) {
        errors.channelManager = 'required'
      }
    }

    // Action
    dispatch({
      type: 'errors',
      payload: errors
    })
    return isEmpty(errors)
  }

  const steps = useMemo(() => {
    let currentSteps = [
      {
        id: 'stepIntegrationType',
        label: <FormattedMessage {...messageKeys.STEP_INTEGRATION_TYPE} />
      },
      {
        id: 'stepIntegrationDetails',
        label: <FormattedMessage {...messageKeys.STEP_INTEGRATION_DETAILS} />
      },
      {
        id: 'stepUserDetails',
        label: <FormattedMessage {...messageKeys.STEP_USER_DETAILS} />
      }
    ]
    if (state.integrationType === 'cm') {
      currentSteps.push({
        id: 'stepCommercialAgreement',
        label: (
          <FormattedMessage {...commercialAgreementKeys.CommercialAgreement} />
        )
      })
    }
    return currentSteps
  }, [state])

  const StepContentElement = (
    <>
      <Box py={4}>
        {activeStep === 0 && (
          <StepIntegrationType
            dispatch={dispatch}
            errors={errors}
            formData={state}
            channelManagerConfigs={channelManagerConfigs}
          />
        )}
        {activeStep === 1 && (
          <>
            {state.integrationType === 'signed' ? (
              <StepIntegrationDetailsSigned
                dispatch={dispatch}
                errors={errors}
                formData={state}
              />
            ) : (
              <StepIntegrationDetailsNotSigned
                dispatch={dispatch}
                errors={errors}
                vatValidationError={vatValidationError}
                onVatValidationErrorClose={handleVatValidationDialogClose}
                formData={state}
              />
            )}
          </>
        )}
        {activeStep === 2 && (
          <StepUserDetails
            dispatch={dispatch}
            errors={errors}
            formData={state}
          />
        )}
        {activeStep === 3 && (
          <StepCommercialAgreement
            dispatch={dispatch}
            errors={errors}
            formData={state}
          />
        )}
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'row-reverse', pt: 2 }}>
        <Button variant="contained" onClick={handleNext}>
          {activeStep === steps.length - 1 ? (
            <FormattedMessage {...globalMessageKeys.Finish} />
          ) : (
            <FormattedMessage {...globalMessageKeys.Next} />
          )}
        </Button>
        {activeStep !== 0 && (
          <Button
            variant="contained"
            color="inherit"
            onClick={handleBack}
            sx={{ mr: 1 }}
          >
            <FormattedMessage {...globalMessageKeys.Back} />
          </Button>
        )}
      </Box>
    </>
  )

  return (
    <>
      <AppBar
        position="fixed"
        elevation={0}
        sx={{
          width: '100%'
        }}
      >
        <Toolbar sx={{ flexWrap: 'wrap' }}>
          <Box sx={{ width: 300, display: { xs: 'none', lg: 'block' } }}>
            <img
              height={26}
              alt="Holidu Partner Portal"
              src="/static/img/WhiteLogo.png"
            />
            <Typography ml={6} variant="subtitle2" color="white">
              Partner Portal
            </Typography>
          </Box>
          <Box flexGrow={1}>
            <Typography
              variant={isMobile ? 'subtitle1' : 'h6'}
              noWrap
              component="div"
            >
              <FormattedMessage {...messageKeys.Title} />
            </Typography>
            <Typography variant="body2" paragraph mb={0}>
              {user.get('email')}
            </Typography>
          </Box>

          <Stack direction="row" alignItems="center" spacing={4}>
            <Stack direction="row" spacing={2} alignItems="center">
              <LanguageMenu />
              <Button
                color="inherit"
                startIcon={<ExitToAppIcon />}
                onClick={handleSignOutClick}
              >
                <FormattedMessage {...globalMessageKeys.SignOut} />
              </Button>
            </Stack>
          </Stack>
        </Toolbar>
      </AppBar>
      <Box>
        <Toolbar />
        <StyledPaper square elevation={0}>
          <form>
            <Stepper
              activeStep={activeStep}
              orientation={isMobile ? 'vertical' : 'horizontal'}
            >
              {steps.map((step, index) => {
                return (
                  <Step key={step.id}>
                    <StepLabel>{step.label}</StepLabel>
                    {isMobile && (
                      <StepContent>{StepContentElement}</StepContent>
                    )}
                  </Step>
                )
              })}
            </Stepper>
            {!isMobile && <Box py={4}>{StepContentElement}</Box>}
          </form>
        </StyledPaper>
        {isVatValidationLoading && (
          <Loader text={<FormattedMessage {...messageKeys.VALIDATING_VAT} />} />
        )}
      </Box>
    </>
  )
}

export default withTransaction(
  'FinalizeRegistrationPage',
  'custom'
)(FinalizeRegistrationPage)

const reducer = (state, action) => {
  switch (action.type) {
    case 'field':
      delete state.errors[action.field]
      return { ...state, [action.field]: action.value }
    case 'errors':
      return { ...state, errors: action.payload }
    case 'error':
      return {
        ...state,
        errors: { ...state.errors, [action.field]: action.value }
      }
    default:
      return state
  }
}

const StyledPaper = styled(Paper)(({ theme }) => ({
  maxWidth: theme.breakpoints.values.lg,
  minHeight: 'calc(100vh - 64px)',
  padding: theme.spacing(4, 2),
  margin: 'auto'
}))
