import React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { Box, Grid, Link, Stack, Tooltip, Typography } from '@mui/material'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import SyncIcon from '@mui/icons-material/Sync'
import moment from 'moment'
import { useIntl } from 'react-intl'
import { DATE_FORMAT, DATE_TIME_FORMAT } from '../../utils/dateUtil'
import messageKeys from './messages'
import { BookingInfo } from './types'
import { LabelValue, Section } from '@holidu/pui-components/dist'
import Analytics from '../../utils/analytics'
import { BOOKING_DETAIL_CLICK_APARTMENT_ID } from '../../utils/analytics/events'
import GuestsValue from './GuestsValue'

type BookingInfoSectionProps = {
  bookingInfo: BookingInfo
}

const BookingInfoSection = (props: BookingInfoSectionProps): JSX.Element => {
  const { bookingInfo } = props
  const intl = useIntl()

  const handleApartmentLinkClick = () => {
    Analytics.trackEvent(BOOKING_DETAIL_CLICK_APARTMENT_ID)
  }

  return (
    <Section
      title={intl.formatMessage(messageKeys.Booking)}
      action={
        <Tooltip title={intl.formatMessage(messageKeys.LastUpdated)}>
          <Stack
            direction="row"
            spacing={1}
            alignItems="center"
            color="textSecondary"
          >
            <SyncIcon fontSize="small" color="inherit" />
            <Typography variant="body2">
              {moment(bookingInfo.updatedAt).format(DATE_TIME_FORMAT)}
            </Typography>
          </Stack>
        </Tooltip>
      }
    >
      <Stack spacing={2}>
        <Stack
          spacing={2}
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Box
            bgcolor="primary.highlight.50"
            p={2}
            flexGrow={1}
            borderRadius={2.5}
          >
            <Typography variant="body2" component="div">
              <strong>{intl.formatMessage(messageKeys.CheckInDate)}</strong>
            </Typography>
            <Typography variant="h6" component="div" color="primary.main">
              {moment(bookingInfo.checkin).format(DATE_FORMAT)}
            </Typography>
          </Box>
          <Box
            bgcolor="primary.highlight.50"
            p={2}
            sx={{ minHeight: '100%' }}
            borderRadius={2.5}
          >
            <ArrowForwardIcon fontSize="large" />
          </Box>
          <Box
            bgcolor="primary.highlight.50"
            p={2}
            flexGrow={1}
            borderRadius={2.5}
          >
            <Typography variant="body2" component="div">
              <strong>{intl.formatMessage(messageKeys.CheckOutDate)}</strong>
            </Typography>
            <Typography variant="h6" component="div" color="primary.main">
              {moment(bookingInfo.checkout).format(DATE_FORMAT)}
            </Typography>
          </Box>
        </Stack>
      </Stack>
      <Box pt={2}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6} lg={3}>
            <LabelValue
              label={intl.formatMessage(messageKeys.TransactionId)}
              value={bookingInfo.externalId}
              valueProps={{
                sx: {
                  wordBreak: 'break-all'
                }
              }}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <LabelValue
              label={intl.formatMessage(messageKeys.Guests)}
              value={
                <GuestsValue
                  numberOfGuests={bookingInfo.numberOfGuests}
                  numberOfAdults={bookingInfo.numberOfAdults}
                  numberOfChildren={bookingInfo.numberOfChildren}
                  numberOfBabies={bookingInfo.numberOfBabies}
                />
              }
            />
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <LabelValue
              label={intl.formatMessage(messageKeys.HoliduId)}
              value={
                <Link
                  color="primary"
                  component={RouterLink}
                  to={`/inventory/property?apartmentId=${bookingInfo.apartmentId}`}
                  onClick={handleApartmentLinkClick}
                >
                  {bookingInfo.apartmentId}
                </Link>
              }
            />
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <LabelValue
              label={intl.formatMessage(messageKeys.ApartmentExternalId)}
              value={bookingInfo.apartmentExternalId}
              valueProps={{
                sx: {
                  wordBreak: 'break-all'
                }
              }}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <LabelValue
              label={intl.formatMessage(messageKeys.BookingAt)}
              value={moment(bookingInfo.bookedAt).format(DATE_TIME_FORMAT)}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={9}>
            <LabelValue
              label={intl.formatMessage(messageKeys.Comment)}
              value={
                <span style={{ whiteSpace: 'initial' }}>
                  {bookingInfo.comment ? bookingInfo.comment : '--'}
                </span>
              }
            />
          </Grid>
        </Grid>
      </Box>
    </Section>
  )
}

export default BookingInfoSection
