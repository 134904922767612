import React from 'react'
import { FilterStateProps } from '../../types'
import { DEFAULT_SORT_BY, DEFAULT_SORT_ORDER } from '../../utils'

const useFilterState = (props: FilterStateProps) => {
  const {
    apartmentStatus,
    imageLabel,
    heroImageContent,
    sortBy,
    sortOrder,
    lowBookabilityScore,
    lowPhotoScore
  } = props
  const [show, setShow] = React.useState<boolean>(true)
  const [isFiltersApplied, setIsFilterApplied] = React.useState<boolean>(false)

  React.useEffect(() => {
    if (
      apartmentStatus ||
      imageLabel ||
      heroImageContent ||
      lowBookabilityScore ||
      lowPhotoScore ||
      sortBy !== DEFAULT_SORT_BY ||
      sortOrder !== DEFAULT_SORT_ORDER
    ) {
      setIsFilterApplied(true)
      setShow(true)
    } else {
      setIsFilterApplied(false)
    }
  }, [
    apartmentStatus,
    imageLabel,
    heroImageContent,
    sortBy,
    sortOrder,
    lowBookabilityScore,
    lowPhotoScore
  ])
  return { show, setShow, isFiltersApplied, setIsFilterApplied }
}

export default useFilterState
