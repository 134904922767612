import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import ValidationTextField from '../ValidationTextField'
import { messageKeys as globalMessageKeys } from '../../../globalMessages'
import { validateEmail } from '../../../utils/validation'

const ValidationEmailField = props => {
  const { validations, ...restProps } = props

  return (
    <ValidationTextField
      validations={{
        emailValidation: {
          validator: value => validateEmail(value),
          message: (
            <FormattedMessage {...globalMessageKeys.ErrorInvalidEmailId} />
          )
        },
        ...validations
      }}
      {...restProps}
    />
  )
}

ValidationEmailField.propTypes = {
  validations: PropTypes.object
}

ValidationEmailField.defaultProps = {
  validations: {}
}

export default ValidationEmailField
