import React, { useState } from 'react'
import { connect } from 'react-redux'
import ReactCountryFlag from 'react-country-flag'
import { Button, ListItemIcon, Menu, MenuItem } from '@mui/material'
import find from 'lodash/find'
import get from 'lodash/get'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import { SUPPORTED_LANGUAGES } from '../../../supportedLanguages'
import { getLocaleSelector } from '../../store/locale/localeSelector'
import { changeLocale } from '../../store/locale/actions'
import Analytics from '../../utils/analytics'

const LanguageMenu = ({ currentLocale, size, onLocaleToggle }) => {
  const [anchorMenu, setAnchorMenu] = useState(null)
  const selectedLanguage = find(SUPPORTED_LANGUAGES, ['value', currentLocale])

  const open = Boolean(anchorMenu)

  const setLanguage = locale => {
    onLocaleToggle(locale)
    closeMenu()
  }

  const toggleMenu = event => {
    setAnchorMenu(event.currentTarget)
  }

  const closeMenu = () => {
    setAnchorMenu(null)
  }

  const handleClick = value => () => {
    setLanguage(value)
  }

  return (
    <>
      <Button
        size={size}
        data-ga-label="Language menu"
        sx={{ textTransform: 'unset' }}
        startIcon={
          <ShowFlag
            countryCode={get(selectedLanguage, 'flagCountryCode', 'GB')}
          />
        }
        endIcon={<ArrowDropDownIcon />}
        aria-haspopup="true"
        aria-owns={open ? 'menu-language' : undefined}
        color="inherit"
        onClick={toggleMenu}
      >
        {get(selectedLanguage, 'label', 'English')}
      </Button>
      <Menu
        anchorEl={anchorMenu}
        id="menu-language"
        open={open}
        onClose={closeMenu}
      >
        {SUPPORTED_LANGUAGES.map(lang => (
          <MenuItem
            dense
            key={lang.value}
            selected={lang.value === get(selectedLanguage, 'value', 'en-GB')}
            onClick={handleClick(lang.value)}
          >
            <ListItemIcon>
              <ShowFlag countryCode={lang.flagCountryCode} />
            </ListItemIcon>
            {lang.label}
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}

const ShowFlag = ({ countryCode }) => {
  return <ReactCountryFlag countryCode={countryCode} svg />
}

const mapDispatchToProps = dispatch => {
  return {
    onLocaleToggle: locale => {
      Analytics.setLocale(locale)
      dispatch(changeLocale(locale))
    }
  }
}

export default connect(getLocaleSelector, mapDispatchToProps)(LanguageMenu)
