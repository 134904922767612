import axios from 'axios'
import {
  useMutation
  // useQueryClient
} from '@tanstack/react-query'
import sanitizeData from '../utils/sanitizeData'
// import QueryKeys from '../utils/queryKeys'
import { ProviderConfiguration } from './types/ProviderConfiguration'

const usePutProviderConfiguration = (selectedProvider: string | null) => {
  // const queryClient = useQueryClient()

  return useMutation<any, Error, ProviderConfiguration>(
    async (data: ProviderConfiguration) => {
      return axios.put(`/provider/configuration?provider=${selectedProvider}`, {
        ...sanitizeData(data)
      })
    },
    {
      onSuccess: () => {
        // ToDo: when we migrate completely from redux-query we need to replace
        window.location.reload()
        // queryClient.invalidateQueries([QueryKeys.ProviderConfiguration])
      }
    }
  )
}

export default usePutProviderConfiguration
