import axios from 'axios'
import { saveAs } from 'file-saver'
import { useMutation } from '@tanstack/react-query'
import { useSnackbar } from 'notistack'
import { useIntl } from 'react-intl'
import messageKeys from './messages'
import { getApiRequestQueryString } from './utils'

type Context = {
  filename: string
}

const usePropertiesDownload = (provider: string) => {
  const { enqueueSnackbar } = useSnackbar()
  const intl = useIntl()
  return useMutation<any, Error>(
    async () => {
      const query = getApiRequestQueryString(provider, true)
      return await axios.get(`/apartments/list/csv?${query}`)
    },
    {
      onMutate: () => {
        const time = new Date().toISOString().split('T')[0]
        const filenameTokens = ['Properties', provider, time]

        const context = {
          filename: `${filenameTokens.join('_')}.csv`
        }
        return context
      },
      onError: error => {
        enqueueSnackbar(
          intl.formatMessage(messageKeys.DownloadCsvError, {
            message: error.message
          }),
          {
            variant: 'error'
          }
        )
      },
      onSuccess: (data, variables, context) => {
        const textData: string = data?.data

        if (typeof textData === 'string') {
          const blob = new Blob([textData], {
            type: 'data:text/csv;charset=utf-8'
          })
          saveAs(blob, (context as Context).filename)

          enqueueSnackbar(intl.formatMessage(messageKeys.DownloadCsvSuccess), {
            variant: 'success'
          })
        } else {
          enqueueSnackbar(
            intl.formatMessage(messageKeys.DownloadCsvError, {
              message: 'Empty'
            }),
            {
              variant: 'error'
            }
          )
        }
      }
    }
  )
}

export default usePropertiesDownload
