const messageKeys = {
  Title: {
    id: 'BookingDetail:TITLE',
    defaultMessage: 'BookingDetail:TITLE'
  },
  ParentPath1: {
    id: 'SidebarTitle:FINANCIALS',
    defaultMessage: 'SidebarTitle:FINANCIALS'
  },
  ParentPath2: {
    id: 'BookingsKeys:TITLE',
    defaultMessage: 'BookingsKeys:TITLE'
  },
  NoBookingFound: {
    id: 'BookingDetail:NO_BOOKING_FOUND',
    defaultMessage: 'BookingDetail:NO_BOOKING_FOUND'
  },
  StatusConfirmed: {
    id: 'BookingDetail:STATUS_CONFIRMED',
    defaultMessage: 'BookingDetail:STATUS_CONFIRMED'
  },
  StatusCancelled: {
    id: 'BookingDetail:STATUS_CANCELLED',
    defaultMessage: 'BookingDetail:STATUS_CANCELLED'
  },
  Booking: {
    id: 'BookingDetail:BOOKING',
    defaultMessage: 'BookingDetail:BOOKING'
  },
  HOLIDU_EXPRESS: {
    id: 'BookingDetail:HOLIDU_EXPRESS',
    defaultMessage: 'BookingDetail:HOLIDU_EXPRESS'
  },
  HOLIDU_PAYMENT: {
    id: 'BookingDetail:HOLIDU_PAYMENT',
    defaultMessage: 'BookingDetail:HOLIDU_PAYMENT'
  },
  REDIRECT: {
    id: 'BookingDetail:REDIRECT',
    defaultMessage: 'BookingDetail:REDIRECT'
  },
  BookingId: {
    id: 'BookingDetail:BOOKING_ID',
    defaultMessage: 'BookingDetail:BOOKING_ID'
  },
  TransactionId: {
    id: 'BookingDetail:TRANSACTION_ID',
    defaultMessage: 'BookingDetail:TRANSACTION_ID'
  },
  Guests: {
    id: 'BookingDetail:GUESTS',
    defaultMessage: 'BookingDetail:GUESTS'
  },
  Adults: {
    id: 'BookingDetail:ADULTS',
    defaultMessage: 'BookingDetail:ADULTS'
  },
  Children: {
    id: 'BookingDetail:CHILDREN',
    defaultMessage: 'BookingDetail:CHILDREN'
  },
  Babies: {
    id: 'BookingDetail:BABIES',
    defaultMessage: 'BookingDetail:BABIES'
  },
  CheckInDate: {
    id: 'BookingDetail:CHECK_IN_DATE',
    defaultMessage: 'BookingDetail:CHECK_IN_DATE'
  },
  CheckOutDate: {
    id: 'BookingDetail:CHECK_OUT_DATE',
    defaultMessage: 'BookingDetail:CHECK_OUT_DATE'
  },
  BookingAt: {
    id: 'BookingDetail:BOOKING_AT',
    defaultMessage: 'BookingDetail:BOOKING_AT'
  },
  CancelledAt: {
    id: 'BookingDetail:CANCELLED_AT',
    defaultMessage: 'BookingDetail:CANCELLED_AT'
  },
  CancellationReason: {
    id: 'BookingDetail:CANCELLATION_REASON',
    defaultMessage: 'BookingDetail:CANCELLATION_REASON'
  },
  LastUpdated: {
    id: 'BookingDetail:LAST_UPDATED',
    defaultMessage: 'BookingDetail:LAST_UPDATED'
  },
  ApartmentExternalId: {
    id: 'BookingDetail:YOUR_APARTMENT_ID',
    defaultMessage: 'BookingDetail:YOUR_APARTMENT_ID'
  },
  HoliduId: {
    id: 'BookingDetail:HOLIDU_APARTMENT_ID',
    defaultMessage: 'BookingDetail:HOLIDU_APARTMENT_ID'
  },
  Comment: {
    id: 'BookingDetail:COMMENT',
    defaultMessage: 'BookingDetail:COMMENT'
  },
  CancellationPolicy: {
    id: 'BookingDetail:CANCELLATION_POLICY',
    defaultMessage: 'BookingDetail:CANCELLATION_POLICY'
  },
  CancellationPolicyNoData: {
    id: 'BookingDetail:CANCELLATION_POLICY_NO_DATA',
    defaultMessage: 'BookingDetail:CANCELLATION_POLICY_NO_DATA'
  },
  CancellationPolicyFreeCancellation: {
    id: 'BookingDetail:CANCELLATION_POLICY_FREE_CANCELLATION',
    defaultMessage: 'BookingDetail:CANCELLATION_POLICY_FREE_CANCELLATION'
  },
  CancellationPolicyBooking: {
    id: 'BookingDetail:CANCELLATION_POLICY_BOOKING',
    defaultMessage: 'BookingDetail:CANCELLATION_POLICY_BOOKING'
  },
  CancellationPolicyCheckin: {
    id: 'BookingDetail:CANCELLATION_POLICY_CHECKIN',
    defaultMessage: 'BookingDetail:CANCELLATION_POLICY_CHECKIN'
  },
  DownloadInvoice: {
    id: 'BookingDetail:DOWNLOAD_INVOICE',
    defaultMessage: 'BookingDetail:DOWNLOAD_INVOICE'
  },
  ShowAllInvoices: {
    id: 'BookingDetail:SHOW_ALL_INVOICES',
    defaultMessage: 'BookingDetail:SHOW_ALL_INVOICES'
  },
  Invoice: {
    id: 'BookingDetail:INVOICE',
    defaultMessage: 'BookingDetail:INVOICE'
  },
  INVOICE_TYPE_BOOKING_PAYOUT: {
    id: 'BookingDetail:INVOICE_TYPE_BOOKING_PAYOUT',
    defaultMessage: 'BookingDetail:INVOICE_TYPE_BOOKING_PAYOUT'
  },
  INVOICE_TYPE_BOOKING_PAYOUT_CANCELLATION: {
    id: 'BookingDetail:INVOICE_TYPE_BOOKING_PAYOUT_CANCELLATION',
    defaultMessage: 'BookingDetail:INVOICE_TYPE_BOOKING_PAYOUT_CANCELLATION'
  },
  NoInvoice: {
    id: 'BookingDetail:NO_INVOICE',
    defaultMessage: 'BookingDetail:NO_INVOICE'
  },
  Customer: {
    id: 'BookingDetail:CUSTOMER',
    defaultMessage: 'BookingDetail:CUSTOMER'
  },
  Name: {
    id: 'BookingDetail:CUSTOMER_NAME',
    defaultMessage: 'BookingDetail:CUSTOMER_NAME'
  },
  Email: {
    id: 'BookingDetail:CUSTOMER_EMAIL',
    defaultMessage: 'BookingDetail:CUSTOMER_EMAIL'
  },
  PhoneNumber: {
    id: 'BookingDetail:CUSTOMER_PHONE_NUMBER',
    defaultMessage: 'BookingDetail:CUSTOMER_PHONE_NUMBER'
  },
  Address: {
    id: 'BookingDetail:CUSTOMER_ADDRESS',
    defaultMessage: 'BookingDetail:CUSTOMER_ADDRESS'
  },
  PayoutSchedule: {
    id: 'BookingDetail:PAYOUT_SCHEDULE',
    defaultMessage: 'BookingDetail:PAYOUT_SCHEDULE'
  },
  PayoutScheduleType: {
    id: 'BookingDetail:PAYOUT_SCHEDULE_TYPE',
    defaultMessage: 'BookingDetail:PAYOUT_SCHEDULE_TYPE'
  },
  PayoutScheduleDate: {
    id: 'BookingDetail:PAYOUT_SCHEDULE_DATE',
    defaultMessage: 'BookingDetail:PAYOUT_SCHEDULE_DATE'
  },
  PayoutScheduleAmount: {
    id: 'BookingDetail:PAYOUT_SCHEDULE_AMOUNT',
    defaultMessage: 'BookingDetail:PAYOUT_SCHEDULE_AMOUNT'
  },
  PayoutScheduleStatus: {
    id: 'BookingDetail:PAYOUT_SCHEDULE_STATUS',
    defaultMessage: 'BookingDetail:PAYOUT_SCHEDULE_STATUS'
  },
  PayoutScheduleTotal: {
    id: 'BookingDetail:PAYOUT_SCHEDULE_TOTAL',
    defaultMessage: 'BookingDetail:PAYOUT_SCHEDULE_TOTAL'
  },
  PayoutScheduleNoData: {
    id: 'BookingDetail:PAYOUT_SCHEDULE_NO_DATA',
    defaultMessage: 'BookingDetail:PAYOUT_SCHEDULE_NO_DATA'
  },
  PayoutScheduleNoProvider: {
    id: 'BookingDetail:PAYOUT_SCHEDULE_NO_PROVIDER',
    defaultMessage: 'BookingDetail:PAYOUT_SCHEDULE_NO_PROVIDER'
  },
  Receipt: {
    id: 'BookingDetail:RECEIPT',
    defaultMessage: 'BookingDetail:RECEIPT'
  },
  BareRentalPrice: {
    id: 'BookingDetail:RECEIPT_BARE_RENTAL_PRICE',
    defaultMessage: 'BookingDetail:RECEIPT_BARE_RENTAL_PRICE'
  },
  Discount: {
    id: 'BookingDetail:RECEIPT_DISCOUNT',
    defaultMessage: 'BookingDetail:RECEIPT_DISCOUNT'
  },
  MandatoryCost: {
    id: 'BookingDetail:RECEIPT_MANDATORY_COST',
    defaultMessage: 'BookingDetail:RECEIPT_MANDATORY_COST'
  },
  MandatoryCostOnSpot: {
    id: 'BookingDetail:RECEIPT_MANDATORY_COST_ON_SPOT',
    defaultMessage: 'BookingDetail:RECEIPT_MANDATORY_COST_ON_SPOT'
  },
  SelectedOptions: {
    id: 'BookingDetail:RECEIPT_SELECTED_OPTIONS',
    defaultMessage: 'BookingDetail:RECEIPT_SELECTED_OPTIONS'
  },
  TotalInclExtras: {
    id: 'BookingDetail:RECEIPT_TOTAL_INCL_EXTRAS',
    defaultMessage: 'BookingDetail:RECEIPT_TOTAL_INCL_EXTRAS'
  },
  Taxes: {
    id: 'BookingDetail:RECEIPT_TAXES',
    defaultMessage: 'BookingDetail:RECEIPT_TAXES'
  },
  TotalInclTaxes: {
    id: 'BookingDetail:RECEIPT_TOTAL_INCL_TAXES',
    defaultMessage: 'BookingDetail:RECEIPT_TOTAL_INCL_TAXES'
  },
  Deposit: {
    id: 'BookingDetail:RECEIPT_DEPOSIT',
    defaultMessage: 'BookingDetail:RECEIPT_DEPOSIT'
  },
  PayoutBreakdown: {
    id: 'BookingDetail:PAYOUT_BREAKDOWN',
    defaultMessage: 'BookingDetail:PAYOUT_BREAKDOWN'
  },
  TotalPricePaidToHolidu: {
    id: 'BookingDetail:PAYOUT_BREAKDOWN_TOTAL_PRICE_PAID_TO_HOLIDU',
    defaultMessage: 'BookingDetail:PAYOUT_BREAKDOWN_TOTAL_PRICE_PAID_TO_HOLIDU'
  },
  CommissionablePrice: {
    id: 'BookingDetail:PAYOUT_BREAKDOWN_COMMISSIONABLE_PRICE',
    defaultMessage: 'BookingDetail:PAYOUT_BREAKDOWN_COMMISSIONABLE_PRICE'
  },
  CommissionPercent: {
    id: 'BookingDetail:PAYOUT_BREAKDOWN_COMMISSION_PERCENT',
    defaultMessage: 'BookingDetail:PAYOUT_BREAKDOWN_COMMISSION_PERCENT'
  },
  Commission: {
    id: 'BookingDetail:PAYOUT_BREAKDOWN_COMMISSION',
    defaultMessage: 'BookingDetail:PAYOUT_BREAKDOWN_COMMISSION'
  },
  VAT: {
    id: 'BookingDetail:PAYOUT_BREAKDOWN_VAT',
    defaultMessage: 'BookingDetail:PAYOUT_BREAKDOWN_VAT'
  },
  TotalPayout: {
    id: 'BookingDetail:PAYOUT_BREAKDOWN_TOTAL_PAYOUT',
    defaultMessage: 'BookingDetail:PAYOUT_BREAKDOWN_TOTAL_PAYOUT'
  },
  CommissionBreakdown: {
    id: 'BookingDetail:COMMISSION_BREAKDOWN',
    defaultMessage: 'BookingDetail:COMMISSION_BREAKDOWN'
  },
  CancelButton: {
    id: 'CancellationModal:CANCEL_BTN',
    defaultMessage: 'CancellationModal:CANCEL_BTN'
  },
  CancellationExpired: {
    id: 'BookingDetail:CANCELLATION_EXPIRED',
    defaultMessage: 'BookingDetail:CANCELLATION_EXPIRED'
  },
  TotalBookingCost: {
    id: 'BookingDetail:TOTAL_BOOKING_COST',
    defaultMessage: 'BookingDetail:TOTAL_BOOKING_COST'
  },
  CostOnUsage: {
    id: 'BookingDetail:COST_ON_USAGE',
    defaultMessage: 'BookingDetail:COST_ON_USAGE'
  },
  OnSpotExtraCost: {
    id: 'BookingDetail:ON_SPOT_EXTRA_COST',
    defaultMessage: 'BookingDetail:ON_SPOT_EXTRA_COST'
  },
  BookingExtraCost: {
    id: 'BookingDetail:BOOKING_EXTRA_COST',
    defaultMessage: 'BookingDetail:BOOKING_EXTRA_COST'
  }
}

export default messageKeys
