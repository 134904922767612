import React from 'react'
import {
  Button,
  Table,
  TableHead,
  TableRow,
  TableContainer,
  TableCell,
  Paper,
  TableBody,
  List,
  ListItemText,
  ListItem,
  Link,
  styled,
  Chip,
  Stack,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle
} from '@mui/material'
import { Link as RouterLink } from 'react-router-dom'
import CloudDownloadIcon from '@mui/icons-material/CloudDownload'
import { injectIntl } from 'react-intl'
import moment from 'moment'
import downloadFile from '../../utils/downloadFile'
import { messageKeys as globalMessageKeys } from '../../globalMessages'
import { DATE_FORMAT } from '../../utils/dateUtil'
import { messageKeys } from './messages'
import { PAID } from './constants'

const TableCellValign = styled(TableCell)({
  tableLayout: 'fixed',
  minWidth: 650
})

const Bookings = props => {
  const { data, intl, onExpand } = props
  const handleExpand = () => {
    onExpand(data)
  }

  if (data && data.size) {
    if (data.size > 1) {
      return (
        <Stack direction="row" spacing={2} alignItems="center">
          <Link component="button" variant="body2" onClick={handleExpand}>
            {data.size} {intl.formatMessage(messageKeys.BOOKINGS)}
          </Link>
        </Stack>
      )
    }
    return (
      <Link component={RouterLink} to={`/inventory/booking?id=${data.first()}`}>
        {data.first()}
      </Link>
    )
  }
  return null
}

const PayoutsContent = props => {
  const { data, intl, selectedTab } = props
  const [selectedBookingIds, setSelectedBookingIds] = React.useState(null)

  const handleExpand = selectedBookings => {
    setSelectedBookingIds(selectedBookings)
  }

  const handleDialogClose = () => {
    setSelectedBookingIds(null)
  }

  const handleDownload = payout => () => {
    const path = `/payouts/${payout.get('id')}/generate-csv`
    const fileName = `Holidu_Payout_${payout.get('id')}.csv`
    downloadFile(path, fileName, 'text/csv')
  }

  return (
    <>
      <TableContainer component={Paper} sx={{ overflowX: 'scroll' }}>
        <Table
          sx={{
            tableLayout: 'fixed',
            minWidth: 650
          }}
        >
          <TableHead>
            <TableRow>
              {selectedTab === PAID && (
                <TableCell>
                  {intl.formatMessage(messageKeys.PAYOUT_ID)}
                </TableCell>
              )}
              <TableCell style={{ width: 200 }}>
                {intl.formatMessage(messageKeys.BOOKINGS)}
              </TableCell>
              <TableCell>
                {intl.formatMessage(
                  selectedTab === PAID
                    ? messageKeys.DATE
                    : messageKeys.EXPECTED_DATE
                )}
              </TableCell>
              <TableCell>{intl.formatMessage(messageKeys.STATUS)}</TableCell>
              <TableCell align="right">
                {intl.formatMessage(messageKeys.AMOUNT)}
              </TableCell>
              {selectedTab === PAID && <TableCell align="right" />}
            </TableRow>
          </TableHead>
          <TableBody>
            {data && data.size ? (
              data.map((payout, index) => (
                <TableRow key={index}>
                  {selectedTab === PAID && (
                    <TableCellValign>{payout.get('id')}</TableCellValign>
                  )}
                  <TableCellValign style={{ width: 200 }}>
                    <Bookings
                      intl={intl}
                      data={payout.get('bookingIds')}
                      onExpand={handleExpand}
                    />
                  </TableCellValign>
                  <TableCellValign>
                    {moment(payout.get('date')).format(DATE_FORMAT)}
                  </TableCellValign>
                  <TableCellValign>
                    <Stack direction="row" spacing={2}>
                      <Chip
                        variant="outlined"
                        color="primary"
                        label={payout.get('status')}
                      />
                    </Stack>
                  </TableCellValign>
                  <TableCellValign align="right">{`${payout.get(
                    'amount'
                  )} ${payout.get('currency')}`}</TableCellValign>
                  {selectedTab === PAID && (
                    <TableCellValign align="right">
                      <Button
                        size="small"
                        onClick={handleDownload(payout)}
                        startIcon={<CloudDownloadIcon />}
                        color="primary"
                        variant="contained"
                      >
                        {intl.formatMessage(messageKeys.DOWNLOAD_CSV)}
                      </Button>
                    </TableCellValign>
                  )}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={6}>
                  {intl.formatMessage(messageKeys.NO_DATA_AVAILABLE)}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <Dialog
        maxWidth="md"
        fullWidth
        open={Boolean(selectedBookingIds?.size)}
        onClose={handleDialogClose}
      >
        <DialogTitle>
          {intl.formatMessage(messageKeys.ALL_BOOKINGS)}
        </DialogTitle>
        <DialogContent>
          <List dense disablePadding>
            {Boolean(selectedBookingIds?.size) &&
              selectedBookingIds.map(bookingId => (
                <ListItem sx={{ paddingLeft: 0 }} key={bookingId}>
                  <ListItemText disableTypography>
                    <Link
                      component={RouterLink}
                      to={`/inventory/booking?id=${bookingId}`}
                    >
                      {bookingId}
                    </Link>
                  </ListItemText>
                </ListItem>
              ))}
          </List>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={handleDialogClose}>
            {intl.formatMessage(globalMessageKeys.CloseButton)}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default injectIntl(PayoutsContent)
