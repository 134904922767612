import { Link } from '@mui/material'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { messageKeys as globalMessageKeys } from '../globalMessages'

type LearnMoreLinkProps = {
  href: string,
  onClick: () => void
}

const LearnMoreLink = (props: LearnMoreLinkProps): JSX.Element => {
  return (
    <Link href={props.href} target="_blank" onClick={props.onClick}>
      <FormattedMessage {...globalMessageKeys.LearnMore} />
    </Link>
  )
}

export default LearnMoreLink
