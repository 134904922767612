enum QueryKeys {
  ProviderStartingChecklist = 'ProviderStartingChecklist',
  Bookings = 'Bookings',
  ApartmentStats = 'ApartmentStats',
  Properties = 'Properties',
  PropertyDetail = 'PropertyDetail',
  PropertyCancellationPolicy = 'PropertyCancellationPolicy',
  InvoiceAggregations = 'InvoiceAggregations',
  Company = 'Company',
  Provider = 'Provider',
  ProviderConfiguration = 'ProviderConfiguration',
  AdyenStatus = 'AdyenStatus',
  AdyenVerification = 'AdyenVerification',
  BookingDetail = 'BookingDetail',
  BookingCancellationReasons = 'BookingCancellationReasons',
  ProviderCommission = 'ProviderCommission'
}

export default QueryKeys
