import React from 'react'
import { History } from 'history'
import QueryString from 'query-string'
import { useSelector } from 'react-redux'
import { Box, Typography, TableContainer, TablePagination } from '@mui/material'
import { getSelectedProvider } from '../../store/providerSelection/providerSelector'
import ErrorPageContent from '../../components/ErrorPage/ErrorPageContent'
import BookingsFilter, { FilterChange, SortChange } from './BookingsFilter'
import EmptyBookings from './EmptyBookings'
import { Page, PageContent, PageHeader } from '@holidu/pui-components/dist'
import useBookings from './useGetBookings'
import { PATH_BOOKINGS } from '../../routes/PathConstants'
import { FilterQueryType, SortOrder } from './types'
import { useIntl } from 'react-intl'
import messageKeys from './messages'
import { messageKeys as globalMessages } from '../../globalMessages'
import millify from 'millify'
import BookingsTable from './BookingsTable'
import Loader from '../../components/Loader'
import Analytics from '../../utils/analytics'
import {
  BOOKINGS_FILTER,
  BOOKINGS_PAGE_CHANGE,
  BOOKINGS_PAGE_SIZE_CHANGE,
  BOOKINGS_SORT,
  BOOKINGS_DOWNLOAD_CSV
} from '../../utils/analytics/events'
import { DateRangeChange } from '../../components/DateRangeFilter'

import BookingsActions from './BookingsActions'

type BookingsProps = {
  history: History
}

const DISPLAY_LIMIT = 10000

const Bookings = (props: BookingsProps): JSX.Element => {
  const { selectedProvider } = useSelector(getSelectedProvider)
  const intl = useIntl()

  const { status, data, error, isLoading, isFetching } =
    useBookings(selectedProvider)

  const totalCount = data?.totalCount || 0

  const locationSearch = QueryString.parse(window.location.search, {
    parseNumbers: true
  })

  const filterQuery: FilterQueryType = {
    searchText: (locationSearch.searchText as string) || '',
    transactionId: (locationSearch.transactionId as string) || '',
    status: (locationSearch.status as string) || '',
    arrivalDate: (locationSearch.arrivalDate as string) || '',
    bookedAtFrom: (locationSearch.bookedAtFrom as string) || '',
    bookedAtTo: (locationSearch.bookedAtTo as string) || '',
    sortBy: (locationSearch.sortBy as string) || 'BOOKED_AT',
    sortOrder: (locationSearch.sortOrder as SortOrder) || 'DESC',
    page: (locationSearch.page as number) || 0,
    size: (locationSearch.size as number) || 10
  }

  const redirectToNewLocation = (newLocationSearch: any) => {
    const query = QueryString.stringify(newLocationSearch, {
      skipNull: true,
      skipEmptyString: true
    })
    props.history.push(`${PATH_BOOKINGS}?${query}`)
    const pageContentEle = document.querySelector('[data-screen=PageContent]')
    if (pageContentEle) {
      pageContentEle.scrollTop = 0
    }
  }

  const handleFilterChange = ({ name, value }: FilterChange) => {
    const locationSearch = QueryString.parse(window.location.search, {
      parseNumbers: true
    })
    const newLocationSearch = {
      ...locationSearch,
      [name]: value,
      page: 0
    }
    Analytics.trackEvent(BOOKINGS_FILTER, { name, value })
    redirectToNewLocation(newLocationSearch)
  }

  const handleBookingDateFilterChange = ({
    from,
    to,
    triggeredBy
  }: DateRangeChange) => {
    const locationSearch = QueryString.parse(window.location.search, {
      parseNumbers: true
    })
    const newLocationSearch = {
      ...locationSearch,
      bookedAtFrom: from || null,
      bookedAtTo: to || null,
      page: 0
    }
    Analytics.trackEvent(BOOKINGS_FILTER, {
      name: 'bookingDateRange',
      value: { from, to, triggeredBy }
    })

    redirectToNewLocation(newLocationSearch)
  }

  const handleSortChange = ({ sortBy, sortOrder }: SortChange) => {
    const locationSearch = QueryString.parse(window.location.search, {
      parseNumbers: true
    })
    const newLocationSearch = {
      ...locationSearch,
      sortBy,
      sortOrder,
      page: 0
    }
    Analytics.trackEvent(BOOKINGS_SORT, { sortBy, sortOrder })
    redirectToNewLocation(newLocationSearch)
  }

  const handlePageChange = (event: unknown, newPage: number) => {
    const locationSearch = QueryString.parse(window.location.search, {
      parseNumbers: true
    })
    const newLocationSearch = {
      ...locationSearch,
      page: newPage
    }
    Analytics.trackEvent(BOOKINGS_PAGE_CHANGE, { page: newPage })
    redirectToNewLocation(newLocationSearch)
  }

  const handleRowsPerPageChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const locationSearch = QueryString.parse(window.location.search, {
      parseNumbers: true
    })
    const size = parseInt(event.target.value, 10)
    const newLocationSearch = {
      ...locationSearch,
      size,
      page: 0
    }
    Analytics.trackEvent(BOOKINGS_PAGE_SIZE_CHANGE, { size })
    redirectToNewLocation(newLocationSearch)
  }

  const handleDownloadCsv = () =>
    Analytics.trackEvent(BOOKINGS_DOWNLOAD_CSV, locationSearch)

  return (
    <Page>
      <PageHeader
        sx={{
          borderBottom: 'none'
        }}
      >
        <Typography variant="h6">
          {intl.formatMessage(messageKeys.Title)}
        </Typography>
        <Box flexGrow={1} />
        <BookingsActions
          onDownloadCsv={handleDownloadCsv}
          totalCount={totalCount}
          disabled={isFetching || !totalCount}
        />
      </PageHeader>
      <PageContent px={3} pb={8}>
        <BookingsFilter
          {...filterQuery}
          totalCount={totalCount}
          onFilterChange={handleFilterChange}
          onBookingDateFilterChange={handleBookingDateFilterChange}
          onSortChange={handleSortChange}
        />
        {status === 'error' ? (
          <ErrorPageContent status={error.message} />
        ) : (
          <>
            {data && data?.bookings?.length ? (
              <Box>
                <TableContainer
                  sx={{
                    border: theme => `1px solid ${theme.palette.grey[300]}`,
                    borderRadius: '10px',
                    background: theme => theme.palette.common.white
                  }}
                >
                  <BookingsTable data={data.bookings} />
                </TableContainer>
                <Box
                  py={1}
                  display="flex"
                  flexDirection="row-reverse"
                  flexWrap="wrap"
                  alignItems="center"
                >
                  <TablePagination
                    rowsPerPageOptions={[10, 20, 30]}
                    component="div"
                    count={totalCount || 0}
                    rowsPerPage={filterQuery.size}
                    page={filterQuery.page}
                    onPageChange={handlePageChange}
                    onRowsPerPageChange={handleRowsPerPageChange}
                    labelRowsPerPage={intl.formatMessage(
                      globalMessages.ROWS_PER_PAGE
                    )}
                  />
                  {totalCount >= DISPLAY_LIMIT && (
                    <Typography variant="caption" ml={2}>
                      {intl.formatMessage(messageKeys.DisplayLimit, {
                        limit: millify(DISPLAY_LIMIT)
                      })}
                    </Typography>
                  )}
                </Box>
              </Box>
            ) : (
              !(isLoading || isFetching) && <EmptyBookings />
            )}
          </>
        )}
      </PageContent>
      {(isLoading || isFetching) && <Loader />}
    </Page>
  )
}

export default Bookings
