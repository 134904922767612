import axios from 'axios'
import QueryUtils from 'query-string'
import { saveAs } from 'file-saver'
import { useMutation } from '@tanstack/react-query'
import { useSnackbar } from 'notistack'
import { useIntl } from 'react-intl'
import messageKeys from './messages'
import { getArrivalDate } from './utils'

type Context = {
  filename: string
}

const useBookingsDownload = (provider: string) => {
  const { enqueueSnackbar } = useSnackbar()
  const intl = useIntl()
  return useMutation<any, Error>(
    async () => {
      const {
        searchText,
        transactionId,
        status,
        arrivalDate,
        bookedAtFrom,
        bookedAtTo,
        sortBy,
        sortOrder
      } = QueryUtils.parse(window.location.search)

      const queryParams = {
        provider,
        searchText,
        transactionId,
        status,
        ...getArrivalDate(arrivalDate),
        bookedAtFrom,
        bookedAtTo,
        sortBy: sortBy || 'BOOKED_AT',
        sortOrder: sortOrder || 'DESC'
      }

      const query = QueryUtils.stringify(queryParams, { strict: true })
      return await axios.get(`/bookings/csv?${query}`)
    },
    {
      onMutate: () => {
        const { searchText, transactionId, status, arrivalDate } =
          QueryUtils.parse(window.location.search)

        const time = new Date().toISOString().split('T')[0]
        const filenameTokens = ['Bookings', provider, time]
        if (searchText || transactionId || status || arrivalDate) {
          filenameTokens.push('filtered')
        }

        const context = {
          filename: `${filenameTokens.join('_')}.csv`
        }
        return context
      },
      onError: error => {
        enqueueSnackbar(
          intl.formatMessage(messageKeys.DownloadCsvError, {
            message: error.message
          }),
          {
            variant: 'error'
          }
        )
      },
      onSuccess: (data, variables, context) => {
        const textData: string = data?.data

        if (typeof textData === 'string') {
          const blob = new Blob([textData], {
            type: 'data:text/csv;charset=utf-8'
          })
          saveAs(blob, (context as Context).filename)

          enqueueSnackbar(intl.formatMessage(messageKeys.DownloadCsvSuccess), {
            variant: 'success'
          })
        } else {
          enqueueSnackbar(
            intl.formatMessage(messageKeys.DownloadCsvError, {
              message: 'Empty'
            }),
            {
              variant: 'error'
            }
          )
        }
      }
    }
  )
}

export default useBookingsDownload
