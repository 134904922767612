import React from 'react'
import { Typography, Stack } from '@mui/material'
import { FormattedMessage } from 'react-intl'
import { messageKeys } from './messages'

const ConfirmPage = () => {
  return (
    <Stack spacing={2} alignItems="center">
      <Typography variant="h6">
        <FormattedMessage {...messageKeys.ConfirmTitle} />
      </Typography>
      <Typography variant="body1">
        <FormattedMessage {...messageKeys.ConfirmMessage} />
      </Typography>
    </Stack>
  )
}

export default ConfirmPage
