import { styled, Chip } from '@mui/material'

type PropertyStatusChipProps = {
  status: string
}
const PropertyStatusChip = styled(Chip)<PropertyStatusChipProps>(({ status, theme }) => {
  if (status === 'ACTIVE') {
    return {
      background: theme.palette.success.main,
      color: theme.palette.success.contrastText
    }
  } else if (status === 'INACTIVE') {
    return {
      background: theme.palette.error.main,
      color: theme.palette.error.contrastText
    }
  }
  return {}
})

export default PropertyStatusChip
