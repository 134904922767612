import React from 'react'
import { Chip, Stack } from '@mui/material'
import { PhotoTag } from '../types'
import { useIntl } from 'react-intl'
import messageKeys from '../messages'
import { LabelValue } from '@holidu/pui-components/dist'

type PhotoTagsProps = {
  values: PhotoTag[]
}

const PhotoTags = (props: PhotoTagsProps): JSX.Element => {
  const { values } = props
  const intl = useIntl()
  return (
    <LabelValue
      label={intl.formatMessage(messageKeys.PHOTO_TAGS)}
      value={
        <Stack direction="row" flexWrap="wrap" gap={2}>
          {values.map(({ photoTag, mainPhotoStatus }) => {
            return (
              <Chip
                key={photoTag}
                label={photoTag}
                size="small"
                color={
                  mainPhotoStatus === 'GOOD'
                    ? 'success'
                    : mainPhotoStatus === 'BAD'
                    ? 'error'
                    : 'default'
                }
              />
            )
          })}
        </Stack>
      }
    />
  )
}

export default PhotoTags
