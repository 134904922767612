import React, { useState, useEffect, useRef } from 'react'
import { Button, Grid, MenuItem, Stack } from '@mui/material'
import SaveIcon from '@mui/icons-material/Save'
import { Map } from 'immutable'
import { FormattedMessage, injectIntl } from 'react-intl'
import countryList from 'react-select-country-list'
import pick from 'lodash/pick'
import { useSnackbar } from '../../utils/snackBar'
import { startTransaction } from '../../utils/apm'
import { messageKeys as globalMessageKeys } from '../../globalMessages'
import { validateEmail, validateVAT, validateZip } from '../../utils/validation'
import MultiTextFieldInput from '../../components/MultiTextFieldInput'
import Section from '../../components/Section'
import { VatUtil, ZipUtil } from '@holidu/pui-utils/dist'
import ValidationTextField from '../../components/Fields/ValidationTextField'
import ValidationEmailField from '../../components/Fields/ValidationEmailField'
import ValidationHint from '../../components/Fields/ValidationHint'
import { messageKeys } from './messages'
import VatValidationErrorDialog from '../../widgets/VatValidationErrorDialog'
import useGetValidateVat from '../../hooks/useGetValidateVat'
import Loader from '../../components/Loader'
import Analytics from '../../utils/analytics'
import { BILLING_INFORMATION_SAVE } from '../../utils/analytics/events'

const BillingInformationPage = props => {
  const { disabled } = props
  const { showSuccessSnackbar, showErrorSnackbar } = useSnackbar(
    <FormattedMessage {...messageKeys.Title} />
  )
  const [hasChanges, setHasChanges] = useState(false)
  const [data, setData] = useState({})
  const [errors, setErrors] = useState({})
  const [vatValidationError, setVatValidationError] = useState(null)

  const prevData = useRef({})

  const {
    isLoading: isVatValidationLoading,
    mutateAsync: mutateVatValidation
  } = useGetValidateVat()

  const { intl, updateCompany } = props

  useEffect(() => {
    const companyData = props.companies
      .get(props.selectedProvider, Map({}))
      .toJSON()
    const nextCompanyData = {
      ...pick(companyData, [
        'legalCompanyName',
        'street',
        'number',
        'zip',
        'city',
        'country',
        'vatNumber',
        'contactLanguage',
        'invoiceRecipients',
        'invoiceCopyRecipients'
      ])
    }

    prevData.current = {
      ...nextCompanyData
    }
    setData(nextCompanyData)
  }, [props.selectedProvider, props.companies]) // eslint-disable-line

  const onSubmit = async () => {
    Analytics.trackEvent(BILLING_INFORMATION_SAVE)
    if (!allMandatoryFieldsAreFilled()) {
      showErrorSnackbar(
        <FormattedMessage {...globalMessageKeys.ErrorDoNotDeleteMandatory} />
      )
      return
    }
    if (!allFilledFieldsAreValid()) {
      showErrorSnackbar(
        <FormattedMessage {...globalMessageKeys.ErrorInvalidDataInForm} />
      )
      return
    }

    const previousVat = props.companies
      .get(props.selectedProvider, Map({}))
      .get('vatNumber')
    if (
      data.vatNumber &&
      data.country !== 'DE' &&
      data.vatNumber !== previousVat
    ) {
      const response = await mutateVatValidation({
        vatNumber: data.vatNumber,
        legalCompanyName: data.legalCompanyName,
        city: data.city,
        zip: data.zip,
        country: data.country
      })

      if (response.data?.valid === true) {
        setVatValidationError(null)
      } else {
        setErrors({
          ...errors,
          vatNumber: 'vatValidation'
        })
        setVatValidationError(response.data)
        return
      }
    }

    const transaction = startTransaction('BillingInformationPage#Save')
    const companyData = props.companies
      .get(props.selectedProvider, Map({}))
      .toJSON()

    updateCompany(
      {
        ...companyData,
        ...data
      },
      () => {
        showSuccessSnackbar()
        setHasChanges(false)
        transaction.end()
      },
      error => {
        showErrorSnackbar(error.text)
        transaction.end()
      }
    )
  }

  const allMandatoryFieldsAreFilled = () => {
    let allGood = true
    Object.keys(errors).forEach(field => {
      const validationFailReason = errors[field]
      if (
        prevData.current &&
        prevData.current[field] &&
        validationFailReason === 'required'
      ) {
        allGood = false
      }
    })

    return allGood
  }

  const allFilledFieldsAreValid = () => {
    let allGood = true
    Object.keys(errors).forEach(field => {
      const validationFailReason = errors[field]
      if (validationFailReason && validationFailReason !== 'required') {
        allGood = false
      }
    })
    return allGood
  }

  const handleTextFieldChange = (e, errorReason) => {
    const { name, value } = e.target
    setData({
      ...data,
      [name]: value || null
    })
    setErrors({
      ...errors,
      [name]: errorReason
    })
    setHasChanges(true)
  }

  const handleCountryChange = (e, err) => {
    const { name, value } = e.target
    setData({
      ...data,
      [name]: value,
      vatNumber: null,
      zip: null
    })
    setErrors({
      ...errors,
      [name]: err,
      vatNumber:
        VatUtil.isValidCountry(value) && prevData?.current?.vatNumber
          ? 'required'
          : null
    })
    setHasChanges(true)
  }

  const handleInvoiceCopyChange = (name, value) => {
    setData({ ...data, invoiceCopyRecipients: value.join(',') })
    const isInValid = value.some(v => !validateEmail(v))
    if (isInValid) {
      setErrors(prevErrors => ({
        ...prevErrors,
        invoiceCopyRecipients: 'invalidEmail'
      }))
    } else {
      setErrors(prevErrors => ({
        ...prevErrors,
        invoiceCopyRecipients: null
      }))
    }
    setHasChanges(true)
  }

  const handleVatValidationDialogClose = () => {
    setVatValidationError(null)
  }

  return (
    <>
      <form autoComplete="off">
        <Stack spacing={4}>
          <Section
            title={<FormattedMessage {...messageKeys.Header} />}
            subtitle={<FormattedMessage {...messageKeys.SubHeader} />}
          >
            <Grid spacing={4} container>
              {/* Legal Name */}
              <Grid xs={12} lg={12} item>
                <ValidationTextField
                  name="legalCompanyName"
                  error={errors.legalCompanyName}
                  label={<FormattedMessage {...messageKeys.LegalName} />}
                  value={data.legalCompanyName || ''}
                  fullWidth
                  required
                  disabled={disabled}
                  onChange={handleTextFieldChange}
                />
              </Grid>
              {/* Billing Address */}
              <Grid xs={12} lg={6} item>
                <ValidationTextField
                  name="street"
                  error={errors.street}
                  label={<FormattedMessage {...messageKeys.BillingAddress} />}
                  value={data.street || ''}
                  fullWidth
                  required
                  disabled={disabled}
                  onChange={handleTextFieldChange}
                />
              </Grid>
              {/* House Number */}
              <Grid xs={12} lg={3} item>
                <ValidationTextField
                  name="number"
                  error={errors.number}
                  label={
                    <FormattedMessage
                      {...messageKeys.BillingAddressHouseNumber}
                    />
                  }
                  value={data.number || ''}
                  fullWidth
                  required
                  disabled={disabled}
                  onChange={handleTextFieldChange}
                />
              </Grid>
              {/* City */}
              <Grid xs={12} lg={3} item>
                <ValidationTextField
                  name="city"
                  error={errors.city}
                  label={
                    <FormattedMessage {...messageKeys.BillingAddressCity} />
                  }
                  value={data.city || ''}
                  fullWidth
                  required
                  disabled={disabled}
                  onChange={handleTextFieldChange}
                />
              </Grid>
              {/* Country */}
              <Grid xs={12} lg={4} item>
                <ValidationTextField
                  error={errors.country}
                  name="country"
                  label={
                    <FormattedMessage {...messageKeys.BillingAddressCountry} />
                  }
                  value={data.country || ''}
                  fullWidth
                  required
                  select
                  disabled={disabled}
                  onChange={handleCountryChange}
                >
                  <MenuItem value="" disabled>
                    <FormattedMessage {...globalMessageKeys.Select} />
                  </MenuItem>
                  {countryList()
                    .getData()
                    .map((country, index) => (
                      <MenuItem key={index} value={country.value}>
                        {country.label}
                      </MenuItem>
                    ))}
                </ValidationTextField>
              </Grid>
              {/* ZIP Code */}
              <Grid xs={12} lg={3} item>
                <ValidationTextField
                  name="zip"
                  error={errors.zip}
                  label={
                    <FormattedMessage {...messageKeys.BillingAddressZip} />
                  }
                  value={data.zip || ''}
                  fullWidth
                  required
                  disabled={disabled}
                  validations={{
                    zipValidation: {
                      validator: zip => validateZip(data.country, zip),
                      message: (
                        <FormattedMessage
                          {...globalMessageKeys.ErrorInvalidZipCode}
                        />
                      )
                    }
                  }}
                  onChange={handleTextFieldChange}
                />
                {ZipUtil.isValidCountry(data.country) && (
                  <ValidationHint>
                    <FormattedMessage {...globalMessageKeys.ForExample} />
                    :&nbsp;{ZipUtil.getZipSample(data.country)}
                  </ValidationHint>
                )}
              </Grid>
              <Grid xs={12} lg={5} item>
                <ValidationTextField
                  disabled={disabled || !VatUtil.isValidCountry(data.country)}
                  error={errors.vatNumber}
                  validations={{
                    vatValidation: {
                      validator: vatNumber =>
                        validateVAT(data.country, vatNumber),
                      message: (
                        <FormattedMessage
                          {...globalMessageKeys.ErrorInvalidVatNumber}
                        />
                      )
                    }
                  }}
                  name="vatNumber"
                  label={<FormattedMessage {...messageKeys.BillingVatNumber} />}
                  value={data.vatNumber || ''}
                  fullWidth
                  required={
                    VatUtil.isValidCountry(data.country) &&
                    !!prevData?.current?.vatNumber
                  }
                  onChange={handleTextFieldChange}
                />
                {VatUtil.isValidCountry(data.country) && (
                  <ValidationHint>
                    <FormattedMessage {...globalMessageKeys.ForExample} />
                    :&nbsp;{VatUtil.getVatSample(data.country)}
                  </ValidationHint>
                )}
              </Grid>
              <Grid xs={12} lg={4} item>
                <ValidationEmailField
                  name="invoiceRecipients"
                  error={errors.invoiceRecipients}
                  label={
                    <FormattedMessage {...messageKeys.InvoiceRecipients} />
                  }
                  value={data.invoiceRecipients || ''}
                  fullWidth
                  required
                  disabled={disabled}
                  onChange={handleTextFieldChange}
                />
              </Grid>
              <Grid xs={12} lg={4} item>
                <ValidationTextField
                  name="contactLanguage"
                  error={errors.contactLanguage}
                  label={<FormattedMessage {...messageKeys.ContactLanguage} />}
                  value={data.contactLanguage || ''}
                  fullWidth
                  required
                  select
                  disabled={disabled}
                  onChange={handleTextFieldChange}
                >
                  <MenuItem value="" disabled>
                    <FormattedMessage {...globalMessageKeys.Select} />
                  </MenuItem>
                  <MenuItem value="EN">English</MenuItem>
                  <MenuItem value="DE">Deutsch</MenuItem>
                </ValidationTextField>
              </Grid>
              <Grid xs={12} item>
                <MultiTextFieldInput
                  error={Boolean(errors.invoiceCopyRecipients)}
                  helperText={
                    errors.invoiceCopyRecipients ? (
                      <FormattedMessage
                        {...globalMessageKeys.ErrorInvalidEmailId}
                      />
                    ) : (
                      ''
                    )
                  }
                  name="invoiceCopyRecipients"
                  label={
                    <FormattedMessage {...messageKeys.InvoiceCopyRecipients} />
                  }
                  value={
                    data.invoiceCopyRecipients
                      ? data.invoiceCopyRecipients.split(',')
                      : []
                  }
                  checkItemErrorState={v => !validateEmail(v)}
                  placeholder={intl.formatMessage({
                    ...messageKeys.InvoiceCopyRecipientsPlaceholder
                  })}
                  fullWidth
                  disabled={disabled}
                  onChange={handleInvoiceCopyChange}
                />
              </Grid>
            </Grid>
          </Section>
          {!disabled && (
            <Button
              size="large"
              color="primary"
              disabled={!hasChanges}
              startIcon={<SaveIcon />}
              variant="contained"
              fullWidth
              onClick={onSubmit}
            >
              <FormattedMessage {...globalMessageKeys.SaveButton} />
            </Button>
          )}
        </Stack>
      </form>
      <VatValidationErrorDialog
        vatValidationError={vatValidationError}
        onVatValidationErrorClose={handleVatValidationDialogClose}
      />
      {isVatValidationLoading && <Loader />}
    </>
  )
}

export default injectIntl(BillingInformationPage)
