import { mutateAsync } from 'redux-query-immutable'
import { updateCompanyRequest } from './companyRequest'

export const updateCompany = (
  data,
  onSuccess = () => {},
  onError = () => {}
) => {
  return dispatch => {
    return dispatch(mutateAsync(updateCompanyRequest(data))).then(response => {
      if (response.status === 200) {
        onSuccess(response)
      } else {
        onError(response)
      }
    })
  }
}
