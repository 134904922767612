export const BOOKING_STATUS = {
  PENDING: {
    text: 'PENDING',
    color: 'warning'
  },
  CONFIRMED: {
    text: 'CONFIRMED',
    color: 'primary'
  },
  PAID: {
    text: 'PAID',
    color: 'success'
  },
  OVERDUE: {
    text: 'OVERDUE',
    color: 'error'
  },
  CANCELLED: {
    text: 'CANCELLED',
    color: 'error'
  },
  C_USER: {
    text: 'CANCELLED BY USER',
    color: 'error'
  },
  C_PAYMENT: {
    text: 'PAYMENT FAILED',
    color: 'error'
  },
  C_HO: {
    text: 'CANCELLED BY OWNER',
    color: 'error'
  },
  C_BOOKIPLY: {
    text: 'CANCELLED BY BOOKIPLY',
    color: 'error'
  },
  C_HOLIDU: {
    text: 'CANCELLED BY HOLIDU',
    color: 'error'
  },
  FAILED: {
    text: 'FAILED',
    color: 'error'
  }
}

export const BOOKING_STATUS_LIST = Object.keys(BOOKING_STATUS)
