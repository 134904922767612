import React from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from '@mui/material'
import { useIntl } from 'react-intl'
import messageKeys from './messages'
import { messageKeys as globalMessageKeys } from '../../../globalMessages'

type ConfirmDialogProps = {
  open?: boolean
  onNo: () => void
  onYes: () => void
}

const ConfirmDialog = (props: ConfirmDialogProps) => {
  const intl = useIntl()
  const { open = false, onNo, onYes } = props
  return (
    <Dialog open={open}>
      <DialogTitle>
        {intl.formatMessage(messageKeys.CancelConfirmTitle)}
      </DialogTitle>
      <DialogContent>
        {intl.formatMessage(messageKeys.CancelConfirmDesc)}
      </DialogContent>
      <DialogActions>
        <Button onClick={onNo} color="primary">
          {intl.formatMessage(globalMessageKeys.No)}
        </Button>
        <Button autoFocus onClick={onYes} variant="contained" color="primary">
          {intl.formatMessage(globalMessageKeys.Yes)}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ConfirmDialog
