import { useIntl } from 'react-intl'
import { ProviderConfiguration } from '../../hooks/types/ProviderConfiguration'
import { messageKeys } from './messages'

export const useCustomerPaymentsMissingFields = (
  providerConfig?: ProviderConfiguration
): any => {
  const intl = useIntl()
  let missingFieldsForCustomerPaymentOptions = []
  if (
    providerConfig &&
    // not hopa
    !providerConfig?.holiduHandlesPayments &&
    // not connfigured on request
    providerConfig?.paymentMethodConfigSource !== 'request' &&
    // has atleast 1 payment methods
    !providerConfig?.paymentMethods?.length
  ) {
    missingFieldsForCustomerPaymentOptions.push(
      intl.formatMessage(messageKeys.PaymentOptionsHeader)
    )
  }
  return missingFieldsForCustomerPaymentOptions
}
