import React from 'react'
import { MenuItem, Stack, TextField } from '@mui/material'
import { useIntl } from 'react-intl'
import messageKeys from '../../messages'
import { HERO_IMAGE_STATUS_FILTER_OPTIONS } from '../../utils'
import CircleIcon from '@mui/icons-material/Circle'

type HeroImageContentFilterProps = {
  value: string
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
}

const HeroImageContentFilter = (
  props: HeroImageContentFilterProps
): JSX.Element => {
  const { value = '', onChange } = props
  const intl = useIntl()
  return (
    <TextField
      label={intl.formatMessage(messageKeys.ApartmentFilterHeroImageContent)}
      margin="dense"
      name="heroImageContent"
      variant="standard"
      sx={{
        width: 240
      }}
      value={value}
      onChange={onChange}
      select
    >
      <MenuItem value="">
        {intl.formatMessage(messageKeys.ApartmentFilterHeroImageAll)}
      </MenuItem>
      {HERO_IMAGE_STATUS_FILTER_OPTIONS.map(option => (
        <MenuItem key={option.value} value={option.value}>
          <Stack direction="row" alignItems="center" spacing={1}>
            <CircleIcon
              fontSize="small"
              color={option.color as 'success' | 'error' | 'disabled'}
            />
            <span>{option.value}</span>
          </Stack>
        </MenuItem>
      ))}
    </TextField>
  )
}

export default HeroImageContentFilter
