import React from 'react'
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack,
  Tooltip,
  Typography
} from '@mui/material'
import SubSection from '../../../components/Section/SubSection'
import { useIntl } from 'react-intl'
import messageKeys from '../messages'
import LabelImportantIcon from '@mui/icons-material/LabelImportant'
import TipsAndUpdatesIcon from '@mui/icons-material/TipsAndUpdates'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import Analytics from '../../../utils/analytics'
import { APARTMENT_DETAIL_HOVER_OPPORTUNITIES_TOOLTIP } from '../../../utils/analytics/events'

type OpportunitiesProps = {
  values: string[]
}

const TRANSLATION_KEY_PREFIX = 'PropertyDetail:OPPORTUNITY_'
const TRANSLATION_KEY_TOOLTIP_POSTFIX = '_EXPLANATION'

const Opportunities = (props: OpportunitiesProps): JSX.Element => {
  const { values } = props
  const intl = useIntl()

  const handleOpportunityMouseEnter = () => {
    Analytics.trackEvent(APARTMENT_DETAIL_HOVER_OPPORTUNITIES_TOOLTIP)
  }
  
  return (
    <SubSection
      title={
        <Stack direction="row" alignItems="center" spacing={1}>
          <TipsAndUpdatesIcon color="info" fontSize="small" />
          <Typography
            variant="subtitle2"
            sx={{
              color: theme => theme.palette.info.main
            }}
          >
            {intl.formatMessage(messageKeys.OPPORTUNITIES)}
          </Typography>
        </Stack>
      }
    >
      <List dense disablePadding>
        {values.map(value => {
          return (
            <ListItem
              key={value}
              disableGutters
              sx={{
                alignItems: 'start',
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: '32px',
                  marginTop: '4px'
                }}
              >
                <LabelImportantIcon fontSize="small" color="secondary" />
              </ListItemIcon>
              <ListItemText
                primary={intl.formatMessage({
                  id: `${TRANSLATION_KEY_PREFIX}${value}`,
                  defaultMessage: `${TRANSLATION_KEY_PREFIX}${value}`
                })}
              />
              {intl.messages.hasOwnProperty(
                `${TRANSLATION_KEY_PREFIX}${value}${TRANSLATION_KEY_TOOLTIP_POSTFIX}`
              ) && (
                <Tooltip
                  placement='left'
                  title={intl.formatMessage({
                    id: `${TRANSLATION_KEY_PREFIX}${value}${TRANSLATION_KEY_TOOLTIP_POSTFIX}`,
                    defaultMessage: `${TRANSLATION_KEY_PREFIX}${value}${TRANSLATION_KEY_TOOLTIP_POSTFIX}`
                  })}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: '32px',
                      marginTop: '4px'
                    }}
                    onMouseEnter={handleOpportunityMouseEnter}
                  >
                    <InfoOutlinedIcon color="info" fontSize="small" />
                  </ListItemIcon>
                </Tooltip>
              )}
            </ListItem>
          )
        })}
      </List>
    </SubSection>
  )
}

export default Opportunities
