import React from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { connectRequest } from 'redux-query-immutable-react'

import { getGeneralConfigSelector } from '../../store/providerConfiguration/providerConfigurationSelector'
import { RequestsAreDone } from '../RequestsAreDone'
import { updateGeneralConfigurationWithLogo } from '../../store/providerConfiguration/actions'
import { getProviderRequest } from '../../store/provider/providerRequest'
import { getProviderConfigurationRequest } from '../../store/providerConfiguration/providerConfigurationRequest'
import { getSelectedProvider } from '../../store/providerSelection/providerSelector'
import { getProviderSelector } from '../../store/provider/providerSelector'
import { getMissingFieldsForIntegrationBasics } from '../Dashboard/OnBoarding/RequiredFieldsFetcher'
import InformationMissingError from '../../components/InformationMissingError'
import IntegrationBasicsPage from './IntegrationBasicsPage'
import { Page, PageContent, PageHeader } from '@holidu/pui-components/dist'
import { Typography } from '@mui/material'
import { FormattedMessage } from 'react-intl'
import { messageKeys } from './messages'

const IntegrationBasics = props => {
  const { providers, providersConfig, selectedProvider } = props
  const provider = providers.get(selectedProvider)
  const providerConfig = providersConfig.get(selectedProvider)
  const ibFields = getMissingFieldsForIntegrationBasics(
    provider,
    providerConfig
  )

  return (
    <Page>
      <PageHeader
        sx={{
          borderBottom: 'none'
        }}
      >
        <Typography variant="h6">
          <FormattedMessage {...messageKeys.Title} />
        </Typography>
      </PageHeader>
      <PageContent px={3} pb={8}>
        {provider && provider.get('status') !== 'LIVE' && ibFields.length ? (
          <InformationMissingError />
        ) : null}
        <IntegrationBasicsPage
          integrationChannel={props.integrationChannel}
          providers={props.providers}
          providersConfig={props.providersConfig}
          saveGeneralConfiguration={props.saveGeneralConfiguration}
          selectedProvider={props.selectedProvider}
        />
      </PageContent>
    </Page>
  )
}

const mapDispatchToProps = (dispatch, props) => ({
  saveGeneralConfiguration: (data, onSuccess, onError) => {
    dispatch(
      updateGeneralConfigurationWithLogo(
        { ...data, selectedProvider: props.selectedProvider },
        onSuccess,
        onError
      )
    )
  }
})

export default compose(
  connect(getSelectedProvider),
  connect(getGeneralConfigSelector, mapDispatchToProps),
  connect(getProviderSelector),
  connectRequest(getProviderConfigurationRequest),
  connectRequest(getProviderRequest),
  RequestsAreDone
)(IntegrationBasics)
