import env from '../../env'
import { currentUserIdToken } from '../../utils/awsCognitoClient'

export const getBizDevsRequest = () => ({
  url: `${env.BACKEND}/provider/business-developers`,
  transform: response => ({
    bizDevs: response
  }),
  update: {
    bizDevs: (prev, next) => next
  },
  options: {
    headers: {
      Authorization: 'Bearer ' + currentUserIdToken()
    }
  }
})
