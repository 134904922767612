import axios from 'axios'
import { useQuery } from '@tanstack/react-query'
import { CancellationReasons } from '../types'
import QueryKeys from '../../../utils/queryKeys'

const useGetBookingCancellationReasons = () => {
  return useQuery<any, Error, CancellationReasons>(
    [QueryKeys.BookingCancellationReasons],
    async () => {
      const { data } = await axios.get(`/bookings/cancellation-reasons`)
      return data
    },
    { keepPreviousData: false, retry: false }
  )
}

export default useGetBookingCancellationReasons
