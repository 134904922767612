import React, { useState } from 'react'
import { Button, Menu, MenuItem } from '@mui/material'
import PersonIcon from '@mui/icons-material/Person'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import { useHistory } from 'react-router-dom'
import { connect } from 'react-redux'
import { connectRequest } from 'redux-query-immutable-react'
import { compose } from 'redux'
import { getUserRequest } from '../../store/user/userRequest'
import { getUserSelector } from '../../store/user/userSelector'
import { PATH_PROFILE } from '../../routes/PathConstants'
import { signOut } from '../../utils/awsCognitoClient'
import Analytics from '../../utils/analytics'
import { SIGN_OUT } from '../../utils/analytics/events'

const UserMenu = props => {
  const { size, user } = props
  const [anchorMenu, setAnchorMenu] = useState(null)
  const history = useHistory()

  const toggleMenu = e => {
    setAnchorMenu(e.currentTarget)
  }

  const handlProfileClick = () => {
    history.push(PATH_PROFILE)
    handleCloseMenu()
  }

  const handleSignOutClick = () => {
    Analytics.trackEvent(SIGN_OUT)
    signOut()
    handleCloseMenu()
    window.location.replace('/')
  }

  const handleCloseMenu = () => {
    setAnchorMenu(null)
  }

  const open = Boolean(anchorMenu)
  if (user && user.size) {
    return (
      <>
        <Button
          data-ga-label="User Menu"
          size={size}
          startIcon={<PersonIcon />}
          endIcon={<ArrowDropDownIcon />}
          aria-haspopup="true"
          aria-owns={open ? 'menu-user' : undefined}
          color="inherit"
          onClick={toggleMenu}
        >
          {user.get('firstName')}
        </Button>
        <Menu
          anchorEl={anchorMenu}
          id="menu-user"
          open={open}
          onClose={handleCloseMenu}
        >
          <MenuItem dense onClick={handlProfileClick}>
            Profile
          </MenuItem>
          <MenuItem dense onClick={handleSignOutClick}>
            Sign out
          </MenuItem>
        </Menu>
      </>
    )
  } else {
    return null
  }
}
export default compose(
  connect(getUserSelector),
  connectRequest(getUserRequest)
)(UserMenu)
