import { Box, styled } from "@mui/material"

type StyledImageProps = {
  alt: string
  src: string
}

const PropertyImage = styled(Box)<StyledImageProps>(({ src, theme }) => {
  return {
    backgroundImage: `url(${src})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    height: '100%',
    minHeight: '300px',
    minWidth: '100px',
    [theme.breakpoints.down('sm')]: {
      borderRadius: theme.spacing(1, 1, 0, 0),
    },
    [theme.breakpoints.up('sm')]: {
      borderRadius: theme.spacing(1, 0, 0, 1),
    }
  }
})

export default PropertyImage
