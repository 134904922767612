export const messageKeys = {
  TITLE: {
    id: 'PropertyDetail:TITLE',
    defaultMessage: 'PropertyDetail:TITLE'
  },
  APARTMENT: {
    id: 'PropertyDetail:APARTMENT',
    defaultMessage: 'PropertyDetail:APARTMENT'
  },
  SHOW_ON_MAP: {
    id: 'PropertyDetail:SHOW_ON_MAP',
    defaultMessage: 'PropertyDetail:SHOW_ON_MAP'
  },
  SHOW_ON_HOLIDU: {
    id: 'PropertyDetail:SHOW_ON_HOLIDU',
    defaultMessage: 'PropertyDetail:SHOW_ON_HOLIDU'
  },
  VALIDATION_ERROR_HEADER: {
    id: 'PropertyDetail:VALIDATION_ERROR_HEADER',
    defaultMessage: 'PropertyDetail:VALIDATION_ERROR_HEADER'
  },
  VALIDATION_ERROR_SHOW_MORE: {
    id: 'PropertyDetail:VALIDATION_ERROR_SHOW_MORE',
    defaultMessage: 'PropertyDetail:VALIDATION_ERROR_SHOW_MORE {value}'
  },
  VALIDATION_ERROR_SHOW_LESS: {
    id: 'PropertyDetail:VALIDATION_ERROR_SHOW_LESS',
    defaultMessage: 'PropertyDetail:VALIDATION_ERROR_SHOW_LESS'
  },
  PHOTO_TAGS: {
    id: 'PropertyDetail:PHOTO_TAGS',
    defaultMessage: 'PropertyDetail:PHOTO_TAGS'
  },
  CANCELLATION_POLICY: {
    id: 'PropertyDetail:CANCELLATION_POLICY',
    defaultMessage: 'PropertyDetail:CANCELLATION_POLICY'
  },
  APARTMENT_HOLIDU_SCORE: {
    id: 'PropertyDetail:APARTMENT_HOLIDU_SCORE',
    defaultMessage: 'PropertyDetail:APARTMENT_HOLIDU_SCORE'
  },
  APARTMENT_DATA_SCORE: {
    id: 'PropertyDetail:APARTMENT_DATA_SCORE',
    defaultMessage: 'PropertyDetail:APARTMENT_DATA_SCORE'
  },
  APARTMENT_BOOKABILITY_SCORE: {
    id: 'PropertyDetail:APARTMENT_BOOKABILITY_SCORE',
    defaultMessage: 'PropertyDetail:APARTMENT_BOOKABILITY_SCORE'
  },
  APARTMENT_PHOTO_SCORE: {
    id: 'PropertyDetail:APARTMENT_PHOTO_SCORE',
    defaultMessage: 'PropertyDetail:APARTMENT_PHOTO_SCORE'
  },
  DESCRIPTIVE: {
    id: 'PropertyDetail:DESCRIPTIVE',
    defaultMessage: 'PropertyDetail:DESCRIPTIVE'
  },
  DESCRIPTIVE_DESCRIPTION: {
    id: 'PropertyDetail:DESCRIPTIVE_DESCRIPTION',
    defaultMessage: 'PropertyDetail:DESCRIPTIVE_DESCRIPTION'
  },
  STATIC: {
    id: 'PropertyDetail:STATIC',
    defaultMessage: 'PropertyDetail:STATIC'
  },
  STATIC_DESCRIPTION: {
    id: 'PropertyDetail:STATIC_DESCRIPTION',
    defaultMessage: 'PropertyDetail:STATIC_DESCRIPTION'
  },
  FACILITIES: {
    id: 'PropertyDetail:FACILITIES',
    defaultMessage: 'PropertyDetail:FACILITIES'
  },
  FACILITIES_DESCRIPTION: {
    id: 'PropertyDetail:FACILITIES_DESCRIPTION',
    defaultMessage: 'PropertyDetail:FACILITIES_DESCRIPTION'
  },
  BOOKABLE_DATES: {
    id: 'PropertyDetail:BOOKABLE_DATES',
    defaultMessage: 'PropertyDetail:BOOKABLE_DATES'
  },
  FLEXIBILTY: {
    id: 'PropertyDetail:FLEXIBILTY',
    defaultMessage: 'PropertyDetail:FLEXIBILTY'
  },
  AVAILABLE_PHOTOS: {
    id: 'PropertyDetail:AVAILABLE_PHOTOS',
    defaultMessage: 'PropertyDetail:AVAILABLE_PHOTOS'
  },
  DOWNLOADED_PHOTO_RATE: {
    id: 'PropertyDetail:DOWNLOADED_PHOTO_RATE',
    defaultMessage: 'PropertyDetail:DOWNLOADED_PHOTO_RATE'
  },
  AVG_RESOLUTION: {
    id: 'PropertyDetail:AVG_RESOLUTION',
    defaultMessage: 'PropertyDetail:AVG_RESOLUTION'
  },
  MAIN_IMAGE_CONTENT: {
    id: 'PropertyDetail:MAIN_IMAGE_CONTENT',
    defaultMessage: 'PropertyDetail:MAIN_IMAGE_CONTENT'
  },
  DELETE_PROPERTY: {
    id: 'PropertyDetail:DELETE_PROPERTY',
    defaultMessage: 'PropertyDetail:DELETE_PROPERTY'
  },
  DELETE_PROPERTY_DESC: {
    id: 'PropertyDetail:DELETE_PROPERTY_DESC',
    defaultMessage: 'PropertyDetail:DELETE_PROPERTY_DESC'
  },
  DELETE_PROPERTY_CONFIRM_TITLE: {
    id: 'PropertyDetail:DELETE_PROPERTY_CONFIRM_TITLE',
    defaultMessage: 'PropertyDetail:DELETE_PROPERTY_CONFIRM_TITLE'
  },
  DELETE_PROPERTY_CONFIRM_DESC: {
    id: 'PropertyDetail:DELETE_PROPERTY_CONFIRM_DESC',
    defaultMessage: 'PropertyDetail:DELETE_PROPERTY_CONFIRM_DESC'
  },
  DELETE_PROPERTY_SUCCESS: {
    id: 'PropertyDetail:DELETE_PROPERTY_SUCCESS',
    defaultMessage: 'PropertyDetail:DELETE_PROPERTY_SUCCESS'
  },
  DELETE_PROPERTY_ERROR: {
    id: 'PropertyDetail:DELETE_PROPERTY_ERROR',
    defaultMessage: 'PropertyDetail:DELETE_PROPERTY_ERROR {message}'
  },
  OPPORTUNITIES: {
    id: 'PropertyDetail:OPPORTUNITIES',
    defaultMessage: 'PropertyDetail:OPPORTUNITIES'
  }
}

export default messageKeys
