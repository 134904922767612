import React from 'react'
import { History } from 'history'
import { useSelector } from 'react-redux'
import QueryString from 'query-string'
import { getSelectedProvider } from '../../store/providerSelection/providerSelector'
import ErrorPageContent from '../../components/ErrorPage/ErrorPageContent'
import EmptyProperties from './components/EmptyProperties'
import {
  Alert,
  Box,
  Button,
  List,
  TablePagination,
  Typography
} from '@mui/material'
import PropertyItem from './components/PropertyItem'
import useProperties from './useProperties'
import PropertiesFilters from './components/PropertiesFilters'
import PropertiesActions from './components/PropertiesActions'
import { PATH_LIVE_PROPERTIES } from '../../routes/PathConstants'
import { FilterChange, FilterQueryType, SortChange } from './types'
import { useIntl } from 'react-intl'
import messageKeys from './messages'
import { messageKeys as globalMessages } from '../../globalMessages'
import millify from 'millify'
import { Page, PageContent, PageHeader } from '@holidu/pui-components/dist'
import Analytics from '../../utils/analytics'
import {
  APARTMENTS_FILTER,
  APARTMENTS_DOWNLOAD_CSV,
  APARTMENTS_SORT,
  APARTMENTS_PAGE_SIZE_CHANGE,
  APARTMENTS_PAGE_CHANGE,
  APARTMENTS_SHOW_INACTIVE
} from '../../utils/analytics/events'
import Loader from '../../components/Loader'
import { getFilterQuery, getQueryObjectFromSearch } from './utils'
import useGetApartmentStats from '../Dashboard/useGetApartmentStats'
import { BAD_COLOR_LIGHT, BAD_COLOR_DARK, ROSY_COLOR } from '../../utils/colors'

type PropertiesProps = {
  history: History
}

const DISPLAY_LIMIT = 10000

const Properties = (props: PropertiesProps): JSX.Element => {
  const { selectedProvider } = useSelector(getSelectedProvider)
  const intl = useIntl()
  const { status, data, error, isLoading, isFetching } =
    useProperties(selectedProvider)

  const totalCount = data?.totalCount || 0

  const filterQuery: FilterQueryType = getFilterQuery()

  const { data: stats } = useGetApartmentStats(
    selectedProvider,
    filterQuery.apartmentStatus !== 'INACTIVE'
  )

  const inactivePropertiesCount = stats?.inactiveProperties || 0

  const showInactivePropertiesWarning =
    filterQuery.apartmentStatus !== 'INACTIVE' &&
    Boolean(inactivePropertiesCount)

  const redirectToNewLocation = (queryObj: any) => {
    const query = QueryString.stringify(queryObj, {
      skipNull: true,
      skipEmptyString: true
    })
    props.history.push(`${PATH_LIVE_PROPERTIES}?${query}`)
    const pageContentEle = document.querySelector('[data-screen=PageContent]')
    if (pageContentEle) {
      pageContentEle.scrollTop = 0
    }
  }

  const updateFilters = ({ name, value }: FilterChange) => {
    const locationQueryObj = getQueryObjectFromSearch()
    const newLocationQueryObj = {
      ...locationQueryObj,
      [name]: value,
      page: 0
    }
    redirectToNewLocation(newLocationQueryObj)
  }

  const handleFilterChange = (filterChange: FilterChange) => {
    updateFilters(filterChange)
    Analytics.trackEvent(APARTMENTS_FILTER, filterChange)
  }

  const handleSortChange = ({ sortBy, sortOrder }: SortChange) => {
    const locationQueryObj = getQueryObjectFromSearch()
    const newLocationQueryObj = {
      ...locationQueryObj,
      sortBy,
      sortOrder,
      page: 0
    }
    Analytics.trackEvent(APARTMENTS_SORT, { sortBy, sortOrder })
    redirectToNewLocation(newLocationQueryObj)
  }

  const handlePageChange = (event: unknown, value: number) => {
    const locationQueryObj = getQueryObjectFromSearch()
    const newLocationQueryObj = {
      ...locationQueryObj,
      page: value
    }
    Analytics.trackEvent(APARTMENTS_PAGE_CHANGE, { page: value })
    redirectToNewLocation(newLocationQueryObj)
  }

  const handleRowsPerPageChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const locationQueryObj = getQueryObjectFromSearch()
    const size = parseInt(event.target.value, 10)
    const newLocationQueryObj = {
      ...locationQueryObj,
      size: parseInt(event.target.value, 10),
      page: 0
    }

    Analytics.trackEvent(APARTMENTS_PAGE_SIZE_CHANGE, { size })
    redirectToNewLocation(newLocationQueryObj)
  }

  const handleDownloadCsv = () =>
    Analytics.trackEvent(APARTMENTS_DOWNLOAD_CSV, getQueryObjectFromSearch())

  const showInactiveProperties = () => {
    updateFilters({ name: 'apartmentStatus', value: 'INACTIVE' })
    Analytics.trackEvent(APARTMENTS_SHOW_INACTIVE)
  }

  return (
    <Page>
      <PageHeader
        sx={{
          borderBottom: 'none'
        }}
      >
        <Typography variant="h6">
          {intl.formatMessage(messageKeys.Title)}
        </Typography>
        <Box flexGrow={1} />
        <PropertiesActions
          onDownloadCsv={handleDownloadCsv}
          totalCount={totalCount}
          disabled={isFetching || !totalCount}
        />
      </PageHeader>
      <PageContent px={3} pb={8}>
        <PropertiesFilters
          {...filterQuery}
          totalCount={data?.totalCount || 0}
          onFilterChange={handleFilterChange}
          onSortChange={handleSortChange}
        />
        {status === 'error' ? (
          <ErrorPageContent status={error.message} />
        ) : (
          <>
            {data && data?.result.length ? (
              <Box>
                {showInactivePropertiesWarning && (
                  <Alert
                    severity="error"
                    variant="filled"
                    sx={{
                      marginBottom: 2,
                      backgroundColor: BAD_COLOR_LIGHT,
                      color: BAD_COLOR_DARK
                    }}
                    action={
                      <Button
                        variant="contained"
                        sx={{
                          backgroundColor: ROSY_COLOR,
                          '&:hover': { backgroundColor: ROSY_COLOR }
                        }}
                        size="small"
                        onClick={showInactiveProperties}
                      >
                        {intl.formatMessage(
                          messageKeys.InactiveApartmentsButton
                        )}
                      </Button>
                    }
                  >
                    {intl.formatMessage(messageKeys.InactiveApartmentsAlert, {
                      count: inactivePropertiesCount
                    })}
                  </Alert>
                )}
                <List disablePadding>
                  {data.result.map(item => (
                    <PropertyItem key={item.apartmentId} data={item} />
                  ))}
                </List>
                <Box
                  py={1}
                  display="flex"
                  flexDirection="row-reverse"
                  flexWrap="wrap"
                  alignItems="center"
                >
                  <TablePagination
                    rowsPerPageOptions={[10, 20, 30]}
                    component="div"
                    count={totalCount || 0}
                    rowsPerPage={filterQuery.size}
                    page={filterQuery.page}
                    onPageChange={handlePageChange}
                    onRowsPerPageChange={handleRowsPerPageChange}
                    labelRowsPerPage={intl.formatMessage(
                      globalMessages.ROWS_PER_PAGE
                    )}
                  />
                  {totalCount >= DISPLAY_LIMIT && (
                    <Typography variant="caption" ml={2}>
                      {intl.formatMessage(messageKeys.DisplayLimit, {
                        limit: millify(DISPLAY_LIMIT)
                      })}
                    </Typography>
                  )}
                </Box>
              </Box>
            ) : (
              <EmptyProperties />
            )}
          </>
        )}
      </PageContent>
      {(isLoading || isFetching) && <Loader />}
    </Page>
  )
}

export default Properties
