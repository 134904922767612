import parsePhoneNumber, {
  isPossiblePhoneNumber,
  validatePhoneNumberLength
} from 'libphonenumber-js'

export const validate = value => {
  let error
  const lengthIssue = validatePhoneNumberLength(value) // TOO_SHORT, TOO_LONG, etc or undefined
  if (lengthIssue) {
    error = lengthIssue
  } else if (!isPossiblePhoneNumber(value)) {
    error = 'INVALID'
  }
  return error || ''
}

export const parse = value => {
  const parsedPhone =
    typeof value === 'string'
      ? parsePhoneNumber(value, {
          extract: false
        })
      : undefined

  if (parsedPhone) {
    const phoneNumber = parsedPhone.formatInternational()
    return {
      phoneNumber,
      country: parsedPhone.country
    }
  }

  return {
    phoneNumber: value,
    country: null
  }
}

export const stringify = value => {
  if (typeof value === 'string') {
    return value.replace(/\s/g, '')
  }
  return value
}
