import React, { useCallback, useEffect, useState } from 'react'
import {
  TextField,
  Typography,
  IconButton,
  Stack,
  Box,
  Button,
  Grid,
  FormControlLabel,
  Radio,
  RadioGroup
} from '@mui/material'
import { FormattedMessage, useIntl } from 'react-intl'
import AddIcon from '@mui/icons-material/Add'
import DeleteIcon from '@mui/icons-material/Delete'
import { messageKeys } from '../messages'
import { Section, Tray } from '@holidu/pui-components/dist'
import isNil from 'lodash/isNil'
import { PaymentRate } from '../../../hooks/types/ProviderConfiguration'
import Analytics from '../../../utils/analytics'
import {
  PAYMENT_OPTIONS_PAYMENT_RATE_ADD,
  PAYMENT_OPTIONS_PAYMENT_RATE_REMOVE
} from '../../../utils/analytics/events'

type PaymentRatesProps = {
  onChange: (newRates: PaymentRate[]) => void
  onError: (err: string) => void
  paymentRates?: PaymentRate[]
  paymentRatesSource?: string
  cancellationPolicy: string | undefined
  initialCancellationPolicy: string | undefined
}

export const PaymentRates = (props: PaymentRatesProps): JSX.Element => {
  const {
    onChange,
    onError,
    paymentRates = [],
    paymentRatesSource = '',
    cancellationPolicy,
    initialCancellationPolicy
  } = props
  const intl = useIntl()
  const [error, setError] = useState('')
  const [ratesConfig, setRatesConfig] = useState('')
  const [prevCp, setPrevCp] = useState(cancellationPolicy)

  const onPercentChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const index = Number(e.target.dataset.index)
    const newRates = paymentRates.map(a => ({ ...a }))

    const percentageValue = e.target.value ? Number(e.target.value) : undefined
    if (
      percentageValue !== undefined &&
      (percentageValue > 100 || percentageValue < 1)
    ) {
      return
    }
    newRates[index]['percentage'] = percentageValue
    const sumOfAllRatesExceptFirst = newRates
      .filter((rate, index) => index !== 0)
      .map(rate => Number(rate.percentage) || 0)
      .reduce((a, b) => a + b, 0)
    if (sumOfAllRatesExceptFirst > 100) {
      newRates[0].percentage = 0
      setError(intl.formatMessage(messageKeys.RatesMustSumTo100))
      onError(intl.formatMessage(messageKeys.RatesMustSumTo100))
    } else {
      newRates[0].percentage = 100 - sumOfAllRatesExceptFirst
      setError('')
      onError('')
    }
    onChange(newRates)
  }

  const onDayChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const index = Number(e.target.dataset.index)
    const newRates = paymentRates.map(a => ({ ...a }))
    const daysBeforeArrival = e.target.value
      ? Number(e.target.value)
      : undefined
    if (
      daysBeforeArrival !== undefined &&
      (daysBeforeArrival > 1000 || daysBeforeArrival < 0)
    ) {
      return
    }
    newRates[index]['daysBeforeArrival'] = daysBeforeArrival
    onChange(newRates)
  }

  const addNewPaymentRate = () => {
    const newRates = paymentRates.map(a => ({ ...a }))
    newRates.push({})
    Analytics.trackEvent(PAYMENT_OPTIONS_PAYMENT_RATE_ADD)
    onChange(newRates)
  }

  const removePaymentRate = (indexToDelete: number) => {
    const newRates = paymentRates
      .filter((rate: PaymentRate, index: number) => index !== indexToDelete)
      .map(a => ({ ...a }))
    Analytics.trackEvent(PAYMENT_OPTIONS_PAYMENT_RATE_REMOVE)

    const sumOfAllRatesExceptFirst = newRates
      .filter((rate: PaymentRate, index: number) => index !== 0)
      .map(rate => Number(rate.percentage))
      .reduce((a, b) => a + b, 0)
    newRates[0].percentage = 100 - sumOfAllRatesExceptFirst
    onChange(newRates)
  }

  const preventMinus = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.code === 'Minus') {
      e.preventDefault()
    }
  }

  const disablePaymentRates = Boolean(
    paymentRatesSource && paymentRatesSource !== 'subProvider'
  )

  const populateAlignedRates = useCallback(() => {
    let newPaymentRates: PaymentRate[] = []
    switch (cancellationPolicy) {
      case 'CANCEL_FLEXIBLE':
        newPaymentRates = [
          { percentage: 0, daysBeforeArrival: undefined },
          { percentage: 100, daysBeforeArrival: 7 }
        ]
        break
      case 'CANCEL_MODERATE':
        newPaymentRates = [
          { percentage: 0, daysBeforeArrival: undefined },
          { percentage: 100, daysBeforeArrival: 14 }
        ]
        break
      case 'CANCEL_FIRM':
        newPaymentRates = [
          { percentage: 0, daysBeforeArrival: undefined },
          { percentage: 100, daysBeforeArrival: 30 }
        ]
        break
      case 'CANCEL_STRICT':
        newPaymentRates = [
          { percentage: 30, daysBeforeArrival: undefined },
          { percentage: 70, daysBeforeArrival: 30 }
        ]
        break
      default:
        break
    }
    onChange(newPaymentRates)
  }, [onChange, cancellationPolicy])

  useEffect(() => {
    if (
      !disablePaymentRates &&
      (!initialCancellationPolicy || ratesConfig === 'aligned') &&
      cancellationPolicy !== prevCp
    ) {
      setPrevCp(cancellationPolicy)
      populateAlignedRates()
      setRatesConfig('aligned')
      return
    }
  }, [
    cancellationPolicy,
    disablePaymentRates,
    populateAlignedRates,
    setPrevCp,
    prevCp,
    initialCancellationPolicy,
    ratesConfig
  ])

  useEffect(() => {
    const paymentRatesAlignedWithCP = () => {
      if (disablePaymentRates) {
        return 'custom'
      }
      if (!cancellationPolicy) {
        return undefined
      }
      switch (cancellationPolicy) {
        case 'CANCEL_FLEXIBLE':
          return paymentRates.length === 2 &&
            paymentRates[0].percentage === 0 &&
            !paymentRates[0].daysBeforeArrival &&
            paymentRates[1].daysBeforeArrival === 7 &&
            paymentRates[1].percentage === 100
            ? 'aligned'
            : 'custom'
        case 'CANCEL_MODERATE':
          return paymentRates.length === 2 &&
            paymentRates[0].percentage === 0 &&
            !paymentRates[0].daysBeforeArrival &&
            paymentRates[1].daysBeforeArrival === 14 &&
            paymentRates[1].percentage === 100
            ? 'aligned'
            : 'custom'
        case 'CANCEL_FIRM':
          return paymentRates.length === 2 &&
            paymentRates[0].percentage === 0 &&
            !paymentRates[0].daysBeforeArrival &&
            paymentRates[1].daysBeforeArrival === 30 &&
            paymentRates[1].percentage === 100
            ? 'aligned'
            : 'custom'
        case 'CANCEL_STRICT':
          return paymentRates.length === 2 &&
            paymentRates[0].percentage === 30 &&
            !paymentRates[0].daysBeforeArrival &&
            paymentRates[1].daysBeforeArrival === 30 &&
            paymentRates[1].percentage === 70
            ? 'aligned'
            : 'custom'
        default:
          return 'custom'
      }
    }
    setRatesConfig(paymentRatesAlignedWithCP() || '')
  }, [
    cancellationPolicy,
    disablePaymentRates,
    paymentRates,
    populateAlignedRates
  ])

  const handleEntityChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setRatesConfig(e.target.value)
    if (e.target.value === 'aligned') {
      populateAlignedRates()
    } else {
      const newPaymentRates = [
        { percentage: 100, daysBeforeArrival: undefined }
      ]
      onChange(newPaymentRates)
    }
  }

  return (
    <Section
      title={intl.formatMessage(messageKeys.PaymentRatesHead)}
      secondaryTitle={
        paymentRatesSource === 'default'
          ? intl.formatMessage(messageKeys.PaymentRatesCmSubHead)
          : paymentRatesSource === 'request'
          ? intl.formatMessage(messageKeys.PaymentRatesRequestHead)
          : intl.formatMessage(messageKeys.PaymentRatesSubHead)
      }
    >
      <Stack spacing={2}>
        <RadioGroup
          aria-labelledby="entity-type-radio-group-label"
          value={ratesConfig}
          onChange={handleEntityChange}
          name="entityType"
        >
          <FormControlLabel
            value="aligned"
            control={<Radio />}
            disabled={
              disablePaymentRates ||
              !cancellationPolicy ||
              cancellationPolicy === 'PER_APARTMENT' ||
              cancellationPolicy === 'PER_PROVIDER'
            }
            label={
              <FormattedMessage {...messageKeys.PaymentRatesAlignOption} />
            }
          />
          <FormControlLabel
            value="custom"
            control={<Radio />}
            disabled={disablePaymentRates || !cancellationPolicy}
            label={
              <FormattedMessage {...messageKeys.PaymentRatesCustomOption} />
            }
          />
        </RadioGroup>

        {Boolean(disablePaymentRates || cancellationPolicy) && (
          <Tray type="outlined" color="grey">
            <Grid container alignItems="center" spacing={2}>
              <Grid item xs={12} lg={5}>
                <Typography variant="body2">
                  <strong>{intl.formatMessage(messageKeys.FirstRate)}</strong>
                </Typography>
              </Grid>
              <Grid item xs={12} lg={7}>
                <Stack direction="row" alignItems="center" spacing={2}>
                  <TextField
                    size="small"
                    margin="dense"
                    type="number"
                    sx={{
                      width: '80px',
                      minWidth: '80px'
                    }}
                    value={paymentRates[0].percentage}
                    variant="outlined"
                    disabled
                  />
                  <Typography variant="body2">
                    {intl.formatMessage(messageKeys.PercentOfBooking)}
                  </Typography>
                </Stack>
              </Grid>
            </Grid>
          </Tray>
        )}
        {Boolean(disablePaymentRates || cancellationPolicy) &&
          paymentRates.map((rate, index) =>
            index === 0 ? null : (
              <Tray type="outlined" key={index}>
                <Grid container alignItems="center" spacing={2}>
                  <Grid item xs={12} lg={2}>
                    <Typography variant="body2">
                      <strong>
                        {intl.formatMessage(messageKeys.Rate)} {index}
                      </strong>
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    lg={3}
                    display="flex"
                    alignItems="center"
                    gap={1}
                  >
                    <TextField
                      name="daysBeforeArrival"
                      disabled={
                        disablePaymentRates || ratesConfig === 'aligned'
                      }
                      inputProps={{
                        min: 0,
                        max: 1000,
                        step: 5,
                        'data-index': index
                      }}
                      margin="dense"
                      type="number"
                      sx={{
                        width: '80px',
                        minWidth: '80px'
                      }}
                      error={isNil(rate.daysBeforeArrival)}
                      value={
                        isNil(rate.daysBeforeArrival)
                          ? ''
                          : rate.daysBeforeArrival
                      }
                      variant="outlined"
                      onChange={onDayChange}
                      onKeyPress={preventMinus}
                    />
                    <Typography variant="body2">
                      {intl.formatMessage(messageKeys.DaysBefore)}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    lg={3}
                    display="flex"
                    alignItems="center"
                    gap={1}
                  >
                    <TextField
                      name="percentage"
                      disabled={
                        disablePaymentRates || ratesConfig === 'aligned'
                      }
                      inputProps={{
                        min: 1,
                        max: 100,
                        step: 1,
                        'data-index': index
                      }}
                      margin="dense"
                      type="number"
                      error={Boolean(error || isNil(rate.percentage))}
                      sx={{
                        width: '80px',
                        minWidth: '80px'
                      }}
                      value={isNil(rate.percentage) ? '' : rate.percentage}
                      variant="outlined"
                      onChange={onPercentChange}
                      onKeyPress={preventMinus}
                    />
                    <Typography variant="body2">
                      {intl.formatMessage(messageKeys.PercentOfBooking)}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    lg={4}
                    display="flex"
                    justifyContent="flex-end"
                  >
                    <IconButton
                      data-ga-label="Remove payment rate"
                      size="large"
                      aria-label="remove payment rate"
                      onClick={() => removePaymentRate(index)}
                      disabled={disablePaymentRates || ratesConfig !== 'custom'}
                    >
                      <DeleteIcon fontSize="inherit" />
                    </IconButton>
                  </Grid>
                </Grid>
              </Tray>
            )
          )}
        <Box display="flex" justifyContent="center">
          <Button
            data-ga-label="Add payment rate"
            startIcon={<AddIcon />}
            size="small"
            aria-label="add payment rate"
            color="primary"
            onClick={addNewPaymentRate}
            disabled={disablePaymentRates || ratesConfig !== 'custom'}
          >
            {intl.formatMessage(messageKeys.AddMore)}
          </Button>
        </Box>
      </Stack>
      {Boolean(error) && (
        <Box mt={2}>
          <Typography color="error" variant="body1" paragraph>
            {error}
          </Typography>
        </Box>
      )}
    </Section>
  )
}
