import env from '../../env'
import * as amplitude from '@amplitude/analytics-browser'
import { isUserAuthenticated } from '../awsCognitoClient'
import { loadLocale } from '../../localStorage'
import { DEFAULT_LOCALE } from '../../../i18n'

const ALLOWED_TARGET_ENVS = ['dev', 'stage', 'live']

const LOCAL_STORAGE_EMAIL_KEY = 'AMPLITUDE_EMAIL'
const LOCAL_STORAGE_ID_KEY = 'AMPLITUDE_USER_ID'

export default class Analytics {
  private static initialized: boolean = false
  private static shouldApplyAmplitude = () => {
    if (!ALLOWED_TARGET_ENVS.includes(env.TARGET_ENV as string)) {
      return false
    }
    if (!isUserAuthenticated()) {
      return false
    }
    const userEmail = localStorage.getItem(LOCAL_STORAGE_EMAIL_KEY)
    const userId = localStorage.getItem(LOCAL_STORAGE_ID_KEY)

    if (!(userEmail && userId)) {
      return false
    }

    if (
      env.AMPLITUDE_USER_TYPE === 'EXTERNAL_ONLY' &&
      userEmail.endsWith('@holidu.com')
    ) {
      return false
    }
    return true
  }

  static shouldSendEvent = () => {
    if (!this.initialized) {
      return false
    }
    if (!isUserAuthenticated()) {
      return false
    }
    const userIdInLocalStorage = localStorage.getItem(LOCAL_STORAGE_ID_KEY)

    if (!userIdInLocalStorage) {
      return false
    }
    return userIdInLocalStorage === amplitude.getUserId()
  }

  static init = () => {
    if (this.shouldApplyAmplitude()) {
      if (!this.initialized) {
        const userId = localStorage.getItem(LOCAL_STORAGE_ID_KEY)
        if (typeof env.AMPLITUDE_API_KEY === 'string' && userId) {
          // initialize amplitude
          amplitude.init(env.AMPLITUDE_API_KEY, userId, {
            serverZone: amplitude.Types.ServerZone.EU
          })
          amplitude.setOptOut(false)

          if (process.env.NODE_ENV === 'development') {
            console.log('Amplitude Initialized with ', userId)
          }

          const identifyObj = new amplitude.Identify()
          const locale = loadLocale() || DEFAULT_LOCALE
          identifyObj.set('locale', locale)
          identifyObj.set(
            'screen_resolution',
            `${window.screen.width}px x ${window.screen.height}px`
          )
          amplitude.identify(identifyObj)

          // set provider group
          const selectedProvider = localStorage.getItem('selectedProvider')
          if (selectedProvider) {
            amplitude.setGroup('provider', selectedProvider)
            if (process.env.NODE_ENV === 'development') {
              console.log('Amplitude setProvider ', selectedProvider)
            }
          }
          this.initialized = true
        }
      }
    } else {
      this.initialized = false
    }
  }

  static setUser = (userId: string, email: string) => {
    localStorage.setItem(LOCAL_STORAGE_ID_KEY, userId)
    localStorage.setItem(LOCAL_STORAGE_EMAIL_KEY, email)
    amplitude.setUserId(userId)
    if (process.env.NODE_ENV === 'development') {
      console.log('Amplitude setUser', userId)
    }
  }

  static pageView = (
    pathname: string,
    duration: any,
    prevPathname?: string
  ) => {
    if (!this.initialized) {
      this.init()
    }
    if (this.shouldSendEvent()) {
      if (
        pathname !== prevPathname &&
        !['', '/'].includes(pathname) &&
        !pathname.startsWith('/auth')
      ) {
        amplitude.track('Page View', {
          page_path: pathname,
          page_location: window.location.href,
          duration
        })
        if (process.env.NODE_ENV === 'development') {
          console.log(`Amplitude PageView ${amplitude.getUserId()}:`, pathname)
        }
      }
    }
  }

  static trackEvent = (action: string, eventProperties?: any) => {
    if (this.shouldSendEvent()) {
      amplitude.track(action, eventProperties)
      if (process.env.NODE_ENV === 'development') {
        console.log(`Amplitude event ${amplitude.getUserId()}:`, action)
      }
    }
  }

  static setProvider = (provider: string) => {
    if (this.initialized) {
      amplitude.setGroup('provider', provider)
      if (process.env.NODE_ENV === 'development') {
        console.log('Amplitude setProvider ', provider)
      }
    }
  }

  static setLocale = (locale: string) => {
    if (this.initialized) {
      const identifyObj = new amplitude.Identify()
      identifyObj.set('locale', locale)
      amplitude.identify(identifyObj)
      if (process.env.NODE_ENV === 'development') {
        console.log('Amplitude setLocale ', locale)
      }
    }
  }

  static reset = () => {
    if (this.initialized) {
      // clearing localstorage
      localStorage.removeItem(LOCAL_STORAGE_EMAIL_KEY)
      localStorage.removeItem(LOCAL_STORAGE_ID_KEY)
      // resting amplitude
      amplitude.setOptOut(true)
      // amplitude.reset()
      // set initialized false
      this.initialized = false
      if (process.env.NODE_ENV === 'development') {
        console.log('Amplitude reset')
      }
    }
  }
}
