import React from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { connectRequest } from 'redux-query-immutable-react'

import { Page, PageContent, PageHeader } from '@holidu/pui-components/dist'
import { Typography } from '@mui/material'
import { FormattedMessage } from 'react-intl'
import { messageKeys } from './messages'

import { getSelectedProvider } from '../../store/providerSelection/providerSelector'
import { getProviderSelector } from '../../store/provider/providerSelector'
import { getProviderRequest } from '../../store/provider/providerRequest'
import { RequestsAreDone } from '../RequestsAreDone'
import { updateCompany } from '../../store/company/actions'
import { getCompanySelector } from '../../store/company/companySelector'
import { getCompanyRequest } from '../../store/company/companyRequest'
import CommercialAgreementPage from './CommercialAgreementPage'
import InformationMissingError from '../../components/InformationMissingError'
import { getMissingFieldsForCommercialAgreement } from '../Dashboard/OnBoarding/RequiredFieldsFetcher'

const CommercialAgreement = props => {
  const { companies, providers, selectedProvider } = props
  const company = companies.get(selectedProvider)
  const provider = providers.get(selectedProvider)
  const caFields = getMissingFieldsForCommercialAgreement(company, provider)

  return (
    <Page>
      <PageHeader
        sx={{
          borderBottom: 'none'
        }}
      >
        <Typography variant="h6">
          <FormattedMessage {...messageKeys.CommercialAgreement} />
        </Typography>
      </PageHeader>
      <PageContent px={3} pb={8}>
        {provider && provider.get('status') !== 'LIVE' && caFields.length ? (
          <InformationMissingError />
        ) : null}
        <CommercialAgreementPage
          providers={props.providers}
          companies={props.companies}
          selectedProvider={props.selectedProvider}
          updateCompany={props.updateCompany}
          disabled={provider && provider.get('status') === 'LIVE'}
        />
      </PageContent>
    </Page>
  )
}

const mapDispatchToProps = (dispatch, props) => ({
  updateCompany: (data, onSuccess, onError) => {
    dispatch(
      updateCompany(
        { ...data, selectedProvider: props.selectedProvider },
        onSuccess,
        onError
      )
    )
  }
})

export default compose(
  connect(getSelectedProvider),
  connect(getProviderSelector, mapDispatchToProps),
  connect(getCompanySelector, mapDispatchToProps),
  connectRequest(getProviderRequest),
  connectRequest(getCompanyRequest),
  RequestsAreDone
)(CommercialAgreement)
