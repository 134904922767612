import React from 'react'
import { Alert, AlertTitle, Box, Stack, Typography } from '@mui/material'
import { FormattedMessage } from 'react-intl'
import { messageKeys } from './messages'
import { useSelector } from 'react-redux'
import Loader from '../../components/Loader'
import { getSelectedProvider } from '../../store/providerSelection/providerSelector'
import useGetKycStatus from './useGetKycStatus'
import OverallKycStatusSection from './components/OverallKycStatusSection'
import ShareholderKycSection from './components/ShareholderKycSection'
import TrustLevelTierSelect from './components/TrustLevelTierSelect'
import { getCompanySelector } from '../../store/company/companySelector'
import { Section } from '@holidu/pui-components/dist'
import { useIntl } from 'react-intl'
import { getProviderSelector } from '../../store/provider/providerSelector'

const KnowYourClientPage = () => {
  const { selectedProvider } = useSelector(getSelectedProvider)
  const { companies } = useSelector(getCompanySelector)
  const { providers } = useSelector(getProviderSelector)
  const provider = providers.get(selectedProvider)

  const company = companies.get(selectedProvider)
  const {
    isFetching,
    isError,
    isLoading,
    data: kycStatus
  } = useGetKycStatus(selectedProvider)
  const intl = useIntl()

  if (isError) {
    return (
      <Alert variant="outlined" severity="error" sx={{ mb: 2 }}>
        <AlertTitle>
          <FormattedMessage {...messageKeys.KycStatusRequestFailed} />
        </AlertTitle>
      </Alert>
    )
  }

  return (
    <Stack spacing={3}>
      {(isFetching || isLoading) && <Loader />}
      <OverallKycStatusSection
        bankAccountVerificationStatus={
          kycStatus?.bankAccountKycStatuses[0]?.bankAccountVerificationStatus
        }
        adyenOverallStatus={kycStatus?.adyenOverallStatus}
        companyVerificationStatus={kycStatus?.companyVerificationStatus}
      />
      <ShareholderKycSection
        shareholderKycStatuses={kycStatus?.shareholderKycStatuses}
      />
      {Boolean(provider && provider.get('channelManager')) && (
        <Section title={intl.formatMessage(messageKeys.AdditionalInformation)}>
          <Box display="flex" alignItems="center">
            <Typography variant="body2">
              <FormattedMessage {...messageKeys.TrustLevelTier} />
            </Typography>
            <TrustLevelTierSelect
              sx={{ ml: 2 }}
              name="trustLevelTier"
              disabled={true}
              value={company && company.get('trustLevelTier')}
            />
          </Box>
        </Section>
      )}
    </Stack>
  )
}

export default KnowYourClientPage
