import axios from 'axios'
import { useQuery } from '@tanstack/react-query'
import { Properties } from './types'
import QueryKeys from '../../utils/queryKeys'
import { getApiRequestQueryString } from './utils'

const useProperties = (provider: string) => {
  const query = getApiRequestQueryString(provider)

  return useQuery<any, Error, Properties>(
    [QueryKeys.Properties, { query: query }],
    async () => {
      const { data } = await axios.get(`/apartments/list?${query}`)
      return data
    },
    { keepPreviousData: true, retry: false }
  )
}

export default useProperties
