import React from 'react'
import { Link } from 'react-router-dom'

import { Button, Typography, Container, Box } from '@mui/material'

const Page500 = () => (
  <Container fixed maxWidth="sm">
    <Box sx={{ minHeight: '100vh' }}>
      <Typography align="center" component="h1" variant="h1" gutterBottom>
        500
      </Typography>
      <Typography align="center" component="h2" variant="h5" gutterBottom>
        Internal server error.
      </Typography>
      <Typography align="center" component="h2" variant="body1" gutterBottom>
        The server encountered something unexpected that did not allow it to
        complete the request.
      </Typography>

      <Button component={Link} mt={2} to="/" variant="contained">
        Return to website
      </Button>
    </Box>
  </Container>
)

export default Page500
