import {
  getSelectedProvider as getLocalStorageSelectedProvider,
  saveSelectedProvider
} from '../../localStorage'

export const getSelectedProvider = state => {
  const localStorageProvider = getLocalStorageSelectedProvider()
  const attchedProviders = state.getIn([
    'entities',
    'user',
    'attachedProviders'
  ])

  if (localStorageProvider) {
    if (attchedProviders.includes(localStorageProvider)) {
      return {
        selectedProvider: localStorageProvider
      }
    }
  }

  const firstProvider = attchedProviders.get(0)
  saveSelectedProvider(firstProvider)
  return {
    selectedProvider: firstProvider
  }
}
