import download from 'downloadjs'
import env from '../env'
import { currentUserIdToken } from './awsCognitoClient'

const downloadFile = async (
  path,
  fileName = 'download',
  mimeType = 'text/plain'
) => {
  try {
    const response = await fetch(env.BACKEND + path, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + currentUserIdToken()
      }
    })
    const blob = await response.blob()
    download(blob, fileName, mimeType)
  } catch (e) {
    console.log('Error while downloading:', e)
  }
}

export default downloadFile
