import React from 'react'
import { Stack } from '@mui/material'
import { FormattedMessage } from 'react-intl'
import Section from '../../../components/Section'
import ValidationTextField from '../../../components/Fields/ValidationTextField'
import { messageKeys } from '../messages'
import { messageKeys as globalMessageKeys } from '../../../globalMessages'

const AdditionalInformation = props => {
  const { data, errors, onChange } = props

  return (
    <Section
      title={<FormattedMessage {...messageKeys.AdditionalInfoHeader} />}
      subtitle={<FormattedMessage {...messageKeys.AdditionalInfoSubHeader} />}
    >
      <Stack spacing={4}>
        {/* Properties Count */}
        <ValidationTextField
          name="propertiesCount"
          error={errors.propertiesCount}
          label={<FormattedMessage {...messageKeys.NumberOfProperties} />}
          type="number"
          inputProps={{
            min: 1,
            step: 1
          }}
          value={data.propertiesCount || ''}
          fullWidth
          required
          validations={{
            invalidNumber: {
              validator: value => {
                const number = Number(value)
                if (
                  !Number.isSafeInteger(number) ||
                  Number.isNaN(number) ||
                  number < 1
                ) {
                  return false
                }
                return true
              },
              message: (
                <FormattedMessage {...globalMessageKeys.ErrorIsMandatory} />
              )
            }
          }}
          onChange={onChange}
        />
        {/* Property Location */}
        <ValidationTextField
          name="locationOfProperties"
          error={errors.locationOfProperties}
          label={<FormattedMessage {...messageKeys.LocationOfProperties} />}
          value={data.locationOfProperties || ''}
          fullWidth
          onChange={onChange}
        />
        {/* Company Website */}
        <ValidationTextField
          name="webSiteURL"
          error={errors.webSiteURL}
          label={<FormattedMessage {...messageKeys.WebSiteUrl} />}
          value={data.webSiteURL || ''}
          fullWidth
          onChange={onChange}
        />
      </Stack>
    </Section>
  )
}

export default AdditionalInformation
