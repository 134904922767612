const REDIRECT_URL = '?redirectUrl='
export const getTargetUrlWithCurrentLocation = url => {
  const redirectUrl =
    url + REDIRECT_URL + window.location.pathname + window.location.search
  return redirectUrl
}

export const getRedirectUrl = () => {
  if (window.location.search.startsWith(REDIRECT_URL)) {
    return window.location.search.replace(REDIRECT_URL, '')
  }
  return null
}
