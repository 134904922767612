import moment from 'moment'

export const DATE_FORMAT = 'll'
export const DATE_TIME_FORMAT = 'lll'

export const getDateFromDateString = dateString => {
  if (dateString) {
    const date = moment(dateString)
    if (moment(date).isValid()) {
      return date
    }
  }
  return null
}

export const getDateStringFromDate = date => {
  if (isValidDate(date)) {
    return moment(date).format('YYYY-MM-DD')
  }
  return ''
}

export const isValidDate = date => {
  return moment(date).isValid()
}
