export const messageKeys = {
  TITLE: {
    id: 'INVOICES:TITLE',
    defaultMessage: 'INVOICES:TITLE'
  },
  EMPTY_INVOICES: {
    id: 'INVOICES:EMPTY_INVOICES',
    defaultMessage: 'INVOICES:EMPTY_INVOICES'
  },
  INVOICE_MONTH: {
    id: 'INVOICES:INVOICE_MONTH',
    defaultMessage: 'INVOICES:INVOICE_MONTH'
  },
  TYPE: {
    id: 'INVOICES:TYPE',
    defaultMessage: 'INVOICES:TYPE'
  },
  REFERENCE: {
    id: 'INVOICES:REFERENCE',
    defaultMessage: 'INVOICES:REFERENCE'
  },
  CREATION_DATE: {
    id: 'INVOICES:CREATION_DATE',
    defaultMessage: 'INVOICES:CREATION_DATE'
  },
  AMOUNT: {
    id: 'INVOICES:AMOUNT',
    defaultMessage: 'INVOICES:AMOUNT'
  },
  CANCELLED_AT: {
    id: 'INVOICES:CANCELLED_AT',
    defaultMessage: 'INVOICES:CANCELLED_AT'
  },
  CORRECTED_AT: {
    id: 'INVOICES:CORRECTED_AT',
    defaultMessage: 'INVOICES:CORRECTED_AT'
  },
  DOWNLOADS: {
    id: 'INVOICES:DOWNLOADS',
    defaultMessage: 'INVOICES:DOWNLOADS'
  },
  DOWNLOAD_INVOICE: {
    id: 'INVOICES:DOWNLOAD_INVOICE',
    defaultMessage: 'INVOICES:DOWNLOAD_INVOICE'
  },
  DOWNLOAD_INVOICE_ATTACHMENT: {
    id: 'INVOICES:DOWNLOAD_INVOICE_ATTACHMENT',
    defaultMessage: 'INVOICES:DOWNLOAD_INVOICE_ATTACHMENT'
  },
  DOWNLOAD_CANCEL_INVOICE: {
    id: 'INVOICES:DOWNLOAD_CANCEL_INVOICE',
    defaultMessage: 'INVOICES:DOWNLOAD_CANCEL_INVOICE'
  },
  DOWNLOAD_CANCEL_INVOICE_ATTACHMENT: {
    id: 'INVOICES:DOWNLOAD_CANCEL_INVOICE_ATTACHMENT',
    defaultMessage: 'INVOICES:DOWNLOAD_CANCEL_INVOICE_ATTACHMENT'
  }
}
