import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import ReactCountryFlag from 'react-country-flag'
import { InputAdornment } from '@mui/material'
import { FormattedMessage } from 'react-intl'
import { styled } from '@mui/system'
import { messageKeys as globalMessageKeys } from '../../../globalMessages'
import ValidationTextField from '../ValidationTextField'
import { parse, stringify, validate } from './util'

const removeStartingPlus = str => {
  if (typeof str == 'string' && str.charAt(0) === '+') {
    return str.substring(1)
  }
  return str
}

const addStartingPlus = str => {
  if (typeof str == 'string' && str.charAt(0) !== '+') {
    return '+' + str
  }
  return str
}

const ValidationPhoneField = props => {
  const { onChange, validations, value, ...restProps } = props
  const [parsed, setParsed] = useState({
    phoneNumber: null,
    country: null
  })

  useEffect(() => {
    const { country, phoneNumber } = parse(value)
    setParsed({
      phoneNumber,
      country
    })
  }, [value])

  const handleChange = (e, error) => {
    const value = addStartingPlus(e.target.value)
    const { country, phoneNumber } = parse(value)
    setParsed({
      phoneNumber,
      country
    })
    e.target.value = stringify(phoneNumber)
    onChange(e, error)
  }

  const { country, phoneNumber } = parsed

  return (
    <ValidationTextField
      validations={{
        phoneNumberValidation: {
          validator: value => {
            const phone = addStartingPlus(value)
            const err = validate(phone)
            return !err
          },
          message: (
            <FormattedMessage
              {...globalMessageKeys.ErrorInvalidPhone.INVALID}
            />
          )
        },
        ...validations
      }}
      value={removeStartingPlus(phoneNumber) || ''}
      onChange={handleChange}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start" sx={{ mr: 0 }}>
            {country ? (
              <ReactCountryFlag
                countryCode={country}
                style={{
                  fontSize: '20px',
                  marginRight: '8px'
                }}
                svg
              />
            ) : null}
            <StyledPlus style={{ color: 'black' }}>+</StyledPlus>
          </InputAdornment>
        )
      }}
      {...restProps}
    />
  )
}

const StyledPlus = styled('span')(({ theme }) => {
  return {
    color: theme.palette.common.black
  }
})

ValidationPhoneField.propTypes = {
  validations: PropTypes.object,
  error: PropTypes.string
}

ValidationPhoneField.defaultProps = {
  validations: {},
  error: null
}

export default ValidationPhoneField
