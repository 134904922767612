import axios from 'axios'
import { useQuery } from '@tanstack/react-query'
import { ApartmentStats } from './types'
import QueryKeys from '../../utils/queryKeys'

const useGetApartmentStats = (provider: string, enabled: boolean = true) => {
  return useQuery<any, Error, ApartmentStats>(
    [QueryKeys.ApartmentStats, { query: { provider } }],
    async () => {
      const { data } = await axios.get(
        `/apartments/stat?provider=${provider}`
      )
      return data
    },
    { keepPreviousData: true, retry: false, enabled }
  )
}

export default useGetApartmentStats
