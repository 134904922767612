import React from 'react'
import { MenuItem, TextField } from '@mui/material'
import { useIntl } from 'react-intl'
import messageKeys from '../../messages'

type SortFilterProps = {
  sortBy: string
  sortOrder: string
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
}

const SortFilter = (props: SortFilterProps): JSX.Element => {
  const { sortBy, sortOrder, onChange } = props
  const intl = useIntl()
  return (
    <TextField
      label={intl.formatMessage(messageKeys.Sort)}
      margin="dense"
      name="sort"
      variant="standard"
      sx={{
        width: 300
      }}
      value={`${sortBy}.${sortOrder}`}
      onChange={onChange}
      select
    >
      <MenuItem value="holiduScore.DESC">
        {intl.formatMessage(messageKeys.SortHoliduScoreDesc)}
      </MenuItem>
      <MenuItem value="holiduScore.ASC">
        {intl.formatMessage(messageKeys.SortHoliduScoreAsc)}
      </MenuItem>
      <MenuItem value="referencePrice.DESC">
        {intl.formatMessage(messageKeys.SortPriceDesc)}
      </MenuItem>
      <MenuItem value="referencePrice.ASC">
        {intl.formatMessage(messageKeys.SortPriceAsc)}
      </MenuItem>
      <MenuItem value="timestamp.DESC">
        {intl.formatMessage(messageKeys.SortLastUpdatedDesc)}
      </MenuItem>
      <MenuItem value="timestamp.ASC">
        {intl.formatMessage(messageKeys.SortLastUpdatedAsc)}
      </MenuItem>
    </TextField>
  )
}

export default SortFilter
