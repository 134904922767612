import axios from 'axios'
import { useMutation } from '@tanstack/react-query'
import { useSnackbar } from 'notistack'
import { useIntl } from 'react-intl'
import messageKeys from './messages'

const useDeleteProperty = (provider: string, apartmentId: string) => {
  const { enqueueSnackbar } = useSnackbar()
  const intl = useIntl()
  return useMutation<any, Error>(
    async () => {
      return await axios.delete(`/apartment/${apartmentId}?provider=${provider}`)
    },
    {
      onError: error => {
        enqueueSnackbar(
          intl.formatMessage(messageKeys.DELETE_PROPERTY_ERROR, {
            message: error.message
          }),
          {
            variant: 'error'
          }
        )
      },
      onSuccess: () => {
        enqueueSnackbar(intl.formatMessage(messageKeys.DELETE_PROPERTY_SUCCESS), {
          variant: 'success'
        })
      }
    }
  )
}

export default useDeleteProperty
