import React, { useLayoutEffect } from 'react'
import PropTypes from 'prop-types'
import { Box } from '@mui/material'
import get from 'lodash/get'

const Page = props => {
  useLayoutEffect(() => {
    function handleResize() {
      const appBarheight = get(
        document.querySelector('header.MuiAppBar-root'),
        'offsetHeight',
        0
      )
      const pageContent = document.querySelector('[data-screen="PageContent"]')
      const pageHeaderHeight = get(
        document.querySelector('[data-screen="PageHeader"]'),
        'offsetHeight',
        0
      )
      const pageFooterHeight = get(
        document.querySelector('[data-screen="PageFooter"]'),
        'offsetHeight',
        0
      )

      if (pageHeaderHeight || pageFooterHeight) {
        const calculatedHeight = `calc(100vh - ${
          appBarheight + pageHeaderHeight + pageFooterHeight + 2
        }px)`
        pageContent.style.maxHeight = calculatedHeight
        pageContent.style.height = calculatedHeight
        pageContent.style.overflowY = 'scroll'
        pageContent.style.maxWidth = '100vw'
        pageContent.style.overflowX = 'auto'
      }
    }
    window.addEventListener('resize', handleResize)

    setTimeout(() => {
      handleResize()
    }, 0)

    return () => window.removeEventListener('resize', handleResize)
  }, [])
  return (
    <Box data-screen="Page" {...props}>
      {props.children}
    </Box>
  )
}

Page.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired
}

export default Page
