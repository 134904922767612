import React from 'react'
import { Typography, Button, Box } from '@mui/material'
import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import { FormattedMessage } from 'react-intl'
import { messageKeys } from '../messages'
import Section from '../../../components/Section'
import Analytics from '../../../utils/analytics'
import { INTEGRATION_BASICS_CLICK_UPLOAD_LOGO } from '../../../utils/analytics/events'

const CompanyLogo = props => {
  const [uploadedLogoFile, setUploadedLogoFile] = React.useState('')
  const { logopath, onLogoFileChange } = props

  return (
    <Section title={<FormattedMessage {...messageKeys.LogoTitle} />}>
      <Box textAlign="center">
        <img
          style={{
            display: 'block',
            maxHeight: 256,
            width: '100%',
            maxWidth: 256,
            margin: '20px auto 20px'
          }}
          alt="Logo"
          src={uploadedLogoFile || logopath || '/static/img/empty.jpg'}
        />
        <input
          accept=".png,.jpg,.jpeg"
          id="logo-file"
          name="file"
          style={{ display: 'none' }}
          type="file"
          onChange={e => {
            Analytics.trackEvent(INTEGRATION_BASICS_CLICK_UPLOAD_LOGO)
            setUploadedLogoFile(URL.createObjectURL(e.target.files[0]))
            onLogoFileChange(e.target.files[0])
          }}
        />
        <label htmlFor="logo-file">
          <Button
            color="primary"
            component="span"
            size="small"
            variant="contained"
          >
            <CloudUploadIcon style={{ paddingRight: '5px' }} />
            <FormattedMessage {...messageKeys.LogoButton} />
          </Button>

          <Typography display="block" variant="caption" mt={2} gutterBottom>
            <FormattedMessage {...messageKeys.LogoSuggestionText} />
          </Typography>
        </label>
      </Box>
    </Section>
  )
}

export default CompanyLogo
