export const messageKeys = {
  Title: {
    id: 'CustomerPayments:TITLE',
    defaultMessage: 'CustomerPayments:TITLE'
  },
  PaymentOptionsHeader: {
    id: 'CustomerPayments:PAYMENT_OPTIONS_HEADER',
    defaultMessage: 'CustomerPayments:PAYMENT_OPTIONS_HEADER'
  },
  PaymentOptionsSubHeader: {
    id: 'CustomerPayments:PAYMENT_OPTIONS_SUB_HEADER',
    defaultMessage: 'CustomerPayments:PAYMENT_OPTIONS_SUB_HEADER'
  },
  PaymentOptionsRequestSubHeader: {
    id: 'CustomerPayments:PAYMENT_OPTIONS_REQUEST_SUB_HEADER',
    defaultMessage: 'CustomerPayments:PAYMENT_OPTIONS_REQUEST_SUB_HEADER'
  },
  PaymentOptionsCmSubHeader: {
    id: 'CustomerPayments:PAYMENT_OPTIONS_CM_SUB_HEADER',
    defaultMessage: 'CustomerPayments:PAYMENT_OPTIONS_CM_SUB_HEADER'
  },
  CreditCard: {
    id: 'CustomerPayments:CREDIT_CARD',
    defaultMessage: 'CustomerPayments:CREDIT_CARD'
  },
  BankTransfer: {
    id: 'CustomerPayments:BANK_TRANSFER',
    defaultMessage: 'CustomerPayments:BANK_TRANSFER'
  },
  Paypal: {
    id: 'CustomerPayments:PAYPAL',
    defaultMessage: 'CustomerPayments:PAYPAL'
  },
  Invoice: {
    id: 'CustomerPayments:INVOICE',
    defaultMessage: 'CustomerPayments:INVOICE'
  },
  ChequeVacances: {
    id: 'CustomerPayments:CHEQUE_VACANCES',
    defaultMessage: 'CustomerPayments:CHEQUE_VACANCES'
  },
  Ideal: {
    id: 'CustomerPayments:IDEAL',
    defaultMessage: 'CustomerPayments:IDEAL'
  }
}
