import React, { useState } from 'react'
import {
  Collapse,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  TypographyProps
} from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { useIntl } from 'react-intl'
import messageKeys from './messages'
import isArray from 'lodash/isArray'
import { BookingExtraCost, Receipt, SimplePrice } from './types'
import { Section, Tray } from '@holidu/pui-components/dist'
import { getAmountCurrencyText } from '../../utils/currencyUtil'

type PriceTextListProps = { receipt: SimplePrice[] } & TypographyProps

const PriceTextList = (props: PriceTextListProps): JSX.Element => {
  const { receipt, ...restProps } = props
  const priceText = receipt
    .map(item => getAmountCurrencyText(item?.value, item?.currency))
    .join(' / ')
  return (
    <Typography variant="body2" {...restProps}>
      {priceText}
    </Typography>
  )
}

type PriceTextProps = { receipt: SimplePrice } & TypographyProps

const PriceText = (props: PriceTextProps): JSX.Element => {
  const { receipt, ...restProps } = props
  return (
    <Typography variant="subtitle2" {...restProps}>
      {getAmountCurrencyText(receipt.value, receipt.currency)}
    </Typography>
  )
}

type ReceiptRowProps = {
  receipt: SimplePrice
  label: string
}

const ReceiptRow = (props: ReceiptRowProps): JSX.Element => {
  const { receipt, label } = props
  return (
    <TableRow>
      <TableCell colSpan={2}>
        <Typography variant="body2">{label}</Typography>
      </TableCell>
      <TableCell align="right">
        <PriceText variant="subtitle2" receipt={receipt} />
      </TableCell>
    </TableRow>
  )
}

type ExtraCostsRowProps = {
  items: BookingExtraCost[]
  total: SimplePrice | SimplePrice[]
  label: string
}

const ExtraCostsRow = (props: ExtraCostsRowProps): JSX.Element => {
  const { items, total, label } = props
  const [open, setOpen] = useState<boolean>(false)
  const onToggle = () => {
    setOpen(!open)
  }

  return (
    <>
      <TableRow
        onClick={onToggle}
        sx={{
          cursor: 'pointer'
        }}
      >
        <TableCell
          sx={{
            borderBottom: 'none'
          }}
          colSpan={2}
        >
          <Stack direction="row" spacing={1} alignItems="center">
            <ExpandMoreIcon
              sx={{
                transform: open ? 'rotate(180deg)' : 'unset'
              }}
            />
            <Typography variant="body2" component="div">
              {label}
            </Typography>
          </Stack>
        </TableCell>
        <TableCell
          sx={{
            borderBottom: 'none'
          }}
          align="right"
        >
          {isArray(total) ? (
            <PriceTextList receipt={total} />
          ) : (
            <PriceText variant="subtitle2" receipt={total} />
          )}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell
          sx={{
            paddingBottom: 0,
            paddingTop: 0,
            paddingRight: 0
          }}
          colSpan={3}
        >
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Table size="small">
              <TableBody>
                {items.map((item, index) => (
                  <TableRow key={index}>
                    <TableCell sx={{ borderBottom: 'none' }}>
                      {item.name}
                    </TableCell>
                    <TableCell sx={{ borderBottom: 'none' }}>
                      {item.type}
                    </TableCell>
                    <TableCell sx={{ borderBottom: 'none' }} align="right">
                      {item.price !== undefined && (
                        <PriceText variant="body2" receipt={item.price} />
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  )
}

type CostOnUsageRowProps = {
  items: BookingExtraCost[]
  label: string
}

const CostsOnUsageRow = (props: CostOnUsageRowProps): JSX.Element => {
  const { items, label } = props
  const [open, setOpen] = useState<boolean>(false)
  const onToggle = () => {
    setOpen(!open)
  }

  return (
    <>
      <TableRow
        sx={{
          cursor: 'pointer'
        }}
        onClick={onToggle}
      >
        <TableCell
          colSpan={3}
          sx={{
            borderBottom: 'none'
          }}
        >
          <Stack direction="row" spacing={1} alignItems="center">
            <ExpandMoreIcon
              sx={{
                transform: open ? 'rotate(180deg)' : 'unset'
              }}
            />
            <Typography variant="body2" component="div">
              {label}
            </Typography>
          </Stack>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell
          sx={{
            paddingBottom: 0,
            paddingTop: 0,
            paddingRight: 0
          }}
          colSpan={3}
        >
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Table size="small">
              <TableBody>
                {items.map((item, index) => (
                  <TableRow key={index}>
                    <TableCell sx={{ borderBottom: 'none' }}>
                      {item.name}
                    </TableCell>
                    <TableCell sx={{ borderBottom: 'none' }}>
                      {item.type} {item.basisOfCalculation}
                    </TableCell>
                    <TableCell sx={{ borderBottom: 'none' }} align="right">
                      {item.price !== undefined && (
                        <PriceText variant="body2" receipt={item.price} />
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  )
}

type BookingReceiptSectionProps = {
  receipt: Receipt
}
const BookingReceiptSection = (
  props: BookingReceiptSectionProps
): JSX.Element => {
  const { receipt } = props
  const intl = useIntl()

  return (
    <Section title={intl.formatMessage(messageKeys.Receipt)}>
      <Stack spacing={2}>
        <Table>
          <TableBody>
            {receipt.bareRentalPrice !== undefined && (
              <ReceiptRow
                receipt={receipt.bareRentalPrice}
                label={intl.formatMessage(messageKeys.BareRentalPrice)}
              />
            )}

            {receipt.discount !== undefined && (
              <ReceiptRow
                receipt={receipt.discount}
                label={intl.formatMessage(messageKeys.Discount)}
              />
            )}
            {receipt.bookingExtraCosts !== undefined &&
              receipt.totalBookingExtraCosts !== undefined &&
              Boolean(receipt.bookingExtraCosts?.length) && (
                <ExtraCostsRow
                  label={intl.formatMessage(messageKeys.BookingExtraCost)}
                  total={receipt.totalBookingExtraCosts}
                  items={receipt.bookingExtraCosts}
                />
              )}
            {receipt.totalOnSpotExtraCosts !== undefined &&
              receipt.onSpotExtraCosts !== undefined &&
              Boolean(receipt.onSpotExtraCosts?.length) && (
                <ExtraCostsRow
                  label={intl.formatMessage(messageKeys.OnSpotExtraCost)}
                  total={receipt.totalOnSpotExtraCosts}
                  items={receipt.onSpotExtraCosts}
                />
              )}
            {Boolean(receipt.onUsageExtraCosts?.length) && (
              <CostsOnUsageRow
                items={receipt.onUsageExtraCosts || []}
                label={intl.formatMessage(messageKeys.CostOnUsage)}
              />
            )}
          </TableBody>
        </Table>
        <Tray color="primary">
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="subtitle2">
              {intl.formatMessage(messageKeys.TotalBookingCost)}
            </Typography>
            {receipt.totalWithBooking !== undefined && (
              <PriceText
                variant="subtitle2"
                receipt={receipt.totalWithBooking}
              />
            )}
          </Stack>
        </Tray>
      </Stack>
    </Section>
  )
}

export default BookingReceiptSection
