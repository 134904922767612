import React, { useCallback, useEffect, useState } from 'react'
import {
  InputAdornment,
  OutlinedInput,
  OutlinedInputProps
} from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import debounce from 'lodash/debounce'

const QuerySearchField = (props: OutlinedInputProps) => {
  const { onChange, value, ...restProps } = props
  const [stateValue, setStateValue] = useState<string>('')

  useEffect(() => {
    setStateValue(value as string)
  }, [value])

  const debounceSearch = debounce((e: React.ChangeEvent<HTMLInputElement>) => {
    typeof onChange === 'function' && onChange(e)
  }, 1000)

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setStateValue(e.target.value)
      debounceSearch(e)
    },
    [debounceSearch]
  )

  return (
    <OutlinedInput
      value={stateValue}
      onChange={handleChange}
      startAdornment={
        <InputAdornment position="start">
          <SearchIcon />
        </InputAdornment>
      }
      {...restProps}
    />
  )
}

export default QuerySearchField
