import { FormattedMessage, injectIntl } from 'react-intl'
import React, { useEffect, useState } from 'react'
import pick from 'lodash/pick'
import { useSnackbar } from '../../utils/snackBar'
import { startTransaction } from '../../utils/apm'
import {
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  MenuItem,
  Stack,
  TextField
} from '@mui/material'
import Section from '../../components/Section'
import { messageKeys } from './messages'
import { messageKeys as billingDetailsKeys } from '../BillingInformation/messages'
import { messageKeys as globalMessageKeys } from '../../globalMessages'
import ValidationTextField from '../../components/Fields/ValidationTextField'
import countryList from 'react-select-country-list'
import { validateBic, validateIban, validateZip } from '../../utils/validation'
import { ZipUtil } from '@holidu/pui-utils/dist'
import ValidationHint from '../../components/Fields/ValidationHint'
import SaveIcon from '@mui/icons-material/Save'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import {
  getDateFromDateString,
  getDateStringFromDate,
  isValidDate
} from '../../utils/dateUtil'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import CommercialAgreementForm from './Form/CommercialAgreementForm'

const CommercialAgreementPage = props => {
  const { updateCompany } = props

  const [companyData, setCompanyData] = useState({
    legalCompanyName: '',
    street: '',
    number: '',
    zip: '',
    city: '',
    country: '',
    bankName: '',
    sepaLocation: '',
    sepaChargeDate: '',
    iban: '',
    bic: '',
    sepaChargeConsent: false
  })
  const [commercialAgreementSignature, setCommercialAgreementSignature] =
    useState({
      signed: false,
      commission: 0.0
    })
  const [hasChanges, setHasChanges] = useState(false)
  const [errors, setErrors] = useState({})

  const datePickerParams = {
    required: true,
    fullWidth: true,
    error: errors.sepaChargeDate || '',
    name: 'sepaChargeDate',
    validations: {
      dateValidation: {
        validator: value => isValidDate(value),
        message: (
          <FormattedMessage {...globalMessageKeys.ErrorInvalidDataInForm} />
        )
      }
    }
  }

  useEffect(() => {
    const company = props.companies.get(props.selectedProvider)

    if (company) {
      const nextCompanyData = {
        ...pick(company.toJSON(), [
          'legalCompanyName',
          'street',
          'number',
          'zip',
          'city',
          'country',
          'bankName',
          'sepaLocation',
          'sepaChargeDate',
          'iban',
          'bic',
          'sepaChargeConsent',
          'holiduPaymentsEnabled'
        ])
      }

      nextCompanyData['sepaChargeDate'] =
        getDateFromDateString(nextCompanyData['sepaChargeDate']) || ''
      setCompanyData(nextCompanyData)
    }

    const provider = props.providers.get(props.selectedProvider)
    if (provider) {
      setCommercialAgreementSignature(
        provider.get('commercialAgreementSignature').toJSON()
      )
    }
  }, [props.companies, props.selectedProvider, props.providers])

  const { showSuccessSnackbar, showErrorSnackbar } = useSnackbar(
    <FormattedMessage {...messageKeys.CommercialAgreement} />
  )

  const handleSepaCheckboxChange = e => {
    setCompanyData({
      ...companyData,
      sepaChargeConsent: e.target.checked
    })
    setHasChanges(true)
  }

  const handleTextFieldChange = (e, errorReason) => {
    const { name, value } = e.target
    setCompanyData({
      ...companyData,
      [name]: value || null
    })
    setErrors({
      ...errors,
      [name]: errorReason
    })
    setHasChanges(true)
  }

  const handleDateFieldChange = value => {
    let nextCompanyData = companyData

    if (value) {
      const dateString = getDateStringFromDate(value)
      if (dateString) {
        nextCompanyData['sepaChargeDate'] = dateString
        setErrors({
          ...errors,
          sepaChargeDate: null
        })
      } else {
        setErrors({
          ...errors,
          sepaChargeDate: 'dateValidation'
        })
        return
      }
    } else {
      nextCompanyData['sepaChargeDate'] = ''
      setErrors({
        ...errors,
        sepaChargeDate: 'required'
      })
    }

    setCompanyData(nextCompanyData)
    setHasChanges(true)
  }

  const handleCountryChange = (e, err) => {
    const { name, value } = e.target
    setCompanyData({
      ...companyData,
      [name]: value,
      zip: null
    })
    setErrors({
      ...errors,
      [name]: err
    })
    setHasChanges(true)
  }

  const allMandatoryFieldsAreFilled = () => {
    let allGood = true
    Object.keys(errors).forEach(field => {
      const validationFailReason = errors[field]
      if (validationFailReason === 'required') {
        allGood = false
      }
    })

    return allGood
  }

  const allFilledFieldsAreValid = () => {
    let allGood = true
    Object.keys(errors).forEach(field => {
      const validationFailReason = errors[field]
      if (validationFailReason && validationFailReason !== 'required') {
        allGood = false
      }
    })
    return allGood
  }

  const onSubmit = async () => {
    if (!allMandatoryFieldsAreFilled()) {
      showErrorSnackbar(
        <FormattedMessage {...globalMessageKeys.ErrorDoNotDeleteMandatory} />
      )
      return
    }
    if (!allFilledFieldsAreValid()) {
      showErrorSnackbar(
        <FormattedMessage {...globalMessageKeys.ErrorInvalidDataInForm} />
      )
      return
    }

    const company = props.companies.get(props.selectedProvider)
    const transaction = startTransaction('CommercialAgreementSepa#Save')

    updateCompany(
      {
        ...company.toJSON(),
        ...companyData
      },
      () => {
        showSuccessSnackbar()
        setHasChanges(false)
        transaction.end()
      },
      error => {
        showErrorSnackbar(error.text)
        transaction.end()
      }
    )
  }

  return (
    <>
      <form autoComplete="off">
        <Stack spacing={4}>
          <Section
            title={
              <FormattedMessage {...messageKeys.CommercialAgreementTCTitle} />
            }
          >
            {/* commercialAgreement */}
            <CommercialAgreementForm
              commercialAgreementSigned={commercialAgreementSignature.signed}
              commission={commercialAgreementSignature.commission}
              errors={errors}
              disabled={true}
            />
          </Section>
          {!companyData.holiduPaymentsEnabled && (
            <Section title={<FormattedMessage {...messageKeys.SepaTitle} />}>
              <Grid item xs={12} lg={12} xl={12}>
                <Grid spacing={4} container>
                  <Grid xs={12} lg={12} item>
                    <FormattedMessage {...messageKeys.SepaDesc} />
                    <br />
                    <FormattedMessage {...messageKeys.SepaLegalDesc} />
                  </Grid>

                  {/* Holidu GmbH Name And Address */}
                  <Grid xs={12} lg={12} item>
                    <TextField
                      name="holiduNameAddress"
                      label={
                        <FormattedMessage {...messageKeys.HoliduNameAddress} />
                      }
                      value={'Holidu GmbH\nRiesstraße 24\n80992 München'}
                      fullWidth
                      disabled={true}
                      onChange={handleTextFieldChange}
                      multiline
                      rows={3}
                    />
                  </Grid>

                  {/* Holidu GmbH Creditor Identifier Number */}
                  <Grid xs={12} lg={12} item>
                    <TextField
                      name="holiduCIF"
                      label={<FormattedMessage {...messageKeys.HoliduCIF} />}
                      value={'DE16ZZZ00002165691'}
                      fullWidth
                      disabled={true}
                      onChange={handleTextFieldChange}
                    />
                  </Grid>

                  {/* Legal Name */}
                  <Grid xs={12} lg={12} item>
                    <ValidationTextField
                      name="legalCompanyName"
                      error={errors.legalCompanyName}
                      label={
                        <FormattedMessage {...billingDetailsKeys.LegalName} />
                      }
                      value={companyData.legalCompanyName || ''}
                      fullWidth
                      required
                      disabled={props.disabled}
                      onChange={handleTextFieldChange}
                    />
                  </Grid>
                  {/* Street */}
                  <Grid xs={12} lg={6} item>
                    <ValidationTextField
                      name="street"
                      error={errors.street}
                      label={
                        <FormattedMessage
                          {...billingDetailsKeys.BillingAddress}
                        />
                      }
                      value={companyData.street || ''}
                      fullWidth
                      required
                      disabled={props.disabled}
                      onChange={handleTextFieldChange}
                    />
                  </Grid>
                  {/* House Number */}
                  <Grid xs={12} lg={3} item>
                    <ValidationTextField
                      name="number"
                      error={errors.number}
                      label={
                        <FormattedMessage
                          {...billingDetailsKeys.BillingAddressHouseNumber}
                        />
                      }
                      value={companyData.number || ''}
                      fullWidth
                      required
                      disabled={props.disabled}
                      onChange={handleTextFieldChange}
                    />
                  </Grid>
                  {/* City */}
                  <Grid xs={12} lg={3} item>
                    <ValidationTextField
                      name="city"
                      error={errors.city}
                      label={
                        <FormattedMessage
                          {...billingDetailsKeys.BillingAddressCity}
                        />
                      }
                      value={companyData.city || ''}
                      fullWidth
                      required
                      disabled={props.disabled}
                      onChange={handleTextFieldChange}
                    />
                  </Grid>
                  {/* Country */}
                  <Grid xs={12} lg={6} item>
                    <ValidationTextField
                      error={errors.country}
                      name="country"
                      label={
                        <FormattedMessage
                          {...billingDetailsKeys.BillingAddressCountry}
                        />
                      }
                      value={companyData.country || ''}
                      fullWidth
                      required
                      select
                      disabled={props.disabled}
                      onChange={handleCountryChange}
                    >
                      <MenuItem value="" disabled>
                        <FormattedMessage {...globalMessageKeys.Select} />
                      </MenuItem>
                      {countryList()
                        .getData()
                        .map((country, index) => (
                          <MenuItem key={index} value={country.value}>
                            {country.label}
                          </MenuItem>
                        ))}
                    </ValidationTextField>
                  </Grid>
                  {/* ZIP Code */}
                  <Grid xs={12} lg={6} item>
                    <ValidationTextField
                      name="zip"
                      error={errors.zip}
                      label={
                        <FormattedMessage
                          {...billingDetailsKeys.BillingAddressZip}
                        />
                      }
                      value={companyData.zip || ''}
                      fullWidth
                      required
                      disabled={props.disabled}
                      validations={{
                        zipValidation: {
                          validator: zip =>
                            validateZip(companyData.country, zip),
                          message: (
                            <FormattedMessage
                              {...globalMessageKeys.ErrorInvalidZipCode}
                            />
                          )
                        }
                      }}
                      onChange={handleTextFieldChange}
                    />
                    {ZipUtil.isValidCountry(companyData.country) && (
                      <ValidationHint>
                        <FormattedMessage {...globalMessageKeys.ForExample} />
                        :&nbsp;{ZipUtil.getZipSample(companyData.country)}
                      </ValidationHint>
                    )}
                  </Grid>

                  {/*IBAN*/}
                  <Grid xs={12} lg={6} item>
                    <ValidationTextField
                      name="iban"
                      error={errors.iban}
                      label={<FormattedMessage {...messageKeys.Iban} />}
                      value={companyData.iban || ''}
                      fullWidth
                      required
                      disabled={props.disabled}
                      onChange={handleTextFieldChange}
                      validations={{
                        ibanValidation: {
                          validator: iban => validateIban(iban),
                          message: (
                            <FormattedMessage
                              {...globalMessageKeys.ErrorInvalidIban}
                            />
                          )
                        }
                      }}
                    />
                  </Grid>
                  {/* BIC */}
                  <Grid xs={12} lg={6} item>
                    <ValidationTextField
                      name="bic"
                      error={errors.bic}
                      label={<FormattedMessage {...messageKeys.Bic} />}
                      value={companyData.bic || ''}
                      fullWidth
                      required
                      disabled={props.disabled}
                      onChange={handleTextFieldChange}
                      validations={{
                        bicValidation: {
                          validator: bic => validateBic(bic),
                          message: (
                            <FormattedMessage {...messageKeys.InvalidBic} />
                          )
                        }
                      }}
                    />
                  </Grid>

                  {/* Bank Name */}
                  <Grid xs={12} lg={6} item>
                    <ValidationTextField
                      name="bankName"
                      error={errors.bankName}
                      label={<FormattedMessage {...messageKeys.BankName} />}
                      value={companyData.bankName || ''}
                      fullWidth
                      required
                      disabled={props.disabled}
                      onChange={handleTextFieldChange}
                    />
                  </Grid>
                  {/* Location */}
                  <Grid xs={12} lg={3} item>
                    <ValidationTextField
                      name="sepaLocation"
                      error={errors.sepaLocation}
                      label={<FormattedMessage {...messageKeys.Location} />}
                      value={companyData.sepaLocation || ''}
                      fullWidth
                      required
                      disabled={props.disabled}
                      onChange={handleTextFieldChange}
                    />
                  </Grid>

                  {/* Date */}
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <Grid xs={12} lg={3} item>
                      <DatePicker
                        name="date"
                        label={<FormattedMessage {...messageKeys.Date} />}
                        value={companyData.sepaChargeDate}
                        onChange={handleDateFieldChange}
                        disabled={props.disabled}
                        renderInput={params => (
                          <ValidationTextField
                            {...params}
                            {...datePickerParams}
                            readOnly
                          />
                        )}
                      />
                    </Grid>
                  </LocalizationProvider>

                  {/* SEPA charge consent */}
                  <Grid xs={12} lg={12} item>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={companyData.sepaChargeConsent}
                          disabled={props.disabled}
                          onChange={handleSepaCheckboxChange}
                        />
                      }
                      label={
                        <FormattedMessage {...messageKeys.SepaConsentHint} />
                      }
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Section>
          )}
          {!companyData.holiduPaymentsEnabled && (
            <Button
              size="large"
              color="primary"
              disabled={!hasChanges}
              startIcon={<SaveIcon />}
              variant="contained"
              fullWidth
              onClick={onSubmit}
            >
              <FormattedMessage {...globalMessageKeys.SaveButton} />
            </Button>
          )}
        </Stack>
      </form>
    </>
  )
}

export default injectIntl(CommercialAgreementPage)
