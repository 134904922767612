import green from "@mui/material/colors/green"
import red from "@mui/material/colors/red"
import pink from "@mui/material/colors/pink"
import orange from "@mui/material/colors/orange"
import blueGrey from "@mui/material/colors/blueGrey"

export const GOOD_COLOR = green[600]
export const BAD_COLOR = red[600]
export const AVERAGE_COLOR = orange[600]
export const ROSY_COLOR = pink.A400

export const GOOD_COLOR_LIGHT = green[50]
export const BAD_COLOR_LIGHT = red[50]
export const AVERAGE_COLOR_LIGHT = orange[50]

export const BAD_COLOR_DARK = red[900]

export const HIGHLIGHT_PRIMARY = blueGrey[50]
