import { mutateAsync } from 'redux-query-immutable'
import { connectToExisting, registerNew } from './registrationRequest'

export const createNewProviderAction = (
  data,
  onSuccess = () => {},
  onError = () => {}
) => {
  return dispatch => {
    return dispatch(mutateAsync(registerNew(data))).then(response => {
      if (response.status === 200) {
        onSuccess(response)
      } else {
        onError(response)
      }
    })
  }
}

export const connectToExistingProviderAction = (
  data,
  onSuccess = () => {},
  onError = () => {}
) => {
  return dispatch => {
    return dispatch(mutateAsync(connectToExisting(data))).then(response => {
      if (response.status === 200) {
        onSuccess(response)
      } else {
        onError(response)
      }
    })
  }
}
