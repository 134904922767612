import React from 'react'
import { Link } from 'react-router-dom'

import { Alert, Button, Stack, Typography } from '@mui/material'

const ErrorPageContent = ({ status }) => {
  return (
    <Alert severity="error">
      <Stack alignItems="center">
        {status && (
          <Typography variant="h5" gutterBottom>
            Error code: {status}
          </Typography>
        )}
        <Typography variant="h6" gutterBottom>
          The server encountered something unexpected that did not allow it to
          complete the request.
        </Typography>
        <Button component={Link} mt={2} to="/" variant="contained">
          Return to Dashboard
        </Button>
      </Stack>
    </Alert>
  )
}

export default ErrorPageContent
