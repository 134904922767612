export const messageKeys = {
  TITLE: {
    id: 'NON_HOPA_INVOICES:TITLE',
    defaultMessage: 'NON_HOPA_INVOICES:TITLE'
  },
  EMPTY_INVOICES: {
    id: 'INVOICES:EMPTY_INVOICES',
    defaultMessage: 'INVOICES:EMPTY_INVOICES'
  },
  TYPE: {
    id: 'INVOICES:TYPE',
    defaultMessage: 'INVOICES:TYPE'
  },
  DATE: {
    id: 'NON_HOPA_INVOICES:DATE',
    defaultMessage: 'NON_HOPA_INVOICES:DATE'
  },
  DOWNLOADS: {
    id: 'INVOICES:DOWNLOADS',
    defaultMessage: 'INVOICES:DOWNLOADS'
  },
  DOWNLOAD_INVOICE_ATTACHMENT: {
    id: 'INVOICES:DOWNLOAD_INVOICE_ATTACHMENT',
    defaultMessage: 'INVOICES:DOWNLOAD_INVOICE_ATTACHMENT'
  },
  INFO: {
    id: 'NON_HOPA_INVOICES:INFO',
    defaultMessage: 'NON_HOPA_INVOICES:INFO'
  }
}
