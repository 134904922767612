import React from 'react'
import { Button, Tooltip } from '@mui/material'
import DownloadIcon from '@mui/icons-material/Download'
import { useIntl } from 'react-intl'
import messageKeys from '../messages'
import usePropertiesDownload from '../usePropertiesDownload'
import { useSelector } from 'react-redux'
import { getSelectedProvider } from '../../../store/providerSelection/providerSelector'
import { LoadingButton } from '@mui/lab'

type PropertiesActionsProps = {
  totalCount: number
  disabled: boolean
  onDownloadCsv?: () => void
}

const PropertiesActions = (props: PropertiesActionsProps): JSX.Element => {
  const { totalCount, disabled, onDownloadCsv } = props
  const { selectedProvider } = useSelector(getSelectedProvider)

  const { isLoading, mutate } = usePropertiesDownload(selectedProvider)
  const intl = useIntl()

  const handleDownload = (event: React.MouseEvent<HTMLButtonElement>) => {
    mutate()
    if (typeof onDownloadCsv === 'function') onDownloadCsv()
  }

  return (
    <>
      {totalCount <= 50000 ? (
        <LoadingButton
          loading={isLoading}
          disabled={disabled}
          onClick={handleDownload}
          startIcon={<DownloadIcon />}
        >
          {intl.formatMessage(messageKeys.DownloadCsv)}
        </LoadingButton>
      ) : (
        <Tooltip title={intl.formatMessage(messageKeys.DownloadCsvLimit)}>
          <span>
            <Button disabled startIcon={<DownloadIcon />}>
              {intl.formatMessage(messageKeys.DownloadCsv)}
            </Button>
          </span>
        </Tooltip>
      )}
    </>
  )
}

export default PropertiesActions
