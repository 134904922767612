import React from 'react'
import { Box, Checkbox, FormControlLabel } from '@mui/material'

type ScoreFilterProps = {
  name: string
  label: string
  checked: boolean
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
}

const ScoreFilter = (props: ScoreFilterProps): JSX.Element => {
  const { name, checked, label, onChange } = props
  return (
    <Box
      sx={{
        flexBasis: 'min-content',
        minWidth: 240
      }}
    >
      <FormControlLabel
        control={<Checkbox name={name} onChange={onChange} checked={checked} />}
        label={label}
      />
    </Box>
  )
}

export default ScoreFilter
