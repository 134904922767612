import { Map as ImmutableMap } from 'immutable'

export const getProviderSelector = state => {
  return {
    providers: state.get('entities').get('providers', new ImmutableMap())
  }
}

export const getOneProviderSelector = state => {
  return {
    provider: state.get('entities').get('provider', new ImmutableMap())
  }
}
