import React, { useEffect, useState } from 'react'
import { useImmerReducer } from 'use-immer'
import CancellationPolicy from './Form/CancellationPolicy'
import { useSnackbar } from '../../utils/snackBar'
import { Page, PageContent, PageHeader } from '@holidu/pui-components/dist'
import { Button, Stack, Typography } from '@mui/material'
import SaveIcon from '@mui/icons-material/Save'
import InformationMissingError from '../../components/InformationMissingError'
import { useIntl } from 'react-intl'
import { messageKeys } from './messages'
import { messageKeys as globalMessageKeys } from '../../globalMessages'
import useGetProviderConfiguration from '../../hooks/useGetProviderConfiguration'
import { getSelectedProvider } from '../../localStorage'
import useGetProvider from '../../hooks/useGetProvider'
import { useCancellationPolicyMissingFields } from './useCancellationPolicyMissingFields'
import usePutProviderConfiguration from '../../hooks/usePutProviderConfig'
import { getErrorMessage } from '../../utils/axiosUtil'
import {
  PaymentRate,
  ProviderConfiguration
} from '../../hooks/types/ProviderConfiguration'
import { AxiosError } from 'axios'
import Loader from '../../components/Loader'
import {
  CancellationValue,
  isCustomCancellationPolicy,
  isDeprecatedCancellationPolicy
} from './utils'
import Analytics from '../../utils/analytics'
import { CANCELLATION_POLICY_SAVE } from '../../utils/analytics/events'
import { PaymentRates } from './Form/PaymentRates'

type State = {
  providerConfig: ProviderConfiguration
  hasChanges: boolean
  paymentRatesError: string
}

const INITIAL_STATE: State = {
  providerConfig: {},
  hasChanges: false,
  paymentRatesError: ''
}

enum ActionTypes {
  UpdatecCancellationPolicy = 'UpdatecCancellationPolicy',
  DataSaved = 'DataSaved',
  LoadData = 'LoadData',
  UpdatePaymentRates = 'UpdatePaymentRates',
  PaymentRatesError = 'PaymentRatesError'
}

type Action =
  | { type: ActionTypes.UpdatecCancellationPolicy; payload: string }
  | { type: ActionTypes.DataSaved }
  | { type: ActionTypes.LoadData; payload: ProviderConfiguration }
  | { type: ActionTypes.UpdatePaymentRates; payload: PaymentRate[] }
  | { type: ActionTypes.PaymentRatesError; payload: string }

const reducer = (draft: State, action: Action) => {
  switch (action.type) {
    case ActionTypes.UpdatecCancellationPolicy: {
      draft.providerConfig.cancellationPolicy = action.payload
      draft.hasChanges = true
      break
    }
    case ActionTypes.DataSaved:
      draft.hasChanges = false
      break
    case ActionTypes.LoadData:
      draft.providerConfig = action.payload
      break
    case ActionTypes.UpdatePaymentRates:
      debugger
      draft.providerConfig.paymentRates = action.payload
      draft.hasChanges = true
      break
    case ActionTypes.PaymentRatesError:
      draft.paymentRatesError = action.payload
      break
    default:
      break
  }
}

const CancellationPolicyPage = (): JSX.Element => {
  const intl = useIntl()
  const selectedProvider = getSelectedProvider()
  const { showSuccessSnackbar, showErrorSnackbar } = useSnackbar(
    intl.formatMessage(messageKeys.Title)
  )
  const { data: provider, isLoading: isProviderLoading } =
    useGetProvider(selectedProvider)
  const { data: providerConfig, isLoading: isProviderConfigLoading } =
    useGetProviderConfiguration(selectedProvider)
  const { isLoading: isSaving, mutateAsync: mutateSave } =
    usePutProviderConfiguration(selectedProvider)
  const cpFields = useCancellationPolicyMissingFields(providerConfig)
  const [initialCancellationPolicy, setInitialCancellationPolicy] = useState<
    string | undefined
  >('')
  const [state, dispatch] = useImmerReducer(reducer, INITIAL_STATE)

  useEffect(() => {
    if (initialCancellationPolicy === '' && !isProviderConfigLoading) {
      setInitialCancellationPolicy(providerConfig?.cancellationPolicy)
    }
    if (providerConfig) {
      dispatch({
        type: ActionTypes.LoadData,
        payload: providerConfig
      })
    }
  }, [
    dispatch,
    selectedProvider,
    providerConfig,
    initialCancellationPolicy,
    isProviderConfigLoading,
    setInitialCancellationPolicy
  ]) //eslint-disable-line

  function paymentRatesHaveEmptyFields() {
    const paymentRates = state.providerConfig.paymentRates || []
    const customRates = paymentRates.filter(
      (rate: PaymentRate, index: number) => index !== 0
    )
    return (
      customRates.length > 0 &&
      customRates.some(
        (rate: PaymentRate) =>
          !rate.percentage || rate.daysBeforeArrival == null
      )
    )
  }

  const submitForm = async () => {
    if (state.paymentRatesError) {
      showErrorSnackbar(state.paymentRatesError)
      return
    }
    if (paymentRatesHaveEmptyFields()) {
      showErrorSnackbar(
        intl.formatMessage(messageKeys.SomeOfPaymentRatesFieldsAreEmpty)
      )
      return
    }
    Analytics.trackEvent(CANCELLATION_POLICY_SAVE)
    setInitialCancellationPolicy(state.providerConfig.cancellationPolicy)
    try {
      await mutateSave(state.providerConfig)
      dispatch({ type: ActionTypes.DataSaved })
      showSuccessSnackbar()
    } catch (err) {
      showErrorSnackbar(getErrorMessage(err as AxiosError))
    }
  }

  const handleCancellationPolicyChange = (newCancellationPolicy: string) => {
    let payload = newCancellationPolicy
    if (newCancellationPolicy === CancellationValue.CUSTOM) {
      payload = providerConfig?.cancellationPolicy || ''
    }
    dispatch({
      type: ActionTypes.UpdatecCancellationPolicy,
      payload: payload
    })
  }

  const isCurrentPolicyCustom = isCustomCancellationPolicy(
    providerConfig?.cancellationPolicy
  )

  let stateCancellationPolicy = state.providerConfig.cancellationPolicy
  if (isCustomCancellationPolicy(stateCancellationPolicy)) {
    stateCancellationPolicy = CancellationValue.CUSTOM
  } else if (isDeprecatedCancellationPolicy(stateCancellationPolicy)) {
    stateCancellationPolicy = CancellationValue.DEPRECATED
  }

  return (
    <Page>
      <PageHeader
        sx={{
          borderBottom: 'none'
        }}
      >
        <Typography variant="h6" sx={{ flexGrow: 1 }}>
          {intl.formatMessage(messageKeys.Title)}
        </Typography>
        <Button
          color="primary"
          disabled={!state.hasChanges}
          startIcon={<SaveIcon />}
          variant="contained"
          onClick={submitForm}
        >
          {intl.formatMessage(globalMessageKeys.SaveButton)}
        </Button>
      </PageHeader>
      <PageContent px={3} pb={8}>
        {provider && provider.status !== 'LIVE' && cpFields.length ? (
          <InformationMissingError />
        ) : null}
        <Stack spacing={2}>
          <CancellationPolicy
            isCustomDisabled={!isCurrentPolicyCustom}
            cancellationPolicy={stateCancellationPolicy}
            cancellationPolicyConfigSource={
              state.providerConfig.cancellationPolicyConfigSource
            }
            bizDev={provider?.buzDevResponsible}
            onChange={handleCancellationPolicyChange}
          />

          <PaymentRates
            paymentRates={
              state.providerConfig.paymentRates &&
              state.providerConfig.paymentRates.length > 0
                ? state.providerConfig.paymentRates
                : [{ percentage: 100 }]
            }
            paymentRatesSource={state.providerConfig.paymentRatesConfigSource}
            onChange={(newRates: PaymentRate[]) =>
              dispatch({
                type: ActionTypes.UpdatePaymentRates,
                payload: newRates
              })
            }
            onError={(error: string) =>
              dispatch({ type: ActionTypes.PaymentRatesError, payload: error })
            }
            cancellationPolicy={state.providerConfig.cancellationPolicy}
            initialCancellationPolicy={initialCancellationPolicy}
          />
        </Stack>

        {(isProviderConfigLoading || isProviderLoading || isSaving) && (
          <Loader />
        )}
      </PageContent>
    </Page>
  )
}

export default CancellationPolicyPage
