import React from 'react'
import { Box, Divider, Typography } from '@mui/material'
import { FormattedMessage } from 'react-intl'
import { messageKeys } from './messages'

const getTotalPayoutAmount = payouts => {
  const totalPayout = payouts.reduce((total, item) => {
    return total + Number(item.get('amount', 0))
  }, 0)

  return totalPayout.toFixed(2)
}

const PayoutsInfoDisplay = props => {
  const { label, value } = props
  return (
    <Box display="flex" flexDirection="column" alignItems="flex-end">
      <Typography color="textSecondary" variant="caption">
        <strong>{label}</strong>
      </Typography>
      <Typography variant="body2">
        <strong>{value}</strong>
      </Typography>
    </Box>
  )
}

const PayoutsInfo = props => {
  const { data } = props

  const count = data?.size
  let totalAmount = '-'
  if (count) {
    totalAmount = getTotalPayoutAmount(data)
    totalAmount = totalAmount + ' ' + data.first().get('currency', '')
  }

  return (
    <Box display="flex">
      <PayoutsInfoDisplay
        label={<FormattedMessage {...messageKeys.COUNT} />}
        value={count}
      />
      <Divider orientation="vertical" flexItem sx={{ mx: 2, my: 1 }} />
      <PayoutsInfoDisplay
        label={<FormattedMessage {...messageKeys.TOTAL_AMOUNT} />}
        value={totalAmount}
      />
    </Box>
  )
}

export default PayoutsInfo
