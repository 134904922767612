export const messageKeys = {
  Title: {
    id: 'GeneralIntegrationDetails:TITLE',
    defaultMessage: 'GeneralIntegrationDetails:TITLE'
  },
  Header: {
    id: 'GeneralIntegrationDetails:HEADER',
    defaultMessage: 'GeneralIntegrationDetails:HEADER'
  },
  SubHeader: {
    id: 'GeneralIntegrationDetails:SUB_HEADER',
    defaultMessage: 'GeneralIntegrationDetails:SUB_HEADER'
  },
  IntegrationChannel: {
    id: 'GeneralIntegrationDetails:INTEGRATION_CHANNEL',
    defaultMessage: 'GeneralIntegrationDetails:INTEGRATION_CHANNEL'
  },
  IntegrationStatus: {
    id: 'GeneralIntegrationDetails:INTEGRATION_STATUS',
    defaultMessage: 'GeneralIntegrationDetails:INTEGRATION_STATUS'
  },
  CompanyName: {
    id: 'GeneralIntegrationDetails:COMPANY_NAME',
    defaultMessage: 'GeneralIntegrationDetails:COMPANY_NAME'
  },
  ShortCompanyName: {
    id: 'GeneralIntegrationDetails:SHORT_COMPANY_NAME',
    defaultMessage: 'GeneralIntegrationDetails:SHORT_COMPANY_NAME'
  },
  AdditionalInfoHeader: {
    id: 'GeneralIntegrationDetails:ADDITIONAL_INFO_HEADER',
    defaultMessage: 'GeneralIntegrationDetails:ADDITIONAL_INFO_HEADER'
  },
  AdditionalInfoSubHeader: {
    id: 'GeneralIntegrationDetails:ADDITIONAL_INFO_SUB_HEADER',
    defaultMessage: 'GeneralIntegrationDetails:ADDITIONAL_INFO_SUB_HEADER'
  },
  NumberOfProperties: {
    id: 'GeneralIntegrationDetails:NUMBER_OF_PROPERTIES',
    defaultMessage: 'GeneralIntegrationDetails:NUMBER_OF_PROPERTIES'
  },
  LocationOfProperties: {
    id: 'GeneralIntegrationDetails:LOCATION_OF_PROPERTIES',
    defaultMessage: 'GeneralIntegrationDetails:LOCATION_OF_PROPERTIES'
  },
  WebSiteUrl: {
    id: 'GeneralIntegrationDetails:WEBSITE_URL',
    defaultMessage: 'GeneralIntegrationDetails:WEBSITE_URL'
  },
  LogoTitle: {
    id: 'GeneralIntegrationDetails:LOGO_TITLE',
    defaultMessage: 'GeneralIntegrationDetails:LOGO_TITLE'
  },
  LogoButton: {
    id: 'GeneralIntegrationDetails:LOGO_BUTTON',
    defaultMessage: 'GeneralIntegrationDetails:LOGO_BUTTON'
  },
  LogoSuggestionText: {
    id: 'GeneralIntegrationDetails:LOGO_SUGGESTION_TEXT',
    defaultMessage: 'GeneralIntegrationDetails:LOGO_SUGGESTION_TEXT'
  }
}
