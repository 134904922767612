import { saveSelectedProvider, getSelectedProvider } from '../../localStorage'
import { CHANGE_PROVIDER } from './actions'

const SELECTED_PROVIDER = getSelectedProvider()

export const providerSelectionReducer = (state = SELECTED_PROVIDER, action) => {
  if (action.type === CHANGE_PROVIDER) {
    saveSelectedProvider(action.provider)
    return action.provider
  }
  return state
}
