import React from 'react'
import { useIntl } from 'react-intl'
import messageKeys from './messages'
import { BookingInfo } from './types'
import { CancellationPolicy, Section } from '@holidu/pui-components/dist'
import useCancellationPolicyProps from './useCancellationPolicyProps'
import { Box } from '@mui/material'
import useResponsiveWidth from '../../components/hooks/useResponsiveWidth'

type CancellationPolicySectionProps = {
  bookingInfo: BookingInfo
}
const CancellationPolicySection = (
  props: CancellationPolicySectionProps
): JSX.Element => {
  const { bookingInfo } = props

  const bookingDate = bookingInfo.bookedAt
  const checkinDate = bookingInfo.checkin
  const cancellationFees =
    bookingInfo.cancellationPolicy?.cancellationFees || []

  const intl = useIntl()
  const cancellationPolicyProps = useCancellationPolicyProps(
    bookingDate,
    checkinDate,
    cancellationFees
  )

  const { width, containerRef } = useResponsiveWidth(600)

  return (
    <Section title={intl.formatMessage(messageKeys.CancellationPolicy)}>
      {cancellationPolicyProps && (
        <Box ref={containerRef}>
          <CancellationPolicy {...cancellationPolicyProps} width={width} />
        </Box>
      )}
    </Section>
  )
}

export default CancellationPolicySection
