import { useIntl } from 'react-intl'
import { ProviderConfiguration } from '../../hooks/types/ProviderConfiguration'
import { messageKeys } from './messages'

export const useCancellationPolicyMissingFields = (
  providerConfig?: ProviderConfiguration
): any => {
  const intl = useIntl()
  let missingFields = []
  if (providerConfig && !providerConfig.cancellationPolicy) {
    missingFields.push(intl.formatMessage(messageKeys.Title))
  }
  return missingFields
}
