import moment from 'moment'

export const getArrivalDate = (arrivalDate: any) => {
  if (arrivalDate === 'FUTURE') {
    return { arrivalFrom: moment().format('YYYY-MM-DD') }
  } else if (arrivalDate === 'PAST') {
    return { arrivalTo: moment().format('YYYY-MM-DD') }
  } else {
    return {}
  }
}
