import env from '../../env'
import { currentUserIdToken } from '../../utils/awsCognitoClient'

export const getFetchKycStatusRequest = ({ selectedProvider, companies }) => {
  const company = companies && companies.get(selectedProvider)
  const isPlutusProvider = Boolean(
    company && company.get('holiduPaymentsEnabled')
  )

  if (!isPlutusProvider) {
    return
  }

  return {
    url: `${env.BACKEND}/adyen-verification-status/${selectedProvider}`,
    transform: response => ({
      kycStatus: response
    }),
    update: {
      kycStatus: (prev, next) => next
    },
    options: {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + currentUserIdToken()
      }
    }
  }
}
