import React from 'react'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Typography from '@mui/material/Typography'
import HelpOutlinedIcon from '@mui/icons-material/HelpOutlined'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import ErrorIcon from '@mui/icons-material/Error'
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined'

export enum OnbordingStepStatus {
  UNKNOWN = 'UNKNOWN',
  GOOD_TO_GO = 'GOOD_TO_GO',
  ACTION_REQUIRED = 'ACTION_REQUIRED',
  PENDING = 'PENDING'
}

type OnboardingStepStatusIconSetType = {
  [key: string]: any
}
const OnboardingStepStatusIconSet: OnboardingStepStatusIconSetType = {
  UNKNOWN: <HelpOutlinedIcon color="primary" />,
  GOOD_TO_GO: <CheckCircleIcon color="success" />,
  ACTION_REQUIRED: <ErrorIcon color="error" />,
  PENDING: <CircleOutlinedIcon />
}

type OnboardingStepProps = {
  label: string
  desc: string
  status: string
  children: React.ReactNode
}
const OnboardingStep = (props: OnboardingStepProps) => {
  const { children, desc, label, status } = props
  return (
    <ListItem sx={{ alignItems: 'flex-start' }}>
      <ListItemIcon sx={{ minWidth: 40, mt: '7px' }}>
        {OnboardingStepStatusIconSet[status]}
      </ListItemIcon>
      <ListItemText disableTypography>
        <Typography variant="subtitle1">
          <strong>{label}</strong>
        </Typography>
        <Typography variant="body2">{desc}</Typography>
        {children}
      </ListItemText>
    </ListItem>
  )
}

export default OnboardingStep
