import React from 'react'
import { Box, Button, Typography, styled } from '@mui/material'
import { FormattedMessage } from 'react-intl'
import { signOut } from '../../utils/awsCognitoClient'
import { messageKeys as globalMessageKeys } from '../../globalMessages'
import { messageKeys } from './messages'
import Analytics from '../../utils/analytics'
import { SIGN_OUT } from '../../utils/analytics/events'

const FinalizeRegistrationThankYouPage = () => {
  const onClickSignOut = () => {
    Analytics.trackEvent(SIGN_OUT, 'From FinalizeRegistrationThankYouPage')
    signOut()
    window.location.replace('/')
  }

  return (
    <StyledBox
      alignItems="center"
      display="flex"
      flexDirection="column"
      justifyContent="center"
    >
      <Typography variant="h1" gutterBottom>
        <FormattedMessage {...messageKeys.ThankYouTitle} />
      </Typography>
      <Typography variant="subtitle1" gutterBottom>
        <FormattedMessage {...messageKeys.ThankYouMessage} />
      </Typography>
      <Button
        color="primary"
        style={{ marginTop: '2rem' }}
        variant="contained"
        onClick={onClickSignOut}
      >
        <FormattedMessage {...globalMessageKeys.SignOut} />
      </Button>
    </StyledBox>
  )
}

export default FinalizeRegistrationThankYouPage

const StyledBox = styled(Box)({
  height: '100vh',
  width: '60%',
  margin: 'auto'
})
