import React from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { connectRequest } from 'redux-query-immutable-react'
import { RequestsAreDone } from '../RequestsAreDone'
import {
  connectToExistingProviderAction,
  createNewProviderAction
} from '../../store/registration/actions'
import { getAllChannelManagerConfigsSelector } from '../../store/channelManager/selectors'
import { getAllChannelManagerConfigurationRequest } from '../../store/channelManager/requests'
import { getUserSelector } from '../../store/user/userSelector'
import { getUserRequest } from '../../store/user/userRequest'
import { getProviderRequest } from '../../store/provider/providerRequest'
import { getProviderSelector } from '../../store/provider/providerSelector'
import FinalizeRegistrationPage from './FinalizeRegistrationPage'

const FinalizeRegistrationPageContainer = props => {
  return (
    <FinalizeRegistrationPage
      {...props}
      channelManagerConfigs={props.channelManagerConfigs}
      connectToExisting={props.connectToExisting}
      registerNew={props.registerNew}
      user={props.user}
    />
  )
}

const mapDispatchToProps = (dispatch, props) => ({
  registerNew: (data, onSuccess, onError) => {
    dispatch(createNewProviderAction(data, onSuccess, onError))
  },
  connectToExisting: (data, onSuccess, onError) => {
    dispatch(connectToExistingProviderAction(data, onSuccess, onError))
  }
})

export default compose(
  connect(getAllChannelManagerConfigsSelector, mapDispatchToProps),
  connect(getUserSelector),
  connect(getProviderSelector),
  connectRequest(getAllChannelManagerConfigurationRequest),
  connectRequest(getUserRequest),
  connectRequest(getProviderRequest),
  RequestsAreDone
)(FinalizeRegistrationPageContainer)
