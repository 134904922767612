import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Stack, styled, Typography } from '@mui/material'
import { messageKeys } from './messages'

const StyledImage = styled('img')({
  maxWidth: '60%'
})

const EmptyInvoices = (): JSX.Element => {
  return (
    <Stack alignItems="center" mt={2}>
      <StyledImage
        alt="No invoices"
        src="/static/img/vectors/EmptyBookings.svg"
      />
      <Typography variant="h6">
        <FormattedMessage {...messageKeys.EMPTY_INVOICES} />
      </Typography>
    </Stack>
  )
}

export default EmptyInvoices
