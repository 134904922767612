// import { addLocaleData } from 'react-intl'
import moment from 'moment'

import 'moment/locale/en-gb'
import 'moment/locale/de'
import 'moment/locale/it'
import 'moment/locale/es'
import 'moment/locale/fr'
import 'moment/locale/nl'

// import enLocaleData from 'react-intl/locale-data/en'
// import deLocaleData from 'react-intl/locale-data/de'
// import itLocaleData from 'react-intl/locale-data/it'
// import esLocaleData from 'react-intl/locale-data/es'
// import frLocaleData from 'react-intl/locale-data/fr'
// import nlLocaleData from 'react-intl/locale-data/nl'

import enTranslationMessages from './app/translations/en-GB.json'
import deTranslationMessages from './app/translations/de-DE.json'
import itTranslationMessages from './app/translations/it-IT.json'
import esTranslationMessages from './app/translations/es-ES.json'
import frTranslationMessages from './app/translations/fr-FR.json'
import nlTranslationMessages from './app/translations/nl-NL.json'

// addLocaleData(enLocaleData)
// addLocaleData(deLocaleData)
// addLocaleData(itLocaleData)
// addLocaleData(esLocaleData)
// addLocaleData(frLocaleData)
// addLocaleData(nlLocaleData)

export const DEFAULT_LOCALE = 'en-GB'

export const translationMessages = {
  'en-GB': enTranslationMessages,
  'de-DE': deTranslationMessages,
  'it-IT': itTranslationMessages,
  'es-ES': esTranslationMessages,
  'fr-FR': frTranslationMessages,
  'nl-NL': nlTranslationMessages
}

const MomentLocaleMapping = {
  'en-GB': 'en-gb',
  'de-DE': 'de',
  'it-IT': 'it',
  'es-ES': 'es',
  'fr-FR': 'fr',
  'nl-NL': 'nl'
}

export const setMomentLocale = locale => {
  if (locale && MomentLocaleMapping[locale]) {
    moment.locale(MomentLocaleMapping[locale])
  } else {
    moment.locale(MomentLocaleMapping[DEFAULT_LOCALE])
  }
}
