export const messageKeys = {
  SelectProvider: {
    id: 'ProviderSelectionMenu:SELECT_PROVIDER',
    defaultMessage: 'ProviderSelectionMenu:SELECT_PROVIDER'
  },
  SearchPlaceholder: {
    id: 'ProviderSelectionMenu:SEARCH_PLACEHOLDER',
    defaultMessage: 'ProviderSelectionMenu:SEARCH_PLACEHOLDER'
  },
  NoProviders: {
    id: 'ProviderSelectionMenu:NO_PROVIDERS',
    defaultMessage: 'ProviderSelectionMenu:NO_PROVIDERS'
  },
  ContactUs: {
    id: 'Header:CONTACT_US',
    defaultMessage: 'Header:CONTACT_US'
  }
}
