import React from 'react'

import { FormattedMessage } from 'react-intl'

import FactCheckOutlinedIcon from '@mui/icons-material/FactCheckOutlined'
import InventoryOutlinedIcon from '@mui/icons-material/InventoryOutlined'
import AccountBalanceWalletOutlinedIcon from '@mui/icons-material/AccountBalanceWalletOutlined'
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined'
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined'
import PersonOutlinedIcon from '@mui/icons-material/PersonOutlined'
import AddCardOutlinedIcon from '@mui/icons-material/AddCardOutlined'
import EuroOutlinedIcon from '@mui/icons-material/EuroOutlined'
import ReceiptOutlinedIcon from '@mui/icons-material/ReceiptOutlined'
import IntegrationInstructionsOutlinedIcon from '@mui/icons-material/IntegrationInstructionsOutlined'
import SupportAgentOutlinedIcon from '@mui/icons-material/SupportAgentOutlined'
import PaymentOutlinedIcon from '@mui/icons-material/PaymentOutlined'
import AccountBalanceIcon from '@mui/icons-material/AccountBalance'
import EventBusyOutlinedIcon from '@mui/icons-material/EventBusyOutlined'
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined'

import { messageKeys as userDetailsKeys } from '../containers/UserProfile/messages'
import { messageKeys as integrationDetailsKeys } from '../containers/IntegrationBasics/messages'
import { messageKeys as invoicingDetailsKeys } from '../containers/BillingInformation/messages'
import { messageKeys as customerServiceDetailsKeys } from '../containers/CustomerService/messages'
import { messageKeys as customerPaymentKeys } from '../containers/CustomerPayments/messages'
import { messageKeys as cancellationPolicyKeys } from '../containers/CancellationPolicy/messages'
import { messageKeys as bookingDetailsKeys } from '../containers/BookingConfiguration/messages'
import { messageKeys as knowYourClientVerficationKeys } from '../containers/KnowYourClientVerification/messages'
import { messageKeys as PayoutsKeys } from '../containers/Payouts/messages'
import { messageKeys as InvoicesKeys } from '../containers/HoliduPaymentsInvoices/messages'
import { messageKeys as NonHopaInvoicesKeys } from '../containers/HoliduInvoices/messages'
import { messageKeys as propertyDetailsKeys } from '../containers/PropertyDetail/messages'
import { messageKeys as regulatoryReportingKeys } from '../containers/RegulatoryReporting/messages'
import { messageKeys as commercialAgreementKeys } from '../containers/CommercialAgreement/messages'

import livePropertiesKeys from '../containers/Properties/messages'
import bookingsKeys from '../containers/Bookings/messages'
import bookingDetailKeys from '../containers/BookingDetail/messages'

// Static Pages
import Page404 from '../containers/StaticPages/Page404'
import Page500 from '../containers/StaticPages/Page500'

// Dashboards components
import DashboardDefault from '../containers/Dashboard'

// Pages components
import UserProfile from '../containers/UserProfile'
import IntegrationDetails from '../containers/IntegrationBasics'
import InvoicingDetails from '../containers/BillingInformation'
import CustomerServiceDetails from '../containers/CustomerService'
import CustomerPayementsDetails from '../containers/CustomerPayments'
import RegulatoryReporting from '../containers/RegulatoryReporting'
import BookingDetails from '../containers/BookingConfiguration'
import Bookings from '../containers/Bookings'
import KnowYourClientVerification from '../containers/KnowYourClientVerification'
import Payouts from '../containers/Payouts'
import Invoices from '../containers/HoliduPaymentsInvoices'
import NonHopaInvoices from '../containers/HoliduInvoices'

import BookingDetail from '../containers/BookingDetail'
import {
  PATH_404,
  PATH_500,
  PATH_BILLING_INFORMATION,
  PATH_BOOKING_DETAILS,
  PATH_CUSTOMER_PAYMENT,
  PATH_CUSTOMER_SERVICE,
  PATH_DASHBOARD,
  PATH_INTEGRATION_DETAILS,
  PATH_PROFILE,
  PATH_LIVE_PROPERTIES,
  PATH_PROPERTY_DETAIL,
  PATH_BOOKINGS,
  PATH_KNOW_YOUR_CUSTOMER_VERIFICATION,
  PATH_PAYOUTS,
  PATH_INVOICES,
  PATH_BOOKING_DETAIL,
  PATH_CANCELLATION_POLICY,
  PATH_REGULATORY_REPORTING,
  PATH_COMMERCIAL_AGREEMENT,
  PATH_INVOICES_NON_HOPA
} from './PathConstants'
import sidebarKeys from './messages'
import Properties from '../containers/Properties'
import PropertyDetail from '../containers/PropertyDetail'
import CancellationPolicy from '../containers/CancellationPolicy'
import CommercialAgreement from '../containers/CommercialAgreement'

const dashboardsRoutes = {
  id: <FormattedMessage {...sidebarKeys.Dashboard} />,
  path: PATH_DASHBOARD,
  icon: <HomeOutlinedIcon />,
  component: DashboardDefault,
  title: <FormattedMessage {...sidebarKeys.Dashboard} />
}

const configurationRoutes = {
  id: <FormattedMessage {...sidebarKeys.Configuration} />,
  path: '/configuration',
  // icon: <SettingsOutlinedIcon />,
  children: [
    {
      path: PATH_INTEGRATION_DETAILS,
      name: <FormattedMessage {...integrationDetailsKeys.Title} />,
      component: IntegrationDetails,
      icon: <IntegrationInstructionsOutlinedIcon />,
      title: <FormattedMessage {...integrationDetailsKeys.Title} />
    },
    {
      path: PATH_CUSTOMER_SERVICE,
      name: <FormattedMessage {...customerServiceDetailsKeys.Title} />,
      component: CustomerServiceDetails,
      icon: <SupportAgentOutlinedIcon />,
      title: <FormattedMessage {...customerServiceDetailsKeys.Title} />
    },
    {
      path: PATH_CUSTOMER_PAYMENT,
      name: <FormattedMessage {...customerPaymentKeys.Title} />,
      component: CustomerPayementsDetails,
      icon: <PaymentOutlinedIcon />,
      title: <FormattedMessage {...customerPaymentKeys.Title} />
    },
    {
      path: PATH_CANCELLATION_POLICY,
      name: <FormattedMessage {...cancellationPolicyKeys.Title} />,
      component: CancellationPolicy,
      icon: <EventBusyOutlinedIcon />,
      title: <FormattedMessage {...cancellationPolicyKeys.Title} />
    },
    {
      path: PATH_BOOKING_DETAILS,
      name: <FormattedMessage {...bookingDetailsKeys.Title} />,
      component: BookingDetails,
      icon: <SettingsOutlinedIcon />,
      title: <FormattedMessage {...bookingDetailsKeys.Title} />
    }
  ]
}

// const bookingsRoutes = {
//   id: <FormattedMessage {...sidebarKeys.Bookings} />,
//   title: <FormattedMessage {...sidebarKeys.Bookings} />,
//   icon: <BookOnlineOutlinedIcon />,
//   children: [
//     {
//       path: PATH_BOOKINGS,
//       name: <FormattedMessage {...bookingsKeys.Title} />,
//       component: Bookings,
//       title: <FormattedMessage {...bookingsKeys.Title} />
//     }
//   ]
// }

const bookingsRoutes = {
  id: <FormattedMessage {...bookingsKeys.Title} />,
  path: PATH_BOOKINGS,
  icon: <FactCheckOutlinedIcon />,
  component: Bookings,
  title: <FormattedMessage {...bookingsKeys.Title} />
}

const financialsRoutes = {
  id: <FormattedMessage {...sidebarKeys.Financials} />,
  path: '/financials',
  // icon: <AccountBalanceWalletOutlinedIcon />,
  children: [
    {
      path: PATH_COMMERCIAL_AGREEMENT,
      name: (
        <FormattedMessage {...commercialAgreementKeys.CommercialAgreement} />
      ),
      icon: <DescriptionOutlinedIcon />,
      component: CommercialAgreement,
      title: (
        <FormattedMessage {...commercialAgreementKeys.CommercialAgreement} />
      )
    },
    {
      path: PATH_KNOW_YOUR_CUSTOMER_VERIFICATION,
      name: <FormattedMessage {...knowYourClientVerficationKeys.Title} />,
      icon: <AccountBalanceWalletOutlinedIcon />,
      component: KnowYourClientVerification,
      title: <FormattedMessage {...knowYourClientVerficationKeys.Title} />
    },
    {
      path: PATH_BILLING_INFORMATION,
      name: <FormattedMessage {...invoicingDetailsKeys.Title} />,
      component: InvoicingDetails,
      icon: <AddCardOutlinedIcon />,
      title: <FormattedMessage {...invoicingDetailsKeys.Title} />
    },
    {
      path: PATH_REGULATORY_REPORTING,
      name: <FormattedMessage {...regulatoryReportingKeys.Title} />,
      component: RegulatoryReporting,
      icon: <AccountBalanceIcon />,
      title: <FormattedMessage {...regulatoryReportingKeys.Title} />
    },
    {
      path: PATH_PAYOUTS,
      name: <FormattedMessage {...PayoutsKeys.Title} />,
      component: Payouts,
      icon: <EuroOutlinedIcon />,
      title: <FormattedMessage {...PayoutsKeys.Title} />
    },
    {
      path: PATH_INVOICES,
      name: <FormattedMessage {...InvoicesKeys.TITLE} />,
      component: Invoices,
      icon: <ReceiptOutlinedIcon />,
      title: <FormattedMessage {...InvoicesKeys.TITLE} />
    },
    {
      path: PATH_INVOICES_NON_HOPA,
      name: <FormattedMessage {...NonHopaInvoicesKeys.TITLE} />,
      component: NonHopaInvoices,
      icon: <ReceiptOutlinedIcon />,
      title: <FormattedMessage {...NonHopaInvoicesKeys.TITLE} />
    }
  ]
}

const bookingDetailRoute = {
  path: PATH_BOOKING_DETAIL,
  name: <FormattedMessage {...bookingDetailKeys.Title} />,
  component: BookingDetail,
  title: <FormattedMessage {...bookingDetailKeys.Title} />
}

const inventoryRoutes = {
  id: <FormattedMessage {...livePropertiesKeys.Title} />,
  path: PATH_LIVE_PROPERTIES,
  icon: <InventoryOutlinedIcon />,
  component: Properties,
  title: <FormattedMessage {...livePropertiesKeys.Title} />
}

const propertyDetailRoute = {
  path: PATH_PROPERTY_DETAIL,
  name: <FormattedMessage {...propertyDetailsKeys.TITLE} />,
  component: PropertyDetail,
  title: <FormattedMessage {...propertyDetailsKeys.TITLE} />
}

const userRoutes = {
  id: <FormattedMessage {...userDetailsKeys.Title} />,
  path: PATH_PROFILE,
  icon: <PersonOutlinedIcon />,
  component: UserProfile,
  title: <FormattedMessage {...userDetailsKeys.Title} />
}

const staticPageRoutes = {
  id: 'Static Pages',
  path: '/auth',
  icon: <PersonOutlinedIcon />,
  children: [
    {
      path: PATH_404,
      name: '404 Page',
      component: Page404
    },
    {
      path: PATH_500,
      name: '500 Page',
      component: Page500
    }
  ]
}

export const routesWithDashboardLayout = [
  dashboardsRoutes,
  bookingsRoutes,
  configurationRoutes,
  bookingDetailRoute,
  financialsRoutes,
  userRoutes,
  inventoryRoutes,
  propertyDetailRoute
]

export const routesWithStaticPageLayout = [staticPageRoutes]

export const APP_ROUTES = [
  dashboardsRoutes,
  inventoryRoutes,
  bookingsRoutes,
  financialsRoutes,
  configurationRoutes
]
export default APP_ROUTES
