import { EnvUtil } from '@holidu/pui-utils'

const getBackend = () => {
  const envUtils = EnvUtil.getInstance(
    process.env.REACT_APP_TARGET_ENV,
    process.env.REACT_APP_BACKEND
  )
  return envUtils.getCurrentBackend()
}

const env = {
  TARGET_ENV: process.env.REACT_APP_TARGET_ENV,
  BACKEND: getBackend(),
  AWS_COGNITO_POOL_ID: process.env.REACT_APP_AWS_COGNITO_POOL_ID,
  AWS_COGNITO_CLIENT_ID: process.env.REACT_APP_AWS_COGNITO_CLIENT_ID,
  APM_SERVER: process.env.REACT_APP_APM_SERVER,
  APM_VERSION: process.env.REACT_APP_APM_VERSION,
  AMPLITUDE_API_KEY: process.env.REACT_APP_AMPLITUDE_API_KEY,
  AMPLITUDE_USER_TYPE: process.env.REACT_APP_AMPLITUDE_USER_TYPE
}

export default env
