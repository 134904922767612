import React from 'react'
import { MenuItem, TextField, TextFieldProps } from '@mui/material'

const TRUST_LEVEL_TIERS = ['TIER1', 'TIER2', 'TIER3']

const TrustLevelTierSelect = (props: TextFieldProps): JSX.Element => {
  const { value, ...restProps } = props
  return (
    <TextField select value={value || ''} {...restProps}>
      <MenuItem disabled value="">
        Please select
      </MenuItem>
      {TRUST_LEVEL_TIERS.map(trustLevelTier => {
        return (
          <MenuItem key={trustLevelTier} value={trustLevelTier}>
            {trustLevelTier}
          </MenuItem>
        )
      })}
    </TextField>
  )
}

export default TrustLevelTierSelect
