import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Box, Pagination, Typography } from '@mui/material'
import { getSelectedProvider } from '../../store/providerSelection/providerSelector'
import ErrorPageContent from '../../components/ErrorPage/ErrorPageContent'
import Loader from '../../components/Loader'
import InvoicesContent from './InvoicesContent'
import EmptyInvoices from './EmptyInvoices'
import { Page, PageContent, PageHeader } from '@holidu/pui-components/dist'
import { FormattedMessage } from 'react-intl'
import { messageKeys } from './messages'
import useGetInvoiceAggregations from './useGetInvoiceAggregations'

const InvoicesContainer = () => {
  const { selectedProvider } = useSelector(getSelectedProvider)

  const [currentPage, setCurrentPage] = useState(0)

  const { data, status, error, isLoading, isFetching } =
    useGetInvoiceAggregations(selectedProvider, currentPage)

  return (
    <Page>
      <PageHeader
        sx={{
          borderBottom: 'none',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}
      >
        <Typography variant="h6">
          <FormattedMessage {...messageKeys.TITLE} />
        </Typography>
        {data && data.invoiceAggregations?.length ? (
          <Pagination
            count={Math.ceil(data.totalCount / data.size)}
            onChange={(_, page) => setCurrentPage(page - 1)}
          />
        ) : null}
      </PageHeader>
      <PageContent px={3} pb={8}>
        {(isLoading || isFetching) && <Loader />}
        <Box>
          {status === 'error' ? (
            <ErrorPageContent status={error.message} />
          ) : (
            <>
              {data && data.invoiceAggregations?.length ? (
                <InvoicesContent data={data.invoiceAggregations} />
              ) : (
                !(isLoading || isFetching) && <EmptyInvoices />
              )}
            </>
          )}
        </Box>
      </PageContent>
    </Page>
  )
}

export default InvoicesContainer
