import env from '../../env'
import { currentUserIdToken } from '../../utils/awsCognitoClient'
import sanitizeData from '../../utils/sanitizeData'

export const updateUserRequest = payload => ({
  url: `${env.BACKEND}/user`,
  body: sanitizeData(payload),
  transform: response => ({
    user: response
  }),
  update: {
    user: (prev, next) => {
      return next
    }
  },
  options: {
    method: 'PUT',
    headers: {
      Authorization: 'Bearer ' + currentUserIdToken()
    }
  }
})

export const getUserRequest = () => {
  return {
    url: `${env.BACKEND}/user`,
    transform: response => ({
      user: response
    }),
    update: {
      user: (prev, next) => {
        return next
      }
    },
    options: {
      headers: {
        Authorization: 'Bearer ' + currentUserIdToken()
      }
    }
  }
}
