import React from 'react'
import { Checkbox, Box, Stack, Grid, FormLabel } from '@mui/material'
import { useIntl } from 'react-intl'
import { messageKeys } from '../messages'
import { Section, Tray } from '@holidu/pui-components/dist'

const OPTIONS = [
  {
    value: 'CREDIT_CARD',
    label: 'CreditCard'
  },
  {
    value: 'PAYPAL',
    label: 'Paypal'
  },
  {
    value: 'CHEQUE_VACANCES',
    label: 'ChequeVacances'
  },
  {
    value: 'BANK_TRANSFER',
    label: 'BankTransfer'
  },
  {
    value: 'INVOICE',
    label: 'Invoice'
  },
  {
    value: 'IDEAL',
    label: 'Ideal'
  }
]

type PaymentMethodsProps = {
  paymentMethods?: string[]
  paymentMethodConfigSource?: string
  onChange: (newPaymentMethods: string[]) => void
}

const PaymentMethods = (props: PaymentMethodsProps): JSX.Element => {
  const {
    paymentMethods = [],
    paymentMethodConfigSource = '',
    onChange
  } = props
  const intl = useIntl()
  const disablePaymentMethods = Boolean(
    paymentMethodConfigSource && paymentMethodConfigSource !== 'subProvider'
  )

  const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
    const value = e.currentTarget.dataset.value
    if (!disablePaymentMethods && value !== undefined) {
      let newPaymentMethods = [...paymentMethods]
      const checked = newPaymentMethods.includes(value)
      if (checked) {
        newPaymentMethods = newPaymentMethods.filter(p => p !== value)
      } else {
        newPaymentMethods.push(value)
      }
      onChange(newPaymentMethods)
    }
  }

  return (
    <Section
      title={intl.formatMessage(messageKeys.PaymentOptionsHeader)}
      secondaryTitle={
        paymentMethodConfigSource === 'default'
          ? intl.formatMessage(messageKeys.PaymentOptionsCmSubHeader)
          : paymentMethodConfigSource === 'request'
          ? intl.formatMessage(messageKeys.PaymentOptionsRequestSubHeader)
          : intl.formatMessage(messageKeys.PaymentOptionsSubHeader)
      }
    >
      <Grid container spacing={2}>
        {OPTIONS.map(option => {
          const checked = paymentMethods.includes(option.value)
          const cursor = !disablePaymentMethods ? 'pointer' : 'default'
          return (
            <Grid item xs={12} lg={4} key={option.value}>
              <Box
                data-value={option.value}
                onClick={handleClick}
                sx={{
                  cursor
                }}
              >
                <Tray color={checked ? 'primary' : 'default'} type="outlined">
                  <Stack direction="row" alignItems="center">
                    <Checkbox
                      checked={checked}
                      disabled={disablePaymentMethods}
                      name={option.value}
                    />
                    <FormLabel
                      focused={checked}
                      disabled={disablePaymentMethods}
                      sx={{
                        cursor
                      }}
                    >
                      {intl.formatMessage(
                        messageKeys[option.label as keyof typeof messageKeys]
                      )}
                    </FormLabel>
                  </Stack>
                </Tray>
              </Box>
            </Grid>
          )
        })}
      </Grid>
    </Section>
  )
}

export default PaymentMethods
