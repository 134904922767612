export const PATH_LOGIN = '/auth/login'
export const PATH_SIGNUP = '/auth/signup'
export const PATH_CONFIRM = '/auth/confirm'
export const PATH_RESET_PASSWORD = '/auth/reset-password'
export const PATH_CHANGE_TEMP_PASSWORD = '/auth/change-temporary-password/'
export const PATH_NEW_PASSWORD = '/auth/new-password/'
export const PATH_CODE_EXPIRED = '/codeExpired'
export const PATH_CODE_INVALID = '/codeInvalid'

export const PATH_DASHBOARD = '/dashboard'
export const PATH_FINALIZE_REGISTRATION = '/finalizeRegistration'
export const PATH_FINALIZE_REGISTRATION_THANK_YOU = '/thank-you'
export const PATH_CONFIRM_SIGNUP = '/confirmSignup'
export const PATH_PROFILE = '/profile'
export const PATH_BILLING_INFORMATION = '/invoices/invoicingDetails'
export const PATH_BOOKING_DETAILS = '/configuration/bookingDetails'
export const PATH_CUSTOMER_PAYMENT = '/configuration/customerPayment'
export const PATH_REGULATORY_REPORTING = '/configuration/regulatoryReporting'
export const PATH_CANCELLATION_POLICY = '/configuration/cancellationPolicy'
export const PATH_CUSTOMER_SERVICE = '/configuration/customerSerivce'
export const PATH_INTEGRATION_DETAILS = '/configuration/integrationDetails'
export const PATH_COMMERCIAL_AGREEMENT = '/configuration/commercialAgreement'
export const PATH_LIVE_PROPERTIES = '/inventory/liveProperties'
export const PATH_PROPERTY_DETAIL = '/inventory/property'
export const PATH_BOOKINGS = '/inventory/bookings'
export const PATH_BOOKING_DETAIL = '/inventory/booking'
export const PATH_KNOW_YOUR_CUSTOMER_VERIFICATION =
  '/financials/kycVerification'
export const PATH_PAYOUTS = '/financials/payouts'
export const PATH_INVOICES = '/financials/invoices'
export const PATH_INVOICES_NON_HOPA = '/financials/non-hopa-invoices'

export const PATH_404 = '/static-page/404'
export const PATH_500 = '/static-page/500'

export const PATH_VERSION = '/version'
