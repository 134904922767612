import React from 'react'
import { Stack, Typography, Grid, Chip } from '@mui/material'
import { useIntl } from 'react-intl'
import messageKeys from './messages'
import DownloadInvoice from './DownloadInvoice'
import { BookingInfo, Invoice } from './types'

type BookingDetailHeaderProps = {
  bookingInfo?: BookingInfo
  invoices?: Array<Invoice>
}

const BookingDetailHeader = (props: BookingDetailHeaderProps): JSX.Element => {
  const { bookingInfo, invoices } = props
  const intl = useIntl()
  if (!bookingInfo) {
    return <></>
  }
  return (
    <Grid container justifyContent="space-between" alignItems="center">
      <Grid item>
        <Stack direction="row" spacing={1} alignItems="center">
          {bookingInfo?.id && (
            <Typography variant="h6">{`${intl.formatMessage(
              messageKeys.Booking
            )} ${bookingInfo.id}`}</Typography>
          )}
          {bookingInfo?.holiduExpressBooking ? (
            <Chip
              size="small"
              label={intl.formatMessage(messageKeys.HOLIDU_EXPRESS)}
              color="primary"
            />
          ) : (
            <Chip
              size="small"
              label={intl.formatMessage(messageKeys.REDIRECT)}
              color="secondary"
            />
          )}
          {bookingInfo?.holiduPayment && (
            <Chip
              size="small"
              label={intl.formatMessage(messageKeys.HOLIDU_PAYMENT)}
              color="primary"
            />
          )}
        </Stack>
      </Grid>
      <Grid item>
        <Stack direction="row" spacing={1} alignItems="center">
          {invoices?.length ? <DownloadInvoice invoices={invoices} /> : null}
        </Stack>
      </Grid>
    </Grid>
  )
}

export default BookingDetailHeader
