import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import queryString from 'query-string'
import { useRequest } from 'redux-query-immutable-react'
import { Box, Divider, Typography } from '@mui/material'
import { getSelectedProvider } from '../../store/providerSelection/providerSelector'
import { fetchPayouts } from '../../store/payouts/payoutsRequests'
import { getPayoutsSelector } from '../../store/payouts/payoutsSelectors'
import ErrorPageContent from '../../components/ErrorPage/ErrorPageContent'
import EmptyBookings from '../Bookings/EmptyBookings'
import PayoutsFilter from './PayoutsFilter'
import PayoutsContent from './PayoutsContent'
import PayoutsTabs from './PayoutsTabs'
import PayoutsInfo from './PayoutsInfo'
import { PAID, SCHEDULED } from './constants'
import { Page, PageContent, PageHeader } from '@holidu/pui-components/dist'
import { FormattedMessage } from 'react-intl'
import { messageKeys } from './messages'
import Analytics from '../../utils/analytics'
import { PAYOUTS_FILTER } from '../../utils/analytics/events'

const getFiltersFromQueryString = search => {
  const locationSearch = queryString.parse(search)
  const filterQuery = {
    bookingId: locationSearch.bookingId,
    untilDate: locationSearch.untilDate
  }

  if (locationSearch.tab === 'PAID') {
    filterQuery.fromDate = locationSearch.fromDate
  }
  return {
    selectedTab: locationSearch.tab || SCHEDULED,
    filterQuery
  }
}

const PayoutsContainer = props => {
  const { selectedProvider } = useSelector(getSelectedProvider)
  const [selectedTab, setSelectedTab] = useState(SCHEDULED)
  const [filterQuery, setFilterQuery] = useState({})
  const [{ isFinished, isPending, status }] = useRequest(
    fetchPayouts(selectedProvider)
  )
  const { payouts } = useSelector(getPayoutsSelector)

  const nextFilterNameRef = useRef(null)

  useEffect(() => {
    const { filterQuery, selectedTab } = getFiltersFromQueryString(
      props.location.search
    )
    setFilterQuery(filterQuery)
    setSelectedTab(selectedTab)
    const query = queryString.stringify(filterQuery, {
      skipNull: true,
      skipEmptyString: true
    })
    props.history.push(
      `/financials/payouts?tab=${selectedTab}${query ? '&' + query : ''}`
    )
  }, []) //eslint-disable-line

  useEffect(() => {
    const nextFilterName = nextFilterNameRef.current
    if (nextFilterName) {
      Analytics.trackEvent(PAYOUTS_FILTER, nextFilterName)
    }
    const query = queryString.stringify(filterQuery, {
      skipNull: true,
      skipEmptyString: true
    })
    props.history.push(
      `/financials/payouts?tab=${selectedTab}${query ? '&' + query : ''}`
    )
  }, [selectedTab, filterQuery]) //eslint-disable-line

  const handleFilterChange = (name, value) => {
    nextFilterNameRef.current = name
    setFilterQuery({
      ...filterQuery,
      [name]: value
    })
  }

  const handleClearAllFilters = () => {
    setFilterQuery({})
  }

  const handleTabChange = tab => {
    setFilterQuery({})
    setSelectedTab(tab)
  }

  if (!payouts) {
    return null
  }

  return (
    <Page>
      <PageHeader progress={isPending}>
        <Box width="100%">
          <Typography variant="h6">
            <FormattedMessage {...messageKeys.Title} />
          </Typography>
          <PayoutsTabs
            selectedTab={selectedTab}
            onTabChange={handleTabChange}
          />
          <Divider />
          <Box display="flex" alignItems="center" flexWrap="wrap" gap={4}>
            <Box flexGrow="1">
              <PayoutsFilter
                allowFromDate={selectedTab === PAID}
                filterQuery={filterQuery}
                onClearAllFilters={handleClearAllFilters}
                onFilterChange={handleFilterChange}
              />
            </Box>
            <PayoutsInfo data={payouts.get('payouts')} />
          </Box>
        </Box>
      </PageHeader>
      <PageContent px={3} pb={8}>
        <Box>
          {typeof status === 'number' && status !== 200 && isFinished ? (
            <ErrorPageContent status={status} />
          ) : (
            <>
              {payouts.size ? (
                <PayoutsContent
                  selectedTab={selectedTab}
                  data={payouts.get('payouts')}
                  errors={payouts.get('errors')}
                />
              ) : (
                <EmptyBookings />
              )}
            </>
          )}
        </Box>
      </PageContent>
    </Page>
  )
}

export default PayoutsContainer
