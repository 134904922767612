import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Grid, MenuItem } from '@mui/material'
import { messageKeys as userDetailsKeys } from '../../UserProfile/messages'
import { messageKeys as globalMessageKeys } from '../../../globalMessages'

import ValidationPhoneField from '../../../components/Fields/ValidationPhoneField'
import { SUPPORTED_LANGUAGES } from '../../../../supportedLanguages'
import ValidationTextField from '../../../components/Fields/ValidationTextField'

const StepUserDetails = props => {
  const { dispatch, errors, formData } = props

  const handleChange = (e, errorReason) => {
    dispatch({
      type: 'field',
      field: e.target.name,
      value: e.target.value
    })
    dispatch({
      type: 'error',
      field: e.target.name,
      value: errorReason
    })
  }

  return (
    <Grid container spacing={4}>
      <Grid xs={12} md={6} item>
        {/* Firstname Field */}
        <ValidationTextField
          error={errors.firstName}
          name="firstName"
          label={<FormattedMessage {...userDetailsKeys.Name} />}
          value={formData.firstName || ''}
          variant="outlined"
          fullWidth
          required
          onChange={handleChange}
        />
      </Grid>
      <Grid xs={12} md={6} item>
        {/* Lastname Field */}
        <ValidationTextField
          error={errors.lastName}
          name="lastName"
          label={<FormattedMessage {...userDetailsKeys.Surname} />}
          value={formData.lastName || ''}
          variant="outlined"
          fullWidth
          required
          onChange={handleChange}
        />
      </Grid>
      {/* Phone Number Field */}
      <Grid xs={12} item>
        <ValidationPhoneField
          error={errors.phoneNumber}
          name="phoneNumber"
          label={<FormattedMessage {...userDetailsKeys.Phone} />}
          value={formData.phoneNumber || ''}
          fullWidth
          required
          onChange={handleChange}
        />
      </Grid>
      {/* Preferred Language Field */}
      <Grid xs={12} item>
        <ValidationTextField
          error={errors.contactLanguage}
          name="contactLanguage"
          label={<FormattedMessage {...userDetailsKeys.ContactLanguage} />}
          value={formData.contactLanguage || ''}
          variant="outlined"
          fullWidth
          required
          select
          onChange={handleChange}
        >
          <MenuItem value="" disabled>
            <FormattedMessage {...globalMessageKeys.Select} />
          </MenuItem>
          {SUPPORTED_LANGUAGES.map(lang => {
            return (
              <MenuItem value={lang.flagCountryCode} key={lang.value}>
                {lang.label}
              </MenuItem>
            )
          })}
        </ValidationTextField>
      </Grid>
      <Grid xs={12} item>
        <ValidationTextField
          error={errors.roleInCompany}
          name="roleInCompany"
          label={<FormattedMessage {...userDetailsKeys.RoleInCompany} />}
          value={formData.roleInCompany || ''}
          variant="outlined"
          fullWidth
          required
          select
          onChange={handleChange}
        >
          <MenuItem value="" disabled>
            <FormattedMessage {...globalMessageKeys.Select} />
          </MenuItem>
          <MenuItem value="DEV">
            <FormattedMessage {...globalMessageKeys.Developer} />
          </MenuItem>
          <MenuItem value="BD">
            <FormattedMessage {...globalMessageKeys.BizDevOrTam} />
          </MenuItem>
          <MenuItem value="PM">
            <FormattedMessage {...globalMessageKeys.PM} />
          </MenuItem>
          <MenuItem value="OWNER">
            <FormattedMessage {...globalMessageKeys.OWNER} />
          </MenuItem>
        </ValidationTextField>
      </Grid>
    </Grid>
  )
}

export default StepUserDetails
