import axios from 'axios'
import { useQuery } from '@tanstack/react-query'
import { PropertyCancellationPolicies } from './types'
import QueryKeys from '../../utils/queryKeys'

const useGetPropertyCancellationPolicy = (provider: string, apartmentId: string) => {
  return useQuery<any, Error, PropertyCancellationPolicies>(
    [QueryKeys.PropertyCancellationPolicy, { query: { apartmentId } }],
    async () => {
      const { data } = await axios.get(
        `/apartments/cancellation-policy/${apartmentId}?provider=${provider}`
      )
      return data
    },
    { keepPreviousData: false, retry: false }
  )
}

export default useGetPropertyCancellationPolicy
