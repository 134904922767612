import React, { useEffect } from 'react'
import { Tab, Tabs } from '@mui/material'
import { STATUS_TABS } from './constants'
import { FormattedMessage } from 'react-intl'
import { messageKeys } from './messages'

const PayoutsTabs = props => {
  const { onTabChange, selectedTab } = props
  const [tabValue, setTabValue] = React.useState(0)
  useEffect(() => {
    let index = STATUS_TABS.indexOf(selectedTab)
    if (index < 0) {
      index = 0
    }
    setTabValue(index)
  }, [selectedTab])

  const handleChange = (event, newValue) => {
    setTabValue(newValue)
    onTabChange(STATUS_TABS[newValue])
  }

  return (
    <Tabs sx={{ mt: 0.5 }} value={tabValue} onChange={handleChange}>
      {STATUS_TABS.map(t => {
        return (
          <Tab
            key={t}
            label={<FormattedMessage {...messageKeys[`PAYOUT_TAB_${t}`]} />}
          />
        )
      })}
    </Tabs>
  )
}

export default PayoutsTabs
