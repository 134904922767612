export const messageKeys = {
  CommercialAgreement: {
    id: 'CommercialAgreementPage:COMMERCIAL_AGREEMENT',
    defaultMessage: 'CommercialAgreementPage:COMMERCIAL_AGREEMENT'
  },
  CommercialAgreementTCTitle: {
    id: 'CommercialAgreementPage:COMMERCIAL_AGREEMENT_TITLE',
    defaultMessage: 'CommercialAgreementPage:COMMERCIAL_AGREEMENT_TITLE'
  },
  CommercialAgreementTCDescription: {
    id: 'CommercialAgreementPage:COMMERCIAL_AGREEMENT_DESC',
    defaultMessage: 'CommercialAgreementPage:COMMERCIAL_AGREEMENT_DESC'
  },
  CommercialAgreementTCCheckbox: {
    id: 'CommercialAgreementPage:COMMERCIAL_AGREEMENT_CHECKBOX',
    defaultMessage: 'CommercialAgreementPage:COMMERCIAL_AGREEMENT_CHECKBOX'
  },
  HolidusTC: {
    id: 'CommercialAgreementPage:HOLIDUS_T_C',
    defaultMessage: 'CommercialAgreementPage:HOLIDUS_T_C'
  },
  SepaTitle: {
    id: 'CommercialAgreementPage:SEPA_TITLE',
    defaultMessage: 'CommercialAgreementPage:SEPA_TITLE'
  },
  BankName: {
    id: 'CommercialAgreementPage:BANK_NAME',
    defaultMessage: 'CommercialAgreementPage:BANK_NAME'
  },
  Location: {
    id: 'CommercialAgreementPage:LOCATION',
    defaultMessage: 'CommercialAgreementPage:LOCATION'
  },
  Date: {
    id: 'CommercialAgreementPage:DATE',
    defaultMessage: 'CommercialAgreementPage:DATE'
  },
  SepaConsentHint: {
    id: 'CommercialAgreementPage:SEPA_CONSENT_HINT',
    defaultMessage: 'CommercialAgreementPage:SEPA_CONSENT_HINT'
  },
  Iban: {
    id: 'CommercialAgreementPage:IBAN',
    defaultMessage: 'CommercialAgreementPage:IBAN'
  },
  Bic: {
    id: 'CommercialAgreementPage:BIC',
    defaultMessage: 'CommercialAgreementPage:BIC'
  },
  InvalidBic: {
    id: 'CommercialAgreementPage:SEPA_INVALID_BIC_DESC',
    defaultMessage: 'CommercialAgreementPage:SEPA_INVALID_BIC_DESC'
  },
  HoliduCIF: {
    id: 'CommercialAgreementPage:HOLIDU_CIF',
    defaultMessage: 'CommercialAgreementPage:HOLIDU_CIF'
  },
  HoliduNameAddress: {
    id: 'CommercialAgreementPage:HOLIDU_NAME_ADDRESS',
    defaultMessage: 'CommercialAgreementPage:HOLIDU_NAME_ADDRESS'
  },
  SepaDesc: {
    id: 'CommercialAgreementPage:SEPA_DESC',
    defaultMessage: 'CommercialAgreementPage:SEPA_DESC'
  },
  SepaLegalDesc: {
    id: 'CommercialAgreementPage:SEPA_LEGAL_DESC',
    defaultMessage: 'CommercialAgreementPage:SEPA_LEGAL_DESC'
  }
}
