import React from 'react'
import { Grid, MenuItem } from '@mui/material'
import { FormattedMessage } from 'react-intl'
import countryList from 'react-select-country-list'

import { messageKeys as userDetailsKeys } from '../../UserProfile/messages'
import { messageKeys as globalMessageKeys } from '../../../globalMessages'
import { messageKeys } from '../messages'
import ValidationTextField from '../../../components/Fields/ValidationTextField'

const StepIntegrationDetailsSigned = props => {
  const { dispatch, errors, formData } = props

  const handleChange = (e, errorReason) => {
    dispatch({
      type: 'field',
      field: e.target.name,
      value: e.target.value
    })
    dispatch({
      type: 'error',
      field: e.target.name,
      value: errorReason
    })
  }

  return (
    <Grid item xs={12} spacing={4} container>
      <Grid xs={12} item>
        <ValidationTextField
          error={errors.integrationChannel}
          name="integrationChannel"
          label={<FormattedMessage {...messageKeys.ChannelManagerQuestion} />}
          value={formData.integrationChannel || ''}
          variant="outlined"
          fullWidth
          required
          onChange={handleChange}
        />
      </Grid>

      <Grid xs={12} item>
        {/* Provider name */}
        <ValidationTextField
          name="providerName"
          error={errors.providerName}
          label={<FormattedMessage {...userDetailsKeys.ProviderName} />}
          value={formData.providerName || ''}
          variant="outlined"
          fullWidth
          required
          onChange={handleChange}
        />
      </Grid>
      {/* Company Location */}
      <Grid xs={12} item>
        <ValidationTextField
          name="country"
          error={errors.country}
          label={<FormattedMessage {...messageKeys.CompanyLocationQuestion} />}
          value={formData.country || ''}
          variant="outlined"
          fullWidth
          required
          select
          onChange={handleChange}
        >
          <MenuItem value="" disabled>
            <FormattedMessage {...globalMessageKeys.Select} />
          </MenuItem>
          {countryList()
            .getData()
            .map((country, index) => (
              <MenuItem key={index} value={country.value}>
                {country.label}
              </MenuItem>
            ))}
        </ValidationTextField>
      </Grid>
    </Grid>
  )
}

export default StepIntegrationDetailsSigned
