//HEADER
export const HEADER_CLICK_CONTACT = 'HEADER_CLICK_CONTACT'

// Finalize registration
export const FINALIZE_REGISTRATION_SAVE = 'FINALIZE_REGISTRATION_SAVE'

// Dashboard
export const DASHBOARD_CLICK_STAT_ACTIVE = 'DASHBOARD_CLICK_STAT_ACTIVE'
export const DASHBOARD_CLICK_STAT_INACTIVE = 'DASHBOARD_CLICK_STAT_INACTIVE'
export const DASHBOARD_CLICK_STAT_BAD_PHOTO = 'DASHBOARD_CLICK_STAT_BAD_PHOTO'
export const DASHBOARD_CLICK_STAT_LOW_BOOKABILITY =
  'DASHBOARD_CLICK_STAT_LOW_BOOKABILITY'
export const DASHBOARD_CLICK_STAT_LOW_PHOTO = 'DASHBOARD_CLICK_STAT_LOW_PHOTO'
export const DASHBOARD_CLICK_CONTACT_ME = 'DASHBOARD_CLICK_CONTACT_ME'
export const DASHBOARD_CLICK_HOLIDU_SCORE_LEARN_MORE =
  'DASHBOARD_CLICK_HOLIDU_SCORE_LEARN_MORE'
export const DASHBOARD_CLICK_MISSING_FIELD = 'DASHBOARD_CLICK_MISSING_FIELD'

// Apartment List
export const APARTMENTS_FILTER = 'APARTMENTS_FILTER'
export const APARTMENTS_SORT = 'APARTMENTS_SORT'
export const APARTMENTS_CLICK_APARTMENT_ID = 'APARTMENTS_CLICK_APARTMENT_ID'
export const APARTMENTS_CLICK = 'APARTMENTS_CLICK'
export const APARTMENTS_DOWNLOAD_CSV = 'APARTMENTS_DOWNLOAD_CSV'
export const APARTMENTS_PAGE_CHANGE = 'APARTMENTS_PAGE_CHANGE'
export const APARTMENTS_PAGE_SIZE_CHANGE = 'APARTMENTS_PAGE_SIZE_CHANGE'
export const APARTMENTS_HIDE_FILTERS = 'APARTMENTS_HIDE_FILTERS'
export const APARTMENTS_SHOW_FILTERS = 'APARTMENTS_SHOW_FILTERS'
export const APARTMENTS_SHOW_INACTIVE = 'APARTMENTS_SHOW_INACTIVE'

// Apartment Detail
export const APARTMENT_DETAIL_CLICK_FEEDBACK = 'APARTMENT_DETAIL_CLICK_FEEDBACK'
export const APARTMENT_DETAIL_CLICK_DELETE = 'APARTMENT_DETAIL_CLICK_DELETE'
export const APARTMENT_DETAIL_CLICK_DELETE_YES =
  'APARTMENT_DETAIL_CLICK_DELETE_YES'
export const APARTMENT_DETAIL_CLICK_DELETE_NO =
  'APARTMENT_DETAIL_CLICK_DELETE_NO'
export const APARTMENT_DETAIL_CLICK_SHOW_ON_HOLIDU =
  'APARTMENT_DETAIL_CLICK_SHOW_ON_HOLIDU'
export const APARTMENT_DETAIL_CLICK_SHOW_ON_MAP =
  'APARTMENT_DETAIL_CLICK_SHOW_ON_MAP'
export const APARTMENT_DETAIL_CLICK_HOLIDU_SCORE_LEARN_MORE =
  'APARTMENT_DETAIL_CLICK_HOLIDU_SCORE_LEARN_MORE'
export const APARTMENT_DETAIL_CLICK_DATA_SCORE_LEARN_MORE =
  'APARTMENT_DETAIL_CLICK_DATA_SCORE_LEARN_MORE'
export const APARTMENT_DETAIL_CLICK_BOOKABILITY_SCORE_LEARN_MORE =
  'APARTMENT_DETAIL_CLICK_BOOKABILITY_SCORE_LEARN_MORE'
export const APARTMENT_DETAIL_CLICK_PHOTO_SCORE_LEARN_MORE =
  'APARTMENT_DETAIL_CLICK_PHOTO_SCORE_LEARN_MORE'
export const APARTMENT_DETAIL_HOVER_OPPORTUNITIES_TOOLTIP =
  'APARTMENT_DETAIL_HOVER_OPPORTUNITIES_TOOLTIP'

// Booking List
export const BOOKINGS_FILTER = 'BOOKINGS_FILTER'
export const BOOKINGS_SORT = 'BOOKINGS_SORT'
export const BOOKINGS_CLICK_BOOKING_ID = 'BOOKINGS_CLICK_BOOKING_ID'
export const BOOKINGS_CLICK = 'BOOKINGS_CLICK'
export const BOOKINGS_PAGE_CHANGE = 'BOOKINGS_PAGE_CHANGE'
export const BOOKINGS_PAGE_SIZE_CHANGE = 'BOOKINGS_PAGE_SIZE_CHANGE'
export const BOOKINGS_HIDE_FILTERS = 'BOOKINGS_HIDE_FILTERS'
export const BOOKINGS_SHOW_FILTERS = 'BOOKINGS_SHOW_FILTERS'
export const BOOKINGS_DOWNLOAD_CSV = 'BOOKINGS_DOWNLOAD_CSV'

// Payouts
export const PAYOUTS_FILTER = 'PAYOUTS_FILTER'

// KYC
export const KYC_VIDEO_CLICKED = 'KYC_VIDEO_CLICKED'
export const KYC_VERIFICATION_CLICK = 'KYC_VERIFICATION_CLICK'
export const KYC_VERIFICATION_FAILURE = 'KYC_VERIFICATION_FAILURE'
export const KYC_VERIFICATION_SUCCESS = 'KYC_VERIFICATION_SUCCESS'

// Booking Detail
export const BOOKING_DETAIL_CLICK_APARTMENT_ID =
  'BOOKING_DETAIL_CLICK_APARTMENT_ID'
export const BOOKING_DETAIL_CLICK_CANCEL = 'BOOKING_DETAIL_CLICK_CANCEL'
export const BOOKING_DETAIL_CLICK_CANCEL_SUBMIT =
  'BOOKING_DETAIL_CLICK_CANCEL_SUBMIT'
export const BOOKING_DETAIL_CLICK_CANCEL_CONFIRM_YES =
  'BOOKING_DETAIL_CLICK_CANCEL_CONFIRM_YES'
export const BOOKING_DETAIL_CLICK_CANCEL_CONFIRM_NO =
  'BOOKING_DETAIL_CLICK_CANCEL_CONFIRM_NO'

// Cancellation Policy
export const CANCELLATION_POLICY_SAVE = 'CANCELLATION_POLICY_SAVE'
export const CANCELLATION_POLICY_CLICK_CUSTOM_CONTACT =
  'CANCELLATION_POLICY_CLICK_CUSTOM_CONTACT'

// Payment Options
export const PAYMENT_OPTIONS_PAYMENT_RATE_REMOVE =
  'PAYMENT_OPTIONS_PAYMENT_RATE_REMOVE'
export const PAYMENT_OPTIONS_PAYMENT_RATE_ADD =
  'PAYMENT_OPTIONS_PAYMENT_RATE_ADD'
export const PAYMENT_OPTIONS_SAVE = 'PAYMENT_OPTIONS_SAVE'

// Billing Information
export const BILLING_INFORMATION_SAVE = 'BILLING_INFORMATION_SAVE'

// Regulatory reporting
export const REGULATORY_REPORTING_SAVE = 'REGULATORY_REPORTING_SAVE'

// Booking configuration
export const BOOKING_CONFIGURATION_SAVE = 'BOOKING_CONFIGURATION_SAVE'

// Customer Service
export const CUSTOMER_SERVICE_SAVE = 'CUSTOMER_SERVICE_SAVE'

// Customer Service
export const INTEGRATION_BASICS_CLICK_UPLOAD_LOGO =
  'INTEGRATION_BASICS_CLICK_UPLOAD_LOGO'
export const INTEGRATION_BASICS_SAVE = 'INTEGRATION_BASICS_SAVE'

// User Profile
export const USER_PROFILE_SAVE = 'USER_PROFILE_SAVE'

// SIGN OUT
export const SIGN_OUT = 'SIGN_OUT'
