import React, { useEffect } from 'react'
import { Button, Stack, Typography } from '@mui/material'
import { FormattedMessage, useIntl } from 'react-intl'
import { useImmerReducer } from 'use-immer'
import SaveIcon from '@mui/icons-material/Save'
import { messageKeys as globalMessageKeys } from '../../globalMessages'
import { useSnackbar } from '../../utils/snackBar'
import { messageKeys } from './messages'
import PaymentMethods from './Form/PaymentMethods'
import usePutProviderConfiguration from '../../hooks/usePutProviderConfig'
import Loader from '../../components/Loader'
import { getErrorMessage } from '../../utils/axiosUtil'
import { ProviderConfiguration } from '../../hooks/types/ProviderConfiguration'
import { AxiosError } from 'axios'
import { getSelectedProvider } from '../../localStorage'
import { useCustomerPaymentsMissingFields } from './useCustomerPaymentsMissingFields'
import useGetProvider from '../../hooks/useGetProvider'
import useGetProviderConfiguration from '../../hooks/useGetProviderConfiguration'
import { Page, PageContent, PageHeader } from '@holidu/pui-components/dist'
import InformationMissingError from '../../components/InformationMissingError'
import { PAYMENT_OPTIONS_SAVE } from '../../utils/analytics/events'
import Analytics from '../../utils/analytics'

type State = {
  providerConfig: ProviderConfiguration
  hasChanges: boolean
}
const INITIAL_STATE: State = {
  providerConfig: {},
  hasChanges: false
}

enum ActionTypes {
  UpdatePaymentMethods = 'UpdatePaymentMethods',
  DataSaved = 'DataSaved',
  LoadData = 'LoadData'
}

type Action =
  | { type: ActionTypes.UpdatePaymentMethods; payload: string[] }
  | { type: ActionTypes.DataSaved }
  | { type: ActionTypes.LoadData; payload: ProviderConfiguration }

const reducer = (draft: State, action: Action) => {
  switch (action.type) {
    case ActionTypes.UpdatePaymentMethods: {
      draft.providerConfig.paymentMethods = action.payload
      draft.hasChanges = true
      break
    }
    case ActionTypes.DataSaved:
      draft.hasChanges = false
      break
    case ActionTypes.LoadData:
      draft.providerConfig = action.payload
      break
    default:
      break
  }
}

const CustomerPaymentsPage = (): JSX.Element => {
  const selectedProvider = getSelectedProvider()
  const { data: provider, isLoading: isProviderLoading } =
    useGetProvider(selectedProvider)
  const { data: providerConfig, isLoading: isProviderConfigLoading } =
    useGetProviderConfiguration(selectedProvider)

  const poFields = useCustomerPaymentsMissingFields(providerConfig)

  const intl = useIntl()
  const { isLoading: isSaving, mutateAsync: mutateSave } =
    usePutProviderConfiguration(selectedProvider)

  const { showSuccessSnackbar, showErrorSnackbar } = useSnackbar(
    intl.formatMessage(messageKeys.Title)
  )

  const [state, dispatch] = useImmerReducer(reducer, INITIAL_STATE)

  useEffect(() => {
    if (providerConfig) {
      dispatch({
        type: ActionTypes.LoadData,
        payload: providerConfig
      })
    }
  }, [selectedProvider, providerConfig]) //eslint-disable-line

  const submitForm = async () => {
    Analytics.trackEvent(PAYMENT_OPTIONS_SAVE)
    try {
      await mutateSave(state.providerConfig)
      dispatch({ type: ActionTypes.DataSaved })
      showSuccessSnackbar()
    } catch (err) {
      showErrorSnackbar(getErrorMessage(err as AxiosError))
    }
  }

  if (state.providerConfig.holiduHandlesPayments) {
    return <>{null}</>
  }

  return (
    <Page>
      <PageHeader
        sx={{
          borderBottom: 'none'
        }}
      >
        <Typography variant="h6" sx={{ flexGrow: 1 }}>
          <FormattedMessage {...messageKeys.Title} />
        </Typography>
        <Button
          color="primary"
          disabled={!state.hasChanges}
          startIcon={<SaveIcon />}
          variant="contained"
          onClick={submitForm}
        >
          {intl.formatMessage(globalMessageKeys.SaveButton)}
        </Button>
      </PageHeader>
      <PageContent px={3} pb={8}>
        {Boolean(
          provider && provider?.status !== 'LIVE' && poFields.length
        ) && <InformationMissingError />}
        <Stack spacing={4}>
          <PaymentMethods
            paymentMethods={state.providerConfig?.paymentMethods || []}
            onChange={(newPaymentMethods: string[]) => {
              dispatch({
                type: ActionTypes.UpdatePaymentMethods,
                payload: newPaymentMethods
              })
            }}
          />
        </Stack>
        {(isProviderLoading || isProviderConfigLoading || isSaving) && (
          <Loader />
        )}
      </PageContent>
    </Page>
  )
}

export default CustomerPaymentsPage
