export const messageKeys = {
  Title: {
    id: 'UserProfile:TITLE',
    defaultMessage: 'UserProfile:TITLE'
  },
  Surname: {
    id: 'UserProfile:LAST_NAME',
    defaultMessage: 'UserProfile:LAST_NAME'
  },
  Name: {
    id: 'UserProfile:FIRST_NAME',
    defaultMessage: 'UserProfile:FIRST_NAME'
  },
  Phone: {
    id: 'UserProfile:PHONE',
    defaultMessage: 'UserProfile:PHONE'
  },
  ContactLanguage: {
    id: 'UserProfile:CONTACT_LANG',
    defaultMessage: 'UserProfile:CONTACT_LANG'
  },
  RoleInCompany: {
    id: 'UserProfile:ROLE',
    defaultMessage: 'UserProfile:ROLE'
  },
  ProviderName: {
    id: 'UserProfile:PROVIDER_NAME',
    defaultMessage: 'UserProfile:PROVIDER_NAME'
  }
}
