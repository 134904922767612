import { Map } from 'immutable'
import env from '../../env'
import { currentUserIdToken } from '../../utils/awsCognitoClient'

export const getProviderRequest = props => ({
  url: `${env.BACKEND}/provider${
    props.selectedProvider ? `?provider=${props.selectedProvider}` : ''
  }`,
  transform: response => {
    return {
      providers: response
    }
  },
  update: {
    providers: (prev, next) => {
      if (!prev) {
        return Map({ [next.get('identifier')]: next })
      } else {
        return prev.set(next.get('identifier'), next)
      }
    }
  },
  options: {
    headers: {
      Authorization: 'Bearer ' + currentUserIdToken()
    }
  }
})
