import React from 'react'
import { PropertyCancellationPolicy } from '../types'
import { useIntl } from 'react-intl'
import {
  CancellationPolicy as PuiCancellationPolicy,
  CancellationPolicyItemProps as PuiCancellationPolicyItemProps
} from '@holidu/pui-components/dist'
import useGetPropertyCancellationPolicy from '../useGetPropertyCancellationPolicy'
import sortBy from 'lodash/sortBy'
import { messageKeys as globalMessageKeys } from '../../../globalMessages'
import useResponsiveWidth from '../../../components/hooks/useResponsiveWidth'
import { Box } from '@mui/material'
import { getAmountCurrencyText } from '../../../utils/currencyUtil'

const getGraphData = (
  item: PropertyCancellationPolicy
): PuiCancellationPolicyItemProps => {
  const { daysBeforeArrival, amount, percentage, currency } = item
  let value: string | number = 0

  if (percentage) {
    value = `${percentage}%`
  } else if (amount) {
    value = getAmountCurrencyText(amount, currency)
  }

  return {
    daysBeforeArrival,
    value
  }
}

type CancellationPolicyProps = {
  provider: string
  apartmentId: string
}

const CancellationPolicy = (props: CancellationPolicyProps): JSX.Element => {
  const { provider, apartmentId } = props
  const { data, isLoading } = useGetPropertyCancellationPolicy(
    provider,
    apartmentId
  )

  const intl = useIntl()
  const { containerRef, width } = useResponsiveWidth(600)

  const graphData = Boolean(data?.length)
    ? sortBy(data, 'daysBeforeArrival').map(item => getGraphData(item))
    : []

  return (
    <Box ref={containerRef}>
      <PuiCancellationPolicy
        data={graphData}
        width={width}
        isLoading={isLoading}
        renderValue={value =>
          value || intl.formatMessage(globalMessageKeys.FREE)
        }
        renderLabel={daysBeforeArrival => {
          const text =
            daysBeforeArrival === 0
              ? intl.formatMessage(globalMessageKeys.CHECK_IN)
              : intl.formatMessage(globalMessageKeys.N_DAYS, {
                  n: daysBeforeArrival
                })
          return text
        }}
      />
    </Box>
  )
}

export default CancellationPolicy
