import React, { useState } from 'react'
import { styled, Chip, InputAdornment, TextField } from '@mui/material'

const MultiTextFieldInput = props => {
  const { checkItemErrorState, name, onChange, value, ...restProps } = props
  const [inputValue, setInputValue] = useState('')

  const handleKeyUp = e => {
    if (e.key === 'Enter') {
      const newValue = inputValue.trim()
      if (newValue) {
        e.preventDefault()
        onChange(name, [...value, inputValue])
      }
      setInputValue('')
    }
  }

  const handleBlur = e => {
    const newValue = inputValue.trim()
    if (newValue) {
      e.preventDefault()
      onChange(name, [...value, inputValue])
    }
    setInputValue('')
  }

  const handleChange = e => {
    setInputValue(e.target.value)
  }

  const handleDelete = i => () => {
    const newValue = [...value.slice(0, i), ...value.slice(i + 1)]
    onChange(name, newValue)
  }

  return (
    <TextField
      InputProps={{
        style: {
          flexWrap: 'wrap'
        },
        startAdornment: (
          <>
            {value.map((v, i) => {
              const hasError =
                typeof checkItemErrorState === 'function'
                  ? checkItemErrorState(v)
                  : false
              return (
                <InputAdornment
                  key={i}
                  sx={{
                    height: '100%',
                    minHeight: theme => theme.spacing(5)
                  }}
                  position="start"
                >
                  <StyledChip
                    label={v}
                    size="small"
                    variant="outlined"
                    onDelete={handleDelete(i)}
                    color={hasError ? 'error' : 'default'}
                  />
                </InputAdornment>
              )
            })}
          </>
        )
      }}
      name={name}
      value={inputValue}
      onChange={handleChange}
      onKeyUp={handleKeyUp}
      onBlur={handleBlur}
      // eslint-disable-next-line
      inputProps={{
        style: {
          flexGrow: 1,
          flexBasis: 300,
          width: 'unset'
        }
      }}
      {...restProps}
    />
  )
}

const StyledChip = styled(Chip)(({ theme }) => {
  return {
    '&.MuiChip-root': {},
    '&.MuiChip-outlinedSecondary': {
      color: theme.palette.error.main,
      border: `1px solid ${theme.palette.error.main}`
    },
    '&.MuiChip-deleteIconOutlinedColorSecondary': {
      color: theme.palette.error.main
    }
  }
})

export default MultiTextFieldInput
