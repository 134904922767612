import isNil from 'lodash/isNil'
import { getAmountCurrencyText } from '../../utils/currencyUtil'
import { Price } from './types'

export const getTextFromPrice = (price?: Price) => {
  let displayPrice = ''
  if (price) {
    const { originalValue, originalCurrency, value, currency } = price
    if (!isNil(value) && currency) {
      displayPrice = getAmountCurrencyText(value, currency)
    }
    if (
      !isNil(originalValue) &&
      originalCurrency &&
      originalCurrency !== currency
    ) {
      displayPrice = `(${getAmountCurrencyText(
        originalValue,
        originalCurrency
      )}) ${displayPrice}`
    }
  }
  return displayPrice
}
