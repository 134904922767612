import React from 'react'
import {
  Typography,
  Box,
  Grid,
  Stack,
  Link,
  ListItemButton,
  Paper
} from '@mui/material'
import { useIntl } from 'react-intl'
import messageKeys from '../messages'
import { Property } from '../types'
import PropertyImage from './PropertyImage'
import Price from './Price'
import Scores from './Scores'
import { useHistory } from 'react-router'
import PropertyStatusChip from '../../../components/PropertyStatusChip'
import { APARTMENTS_CLICK } from '../../../utils/analytics/events'
import Analytics from '../../../utils/analytics'
import { HIGHLIGHT_PRIMARY } from '../../../utils/colors'
import { getScoreColorLight } from '../../../utils/scoreUtil'
import { LabelValue } from '@holidu/pui-components/dist'
import { BACKGROUND_HIGHLIGHT } from '../../../theme'
import { enumToCapitalized } from '../utils'

const getTitle = (data: Property) => {
  const { title, apartmentType, persons } = data
  if (title) {
    return title
  }
  let str = ''
  if (apartmentType) {
    str += `${apartmentType}`
  }
  if (persons) {
    str += ` for ${persons} persons`
  }
  return str
}

type PropertyItemProps = {
  data: Property
}

const PropertyItem = (props: PropertyItemProps): JSX.Element => {
  const { data } = props
  const history = useHistory()
  const intl = useIntl()

  const handleClick = () => {
    Analytics.trackEvent(APARTMENTS_CLICK, {
      ...data
    })
    history.push(`/inventory/property?apartmentId=${data.apartmentId}`)
  }

  const handleTitleClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.stopPropagation()
    e.preventDefault()
    handleClick()
  }

  return (
    <ListItemButton
      sx={{
        maxWidth: 'calc(100vw - 16px)',
        marginBottom: theme => theme.spacing(1),
        border: theme => `1px solid ${theme.palette.divider}`,
        borderRadius: 2,
        backgroundColor: 'white',
        padding: 0,
        '&:hover': {
          backgroundColor: BACKGROUND_HIGHLIGHT
        }
      }}
      onClick={handleClick}
      alignItems="center"
    >
      <Grid container spacing={1}>
        <Grid item xs={12} sm={4} md={3} lg={2}>
          <PropertyImage
            alt="apartment pic"
            src={data.thumbnailUrl || '/static/img/empty.jpg'}
          />
        </Grid>
        <Grid item xs={12} sm={8} md={9} lg={10}>
          <Stack p={1} spacing={1}>
            <Stack direction="row" justifyContent="space-between" spacing={2}>
              <Link onClick={handleTitleClick}>
                <Typography variant="subtitle1" noWrap>
                  {getTitle(data)}
                </Typography>
              </Link>
              <PropertyStatusChip
                size="small"
                status={data.status}
                label={data.status || '-'}
              />
            </Stack>
            <Stack
              direction="row"
              flexWrap="wrap"
              justifyContent="space-between"
              alignItems="flex-end"
              spacing={1}
            >
              <Box width="100px" flexGrow={1}>
                <LabelValue
                  label={intl.formatMessage(messageKeys.ApartmentId)}
                  value={data.externalId}
                  valueProps={{
                    noWrap: true
                  }}
                />
              </Box>
              <Box width="100px" flexGrow={1}>
                <LabelValue
                  label={intl.formatMessage(messageKeys.ApartmentHoliduId)}
                  value={data.apartmentId}
                />
              </Box>
              <Box width="100px" flexGrow={1}>
                <LabelValue
                  label={intl.formatMessage(messageKeys.ApartmentType)}
                  value={enumToCapitalized(data.apartmentType)}
                />
              </Box>
              <Box width="100px" flexGrow={1}>
                <LabelValue
                  label={intl.formatMessage(messageKeys.ApartmentPersons)}
                  value={data.persons}
                />
              </Box>
              <Box width="120px" flexGrow={2}>
                <Paper
                  elevation={0}
                  sx={{
                    padding: 1,
                    background: HIGHLIGHT_PRIMARY,
                    minHeight: '72px'
                  }}
                >
                  <LabelValue
                    label={intl.formatMessage(messageKeys.ApartmentPrice)}
                    value={<Price value={data.referencePrice} />}
                  />
                </Paper>
              </Box>
              <Box width="120px" flexGrow={2}>
                <Paper
                  elevation={0}
                  sx={{
                    background: getScoreColorLight(data.holiduScore),
                    padding: 1,
                    minHeight: '72px'
                  }}
                >
                  <Scores
                    holiduScore={data.holiduScore}
                    bookabilityScore={data.bookabilityScore}
                    photoScore={data.photoScore}
                  />
                </Paper>
              </Box>
            </Stack>
          </Stack>
        </Grid>
      </Grid>
    </ListItemButton>
  )
}

export default PropertyItem
