import React from 'react'
import { Grid, Button, TextField } from '@mui/material'
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import moment from 'moment'
import { FormattedMessage } from 'react-intl'
import ClearAllIcon from '@mui/icons-material/ClearAll'
import {
  getDateFromDateString,
  getDateStringFromDate
} from '../../utils/dateUtil'
import { messageKeys as globalMessageKeys } from '../../globalMessages'

import QueryTextField from '../../components/QueryFields/QueryTextField'
import { messageKeys } from './messages'

const PayoutsFilter = props => {
  const { allowFromDate, filterQuery, onClearAllFilters, onFilterChange } =
    props
  const textfieldProps = {
    margin: 'dense',
    size: 'small',
    fullWidth: true
  }

  const handleChange = event => {
    const { name, value } = event.target
    onFilterChange(name, value)
  }

  const handleDateChange = name => value => {
    onFilterChange(name, getDateStringFromDate(value))
  }

  const untilDateProps = {}
  const fromDate = allowFromDate
    ? getDateFromDateString(filterQuery.fromDate)
    : null
  const untilDate = getDateFromDateString(filterQuery.untilDate)

  if (allowFromDate && filterQuery.fromDate) {
    untilDateProps.minDate = moment(getDateFromDateString(filterQuery.fromDate))
  }

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <Grid item container spacing={1} alignItems="center">
        {allowFromDate && (
          <Grid item xs={12} lg={3}>
            <MobileDatePicker
              label={<FormattedMessage {...messageKeys.FROM_DATE} />}
              inputFormat="ll"
              clearable
              value={fromDate}
              onChange={handleDateChange('fromDate')}
              showToolbar={false}
              disableCloseOnSelect={false}
              renderInput={params => (
                <TextField {...textfieldProps} {...params} readOnly />
              )}
            />
          </Grid>
        )}
        <Grid item xs={12} lg={3}>
          <MobileDatePicker
            label={<FormattedMessage {...messageKeys.UNTIL_DATE} />}
            inputFormat="ll"
            clearable
            value={untilDate}
            onChange={handleDateChange('untilDate')}
            showToolbar={false}
            disableCloseOnSelect={false}
            renderInput={params => (
              <TextField {...textfieldProps} {...params} readOnly />
            )}
            {...untilDateProps}
          />
        </Grid>
        <Grid item xs={12} lg={3}>
          <QueryTextField
            label={<FormattedMessage {...messageKeys.BOOKING_ID} />}
            fullWidth
            type="number"
            name="bookingId"
            value={filterQuery.bookingId || ''}
            {...textfieldProps}
            onChange={handleChange}
          />
        </Grid>
        {Boolean(
          filterQuery?.bookingId ||
            filterQuery?.fromDate ||
            filterQuery?.untilDate
        ) && (
          <Grid item xs={12} lg={3}>
            <Button
              color="inherit"
              variant="contained"
              startIcon={<ClearAllIcon />}
              onClick={onClearAllFilters}
            >
              <FormattedMessage {...globalMessageKeys.CLEAR_FILTERS} />
            </Button>
          </Grid>
        )}
      </Grid>
    </LocalizationProvider>
  )
}

export default PayoutsFilter
