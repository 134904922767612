import React from 'react'
import { Alert, AlertTitle } from '@mui/material'
import axios, { AxiosError } from 'axios'

type AxiosErrorAlertProps = {
  error: Error | AxiosError
}

const AxiosErrorAlert = (props: AxiosErrorAlertProps): JSX.Element => {
  const { error } = props
  return (
    <Alert severity="error">
      {axios.isAxiosError(error) && error.code && (
        <AlertTitle>Error code: {error.code}</AlertTitle>
      )}
      {error.message || (
        <>
          The server encountered something unexpected that did not allow it to
          complete the request.
        </>
      )}
    </Alert>
  )
}

export default AxiosErrorAlert
