import axios from 'axios'
import { useQuery } from '@tanstack/react-query'
import { ProviderStartingChecklist } from './types'
import QueryKeys from '../../../utils/queryKeys'

const useGetProviderStartingChecklist = (
  provider: string,
  providerStatus: string
) => {
  return useQuery<any, Error, ProviderStartingChecklist>(
    [QueryKeys.ProviderStartingChecklist, { query: { provider } }],
    async () => {
      const { data } = await axios.get(
        `/provider/startingChecklist?provider=${provider}`
      )
      return data
    },
    { keepPreviousData: true, retry: false, enabled: providerStatus === 'NEW' }
  )
}

export default useGetProviderStartingChecklist
