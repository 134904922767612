import React from 'react'
import { useIntl } from 'react-intl'
import {
  Radio,
  Alert,
  Stack,
  Box,
  Typography,
  Button,
  Chip
} from '@mui/material'
import ThumbUpIcon from '@mui/icons-material/ThumbUp'
import { messageKeys } from '../messages'
import { messageKeys as globalMessageKeys } from '../../../globalMessages'
import {
  CancellationPolicy as PuiCancellationPolicy,
  Section,
  Tray
} from '@holidu/pui-components/dist'
import {
  CANCELLATION_POLICY_ACCEPTED,
  CancellationValue,
  CANCELLATION_POLICY_GRAPH_DATA
} from '../utils'
import { getSelectedProvider } from '../../../localStorage'
import { getContactLink } from '../../../utils/support'
import Analytics from '../../../utils/analytics'
import { CANCELLATION_POLICY_CLICK_CUSTOM_CONTACT } from '../../../utils/analytics/events'

const CANCELLATION_POLICIES_OPTIONS = [
  CancellationValue.CANCEL_FLEXIBLE,
  CancellationValue.CANCEL_MODERATE,
  CancellationValue.CANCEL_FIRM,
  CancellationValue.CANCEL_STRICT,
  CancellationValue.CUSTOM,
  CancellationValue.DEPRECATED
]

type CancellationPolicytValueType =
  | 'CANCEL_FLEXIBLE'
  | 'CANCEL_MODERATE'
  | 'CANCEL_FIRM'
  | 'CANCEL_STRICT'
  | 'CUSTOM'
  | 'DEPRECATED'

type CancellationPolicyItemProps = {
  value: CancellationPolicytValueType
  recommended?: boolean
  checked?: boolean
  onClick: (e: React.MouseEvent<HTMLDivElement>) => void
  disabled?: boolean
  action?: React.ReactNode
  alert?: React.ReactNode
}

const CancellationPolicyItem = (
  props: CancellationPolicyItemProps
): JSX.Element => {
  const intl = useIntl()
  const {
    value,
    checked = false,
    disabled = false,
    recommended = false,
    onClick,
    action,
    alert
  } = props
  const cursor = !disabled ? 'pointer' : 'default'
  const trayColor =
    value === CancellationValue.DEPRECATED
      ? 'error'
      : checked
      ? 'primary'
      : 'default'
  const textColor = !disabled ? 'text.primary' : 'text.disabled'

  const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (!disabled) {
      onClick(e)
    }
  }

  return (
    <Box
      data-value={value}
      onClick={handleClick}
      sx={{
        cursor: cursor
      }}
    >
      <Tray color={trayColor} type="outlined">
        <Stack direction="row" alignItems="center">
          <Radio
            checked={checked}
            value={value}
            disabled={disabled}
            inputProps={{ 'aria-label': value }}
          />
          <Stack flexGrow={1}>
            <Stack direction="row" alignItems="center" spacing={2}>
              <Typography color={textColor}>
                <strong>
                  {intl.formatMessage(
                    messageKeys[(value + '_LABEL') as keyof typeof messageKeys]
                  )}
                  :
                </strong>
              </Typography>
              {recommended && (
                <Chip
                  size="small"
                  color="primary"
                  label={intl.formatMessage(globalMessageKeys.RECOMMENDED)}
                  icon={<ThumbUpIcon />}
                />
              )}
            </Stack>

            <Typography color={textColor}>
              {intl.formatMessage(
                messageKeys[(value + '_DESC') as keyof typeof messageKeys]
              )}
            </Typography>
          </Stack>
          {action}
        </Stack>
        {Boolean(alert) && <Box mt={1}>{alert}</Box>}
      </Tray>
    </Box>
  )
}

type CancellationPolicyProps = {
  cancellationPolicyConfigSource?: string
  cancellationPolicy?: string
  isCustomDisabled?: boolean
  bizDev?: string
  onChange: (cancellationPolicy: string) => void
}

const CancellationPolicy = (props: CancellationPolicyProps) => {
  const {
    cancellationPolicy = '',
    cancellationPolicyConfigSource = '',
    isCustomDisabled = false,
    bizDev,
    onChange
  } = props
  const selectedProvider = getSelectedProvider()
  const intl = useIntl()

  const disableCancelationPolicies = Boolean(
    cancellationPolicyConfigSource &&
      cancellationPolicyConfigSource !== 'subProvider'
  )

  const handleCancellationPolicyChange = (
    e: React.MouseEvent<HTMLDivElement>
  ) => {
    const value = e.currentTarget.dataset.value
    if (value !== undefined) {
      onChange(value)
    }
  }

  return (
    <Tray color="primary">
      <Box bgcolor="common.white" borderRadius={2.5}>
        <Section
          title={intl.formatMessage(messageKeys.CancellationHeader)}
          secondaryTitle={
            cancellationPolicyConfigSource === 'default'
              ? intl.formatMessage(messageKeys.CancellationCmSubHeader)
              : cancellationPolicyConfigSource === 'request'
              ? intl.formatMessage(messageKeys.CancellationRequestSubHeader)
              : intl.formatMessage(messageKeys.CancellationSubHeader)
          }
        >
          <Stack spacing={2}>
            {CANCELLATION_POLICIES_OPTIONS.map(option => {
              if (
                option === CancellationValue.DEPRECATED &&
                cancellationPolicy !== CancellationValue.DEPRECATED
              ) {
                return null
              }

              const checked = cancellationPolicy === option

              const disabled =
                disableCancelationPolicies ||
                (option === CancellationValue.CUSTOM && isCustomDisabled)

              let ActionItem = null

              if (CANCELLATION_POLICY_ACCEPTED.includes(option)) {
                ActionItem = (
                  <PuiCancellationPolicy
                    width={600}
                    data={CANCELLATION_POLICY_GRAPH_DATA[option]}
                    maxDaysBeforeArival={40}
                    renderValue={value =>
                      value || intl.formatMessage(globalMessageKeys.FREE)
                    }
                    renderLabel={(daysBeforeArrival: number) => {
                      let text = intl.formatMessage(globalMessageKeys.N_DAYS, {
                        n: daysBeforeArrival
                      })
                      if (daysBeforeArrival === 0) {
                        text = intl.formatMessage(globalMessageKeys.CHECK_IN)
                      }
                      return text
                    }}
                  />
                )
              } else if (
                option === CancellationValue.CUSTOM &&
                selectedProvider &&
                bizDev
              ) {
                ActionItem = (
                  <Button
                    onClick={() => {
                      Analytics.trackEvent(
                        CANCELLATION_POLICY_CLICK_CUSTOM_CONTACT
                      )
                    }}
                    sx={{ whiteSpace: 'nowrap' }}
                    href={getContactLink(selectedProvider, bizDev)}
                    target="_blank"
                    color="primary"
                  >
                    {intl.formatMessage(messageKeys.ContactHolidu)}
                  </Button>
                )
              }

              let alert = null

              if (
                cancellationPolicy === CancellationValue.CANCEL_STRICT &&
                option === CancellationValue.CANCEL_STRICT
              ) {
                alert = (
                  <Alert severity="warning" variant="filled">
                    {intl.formatMessage(messageKeys.CancellationStrictWarning)}
                  </Alert>
                )
              }
              return (
                <CancellationPolicyItem
                  key={option}
                  value={option}
                  checked={checked}
                  disabled={disabled}
                  recommended={option === CancellationValue.CANCEL_FLEXIBLE}
                  onClick={handleCancellationPolicyChange}
                  action={ActionItem}
                  alert={alert}
                />
              )
            })}
          </Stack>
        </Section>
      </Box>
    </Tray>
  )
}

export default CancellationPolicy
