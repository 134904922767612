import { startTransaction } from '../../utils/apm'

export const reducer = (state, action) => {
  switch (action.type) {
    case 'init': {
      return {
        ...state,
        ...action.payload,
        errors: {},
        hasChanges: false
      }
    }
    case 'toggleChildSupport': {
      const kidsSupported = action.payload
      return {
        ...state,
        kidsSupported,
        kidsUpToAge: kidsSupported ? state.kidsUpToAge : null,
        kidsAgeRequired: kidsSupported ? state.kidsAgeRequired : null,
        hasChanges: true
      }
    }
    case 'toggleBabiesSupport': {
      const babiesSupported = action.payload
      return {
        ...state,
        babiesSupported,
        babiesUpToAge: babiesSupported ? state.babiesUpToAge : null,
        babiesAgeRequired: babiesSupported ? state.babiesAgeRequired : null,
        babiesUnlimited: babiesSupported ? state.babiesUnlimited : null,
        hasChanges: true
      }
    }
    case 'field': {
      const newErrors = { ...state.errors }
      delete newErrors[action.field]
      return {
        ...state,
        errors: { ...newErrors },
        [action.field]: action.value,
        hasChanges: true
      }
    }
    case 'errors':
      return { ...state, errors: action.payload }
    case 'saveBookingDetails': {
      let adultFromAge = 0
      let kidsFromAge = null
      if (state.babiesSupported && state.kidsSupported) {
        adultFromAge = Number(state.kidsUpToAge) + 1
        kidsFromAge = Number(state.babiesUpToAge) + 1
      } else if (state.kidsSupported) {
        adultFromAge = Number(state.kidsUpToAge) + 1
        kidsFromAge = 0
      } else if (state.babiesSupported) {
        adultFromAge = Number(state.babiesUpToAge) + 1
      }
      const transaction = startTransaction('BookingDetailsPage#Save')
      state.updateBookingConfigurationDetails(
        {
          adultFromAge: adultFromAge,
          kidsFromAge: kidsFromAge,
          kidsAgesRequired: state.kidsAgeRequired,
          babiesAgesRequired: state.babiesAgeRequired,
          babiesSupported: state.babiesSupported,
          unlimitedBabies: state.babiesUnlimited,
          phoneNumberRequired: state.phoneNumberRequired,
          fullAddressRequired: state.fullAddressRequired,
          commentRequired: state.commentRequired
        },
        () => {
          state.showSuccessSnackbar()
          transaction.end()
        },
        error => {
          state.showErrorSnackbar(error.text)
          transaction.end()
        }
      )
      return state
    }
    default:
      return state
  }
}
