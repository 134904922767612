import React from 'react'
import { Link } from 'react-router-dom'

import { Button, Typography, Container, Box } from '@mui/material'

const Page404 = () => (
  <Container fixed maxWidth="sm">
    <Box sx={{ minHeight: '100vh' }}>
      <Typography align="center" component="h1" variant="h1" gutterBottom>
        404
      </Typography>
      <Typography align="center" component="h2" variant="h5" gutterBottom>
        Page not found.
      </Typography>
      <Typography align="center" component="h2" variant="body1" gutterBottom>
        The page you are looking for might have been removed.
      </Typography>
      <Button component={Link} mt={2} to="/" variant="contained">
        Return to website
      </Button>
    </Box>
  </Container>
)

export default Page404
