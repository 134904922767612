const messages = {
  Dashboard: {
    id: 'SidebarTitle:DASHBOARD',
    defaultMessage: 'SidebarTitle:DASHBOARD'
  },
  Configuration: {
    id: 'SidebarTitle:CONFIGURATION',
    defaultMessage: 'SidebarTitle:CONFIGURATION'
  },
  Financials: {
    id: 'SidebarTitle:FINANCIALS',
    defaultMessage: 'SidebarTitle:FINANCIALS'
  },
  Invoices: {
    id: 'SidebarTitle:INVOICES',
    defaultMessage: 'SidebarTitle:INVOICES'
  },
  Inventory: {
    id: 'SidebarTitle:INVENTORY',
    defaultMessage: 'SidebarTitle:INVENTORY'
  },
  Bookings: {
    id: 'SidebarTitle:BOOKINGS',
    defaultMessage: 'SidebarTitle:BOOKINGS'
  }
}

export default messages
