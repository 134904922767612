import { capitalize } from 'lodash'
import QueryUtils from 'query-string'
import { FilterQueryType, PropertyStatus, SortOrder } from './types'

export const DEFAULT_SORT_BY = 'holiduScore'
export const DEFAULT_SORT_ORDER = 'DESC'
export const DEFAULT_PAGE = 0
export const DEFAULT_SIZE = 10

// TODO: move this function to pui-lib
export const enumToCapitalized = (enumStr: string): string => {
  const capitalized = capitalize(enumStr.replaceAll('_', ' '))
  return capitalized
}

export const getQueryObjectFromSearch = () => {
  return QueryUtils.parse(window.location.search, {
    parseNumbers: true,
    parseBooleans: true
  })
}

export const getApiRequestQueryString = (
  provider: string,
  ignorePagination: boolean = false
): string => {
  const {
    page,
    size,
    queryString,
    apartmentStatus,
    imageLabel,
    heroImageContent,
    lowBookabilityScore,
    lowPhotoScore,
    sortBy,
    sortOrder
  } = getQueryObjectFromSearch()

  const queryParams: any = {
    provider,
    queryString,
    apartmentStatus,
    imageLabel,
    heroImage: heroImageContent,
    sortBy: sortBy || DEFAULT_SORT_BY,
    sortOrder: sortOrder || DEFAULT_SORT_ORDER
  }

  if (!ignorePagination) {
    queryParams.page = page || DEFAULT_PAGE
    queryParams.size = size || DEFAULT_SIZE
  }

  if (lowBookabilityScore) {
    queryParams.bookabilityScore = 'LOW'
  }
  if (lowPhotoScore) {
    queryParams.photoScore = 'LOW'
  }

  return QueryUtils.stringify(queryParams, { strict: true })
}

export const getFilterQuery = (): FilterQueryType => {
  const queryObj = getQueryObjectFromSearch()

  const filterQuery: FilterQueryType = {
    queryString: (queryObj.queryString as string) || '',
    apartmentStatus: (queryObj.apartmentStatus as PropertyStatus) || '',
    imageLabel: (queryObj.imageLabel as string) || '',
    heroImageContent: (queryObj.heroImageContent as string) || '',
    lowBookabilityScore: (queryObj.lowBookabilityScore as boolean) || false,
    lowPhotoScore: (queryObj.lowPhotoScore as boolean) || false,
    sortBy: (queryObj.sortBy as string) || DEFAULT_SORT_BY,
    sortOrder: (queryObj.sortOrder as SortOrder) || DEFAULT_SORT_ORDER,
    page: (queryObj.page as number) || DEFAULT_PAGE,
    size: (queryObj.size as number) || DEFAULT_SIZE
  }

  return filterQuery
}

export const HERO_IMAGE_FILTER_OPTIONS = [
  'ANIMAL',
  'CAR',
  'FACE',
  'FOOD',
  'HUMAN',
  'TEXT',
  'TOILET'
]

export const STATUS_FILTER_OPTIONS = [
  {
    color: 'success',
    value: 'ACTIVE'
  },
  {
    color: 'error',
    value: 'INACTIVE'
  },
  {
    color: 'disabled',
    value: 'DELETED'
  }
]

export const HERO_IMAGE_STATUS_FILTER_OPTIONS = [
  {
    color: 'success',
    value: 'GOOD'
  },
  {
    color: 'error',
    value: 'BAD'
  },
  {
    color: 'disabled',
    value: 'NEUTRAL'
  }
]
