import React, { useState } from 'react'
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Alert,
  SelectChangeEvent
} from '@mui/material'
import { useIntl } from 'react-intl'
import Loader from '../../../components/Loader'
import { messageKeys as globalMessageKeys } from '../../../globalMessages'
import ConfirmDialog from './ConfirmDialog'
import CancellationReasonField from './CancellationReasonField'
import CancellationCommentField from './CancellationCommentField'
import messageKeys from './messages'
import usePostCancelBooking from './usePostCancelBooking'
import Analytics from '../../../utils/analytics'
import {
  BOOKING_DETAIL_CLICK_CANCEL_CONFIRM_NO,
  BOOKING_DETAIL_CLICK_CANCEL_CONFIRM_YES,
  BOOKING_DETAIL_CLICK_CANCEL_SUBMIT
} from '../../../utils/analytics/events'

type CancelBookingProps = {
  bookingId: number
  onClose: () => void
}

const CancelBookingDialog = (props: CancelBookingProps): JSX.Element => {
  const { bookingId, onClose } = props
  const intl = useIntl()
  const [reason, setReason] = useState<string>('')
  const [comment, setComment] = useState<string>('')
  const [openConfirmation, setOpenConfirmation] = useState<boolean>(false)

  const {
    isLoading: isCancelBookingLoading,
    mutateAsync: mutateAsyncCancelBooking
  } = usePostCancelBooking(bookingId)

  const handleSubmit = () => {
    Analytics.trackEvent(BOOKING_DETAIL_CLICK_CANCEL_SUBMIT)
    setOpenConfirmation(true)
  }

  const handleReasonChange = (e: SelectChangeEvent) => {
    setReason(e.target.value)
  }

  const handleCommentChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setComment(e.target.value)
  }

  const handleCancelBooking = async () => {
    Analytics.trackEvent(BOOKING_DETAIL_CLICK_CANCEL_CONFIRM_YES)

    setOpenConfirmation(false)

    try {
      await mutateAsyncCancelBooking({
        cancellationReason: reason,
        cancellationComment: comment
      })
    } catch (err) {}
    onClose()
  }

  const handleNo = () => {
    Analytics.trackEvent(BOOKING_DETAIL_CLICK_CANCEL_CONFIRM_NO)
    onClose()
  }

  const isValidForm = Boolean(reason)

  return (
    <>
      <Dialog open onClose={onClose}>
        <DialogTitle>{intl.formatMessage(messageKeys.CancelTitle)}</DialogTitle>
        <DialogContent>
          <Alert severity="info" sx={{ mb: 4 }}>
            {intl.formatMessage(messageKeys.CancelInfo)}
          </Alert>
          <form noValidate autoComplete="off">
            <CancellationReasonField
              value={reason}
              onChange={handleReasonChange}
            />
            {reason === 'CUSTOMER_OTHER' && (
              <CancellationCommentField
                value={comment}
                onChange={handleCommentChange}
              />
            )}
          </form>
        </DialogContent>
        <DialogActions>
          <Button
            data-ga-label="Cancel booking close"
            onClick={onClose}
            color="primary"
          >
            {intl.formatMessage(globalMessageKeys.CloseButton)}
          </Button>
          <Button
            data-ga-label="Cancel booking submit"
            disabled={!isValidForm}
            onClick={handleSubmit}
            variant="contained"
            color="primary"
            autoFocus
          >
            {intl.formatMessage(globalMessageKeys.SubmitButton)}
          </Button>
        </DialogActions>
      </Dialog>
      <ConfirmDialog
        open={openConfirmation}
        onNo={handleNo}
        onYes={handleCancelBooking}
      />
      {isCancelBookingLoading && <Loader />}
    </>
  )
}

export default CancelBookingDialog
