import React from 'react'
import { Checkbox, FormControlLabel, Box, Stack } from '@mui/material'
import { FormattedMessage } from 'react-intl'

import { messageKeys } from '../messages'
import Section from '../../../components/Section'

const CustomerInformation = props => {
  const { bookingDetails, dispatch } = props
  const bookingInfoConfigSource = bookingDetails.bookingInfoConfigSource
  const disableAdditionalGuestFields =
    bookingInfoConfigSource && bookingInfoConfigSource !== 'subProvider'

  return (
    <Section
      title={
        <FormattedMessage {...messageKeys.OtherBookingInformationHeader} />
      }
      subtitle={
        bookingInfoConfigSource === 'default' ? (
          <FormattedMessage
            {...messageKeys.OtherBookingInformationSubHeaderCM}
          />
        ) : bookingInfoConfigSource === 'request' ? (
          <FormattedMessage
            {...messageKeys.OtherBookingInformationSubHeaderRequest}
          />
        ) : (
          <FormattedMessage {...messageKeys.OtherBookingInformationSubHeader} />
        )
      }
    >
      <Stack direction={{ xs: 'column', lg: 'row' }} flexWrap="wrap">
        {/* Email Address */}
        <Box mr={2}>
          <FormControlLabel
            data-ga-label="EMAIL"
            control={<Checkbox id="EMAIL" disabled checked />}
            label="Email"
          />
        </Box>
        {/* Phone Number */}
        <Box mr={2}>
          <FormControlLabel
            data-ga-label="PHONE"
            control={
              <Checkbox
                id="PHONE"
                disabled={disableAdditionalGuestFields}
                checked={props.phoneNumberRequired}
                onChange={e =>
                  dispatch({
                    type: 'field',
                    field: 'phoneNumberRequired',
                    value: e.target.checked
                  })
                }
                name="phoneNumber"
              />
            }
            label={<FormattedMessage {...messageKeys.PhoneNumber} />}
          />
        </Box>
        {/* Full address */}
        <Box mr={2}>
          <FormControlLabel
            data-ga-label="ADDRESS"
            control={
              <Checkbox
                disabled={disableAdditionalGuestFields}
                checked={props.fullAddressRequired}
                onChange={e =>
                  dispatch({
                    type: 'field',
                    field: 'fullAddressRequired',
                    value: e.target.checked
                  })
                }
                name="fullAddress"
              />
            }
            label={<FormattedMessage {...messageKeys.FullAddress} />}
          />
        </Box>
        {/* Comment Support */}
        <Box mr={2}>
          <FormControlLabel
            data-ga-label="COMMENT"
            control={
              <Checkbox
                disabled={disableAdditionalGuestFields}
                checked={props.commentRequired}
                onChange={e =>
                  dispatch({
                    type: 'field',
                    field: 'commentRequired',
                    value: e.target.checked
                  })
                }
                name="comment"
              />
            }
            label={<FormattedMessage {...messageKeys.Comment} />}
          />
        </Box>
      </Stack>
    </Section>
  )
}

export default CustomerInformation
