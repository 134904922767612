import Analytics from './utils/analytics'

export const loadLocale = () => {
  try {
    return localStorage.getItem('locale')
  } catch (err) {
    return undefined
  }
}

export const saveLocale = locale => {
  try {
    localStorage.setItem('locale', locale)
  } catch (err) {
    // die
  }
}

export const saveSelectedProvider = provider => {
  try {
    Analytics.setProvider(provider)
    return localStorage.setItem('selectedProvider', provider)
  } catch (err) {
    // error in saving
  }
}

export const getSelectedProvider = () => {
  try {
    return localStorage.getItem('selectedProvider')
  } catch (err) {
    return null
  }
}

export const removeSelectedProvider = () => {
  try {
    return localStorage.removeItem('selectedProvider')
  } catch (err) {
    // error in saving
  }
}

export const clearLocalStorage = () => {
  // Next line commented reason: Issue: https://holidu.atlassian.net/browse/PAIN-2043
  // removeSelectedProvider()
}
