import React from 'react'
import NoteAltOutlinedIcon from '@mui/icons-material/NoteAltOutlined'
import HomeWorkOutlinedIcon from '@mui/icons-material/HomeWorkOutlined'
import VerifiedUserOutlinedIcon from '@mui/icons-material/VerifiedUserOutlined'
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined'
import FactCheckOutlinedIcon from '@mui/icons-material/FactCheckOutlined'
import PlayArrowOutlinedIcon from '@mui/icons-material/PlayArrowOutlined'
import { messageKeys } from '../messages'
import {
  DASHBOARD_CLICK_STAT_ACTIVE,
  DASHBOARD_CLICK_STAT_BAD_PHOTO,
  DASHBOARD_CLICK_STAT_INACTIVE,
  DASHBOARD_CLICK_STAT_LOW_BOOKABILITY,
  DASHBOARD_CLICK_STAT_LOW_PHOTO
} from '../../../utils/analytics/events'

export const OnboardingContents = {
  SIGN: {
    label: messageKeys.OnboardingStepSign_Label,
    desc: messageKeys.OnboardingStepSign_Desc,
    icon: <NoteAltOutlinedIcon />
  },
  SIGN_COMMERCIAL_AGREEMENT: {
    label: messageKeys.OnboardingStepSignCommercialAgreement_Label,
    desc: messageKeys.OnboardingStepSignCommercialAgreement_Desc,
    icon: <NoteAltOutlinedIcon />
  },
  ENABLE_CM: {
    label: messageKeys.OnboardingStepEnableCM_Label,
    desc: messageKeys.OnboardingStepEnableCM_Desc,
    icon: <HomeWorkOutlinedIcon />
  },
  KYC: {
    label: messageKeys.OnboardingStepKyc_Label,
    desc: messageKeys.OnboardingStepKyc_Desc,
    icon: <VerifiedUserOutlinedIcon />
  },
  CONFIGURATION: {
    label: messageKeys.OnboardingStepConfiguration_Label,
    desc: messageKeys.OnboardingStepConfiguration_Desc,
    icon: <SettingsOutlinedIcon />
  },
  TEST_BOOKING: {
    label: messageKeys.OnboardingStepTestBooking_Label,
    desc: messageKeys.OnboardingStepTestBooking_Desc,
    icon: <FactCheckOutlinedIcon />
  },
  READY: {
    label: messageKeys.OnboardingStepReady_Label,
    desc: messageKeys.OnboardingStepReady_Desc,
    icon: <PlayArrowOutlinedIcon />
  }
}

export const STATS_LIST = [
  {
    key: 'activeProperties',
    label: messageKeys.statsActiveProperties,
    link: '/inventory/liveProperties?apartmentStatus=ACTIVE&page=0',
    linkText: messageKeys.statsActivePropertiesLink,
    analyticsEvent: DASHBOARD_CLICK_STAT_ACTIVE
  },
  {
    key: 'inactiveProperties',
    label: messageKeys.statsInactiveProperties,
    checkError: value => value > 0,
    link: '/inventory/liveProperties?apartmentStatus=INACTIVE&page=0',
    linkText: messageKeys.statsInactivePropertiesLink,
    analyticsEvent: DASHBOARD_CLICK_STAT_INACTIVE
  },
  {
    key: 'badHeroImageProperties',
    label: messageKeys.statsBadHeroImageProperties,
    link: '/inventory/liveProperties?apartmentStatus=ACTIVE&heroImageContent=BAD&page=0',
    linkText: messageKeys.statsBadHeroImagePropertiesLink,
    analyticsEvent: DASHBOARD_CLICK_STAT_BAD_PHOTO
  }
]

export const STATS_WITH_HISTOGRAM = [
  {
    key: 'averageBookabilityScore',
    label: messageKeys.statsAverageBookabilityScore,
    lowScoreLabel: messageKeys.statsLowBookabilityScore,
    link: '/inventory/liveProperties?lowBookabilityScore=true&apartmentStatus=ACTIVE&page=0',
    linkText: messageKeys.statsLowBookabilityScore,
    histogramKey: 'bookabilityScoreHist',
    analyticsEvent: DASHBOARD_CLICK_STAT_LOW_BOOKABILITY
  },
  {
    key: 'averagePhotoScore',
    label: messageKeys.statsAveragePhotoScore,
    lowScoreLabel: messageKeys.statsLowPhotoScore,
    link: '/inventory/liveProperties?lowPhotoScore=true&apartmentStatus=ACTIVE&page=0',
    linkText: messageKeys.statsLowPhotoScore,
    histogramKey: 'photoScoreHist',
    analyticsEvent: DASHBOARD_CLICK_STAT_LOW_PHOTO
  }
]
