import React from 'react'
import isNil from 'lodash/isNil'
import { useIntl } from 'react-intl'
import messageKeys from './messages'
import { CustomerInfo } from './types'
import { LabelValue, Section } from '@holidu/pui-components/dist'
import { Avatar, Stack } from '@mui/material'
import PersonIcon from '@mui/icons-material/Person'

const getCustomerAddress = (customerInfo: CustomerInfo) => {
  const values = [
    customerInfo.street,
    customerInfo.city,
    customerInfo.state,
    customerInfo.zip,
    customerInfo.countryIso
  ]
  return values.filter(v => !isNil(v)).join(', ')
}

type CustomerInfoSectionProps = {
  customerInfo: CustomerInfo
}

const CustomerInfoSection = (props: CustomerInfoSectionProps) => {
  const { customerInfo } = props
  const intl = useIntl()
  return (
    <Section title={intl.formatMessage(messageKeys.Customer)}>
      <Stack spacing={2}>
        <Stack direction="row" alignItems="center" spacing={2}>
          <Avatar>
            <PersonIcon />
          </Avatar>
          <LabelValue
            label={intl.formatMessage(messageKeys.Name)}
            value={`${customerInfo.firstName} ${customerInfo.lastName}`}
          />
        </Stack>

        <LabelValue
          label={intl.formatMessage(messageKeys.Email)}
          value={customerInfo.email}
        />
        <LabelValue
          label={intl.formatMessage(messageKeys.PhoneNumber)}
          value={customerInfo.phoneNumber}
        />
        <LabelValue
          label={intl.formatMessage(messageKeys.Address)}
          value={getCustomerAddress(customerInfo)}
        />
      </Stack>
    </Section>
  )
}

export default CustomerInfoSection
