import { Section, Tray } from '@holidu/pui-components/dist'
import { Chip, Grid, Stack, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React, { useRef } from 'react'
import { useIntl } from 'react-intl'
import { messageKeys } from '../messages'
import { AdyenOverallStatus, VerificationStatus } from '../types'
import StatusTray from './StatusTray'
import Analytics from '../../../utils/analytics'
import { KYC_VIDEO_CLICKED } from '../../../utils/analytics/events'

const OVERALL_STATUS_COLORS = {
  OPEN: 'warning',
  APPROVED: 'success',
  CLOSED: 'error'
}

type OverallKycStatusSectionProps = {
  adyenOverallStatus?: AdyenOverallStatus
  bankAccountVerificationStatus?: VerificationStatus
  companyVerificationStatus?: VerificationStatus
}

function OverallKycStatusSection(props: OverallKycStatusSectionProps) {
  const {
    adyenOverallStatus,
    bankAccountVerificationStatus,
    companyVerificationStatus
  } = props
  const videoPlayed = useRef<boolean>()

  const intl = useIntl()

  const handleVideoClick = () => {
    try {
      if (!videoPlayed?.current) {
        Analytics.trackEvent(KYC_VIDEO_CLICKED)
        videoPlayed.current = true
      }
    } catch (err) {}
  }

  return (
    <Section
      title={intl.formatMessage(messageKeys.Header)}
      secondaryTitle={intl.formatMessage(messageKeys.SubHeader)}
    >
      {adyenOverallStatus &&
        companyVerificationStatus &&
        bankAccountVerificationStatus && (
          <Stack direction={'column'} spacing={2}>
            <Tray
              color={OVERALL_STATUS_COLORS[adyenOverallStatus] as 'success'}
            >
              <Stack
                direction="row"
                alignItems="center"
                justifyContent={'space-between'}
                flexWrap="wrap"
              >
                <Typography variant="h5">
                  {intl.formatMessage(messageKeys.OverallStatus)}:
                </Typography>
                <Chip
                  sx={{
                    background: theme =>
                      theme.palette[
                        OVERALL_STATUS_COLORS[adyenOverallStatus] as 'success'
                      ].main,
                    color: '#fff',
                    minWidth: 100
                  }}
                  component={'div'}
                  label={adyenOverallStatus}
                />
              </Stack>
            </Tray>
            <Grid container direction={'row'} alignItems={'flex-start'}>
              <Grid
                item
                xs={4}
                sm={6}
                sx={{ paddingRight: theme => theme.spacing(3) }}
              >
                <Stack spacing={2}>
                  <StatusTray
                    titleMessageKey={messageKeys.Accountholder}
                    textMessageKey={messageKeys.AccountholderInfo}
                    helpLinkKey={'linkHelpdeskAccountHolder'}
                    verificationStatus={companyVerificationStatus}
                  />
                  <StatusTray
                    titleMessageKey={messageKeys.Shareholders}
                    textMessageKey={messageKeys.ShareholdersInfo}
                    helpLinkKey={'linkHelpdeskShareholder'}
                    verificationStatus={companyVerificationStatus}
                  />
                  <StatusTray
                    titleMessageKey={messageKeys.Bankaccount}
                    textMessageKey={messageKeys.BankaccountInfo}
                    helpLinkKey={'linkHelpdeskBankaccount'}
                    verificationStatus={bankAccountVerificationStatus}
                  />
                </Stack>
              </Grid>
              <Grid item xs={8} sm={6}>
                <Box>
                  <video
                    width={'100%'}
                    poster="/static/img/kyc.png"
                    controls
                    onPlay={handleVideoClick}
                  >
                    <source
                      src="https://holidu-tad.s3.eu-west-1.amazonaws.com/videos/KYC_video.mp4"
                      type="video/mp4"
                    />
                    Your browser does not support the video tag.
                  </video>
                </Box>
              </Grid>
            </Grid>
          </Stack>
        )}
    </Section>
  )
}

export default OverallKycStatusSection
