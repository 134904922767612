export const messageKeys = {
  SignInHeader: {
    id: 'Auth:SIGN_IN_HEADER',
    defaultMessage: 'Auth:SIGN_IN_HEADER'
  },
  SignInButton: {
    id: 'Auth:SIGN_IN_BUTTON',
    defaultMessage: 'Auth:SIGN_IN_BUTTON'
  },
  ForgotPassLink: {
    id: 'Auth:FORGOT_PASSWORD_LINK',
    defaultMessage: 'Auth:FORGOT_PASSWORD_LINK'
  },
  IfYouDoNotHaveAccountYouCan: {
    id: 'Auth:YOU_CAN',
    defaultMessage: 'Auth:YOU_CAN'
  },
  SignUpHereButton: {
    id: 'Auth:SIGN_UP_HERE_BUTTON',
    defaultMessage: 'Auth:SIGN_UP_HERE_BUTTON'
  },

  SignUpHeader: {
    id: 'Auth:SIGN_UP_HEADER',
    defaultMessage: 'Auth:SIGN_UP_HEADER'
  },
  SignUpButton: {
    id: 'Auth:SIGN_UP_BUTTON',
    defaultMessage: 'Auth:SIGN_UP_BUTTON'
  },
  AlreadyHaveAccount: {
    id: 'Auth:ALREADY_HAVE_ACCOUNT',
    defaultMessage: 'Auth:ALREADY_HAVE_ACCOUNT'
  },
  LoginHere: {
    id: 'Auth:LOGIN_HERE',
    defaultMessage: 'Auth:LOGIN_HERE'
  },
  NewPassword: {
    id: 'Auth:NEW_PASSWORD',
    defaultMessage: 'Auth:NEW_PASSWORD'
  },
  RepeatPassword: {
    id: 'Auth:REPEAT_PASSWORD',
    defaultMessage: 'Auth:REPEAT_PASSWORD'
  },
  TemporaryPassword: {
    id: 'Auth:TEMP_PASSWORD',
    defaultMessage: 'Auth:TEMP_PASSWORD'
  },
  PasswordsDontMatch: {
    id: 'Auth:PASS_DO_NOT_MATCH',
    defaultMessage: 'Auth:PASS_DO_NOT_MATCH'
  },
  ChangePassTitle: {
    id: 'Auth:CHANGE_TEMP_PASS_HEADER',
    defaultMessage: 'Auth:CHANGE_TEMP_PASS_HEADER'
  },
  RessetPassHeader: {
    id: 'Auth:RESET_PASS_HEADER',
    defaultMessage: 'Auth:RESET_PASS_HEADER'
  },
  RessetPassButton: {
    id: 'Auth:RESET_PASS_BUTTON',
    defaultMessage: 'Auth:RESET_PASS_BUTTON'
  },

  ChangePassHeader: {
    id: 'Auth:CHANGE_PASS_HEADER',
    defaultMessage: 'Auth:CHANGE_PASS_HEADER'
  },
  ChangePassButton: {
    id: 'Auth:CHANGE_PASS_BUTTON',
    defaultMessage: 'Auth:CHANGE_PASS_BUTTON'
  },

  ConfirmTitle: {
    id: 'Auth:CONFIRM_EMAIL_TITLE',
    defaultMessage: 'Auth:CONFIRM_EMAIL_TITLE'
  },
  ConfirmMessage: {
    id: 'Auth:CONFIRM_MESSAGE',
    defaultMessage: 'Auth:CONFIRM_MESSAGE'
  },
  UserConfirmed: {
    id: 'UserConfirmKeys:CONFIRMED',
    defaultMessage: 'UserConfirmKeys:CONFIRMED'
  },

  InvalidParametedr: {
    id: 'Auth:INVALID_PARAM',
    defaultMessage: 'Auth:INVALID_PARAM'
  },
  PasswordIsNotMatchingRequirements: {
    id: 'Auth:PASSWORD_NOT_MATCHING_REQUIREMENTS',
    defaultMessage: 'Auth:PASSWORD_NOT_MATCHING_REQUIREMENTS'
  },
  PasswordIsNotCorrect: {
    id: 'Auth:PASSWORD_NOT_CORRECT',
    defaultMessage: 'Auth:PASSWORD_NOT_CORRECT'
  },
  PasswordRequirements: {
    id: 'Auth:PASSWORD_REQUIREMENTS',
    defaultMessage: 'Auth:PASSWORD_REQUIREMENTS'
  },
  UserAlreadyExists: {
    id: 'Auth:USER_ALREADY_EXISTS',
    defaultMessage: 'Auth:USER_ALREADY_EXISTS'
  },
  UserNotConfirmed: {
    id: 'Auth:USER_NOT_CONFIRMED',
    defaultMessage: 'Auth:USER_NOT_CONFIRMED'
  },
  ResendVerificationLink: {
    id: 'Auth:RESEND_VERIFICATION',
    defaultMessage: 'Auth:RESEND_VERIFICATION'
  }
}
