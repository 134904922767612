import axios from 'axios'
import { useQuery } from '@tanstack/react-query'
import QueryKeys from '../../utils/queryKeys'
import { NonHopaInvoiceAggregations } from './types'

const useGetNonHopaInvoiceAggregations = (provider: string) => {
  return useQuery<NonHopaInvoiceAggregations, Error>(
    [QueryKeys.InvoiceAggregations, { query: provider }],
    async () => {
      const { data } = await axios.get(
        `/invoice/non-hopa/aggregation/${provider}`
      )
      return data
    },
    { keepPreviousData: true, retry: false }
  )
}

export default useGetNonHopaInvoiceAggregations
