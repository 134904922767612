export const messageKeys = {
  Title: {
    id: 'CustomerService:TITLE',
    defaultMessage: 'CustomerService:TITLE'
  },
  Header: {
    id: 'CustomerService:HEADER',
    defaultMessage: 'CustomerService:HEADER'
  },
  SubHeader: {
    id: 'CustomerService:SUB_HEADER',
    defaultMessage: 'CustomerService:SUB_HEADER'
  },
  CustomerServiceEmail: {
    id: 'CustomerService:CUSTOMER_SERVICE_EMAIL',
    defaultMessage: 'CustomerService:CUSTOMER_SERVICE_EMAIL'
  },
  CustomerServicePhone: {
    id: 'CustomerService:CUSTOMER_SERVICE_PHONE',
    defaultMessage: 'CustomerService:CUSTOMER_SERVICE_PHONE'
  },
  BookingNotificationHeader: {
    id: 'CustomerService:BOOKING_NOTIFICATION_HEADER',
    defaultMessage: 'CustomerService:BOOKING_NOTIFICATION_HEADER'
  },
  BookingNotificationSubHeader: {
    id: 'CustomerService:BOOKING_NOTIFICATION_SUB_HEADER',
    defaultMessage: 'CustomerService:BOOKING_NOTIFICATION_SUB_HEADER'
  },
  SendBookingNotificationEmail: {
    id: 'CustomerService:SEND_BOOKING_NOTIFICATION_EMAIL',
    defaultMessage: 'CustomerService:SEND_BOOKING_NOTIFICATION_EMAIL'
  },
  BookingNotificationEmail: {
    id: 'CustomerService:BOOKING_NOTIFICATION_EMAIL',
    defaultMessage: 'CustomerService:BOOKING_NOTIFICATION_EMAIL'
  }
}
