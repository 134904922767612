import React from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { connectRequest } from 'redux-query-immutable-react'

import { updateBookingConfigurationDetails } from '../../store/bookingConfigurationDetails/actions'
import { getBookingConfigurationDetailsSelector } from '../../store/bookingConfigurationDetails/bookingDetailsSelector'
import { getBookingConfigurationDetailsRequest } from '../../store/bookingConfigurationDetails/bookingDetailsRequest'
import { getSelectedProvider } from '../../store/providerSelection/providerSelector'
import { RequestsAreDone } from '../RequestsAreDone'
import BookingConfigurationPage from './BookingConfigurationPage'
import { Page, PageContent, PageHeader } from '@holidu/pui-components/dist'
import { Typography } from '@mui/material'
import { FormattedMessage } from 'react-intl'
import { messageKeys } from './messages'

const BookingConfiguration = props => {
  return (
    <Page>
      <PageHeader
        sx={{
          borderBottom: 'none'
        }}
      >
        <Typography variant="h6">
          <FormattedMessage {...messageKeys.Title} />
        </Typography>
      </PageHeader>
      <PageContent px={3} pb={8}>
        <BookingConfigurationPage
          bookingsDetail={props.bookingsDetail}
          selectedProvider={props.selectedProvider}
          updateBookingConfigurationDetails={
            props.updateBookingConfigurationDetails
          }
        />
      </PageContent>
    </Page>
  )
}

const mapDispatchToProps = (dispatch, props) => ({
  updateBookingConfigurationDetails: (data, onSuccess, onError) => {
    dispatch(
      updateBookingConfigurationDetails(
        { data, selectedProvider: props.selectedProvider },
        onSuccess,
        onError
      )
    )
  }
})

export default compose(
  connect(getSelectedProvider),
  connect(getBookingConfigurationDetailsSelector, mapDispatchToProps),
  connectRequest(getBookingConfigurationDetailsRequest),
  RequestsAreDone
)(BookingConfiguration)
