import React from 'react'

import { Backdrop, CircularProgress, Stack, Typography } from '@mui/material'
import PropTypes from 'prop-types'

function Loader(props) {
  return (
    <Backdrop
      open
      sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }}
    >
      <Stack alignItems="center" spacing={2}>
        <CircularProgress color="primary" m={2} />
        {props.text && <Typography>{props.text}</Typography>}
      </Stack>
    </Backdrop>
  )
}

Loader.propTypes = {
  text: PropTypes.string
}

export default Loader
