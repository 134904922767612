import blue from '@mui/material/colors/blue'
import { createTheme } from '@mui/material/styles'

export const BACKGROUND_HIGHLIGHT = blue[50]

let theme = createTheme({
  palette: {
    primary: {
      main: '#00809D',
      dark: '#024251'
    },
    secondary: {
      main: '#FFD2C8',
      dark: '#FD937B'
    }
  },
  components: {
    MuiTextField: {
      defaultProps: {
        size: 'small'
      }
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: 14
        }
      }
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          '&.MuiTableRow-hover:hover': {
            backgroundColor: BACKGROUND_HIGHLIGHT,
            cursor: 'pointer'
          }
        }
      }
    }
  }
})

theme = createTheme(theme, {
  components: {
    MuiInputLabel: {
      styleOverrides: {
        asterisk: {
          color: theme.palette.error.main
        }
      }
    }
  }
})

export default theme
