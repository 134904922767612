import React, { useEffect, useState } from 'react'
import KnowYourClientPage from './KnowYourClientPage'
import { useIntl } from 'react-intl'
import { messageKeys } from './messages'
import { Page, PageContent, PageHeader } from '@holidu/pui-components/dist'
import { Stack, Typography } from '@mui/material'
import { useSelector } from 'react-redux'
import { getLocaleSelector } from '../../store/locale/localeSelector'
import { getSelectedProvider } from '../../store/providerSelection/providerSelector'
import useGetAdyenLink from './useGetAdyenLink'
import { LoadingButton } from '@mui/lab'
import Analytics from '../../utils/analytics'
import {
  KYC_VERIFICATION_CLICK,
  KYC_VERIFICATION_FAILURE,
  KYC_VERIFICATION_SUCCESS
} from '../../utils/analytics/events'

function KnowYourClientVerificationPage() {
  const { selectedProvider } = useSelector(getSelectedProvider)
  const { currentLocale } = useSelector(getLocaleSelector)
  const [isVerificationRequestEnabled, setIsVerificationRequestEnabled] =
    useState<boolean>(false)

  const intl = useIntl()

  const {
    isError: isAdyenLinkError,
    data: adyenLink,
    isFetching: isAdyenLinkFetching,
    refetch
  } = useGetAdyenLink(
    selectedProvider,
    window.location.href,
    currentLocale,
    isVerificationRequestEnabled
  )

  useEffect(() => {
    if (isAdyenLinkError) {
      Analytics.trackEvent(KYC_VERIFICATION_FAILURE)
    } else if (adyenLink) {
      Analytics.trackEvent(KYC_VERIFICATION_SUCCESS)
      window.location.href = adyenLink.verificationUrl
    }
  }, [isAdyenLinkError, adyenLink])

  const redirectToAdyen = async () => {
    Analytics.trackEvent(KYC_VERIFICATION_CLICK)
    if (isVerificationRequestEnabled) refetch()
    else setIsVerificationRequestEnabled(true)
  }

  return (
    <Page pt={1}>
      <PageHeader
        sx={{
          borderBottom: 'none'
        }}
      >
        <Stack direction="row" justifyContent="space-between" flexGrow={1}>
          <Typography variant="h6">
            {intl.formatMessage(messageKeys.Title)}
          </Typography>
          <LoadingButton
            variant="contained"
            color="primary"
            loading={isAdyenLinkFetching}
            disabled={isAdyenLinkFetching}
            loadingIndicator={intl.formatMessage(messageKeys.Loading)}
            onClick={redirectToAdyen}
          >
            {intl.formatMessage(messageKeys.GoToVerficationProcess)}
          </LoadingButton>
        </Stack>
      </PageHeader>
      <PageContent px={3} pb={8}>
        <KnowYourClientPage />
      </PageContent>
    </Page>
  )
}

export default KnowYourClientVerificationPage
