import React, { useState } from 'react'
import { Alert, AlertTitle, Box, Collapse, IconButton } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'

type ErrorBannerProps = {
  message: string
  detailedMessage: string
}

const ErrorBanner = (props: ErrorBannerProps): JSX.Element => {
  const { detailedMessage, message } = props
  const [open, setOpen] = useState<boolean>(true)

  return (
    <Collapse in={open}>
      <Box mb={4}>
        <Alert
          variant="standard"
          severity="error"
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setOpen(false)
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
        >
          <AlertTitle>{message}</AlertTitle>
          {detailedMessage}
        </Alert>
      </Box>
    </Collapse>
  )
}

export default ErrorBanner
