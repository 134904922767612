export const getAmountCurrencyText = (
  amount?: number | null,
  currency?: string | null,
  maximumFractionDigits?: number
) => {
  const locale = localStorage.getItem('locale') || 'en-GB'
  return new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: currency || 'EUR',
    maximumFractionDigits:
      maximumFractionDigits !== undefined ? maximumFractionDigits : 2
  }).format(amount || 0)
}
