import isString from 'lodash/isString'
import { VatUtil, ZipUtil } from '@holidu/pui-utils/dist'

export const validateEmail = value => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
  return !(value && isString(value) && !value.match(emailRegex))
}

export const validateVAT = (countryCode, vatNumber) => {
  if (!VatUtil.isValidCountry(countryCode)) {
    return true
  }
  return VatUtil.isValidVat(countryCode, vatNumber)
}

export const validateZip = (countryCode, zip) => {
  if (!ZipUtil.isValidCountry(countryCode)) {
    return true
  }
  return ZipUtil.isValidZip(countryCode, zip)
}

export const validateIban = value => {
  const pattern = /^[A-Z]{2}[0-9A-Z]*$/
  return !(value && !pattern.test(value))
}

export const validateBic = value => {
  const pattern = /^[0-9A-Z]{11}$/
  return !(value && !pattern.test(value))
}
