import { Tray } from '@holidu/pui-components/dist'
import { Link, Stack, Typography } from '@mui/material'
import React from 'react'
import { useIntl } from 'react-intl'
import { VerificationStatus } from '../types'
import { HELP_LINKS } from '../utils'
import StatusIcon from './StatusIcon'

type StatusTrayProps = {
  verificationStatus: VerificationStatus
  titleMessageKey: { id: string; defaultMessage: string }
  textMessageKey: { id: string; defaultMessage: string }
  helpLinkKey:
    | 'linkHelpdeskAccountHolder'
    | 'linkHelpdeskShareholder'
    | 'linkHelpdeskBankaccount'
}

const StatusTray = (props: StatusTrayProps) => {
  const intl = useIntl()
  return (
    <Tray color="primary">
      <Stack direction="row" alignItems={'center'} spacing={2} mb={1}>
        <StatusIcon status={props.verificationStatus} />
        <Typography variant="h6">
          {intl.formatMessage(props.titleMessageKey)}
        </Typography>
      </Stack>
      {intl.formatMessage(props.textMessageKey, {
        [props.helpLinkKey]: (
          <Link href={HELP_LINKS[props.helpLinkKey]}>
            Holidu Partner Helpdesk
          </Link>
        )
      })}
    </Tray>
  )
}

export default StatusTray
