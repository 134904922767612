export const allFieldsAreFilled = (user, provider) => {
  return (
    user.size > 0 &&
    provider.size > 0 &&
    !!(
      user.get('firstName') &&
      user.get('lastName') &&
      user.get('phoneNumber') &&
      user.get('contactLanguage') &&
      user.get('roleInCompany') &&
      provider.get('identifier')
    )
  )
}

export const userFieldsAreFilled = user => {
  return (
    user.size > 0 &&
    !!(
      user.get('firstName') &&
      user.get('lastName') &&
      user.get('phoneNumber') &&
      user.get('contactLanguage') &&
      user.get('roleInCompany')
    )
  )
}
