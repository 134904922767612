import { CancellationPolicyItemProps } from '@holidu/pui-components/dist'

export enum CancellationValue {
  CANCEL_FLEXIBLE = 'CANCEL_FLEXIBLE',
  CANCEL_MODERATE = 'CANCEL_MODERATE',
  CANCEL_FIRM = 'CANCEL_FIRM',
  CANCEL_STRICT = 'CANCEL_STRICT',
  CUSTOM = 'CUSTOM',
  DEPRECATED = 'DEPRECATED'
}

export const CANCELLATION_POLICY_ACCEPTED: string[] = [
  CancellationValue.CANCEL_FLEXIBLE,
  CancellationValue.CANCEL_MODERATE,
  CancellationValue.CANCEL_FIRM,
  CancellationValue.CANCEL_STRICT
]

export enum CancellationCustomValue {
  PER_APARTMENT = 'PER_APARTMENT',
  PER_PROVIDER = 'PER_PROVIDER',
  CANCEL_SUPER_STRICT = 'CANCEL_SUPER_STRICT'
}

export const CANCELLATION_POLICY_CUSTOM: string[] = [
  CancellationCustomValue.PER_APARTMENT,
  CancellationCustomValue.PER_PROVIDER,
  CancellationCustomValue.CANCEL_SUPER_STRICT
]

export enum CancellationDeprecatedValue {
  HOLIDU_FREE = 'HOLIDU_FREE',
  HOLIDU_FLEXIBLE = 'HOLIDU_FLEXIBLE',
  HOLIDU_MODERATE = 'HOLIDU_MODERATE',
  HOLIDU_STRICT = 'HOLIDU_STRICT',
  HOLIDU_SUPER_STRICT = 'HOLIDU_SUPER_STRICT'
}

export const CANCELLATION_POLICY_DEPRECATED: string[] = [
  CancellationDeprecatedValue.HOLIDU_FREE,
  CancellationDeprecatedValue.HOLIDU_FLEXIBLE,
  CancellationDeprecatedValue.HOLIDU_MODERATE,
  CancellationDeprecatedValue.HOLIDU_STRICT,
  CancellationDeprecatedValue.HOLIDU_SUPER_STRICT
]

export const isCustomCancellationPolicy = (cancellationPolicy?: string) => {
  if (cancellationPolicy) {
    return CANCELLATION_POLICY_CUSTOM.includes(cancellationPolicy)
  }
  return false
}

export const isDeprecatedCancellationPolicy = (cancellationPolicy?: string) => {
  if (cancellationPolicy) {
    return CANCELLATION_POLICY_DEPRECATED.includes(cancellationPolicy)
  }
  return false
}

export const CANCELLATION_POLICY_GRAPH_DATA: Record<
  string,
  CancellationPolicyItemProps[]
> = {
  CANCEL_FLEXIBLE: [
    {
      daysBeforeArrival: 0,
      value: '100%'
    },
    {
      daysBeforeArrival: 7,
      value: 0
    }
  ],
  CANCEL_MODERATE: [
    {
      daysBeforeArrival: 0,
      value: '100%'
    },
    {
      daysBeforeArrival: 14,
      value: 0
    }
  ],
  CANCEL_FIRM: [
    {
      daysBeforeArrival: 0,
      value: '100%'
    },
    {
      daysBeforeArrival: 30,
      value: 0
    }
  ],
  CANCEL_STRICT: [
    {
      daysBeforeArrival: 0,
      value: '100%'
    },
    {
      daysBeforeArrival: 30,
      value: '30%'
    }
  ]
}
