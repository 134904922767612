import axios from 'axios'
import { useQuery } from '@tanstack/react-query'
import QueryKeys from '../../utils/queryKeys'
import { InvoiceAggregations } from './types'

const useGetInvoiceAggregations = (provider: string, page: number = 0) => {
  return useQuery<InvoiceAggregations, Error>(
    [QueryKeys.InvoiceAggregations, { query: { provider, page } }],
    async () => {
      const { data } = await axios.get(
        `/bookings/invoice/aggregation/${provider}?page=${page}`
      )
      return data
    },
    { keepPreviousData: true, retry: false }
  )
}

export default useGetInvoiceAggregations
