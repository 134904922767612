import React from 'react'
import PropTypes from 'prop-types'

import { Box } from '@mui/material'

const PageContent = props => {
  return (
    <Box data-screen="PageContent" {...props}>
      <Box sx={{ m: { xs: 2, sm: 3 }, overflowX: 'auto' }}>
        {props.children}
      </Box>
    </Box>
  )
}

PageContent.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired
}

PageContent.defaultProps = {}

export default PageContent
