import { Map } from 'immutable'
import env from '../../env'
import { currentUserIdToken } from '../../utils/awsCognitoClient'
import sanitizeData from '../../utils/sanitizeData'

export const updateBookingConfigurationDetailsRequest = payload => {
  return {
    url: `${env.BACKEND}/provider/bookingDetails?provider=${payload.selectedProvider}`,
    body: sanitizeData(payload.data),
    transform: response => ({
      bookingsDetail: response
    }),
    update: {
      bookingsDetail: (prev, next) => prev.set(payload.selectedProvider, next)
    },
    options: {
      method: 'PUT',
      headers: {
        Authorization: 'Bearer ' + currentUserIdToken()
      }
    }
  }
}

export const getBookingConfigurationDetailsRequest = props => ({
  url: `${env.BACKEND}/provider/bookingDetails?provider=${props.selectedProvider}`,
  transform: response => {
    return {
      bookingsDetail: response
    }
  },
  update: {
    bookingsDetail: (prev, next) => {
      if (!prev) {
        return Map({ [props.selectedProvider]: next })
      } else {
        return prev.set(props.selectedProvider, next)
      }
    }
  },
  options: {
    headers: {
      Authorization: 'Bearer ' + currentUserIdToken()
    }
  }
})
