import React, { useEffect, useRef, useState } from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'
import { messageKeys } from './messages'
import SaveIcon from '@mui/icons-material/Save'
import { messageKeys as globalMessageKeys } from '../../globalMessages'
import {
  Stack,
  Button,
  Link,
  Grid,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
  TextField,
  Paper,
  TableRow,
  TableCell,
  TableHead,
  TableContainer,
  Table,
  TableBody,
  tableCellClasses
} from '@mui/material'
import Section from '../../components/Section'
import { Map } from 'immutable'
import pick from 'lodash/pick'
import ValidationTextField from '../../components/Fields/ValidationTextField'
import Analytics from '../../utils/analytics'
import { REGULATORY_REPORTING_SAVE } from '../../utils/analytics/events'
import { useSnackbar } from '../../utils/snackBar'
import { startTransaction } from '../../utils/apm'
import ValidationHint from '../../components/Fields/ValidationHint'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { getDateStringFromDate, isValidDate } from '../../utils/dateUtil'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import axios from 'axios'
import env from '../../env'
import { styled } from '@mui/system'

const RegulatoryReportingPage = props => {
  const [hasChanges, setHasChanges] = useState(false)
  const [data, setData] = useState({})
  const [errors, setErrors] = useState({})
  const [loadedReportingData, setLoadedReportingData] = useState({})
  const [selectedDate, setSelectedDate] = useState(new Date())
  const [loading, setLoading] = useState(false)
  const { showSuccessSnackbar, showErrorSnackbar } = useSnackbar(
    <FormattedMessage {...messageKeys.Title} />
  )

  const HeaderTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.grey[50]
    }
  }))

  const prevData = useRef({})

  const { selectedProvider, updateRegulatoryReportingData } = props

  const loadData = async e => {
    if (loading) {
      return
    }
    const transaction = startTransaction('RegulatoryReporting#Load')
    setLoading(true)
    try {
      const response = await axios.get(
        `${
          env.BACKEND
        }/reportingData?provider=${selectedProvider}&reportingYear=${selectedDate?.getFullYear()}`
      )
      setLoadedReportingData(response.data)
      transaction.end()
      setLoading(false)
    } catch (error) {
      transaction.end()
      setLoading(false)
      if (error.response && error.response.status === 404) {
        showErrorSnackbar(<FormattedMessage {...messageKeys.NoDataFound} />)
      } else {
        showErrorSnackbar(error.text)
      }
      setLoadedReportingData({})
    }
  }

  const datePickerParams = {
    required: data.entityType === 'individual_entity',
    fullWidth: true,
    error: errors.birthDate || '',
    name: 'birthDate',
    validations: {
      dateValidation: {
        validator: value => isValidDate(value),
        message: (
          <FormattedMessage {...globalMessageKeys.ErrorInvalidDataInForm} />
        )
      }
    }
  }

  const handleEntityChange = e => {
    let newData = {
      ...data,
      entityType: e.target.value
    }

    switch (e.target.value) {
      case 'legal_business_entity':
        newData = { ...newData, birthDate: null }
        break
      case 'individual_entity':
        newData = { ...newData, registrationNumber: null }
        break
      default:
        newData = {
          ...newData,
          birthDate: null,
          registrationNumber: null,
          taxId: null
        }
    }

    setData(newData)
    setHasChanges(true)
  }

  const handleBirthDateFieldChange = value => {
    let newData = data

    if (value) {
      const dateString = getDateStringFromDate(value)
      if (dateString) {
        newData['birthDate'] = dateString
        setErrors({
          ...errors,
          birthDate: null
        })
      } else {
        setErrors({
          ...errors,
          birthDate: 'dateValidation'
        })
        return
      }
    } else {
      newData['birthDate'] = null
      setErrors({
        ...errors,
        birthDate: 'required'
      })
    }

    setData(newData)
    setHasChanges(true)
  }

  const handleTextFieldChange = (e, errorReason) => {
    const { name, value } = e.target
    setData({
      ...data,
      [name]: value || null
    })
    setErrors({
      ...errors,
      [name]: errorReason
    })
    setHasChanges(true)
  }

  useEffect(() => {
    const companyData = props.companies
      .get(props.selectedProvider, Map({}))
      .toJSON()
    const nextCompanyData = {
      ...pick(companyData, [
        'entityType',
        'registrationNumber',
        'taxId',
        'birthDate'
      ])
    }

    prevData.current = {
      ...nextCompanyData
    }
    setData(nextCompanyData)
  }, [props.selectedProvider, props.companies]) // eslint-disable-line

  const mapQuarter = date => {
    return Math.floor(parseInt(date.substring(5, 7)) / 3) + 1
  }

  const onSubmit = async () => {
    Analytics.trackEvent(REGULATORY_REPORTING_SAVE)
    const transaction = startTransaction('RegulatoryReporting#Save')
    const companyData = props.companies
      .get(props.selectedProvider, Map({}))
      .toJSON()

    updateRegulatoryReportingData(
      {
        ...companyData,
        ...data
      },
      () => {
        showSuccessSnackbar()
        setHasChanges(false)
        transaction.end()
      },
      error => {
        showErrorSnackbar(error.text)
        transaction.end()
      }
    )
  }

  return (
    <form autoComplete="off">
      <Stack spacing={4}>
        <Section
          title={<FormattedMessage {...messageKeys.Header} />}
          subtitle={
            <FormattedMessage
              {...messageKeys.SubHeader}
              values={{
                link: (
                  <Link
                    href="https://holidu4.zendesk.com/hc/en-us/articles/10440754054684-DAC7-EU-Tax-Directive"
                    target="_blank"
                  >
                    DAC7 FAQ
                  </Link>
                )
              }}
            />
          }
        >
          <Grid container spacing={2}>
            <Grid xs={12} md={12} item>
              <FormattedMessage required {...messageKeys.SelectEntityType} />
              <Typography component="span" color="error">
                {' '}
                {'*'}{' '}
              </Typography>
              <RadioGroup
                aria-labelledby="entity-type-radio-group-label"
                value={data.entityType || ''}
                onChange={handleEntityChange}
                name="entityType"
              >
                <FormControlLabel
                  value="legal_business_entity"
                  control={<Radio />}
                  label={
                    <FormattedMessage {...messageKeys.LegalBusinessDesc} />
                  }
                />
                <FormControlLabel
                  value="state_or_public_entity"
                  control={<Radio />}
                  label={
                    <FormattedMessage {...messageKeys.StateOrPublicDesc} />
                  }
                />
                <FormControlLabel
                  value="individual_entity"
                  control={<Radio />}
                  label={<FormattedMessage {...messageKeys.IndividualDesc} />}
                />
              </RadioGroup>
            </Grid>

            <Grid xs={12} md={6} item>
              <ValidationTextField
                name="taxId"
                label={<FormattedMessage {...messageKeys.TaxId} />}
                value={data.taxId || ''}
                fullWidth
                required={data.entityType !== 'state_or_public_entity'}
                disabled={data.entityType === 'state_or_public_entity'}
                onChange={handleTextFieldChange}
              />
              <ValidationHint>
                <FormattedMessage {...messageKeys.TaxIdHint} />
              </ValidationHint>
            </Grid>

            <Grid xs={12} md={6} item>
              <ValidationTextField
                disabled={data.entityType !== 'legal_business_entity'}
                name="registrationNumber"
                label={<FormattedMessage {...messageKeys.RegistrationNumber} />}
                value={data.registrationNumber || ''}
                required={data.entityType === 'legal_business_entity'}
                fullWidth
                onChange={handleTextFieldChange}
              />
              <ValidationHint>
                <FormattedMessage {...messageKeys.RegistrationNumberHint} />
              </ValidationHint>
            </Grid>

            <LocalizationProvider dateAdapter={AdapterMoment}>
              <Grid xs={12} md={6} item>
                <DatePicker
                  name="birthDate"
                  disabled={data.entityType !== 'individual_entity'}
                  label={<FormattedMessage {...messageKeys.BirthDate} />}
                  value={data.birthDate}
                  onChange={handleBirthDateFieldChange}
                  renderInput={params => (
                    <ValidationTextField
                      {...params}
                      {...datePickerParams}
                      readOnly
                    />
                  )}
                />
                <ValidationHint>
                  <FormattedMessage {...messageKeys.BirthDateHint} />
                </ValidationHint>
              </Grid>
            </LocalizationProvider>
          </Grid>
        </Section>
        <Button
          size="large"
          color="primary"
          disabled={!hasChanges}
          startIcon={<SaveIcon />}
          variant="contained"
          fullWidth
          onClick={onSubmit}
        >
          {' '}
          <FormattedMessage {...globalMessageKeys.SaveButton} />
        </Button>

        <Section
          title={<FormattedMessage {...messageKeys.DataHeader} />}
          subtitle={<FormattedMessage {...messageKeys.DataSubHeader} />}
        >
          <Grid container spacing={2}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <Grid xs={12} md={3} item>
                <DatePicker
                  views={['year']}
                  label={<FormattedMessage {...messageKeys.ReportingYear} />}
                  value={selectedDate}
                  onChange={newValue => {
                    setSelectedDate(new Date(Date.parse(newValue)))
                  }}
                  renderInput={params => (
                    <TextField
                      {...params}
                      label={
                        <FormattedMessage {...messageKeys.ReportingYear} />
                      }
                    />
                  )}
                />
              </Grid>
            </LocalizationProvider>

            <Grid xs={12} md={4} item>
              <Button
                color="primary"
                component="span"
                variant="contained"
                disabled={loading}
                onClick={loadData}
              >
                <FormattedMessage {...messageKeys.ShowReportingDataButton} />
              </Button>
            </Grid>
          </Grid>

          {/* Partner Details Accordion */}
          {loadedReportingData.partnerDetails !== undefined && (
            <Grid container spacing={1} marginTop={2} marginBottom={2}>
              <Grid xs={12} md={5} item>
                <FormattedMessage {...messageKeys.CompanyName} />:
              </Grid>
              <Grid xs={12} md={7} item>
                {loadedReportingData.partnerDetails.partner_company_name ||
                  'N/A'}
              </Grid>

              <Grid xs={12} md={5} item>
                <FormattedMessage {...messageKeys.PrivateTaxNumber} />:
              </Grid>
              <Grid xs={12} md={7} item>
                {loadedReportingData.partnerDetails
                  .partner_private_tax_number || 'N/A'}
              </Grid>

              <Grid xs={12} md={5} item>
                <FormattedMessage {...messageKeys.TinCountry} />:
              </Grid>
              <Grid xs={12} md={7} item>
                {loadedReportingData.partnerDetails.partner_tin_country ||
                  'N/A'}
              </Grid>

              <Grid xs={12} md={5} item>
                <FormattedMessage {...messageKeys.Address} />:
              </Grid>
              <Grid xs={12} md={7} item>
                {loadedReportingData.partnerDetails.partner_adress || 'N/A'}
              </Grid>

              <Grid xs={12} md={5} item>
                <FormattedMessage
                  {...messageKeys.CommercialRegistrationNumber}
                />
                :
              </Grid>
              <Grid xs={12} md={7} item>
                {loadedReportingData.partnerDetails
                  .partner_commercial_register_number || 'N/A'}
              </Grid>

              <Grid xs={12} md={5} item>
                <FormattedMessage {...messageKeys.FirstLastName} />:
              </Grid>
              <Grid xs={12} md={7} item>
                {loadedReportingData.partnerDetails.partner_first_name || 'N/A'}
              </Grid>

              <Grid xs={12} md={5} item>
                <FormattedMessage {...messageKeys.DateOfBirth} />:
              </Grid>
              <Grid xs={12} md={7} item>
                {loadedReportingData.partnerDetails.partner_date_of_birth ||
                  'N/A'}
              </Grid>
            </Grid>
          )}

          {loadedReportingData.partnerDetails !== undefined && (
            <TableContainer
              component={Paper}
              sx={{
                overflowX: 'scroll',
                border: theme => `1px solid ${theme.palette.grey[300]}`,
                borderRadius: '10px',
                marginTop: 2
              }}
            >
              <Table
                sx={{
                  tableLayout: 'fixed',
                  minWidth: 650
                }}
              >
                <TableHead>
                  <TableRow>
                    <HeaderTableCell sx={{ width: '10%' }} align="center">
                      <FormattedMessage {...messageKeys.ApartmentId} />
                    </HeaderTableCell>
                    <HeaderTableCell sx={{ width: '5%' }} align="center">
                      <FormattedMessage {...messageKeys.Country} />
                    </HeaderTableCell>
                    <HeaderTableCell sx={{ width: '20%' }} align="center">
                      <FormattedMessage {...messageKeys.Address} />
                    </HeaderTableCell>
                    <HeaderTableCell sx={{ width: '60%' }} align="center">
                      <FormattedMessage {...messageKeys.InfoByQuarter} />
                    </HeaderTableCell>
                  </TableRow>
                </TableHead>

                {loadedReportingData.apartmentDetails !== undefined && (
                  <TableBody>
                    {loadedReportingData.apartmentDetails.map(
                      (apartment, index) => (
                        <React.Fragment key={index}>
                          <TableRow>
                            <TableCell sx={{ width: '10%' }} align="center">
                              {apartment.apt_id}
                            </TableCell>
                            <TableCell sx={{ width: '5%' }} align="center">
                              {apartment.apt_country}
                            </TableCell>
                            <TableCell sx={{ width: '20%' }} align="center">
                              {apartment.apt_adress}
                            </TableCell>
                            <TableCell sx={{ width: '60%', padding: 0 }}>
                              <Table>
                                <TableHead>
                                  <TableRow>
                                    <HeaderTableCell
                                      sx={{ width: '20%', paddingY: 0 }}
                                    >
                                      <FormattedMessage
                                        {...messageKeys.Quarter}
                                      />
                                    </HeaderTableCell>
                                    <HeaderTableCell
                                      sx={{ width: '20%', paddingY: 0 }}
                                    >
                                      <FormattedMessage
                                        {...messageKeys.TotalPayouts}
                                      />
                                    </HeaderTableCell>
                                    <HeaderTableCell
                                      sx={{ width: '20%', paddingY: 0 }}
                                    >
                                      <FormattedMessage
                                        {...messageKeys.NumberOfBookings}
                                      />
                                    </HeaderTableCell>
                                    <HeaderTableCell
                                      sx={{ width: '20%', paddingY: 0 }}
                                    >
                                      <FormattedMessage
                                        {...messageKeys.TotalRevenue}
                                      />
                                    </HeaderTableCell>
                                    <HeaderTableCell
                                      sx={{ width: '20%', paddingY: 0 }}
                                    >
                                      <FormattedMessage
                                        {...messageKeys.AdditionalTax}
                                      />
                                    </HeaderTableCell>
                                  </TableRow>
                                </TableHead>

                                <TableBody>
                                  {Object.entries(
                                    apartment.quarter_of_booking
                                  ).map(([date, details]) => (
                                    <TableRow key={mapQuarter(date)}>
                                      <TableCell>{mapQuarter(date)}</TableCell>
                                      <TableCell>
                                        {details.total_payouts_in_quarter}
                                      </TableCell>
                                      <TableCell>
                                        {details.num_of_bookings_in_quarter}
                                      </TableCell>
                                      <TableCell>
                                        {details.total_revenue_in_quarter}
                                      </TableCell>
                                      <TableCell>
                                        {details.additional_tax}
                                      </TableCell>
                                    </TableRow>
                                  ))}
                                </TableBody>
                              </Table>
                            </TableCell>
                          </TableRow>
                        </React.Fragment>
                      )
                    )}
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          )}
        </Section>
      </Stack>
    </form>
  )
}

export default injectIntl(RegulatoryReportingPage)
