import axios from 'axios'
import { useQuery } from '@tanstack/react-query'
import QueryKeys from '../../utils/queryKeys'
import { AdyenStatusResponse } from './types'

// export const getFetchKycStatusRequest = ({ selectedProvider, companies }) => {
//   const company = companies && companies.get(selectedProvider)
//   const isPlutusProvider = Boolean(
//     company && company.get('holiduPaymentsEnabled')
//   )

//   if (!isPlutusProvider) {
//     return
//   }

//   }
// }

const useGetKycStatus = (provider: string) => {
  return useQuery<AdyenStatusResponse, Error>(
    [QueryKeys.AdyenStatus, { query: provider }],
    async () => {
      const { data } = await axios.get(`/adyen-verification-status/${provider}`)
      return data
    },
    { keepPreviousData: true, retry: false }
  )
}

export default useGetKycStatus
