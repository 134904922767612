import React from 'react'
import { createRoot } from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'

import App from './app/containers/App/index'
import { translationMessages } from './i18n'

const render = messages => {
  const container = document.getElementById('root')
  const root = createRoot(container) // createRoot(container!) if you use TypeScript
  root.render(
    <BrowserRouter>
      <App messages={messages} />
    </BrowserRouter>
  )
}

if (!window.Intl) {
  new Promise(resolve => {
    resolve(import('intl'))
  })
    .then(() =>
      Promise.all([
        import('intl/locale-data/jsonp/en.js'),
        import('intl/locale-data/jsonp/de.js'),
        import('intl/locale-data/jsonp/it.js'),
        import('intl/locale-data/jsonp/es.js'),
        import('intl/locale-data/jsonp/fr.js')
      ])
    )
    .then(() => render(translationMessages))
    .catch(err => {
      throw err
    })
} else {
  render(translationMessages)
}
