import axios from 'axios'
import { useQuery } from '@tanstack/react-query'
import { PropertyDetail } from './types'
import QueryKeys from '../../utils/queryKeys'

const useGetPropertyDetail = (provider: string, apartmentId: string) => {
  return useQuery<any, Error, PropertyDetail>(
    [QueryKeys.PropertyDetail, { query: { apartmentId } }],
    async () => {
      const { data } = await axios.get(
        `/apartments/details/${apartmentId}?provider=${provider}`
      )
      return data
    },
    { keepPreviousData: false, retry: false }
  )
}

export default useGetPropertyDetail
