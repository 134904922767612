import env from '../../env'
import { currentUserIdToken } from '../../utils/awsCognitoClient'

export const getChannelManagersRequest = () => ({
  url: `${env.BACKEND}/channelManagers`,
  transform: response => ({
    channelManagers: response
  }),
  update: {
    channelManagers: (prev, next) => next
  },
  options: {
    headers: {
      Authorization: 'Bearer ' + currentUserIdToken()
    }
  }
})

export const getAllChannelManagerConfigurationRequest = () => ({
  url: `${env.BACKEND}/channelManagers/configuration`,
  transform: response => ({
    channelManagerConfigs: response
  }),
  update: {
    channelManagerConfigs: (prev, next) => {
      return next.sortBy(cm => cm.get('name') || cm.get('identifier'))
    }
  },
  options: {
    headers: {
      Authorization: 'Bearer ' + currentUserIdToken()
    }
  }
})
