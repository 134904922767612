export const SUPPORTED_LANGUAGES = [
  {
    value: 'en-GB',
    label: 'English',
    flagCountryCode: 'GB'
  },
  {
    value: 'de-DE',
    label: 'Deutsch',
    flagCountryCode: 'DE'
  },
  {
    value: 'it-IT',
    label: 'Italian',
    flagCountryCode: 'IT'
  },
  {
    value: 'es-ES',
    label: 'Español',
    flagCountryCode: 'ES'
  },
  {
    value: 'fr-FR',
    label: 'Français',
    flagCountryCode: 'FR'
  },
  {
    value: 'nl-NL',
    label: 'Dutch',
    flagCountryCode: 'NL'
  }
]
