import React from 'react'
import PropTypes from 'prop-types'
import { Box, Divider, Paper, Typography } from '@mui/material'

const Section = props => {
  const { actions, id, subtitle, title, ...restProps } = props

  return (
    <Paper className="Section" variant="outlined" {...restProps}>
      {Boolean(title || actions) && (
        <>
          <Box
            className="SectionHeader"
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              flexWrap: 'wrap',
              minHeight: 48,
              paddingY: 1.5,
              paddingX: 2
            }}
          >
            <Box
              sx={{ flexGrow: 1, borderBottom: theme => theme.palette.divider }}
            >
              <Typography
                variant="h6"
                color="textSecondary"
                sx={{
                  fontWeight: 'bold'
                }}
              >
                {title}
              </Typography>
              <Typography variant="caption" color="textSecondary">
                {subtitle}
              </Typography>
            </Box>
            <Box>{actions}</Box>
          </Box>
          <Divider />
        </>
      )}

      <Box
        className="SectionContent"
        sx={{
          padding: 2
        }}
      >
        {props.children}
      </Box>
    </Paper>
  )
}

Section.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  title: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  subtitle: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  actions: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  sx: PropTypes.any
}

export default Section
