export const getQueriesStatus = state => {
  const hasPendingQueries = state.get('queries').some(q => q.get('isPending'))
  const backendIsDown = state.get('queries').some(q => {
    return q.has('headers') && q.get('status') === 0 && q.get('queryCount') > 0
  })
  return {
    hasPendingQueries,
    backendIsDown
  }
}
