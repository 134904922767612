import { combineReducers } from 'redux-immutable'
import { entitiesReducer, queriesReducer } from 'redux-query-immutable'
import { languageProviderReducer } from './locale/reducer'
import { providerSelectionReducer } from './providerSelection/reducer'

export default combineReducers({
  locale: languageProviderReducer,
  selectedProvider: providerSelectionReducer,
  queries: queriesReducer,
  entities: entitiesReducer
})
