import React from 'react'
import { connect } from 'react-redux'
import Loader from '../components/Loader'
import { getQueriesStatus } from '../store/query/querySelector'
import Page500 from './StaticPages/Page500'

const RenderIfAllQueriesFinished = WrappedComponent => props => {
  if (props.hasPendingQueries) {
    return <Loader />
  }
  if (props.backendIsDown) {
    return <Page500 />
  }
  return <WrappedComponent {...props} />
}

//This HOC would prevent wrapped component from rendering until all queries are finished
export const RequestsAreDone = Wrapped =>
  connect(getQueriesStatus)(RenderIfAllQueriesFinished(Wrapped))
