import { VerificationStatus } from './types'

export const STATUS_COLORS: Record<VerificationStatus, string> = {
  AWAITING_DATA: 'warning',
  DATA_PROVIDED: 'info',
  FAILED: 'error',
  INVALID_DATA: 'error',
  PASSED: 'success',
  PENDING: 'info',
  PENDING_REVIEW: 'info',
  RETRY_LIMIT_REACHED: 'error',
  UNCHECKED: 'error'
}

export const HELP_LINKS = {
  linkHelpdeskAccountHolder:
    'https://holidu4.zendesk.com/hc/en-us/articles/7241021679505-KYC-How-to-upload-Company-details',
  linkHelpdeskShareholder:
    'https://holidu4.zendesk.com/hc/en-us/articles/7241257336593-KYC-How-to-upload-ID-Photos',
  linkHelpdeskBankaccount:
    'https://holidu4.zendesk.com/hc/en-us/articles/7241097847441-KYC-How-to-upload-your-Bank-details'
}
