import axios from 'axios'
import { useQuery } from '@tanstack/react-query'
import QueryKeys from '../../utils/queryKeys'
import { AdyenVerificationResponse } from './types'
import { useSnackbar } from 'notistack'
import { useIntl } from 'react-intl'
import { messageKeys } from './messages'

// export const getFetchKycStatusRequest = ({ selectedProvider, companies }) => {
//   const company = companies && companies.get(selectedProvider)
//   const isPlutusProvider = Boolean(
//     company && company.get('holiduPaymentsEnabled')
//   )

//   if (!isPlutusProvider) {
//     return
//   }

//   }
// }

const useGetAdyenLink = (
  provider: string,
  redirectUrl: string,
  locale: string,
  enabled: boolean = false
) => {
  const { enqueueSnackbar } = useSnackbar()
  const intl = useIntl()

  return useQuery<AdyenVerificationResponse, Error>(
    [QueryKeys.AdyenVerification, { query: provider }],
    async () => {
      const { data } = await axios.get(
        `/adyen-verification-link?provider=${provider}&redirectUrl=${redirectUrl}&locale=${locale}`
      )
      return data
    },
    {
      onError: error => {
        enqueueSnackbar(
          intl.formatMessage(messageKeys.FailedToGetAdyenLink, {
            message: error.message
          }),
          {
            variant: 'error'
          }
        )
      },
      keepPreviousData: true,
      retry: false,
      enabled
    }
  )
}

export default useGetAdyenLink
