import { useQuery } from '@tanstack/react-query'
import QueryKeys from '../../utils/queryKeys'
import axios from 'axios'

export const useGetCommissionForProviderRequest = (country: string) => {
  return useQuery<any, Error, Number>(
    [QueryKeys.ProviderCommission, { query: { country } }],
    async () => {
      const { data } = await axios.get(
        `/provider/commission?country=${country}`
      )
      return data
    },
    { keepPreviousData: false, retry: 2 }
  )
}

export default useGetCommissionForProviderRequest
