import React, { useState, useEffect, useRef } from 'react'
import { Button, Stack, Grid } from '@mui/material'
import SaveIcon from '@mui/icons-material/Save'
import { FormattedMessage } from 'react-intl'

import { Map as ImmutableMap } from 'immutable'
import pick from 'lodash/pick'
import { useSnackbar } from '../../utils/snackBar'
import { startTransaction } from '../../utils/apm'
import { messageKeys as globalMessageKeys } from '../../globalMessages'
import { messageKeys } from './messages'
import CompanyLogo from './Form/CompanyLogo'
import AdditionalInformation from './Form/AdditionalInformation'
import HoliduAppearance from './Form/HoliduAppearance'
import Analytics from '../../utils/analytics'
import { INTEGRATION_BASICS_SAVE } from '../../utils/analytics/events'

const IntegrationBasicsPage = props => {
  const { showSuccessSnackbar, showErrorSnackbar } = useSnackbar(
    <FormattedMessage {...messageKeys.Title} />
  )
  const [hasChanges, setHasChanges] = useState(false)
  const [data, setData] = useState({})
  const [errors, setErrors] = useState({})
  const prevData = useRef({})
  const { saveGeneralConfiguration } = props

  useEffect(() => {
    if (props.selectedProvider) {
      const providersConfigData = props.providersConfig
        .get(props.selectedProvider, new ImmutableMap())
        .toJSON()
      const providerData = props.providers
        .get(props.selectedProvider, new ImmutableMap())
        .toJSON()

      const nextData = {
        ...pick(providersConfigData, ['name', 'shortName', 'logopath']),
        ...pick(providerData, [
          'propertiesCount',
          'locationOfProperties',
          'webSiteURL'
        ])
      }
      prevData.current = {
        ...nextData
      }
      setData(nextData)
      setErrors({})
    }
  }, [props.selectedProvider, props.providersConfig, props.providers]) //eslint-disable-line

  function onSubmit() {
    Analytics.trackEvent(INTEGRATION_BASICS_SAVE)

    if (!allMandatoryFieldsAreFilled()) {
      showErrorSnackbar(
        <FormattedMessage {...globalMessageKeys.ErrorDoNotDeleteMandatory} />
      )
      return
    }
    if (!allFilledFieldsAreValid()) {
      showErrorSnackbar(
        <FormattedMessage {...globalMessageKeys.ErrorInvalidDataInForm} />
      )
      return
    }

    const providersConfigData = props.providersConfig
      .get(props.selectedProvider, new ImmutableMap())
      .toJSON()
    const providerData = props.providers
      .get(props.selectedProvider, new ImmutableMap())
      .toJSON()

    const transaction = startTransaction('IntegrationBasicsPage#Save')

    saveGeneralConfiguration(
      {
        configuration: {
          ...providersConfigData,
          ...pick(data, ['name', 'shortName', 'logopath'])
        },
        provider: {
          ...providerData,
          ...pick(data, [
            'propertiesCount',
            'locationOfProperties',
            'webSiteURL'
          ])
        },
        logoFile: data.logoFile
      },
      () => {
        showSuccessSnackbar()
        setHasChanges(false)
        transaction.end()
      },
      error => {
        showErrorSnackbar(error.text)
        transaction.end()
      }
    )
  }

  const allMandatoryFieldsAreFilled = () => {
    let allGood = true
    Object.keys(errors).forEach(field => {
      const validationFailReason = errors[field]
      if (
        prevData.current &&
        prevData.current[field] &&
        validationFailReason === 'required'
      ) {
        allGood = false
      }
    })

    return allGood
  }

  const allFilledFieldsAreValid = () => {
    let allGood = true
    Object.keys(errors).forEach(field => {
      const validationFailReason = errors[field]
      if (validationFailReason && validationFailReason !== 'required') {
        allGood = false
      }
    })
    return allGood
  }

  const handleTextFieldChange = (e, errorReason) => {
    const { name, value } = e.target
    setData({
      ...data,
      [name]: value
    })
    setErrors({
      ...errors,
      [name]: errorReason || null
    })
    setHasChanges(true)
  }

  const handleLogoFileChange = logoFile => {
    setData({
      ...data,
      logoFile
    })
    setHasChanges(true)
  }

  return (
    <form autoComplete="off" onChange={() => setHasChanges(true)}>
      <Grid spacing={4} container>
        <Grid xs={12} lg={6} item>
          <Stack spacing={4}>
            <HoliduAppearance
              data={data}
              errors={errors}
              onChange={handleTextFieldChange}
            />
            <AdditionalInformation
              data={data}
              errors={errors}
              onChange={handleTextFieldChange}
            />
          </Stack>
        </Grid>
        {/* Photo Upload */}
        <Grid xs={12} lg={6} item>
          <CompanyLogo
            logopath={data.logopath}
            onLogoFileChange={handleLogoFileChange}
          />
        </Grid>
        <Grid xs={12} item>
          <Button
            size="large"
            color="primary"
            disabled={!hasChanges}
            startIcon={<SaveIcon />}
            variant="contained"
            fullWidth
            onClick={onSubmit}
          >
            <FormattedMessage {...globalMessageKeys.SaveButton} />
          </Button>
        </Grid>
      </Grid>
    </form>
  )
}

export default IntegrationBasicsPage
