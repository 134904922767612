import React from 'react'
import {
  Box,
  Grid,
  Skeleton,
  Typography,
  Stack,
  Paper,
  Card,
  CardContent,
  CardActions,
  Button
} from '@mui/material'
import { useIntl } from 'react-intl'
import useGetApartmentStats from '../useGetApartmentStats'
import AxiosErrorAlert from '../../../components/AxiosErrorAlert'
import { Link as RouterLink } from 'react-router-dom'
import { STATS_LIST, STATS_WITH_HISTOGRAM } from './utils'
import millify from 'millify'
import { getScoreColorLight } from '../../../utils/scoreUtil'
import CircularScore from '../../../components/Score/CicularScore'
import LinearScore from '../../../components/Score/LinearScore'
import grey from '@mui/material/colors/grey'
import Section from '../../../components/Section'
import ApartmentStatChart, { generateChartData } from './ApartmentStatChart'
import {
  BAD_COLOR,
  BAD_COLOR_DARK,
  BAD_COLOR_LIGHT,
  GOOD_COLOR,
  ROSY_COLOR
} from '../../../utils/colors'
import { messageKeys } from '../messages'
import Analytics from '../../../utils/analytics'
import LearnMoreLink from '../../../components/LearnMoreLink'
import { DASHBOARD_CLICK_HOLIDU_SCORE_LEARN_MORE } from '../../../utils/analytics/events'

const LEARN_MORE_LINK =
  'https://holidu4.zendesk.com/hc/en-us/articles/8979193621533'

type ApartmentStatsProps = {
  provider: string
}
const ApartmentStats = (props: ApartmentStatsProps): JSX.Element => {
  const { isLoading, isFetching, isError, error, data } = useGetApartmentStats(
    props.provider
  )
  const intl = useIntl()

  const handleClick = (eventName: string) => () => {
    Analytics.trackEvent(eventName)
  }

  if (isError) {
    return <AxiosErrorAlert error={error} />
  }

  return (
    <Grid spacing={2} container>
      {STATS_LIST.map(stat => {
        const errorStyles =
          !isLoading &&
          typeof stat.checkError === 'function' &&
          stat.checkError(data[stat.key])
            ? {
                backgroundColor: BAD_COLOR_LIGHT,
                contrastText: BAD_COLOR_DARK,
                buttonVariant: 'contained' as 'contained',
                buttonColor: ROSY_COLOR,
                buttonColorHover: ROSY_COLOR
              }
            : {}

        return (
          <Grid key={stat.key} item xs={12} sm={6} xl={4}>
            <Card
              variant="outlined"
              sx={{
                minHeight: '160px',
                height: '100%',
                backgroundColor: errorStyles.backgroundColor
              }}
            >
              <CardContent>
                <Stack height="100%" justifyContent="space-between">
                  <Box>
                    <Typography variant="h4" color={errorStyles.contrastText}>
                      {isLoading ? <Skeleton /> : millify(data[stat.key])}
                    </Typography>
                    <Typography color={errorStyles.contrastText}>
                      {intl.formatMessage(stat.label)}
                    </Typography>
                  </Box>
                </Stack>
              </CardContent>
              {Boolean(data && data[stat.key]) && (
                <CardActions>
                  <Button
                    variant={errorStyles.buttonVariant}
                    sx={{
                      backgroundColor: errorStyles.buttonColor,
                      '&:hover': {
                        backgroundColor: errorStyles.buttonColorHover
                      }
                    }}
                    component={RouterLink}
                    to={stat.link}
                    onClick={handleClick(stat.analyticsEvent)}
                  >
                    {intl.formatMessage(stat.linkText)}
                  </Button>
                </CardActions>
              )}
            </Card>
          </Grid>
        )
      })}
      <Grid item xs={12}>
        <Paper
          variant="outlined"
          sx={{
            minHeight: '140px',
            height: '100%',
            padding: 2
          }}
        >
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} lg={4} display="flex" justifyContent="center">
              <CircularScore
                thickness={4}
                size={240}
                label={intl.formatMessage(messageKeys.statsAverageHoliduScore)}
                value={data ? data['averageHoliduScore'] : 0}
                isLoading={isFetching || isLoading}
                labelProps={{
                  sx: {
                    width: 180
                  }
                }}
                valueProps={{
                  sx: {
                    minWidth: 80
                  }
                }}
              />
            </Grid>
            <Grid item xs={12} lg={8}>
              <Section
                title={intl.formatMessage(
                  messageKeys.statsHoliduScoreComponents
                )}
                actions={
                  <LearnMoreLink
                    href={LEARN_MORE_LINK}
                    onClick={handleClick(
                      DASHBOARD_CLICK_HOLIDU_SCORE_LEARN_MORE
                    )}
                  />
                }
              >
                <Grid container spacing={1}>
                  {STATS_WITH_HISTOGRAM.map(stat => {
                    const chartData = data
                      ? generateChartData(data[stat.histogramKey])
                      : []
                    return (
                      <Grid key={stat.key} item xs={12} lg={6}>
                        <Card
                          elevation={0}
                          sx={{
                            background: data
                              ? getScoreColorLight(data[stat.key])
                              : grey[50],
                            height: '100%'
                          }}
                        >
                          <CardContent>
                            <Stack>
                              <Box height="80px">
                                <Typography variant="subtitle1">
                                  {intl.formatMessage(stat.label)}
                                </Typography>
                                <LinearScore
                                  value={data ? data[stat.key] : 0}
                                  sx={{
                                    width: '100%',
                                    height: theme => theme.spacing(2)
                                  }}
                                  TypographyProps={{
                                    sx: {
                                      fontSize: '20px'
                                    }
                                  }}
                                />
                              </Box>
                              <Box height="220px">
                                <ApartmentStatChart
                                  chartData={chartData}
                                  isLoading={isLoading || isFetching}
                                />
                              </Box>
                              <Box height="60px">
                                <Typography variant="h4">
                                  {isLoading ? (
                                    <Skeleton />
                                  ) : (
                                    <span
                                      style={{
                                        color: chartData[0]?.value
                                          ? BAD_COLOR
                                          : GOOD_COLOR
                                      }}
                                    >
                                      {millify(chartData[0]?.value)}
                                    </span>
                                  )}
                                </Typography>
                                <Typography>
                                  {intl.formatMessage(stat.lowScoreLabel)}
                                </Typography>
                              </Box>
                            </Stack>
                          </CardContent>
                          <CardActions>
                            {Boolean(chartData[0]?.value) && (
                              <Button
                                component={RouterLink}
                                to={stat.link}
                                onClick={handleClick(stat.analyticsEvent)}
                              >
                                {intl.formatMessage(
                                  messageKeys.statsVisitAparments
                                )}
                              </Button>
                            )}
                          </CardActions>
                        </Card>
                      </Grid>
                    )
                  })}
                </Grid>
              </Section>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  )
}

export default ApartmentStats
