import React from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { connectRequest } from 'redux-query-immutable-react'
import { getUserRequest } from '../../store/user/userRequest'
import { getUserSelector } from '../../store/user/userSelector'
import { updateUser } from '../../store/user/actions'
import { RequestsAreDone } from '../RequestsAreDone'
import Page from '../../components/Page'
import PageContent from '../../components/Page/PageContent'
import UserDetailsPage from './UserDetailsPage'

const UserProfile = props => {
  return (
    <Page>
      <PageContent>
        <UserDetailsPage updateUser={props.updateUser} user={props.user} />
      </PageContent>
    </Page>
  )
}

const mapDispatchToProps = (dispatch, props) => ({
  updateUser: (data, onSuccess, onError) => {
    dispatch(updateUser(data, onSuccess, onError))
  }
})

export default compose(
  connect(getUserSelector, mapDispatchToProps),
  connectRequest(getUserRequest),
  RequestsAreDone
)(UserProfile)
