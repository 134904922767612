import { FormattedMessage } from 'react-intl'
import React from 'react'
import { messageKeys } from './messages'

export const translateError = error => {
  switch (error.code) {
    case 'UsernameExistsException':
      return <FormattedMessage {...messageKeys.UserAlreadyExists} />
    case 'InvalidPasswordException':
      return <FormattedMessage {...messageKeys.PasswordIsNotCorrect} />
    case 'InvalidParameterException':
      return <FormattedMessage {...messageKeys.InvalidParametedr} />
    default:
      return error.message
  }
}
