import React, { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import {
  Grid,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  styled,
  FormHelperText,
  Typography,
  Stack,
  Button
} from '@mui/material'
import { Box } from '@mui/system'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import { messageKeys as globalMessageKeys } from '../../../globalMessages'
import { messageKeys as finalizeMessageKeys } from '../messages'
import SelectCmModal from './SelectCmModal'

const StyledFormControlLabel = styled(FormControlLabel)(({ theme }) => {
  return {
    border: '1px solid #ccc',
    borderRadius: '5px',
    padding: theme.spacing(1),
    marginBottom: theme.spacing(2),
    width: '100%'
  }
})

const StepIntegrationType = props => {
  const { channelManagerConfigs, dispatch, errors, formData } = props
  const [openCmModal, setOpenCmModal] = useState(false)

  const handleTypeChange = e => {
    dispatch({
      type: 'field',
      field: 'integrationType',
      value: e.target.value
    })

    dispatch({
      type: 'field',
      field: 'channelManager',
      value: null
    })
    dispatch({
      type: 'field',
      field: 'selectedChannelManager',
      value: null
    })

    if (e.target.value === 'cm') {
      setOpenCmModal(true)
    }
  }

  const handleCmSelect = selectedCm => {
    dispatch({
      type: 'field',
      field: 'channelManager',
      value: selectedCm
    })

    dispatch({
      type: 'field',
      field: 'selectedChannelManager',
      value: channelManagerConfigs.find(
        cm => cm.get('identifier') === selectedCm
      )
    })
  }

  const handleCmOpen = () => {
    setOpenCmModal(true)
  }

  const handleCmClose = () => {
    setOpenCmModal(false)
  }

  return (
    <>
      <Grid spacing={4} container>
        <Grid xs={12} item>
          <FormControl fullWidth error={Boolean(errors.integrationType)}>
            <RadioGroup
              aria-labelledby="integration-type-radio-group-label"
              value={formData.integrationType}
              onChange={handleTypeChange}
            >
              <StyledFormControlLabel
                value="cm"
                control={<Radio />}
                label={
                  <Stack>
                    <Typography>
                      <FormattedMessage
                        {...finalizeMessageKeys.OPTION_CONNECT_CHANNEL_MANAGER}
                      />
                    </Typography>
                    {formData.selectedChannelManager ? (
                      <Button
                        onClick={handleCmOpen}
                        color="inherit"
                        sx={{
                          justifyContent: 'flex-start',
                          textTransform: 'unset'
                        }}
                        endIcon={<KeyboardArrowDownIcon />}
                      >
                        <Stack direction="row" spacing={2} alignItems="center">
                          <Box
                            display="flex"
                            alignItems="center"
                            minHeight={48}
                            minWidth={48}
                          >
                            <img
                              style={{
                                maxHeight: '48px',
                                maxWidth: '48px'
                              }}
                              alt="Logo"
                              src={
                                formData.selectedChannelManager.get(
                                  'logoPath'
                                ) || '/static/img/empty.jpg'
                              }
                            />
                          </Box>
                          <Typography noWrap>
                            {formData.selectedChannelManager.get('name') ||
                              formData.selectedChannelManager.get('identifier')}
                          </Typography>
                        </Stack>
                      </Button>
                    ) : formData.integrationType === 'cm' ? (
                      <>
                        <Button
                          onClick={handleCmOpen}
                          color={errors.channelManager ? 'error' : 'primary'}
                          sx={{
                            justifyContent: 'flex-start',
                            textTransform: 'unset'
                          }}
                          endIcon={<KeyboardArrowDownIcon />}
                        >
                          <FormattedMessage
                            {...finalizeMessageKeys.SELECT_CHANNEL_MANAGER}
                          />
                        </Button>
                        {errors.channelManager && (
                          <FormHelperText error>
                            <FormattedMessage
                              {...globalMessageKeys.ErrorIsMandatory}
                            />
                          </FormHelperText>
                        )}
                      </>
                    ) : (
                      <FormHelperText sx={{ marginLeft: 0 }}>
                        <FormattedMessage
                          {...finalizeMessageKeys.OPTION_CONNECT_CHANNEL_MANAGER_HELP}
                        />
                      </FormHelperText>
                    )}
                  </Stack>
                }
              />
              <StyledFormControlLabel
                value="hapi"
                control={<Radio />}
                label={
                  <Stack>
                    <Typography>
                      <FormattedMessage
                        {...finalizeMessageKeys.OPTION_CONNECT_HAPI}
                      />
                    </Typography>
                    <FormHelperText sx={{ marginLeft: 0 }}>
                      <FormattedMessage
                        {...finalizeMessageKeys.OPTION_CONNECT_HAPI_HELP}
                      />
                    </FormHelperText>
                  </Stack>
                }
              />
              <StyledFormControlLabel
                value="signed"
                control={<Radio />}
                label={
                  <Stack>
                    <Typography>
                      <FormattedMessage
                        {...finalizeMessageKeys.OPTION_CONNECT_CONTRACT_SIGNED}
                      />
                    </Typography>
                    <FormHelperText sx={{ marginLeft: 0 }}>
                      <FormattedMessage
                        {...finalizeMessageKeys.OPTION_CONNECT_CONTRACT_SIGNED_HELP}
                      />
                    </FormHelperText>
                  </Stack>
                }
              />
            </RadioGroup>
            {errors.integrationType && (
              <FormHelperText>
                <FormattedMessage {...globalMessageKeys.ErrorIsMandatory} />
              </FormHelperText>
            )}
          </FormControl>
        </Grid>
      </Grid>
      <SelectCmModal
        open={openCmModal}
        onSelect={handleCmSelect}
        onClose={handleCmClose}
        channelManagerConfigs={channelManagerConfigs}
      />
    </>
  )
}

export default StepIntegrationType
