import React from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  ListItemIcon,
  ListItemText
} from '@mui/material'
import HorizontalRuleIcon from '@mui/icons-material/HorizontalRule'
import { FormattedMessage } from 'react-intl'
import { messageKeys as globalMessageKeys } from '../globalMessages'

type VatValidationErrorDialogProps = {
  vatValidationError: {
    vatNumberValid?: boolean
    nameMatches?: boolean
    locationMatches?: boolean
  }
  onVatValidationErrorClose: () => void
}

const VatValidationErrorDialog = (
  props: VatValidationErrorDialogProps
): JSX.Element => {
  const { vatValidationError, onVatValidationErrorClose } = props
  return (
    <Dialog
      onClose={onVatValidationErrorClose}
      open={Boolean(vatValidationError)}
    >
      <DialogTitle color="error">
        <FormattedMessage {...globalMessageKeys.ErrorInvalidVat} />
      </DialogTitle>
      <DialogContent>
        <List dense sx={{ color: theme => theme.palette.error.main }}>
          {!vatValidationError?.vatNumberValid && (
            <ListItem disablePadding>
              <ListItemIcon>
                <HorizontalRuleIcon color="error" />
              </ListItemIcon>
              <ListItemText
                primary={
                  <FormattedMessage
                    {...globalMessageKeys.ErrorInvalidVatNumber}
                  />
                }
              />
            </ListItem>
          )}
          {!vatValidationError?.nameMatches && (
            <ListItem disablePadding>
              <ListItemIcon>
                <HorizontalRuleIcon color="error" />
              </ListItemIcon>
              <ListItemText
                primary={
                  <FormattedMessage
                    {...globalMessageKeys.ErrorInvalidVatName}
                  />
                }
              />
            </ListItem>
          )}
          {!vatValidationError?.locationMatches && (
            <ListItem disablePadding>
              <ListItemIcon>
                <HorizontalRuleIcon color="error" />
              </ListItemIcon>
              <ListItemText
                primary={
                  <FormattedMessage
                    {...globalMessageKeys.ErrorInvalidVatLocation}
                  />
                }
              />
            </ListItem>
          )}
        </List>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          size="small"
          onClick={onVatValidationErrorClose}
        >
          <FormattedMessage {...globalMessageKeys.CloseButton} />
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default VatValidationErrorDialog
