import React from 'react'
import { Box, FormHelperText, styled } from '@mui/material'
import InfoIcon from '@mui/icons-material/Info'

const HintText = styled(FormHelperText)(({ theme }) => {
  return {
    color: theme.palette.info.main,
    marginTop: 2,
    marginLeft: 4
  }
})

const ValidationHint = props => {
  const { children } = props

  return (
    <Box display="flex" alignItems="center" ml="14px">
      <InfoIcon color="info" fontSize="10" />
      <HintText>{children}</HintText>
    </Box>
  )
}

export default ValidationHint
