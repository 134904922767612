import React from 'react'
import { Box, Collapse, Divider, Paper } from '@mui/material'
import messageKeys from '../messages'
import { messageKeys as globalMessages } from '../../../globalMessages'
import { useIntl } from 'react-intl'
import { FilterChange, FilterQueryType, SortChange, SortOrder } from '../types'
import QuerySearchField from '../../../components/QueryFields/QuerySearchField'
import FilterButton from '../../../components/FilterButton'
import { LabelValue } from '@holidu/pui-components/dist'
import {
  APARTMENTS_HIDE_FILTERS,
  APARTMENTS_SHOW_FILTERS
} from '../../../utils/analytics/events'
import Analytics from '../../../utils/analytics'
import { DEFAULT_SORT_BY, DEFAULT_SORT_ORDER } from '../utils'
import ApartmentStatusFilter from './Filters/ApartmentStatusFilter'
import HeroImageContentFilter from './Filters/HeroImageContentFilter'
import ImageLabelFilter from './Filters/ImageLabelFilter'
import SortFilter from './Filters/SortFilter'
import ScoreFilter from './Filters/ScoreFilter'
import useFilterState from './Filters/useFilterState'

type PropertiesFiltersProps = FilterQueryType & {
  totalCount: number
  onFilterChange: ({ name, value }: FilterChange) => void
  onSortChange: ({ sortBy, sortOrder }: SortChange) => void
}

const PropertiesFilters = (props: PropertiesFiltersProps): JSX.Element => {
  const {
    queryString,
    apartmentStatus,
    imageLabel,
    heroImageContent,
    lowBookabilityScore,
    lowPhotoScore,
    sortBy,
    sortOrder,
    totalCount,
    onFilterChange,
    onSortChange
  } = props

  const intl = useIntl()
  const { show, setShow, isFiltersApplied } = useFilterState(props)

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target
    onFilterChange({ name, value } as FilterChange)
  }

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target
    onFilterChange({ name, value: checked } as FilterChange)
  }

  const handleSortChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target
    const [sortBy, sortOrder] = value.split('.')
    onSortChange({ sortBy, sortOrder: sortOrder as SortOrder })
  }

  const handleFilterButtonClick = () => {
    if (show) {
      Analytics.trackEvent(APARTMENTS_HIDE_FILTERS)
    } else {
      Analytics.trackEvent(APARTMENTS_SHOW_FILTERS)
    }
    setShow(!show)
  }

  return (
    <Paper
      variant="outlined"
      sx={{
        width: '100%',
        mb: 2,
        p: 2
      }}
    >
      <Box display="flex" alignItems="center" gap={2} flexWrap="wrap">
        <QuerySearchField
          placeholder={intl.formatMessage(messageKeys.SearchText)}
          name="queryString"
          size="small"
          sx={{
            width: 480,
            flexGrow: 1
          }}
          value={queryString || ''}
          onChange={handleChange}
        />
        <Divider orientation="vertical" flexItem />
        <LabelValue
          label={intl.formatMessage(globalMessages.TOTAL_COUNT)}
          value={totalCount}
        />
        <Divider orientation="vertical" flexItem />
        <FilterButton
          open={show}
          showBadge={isFiltersApplied && !show}
          onClick={handleFilterButtonClick}
        />
      </Box>
      <Collapse in={show}>
        <Box mt={1} display="flex" alignItems="center" flexWrap="wrap">
          <Box
            width="100%"
            display="flex"
            alignItems="center"
            gap={2}
            flexWrap="wrap"
          >
            <ApartmentStatusFilter
              value={apartmentStatus || ''}
              onChange={handleChange}
            />
            <HeroImageContentFilter
              value={heroImageContent || ''}
              onChange={handleChange}
            />
            <ImageLabelFilter
              value={imageLabel || ''}
              onChange={handleChange}
            />
            <Box flexGrow={1} />
            <SortFilter
              sortBy={sortBy || DEFAULT_SORT_BY}
              sortOrder={sortOrder || DEFAULT_SORT_ORDER}
              onChange={handleSortChange}
            />
          </Box>
          <Box
            width="100%"
            display="flex"
            alignItems="center"
            gap={2}
            mt={1}
            flexWrap="wrap"
          >
            <ScoreFilter
              name="lowBookabilityScore"
              label={intl.formatMessage(
                messageKeys.ApartmentFilterLowBookabiltyScore
              )}
              checked={lowBookabilityScore || false}
              onChange={handleCheckboxChange}
            />
            <ScoreFilter
              name="lowPhotoScore"
              label={intl.formatMessage(
                messageKeys.ApartmentFilterLowPhotoScore
              )}
              checked={lowPhotoScore || false}
              onChange={handleCheckboxChange}
            />
            <Box flexGrow={1} />
          </Box>
        </Box>
      </Collapse>
    </Paper>
  )
}

export default PropertiesFilters
