import axios from 'axios'
import QueryUtils from 'query-string'
import { useQuery } from '@tanstack/react-query'
import { Bookings } from './types'
import QueryKeys from '../../utils/queryKeys'
import { getArrivalDate } from './utils'

const useBookings = (provider: string) => {
  const {
    arrivalDate,
    bookedAtFrom,
    bookedAtTo,
    page,
    size,
    searchText,
    sortBy,
    sortOrder,
    status,
    transactionId
  } = QueryUtils.parse(window.location.search)

  const queryParams = {
    page: page || 0,
    size: size || 10,
    searchText,
    transactionId,
    bookedAtFrom,
    bookedAtTo,
    ...getArrivalDate(arrivalDate),
    sortBy: sortBy || 'BOOKED_AT',
    sortOrder: sortOrder || 'DESC',
    status: status === '' ? null : status,
    provider
  }
  const query = QueryUtils.stringify(queryParams, {
    strict: true,
    skipNull: true
  })

  return useQuery<Bookings, Error>(
    [QueryKeys.Bookings, { query: query }],
    async () => {
      const { data } = await axios.get(`/bookings?${query}`)
      return data
    },
    { keepPreviousData: true, retry: false }
  )
}

export default useBookings
