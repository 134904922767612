import React from 'react'
import { Stack } from '@mui/material'
import { getScoreColor } from '../../../utils/scoreUtil'
import LinearScore from '../../../components/Score/LinearScore'
import EventIcon from '@mui/icons-material/Event'
import PhotoIcon from '@mui/icons-material/Photo'

type ScoresProps = {
  holiduScore: number
  bookabilityScore: number
  photoScore: number
}

const Scores = (props: ScoresProps): JSX.Element => {
  const { holiduScore, bookabilityScore, photoScore } = props
  return (
    <Stack alignItems="flex-end" justifyContent="center">
      {typeof holiduScore === 'number' ? (
        <LinearScore
          sx={theme => ({
            height: theme.spacing(2),
            color: getScoreColor(holiduScore)
          })}
          TypographyProps={{
            variant: 'h6'
          }}
          value={holiduScore}
        />
      ) : (
        '-'
      )}
      <Stack direction="row" spacing={1}>
        {typeof bookabilityScore === 'number' && (
          <EventIcon
            sx={{
              color: getScoreColor(bookabilityScore)
            }}
          />
        )}
        {typeof photoScore === 'number' && (
          <PhotoIcon
            sx={{
              color: getScoreColor(photoScore)
            }}
          />
        )}
      </Stack>
    </Stack>
  )
}

export default Scores
