import React from 'react'
import { EXCLUDED_QUERY_KEYS } from '../utils/analytics/constants'
import usePageView from '../utils/analytics/usePageView'

const PageViewWrapper = ({
  children
}: {
  children: React.ReactNode
}): JSX.Element => {
  usePageView(EXCLUDED_QUERY_KEYS)
  return <React.Fragment>{children}</React.Fragment>
}

export default PageViewWrapper
