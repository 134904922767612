const messageKeys = {
  CancelTitle: {
    id: 'CancellationModal:TITLE',
    defaultMessage: 'CancellationModal:TITLE'
  },
  CancelInfo: {
    id: 'CancellationModal:INFO',
    defaultMessage: 'CancellationModal:INFO'
  },
  CancelReason: {
    id: 'CancellationModal:REASON_SELECTOR',
    defaultMessage: 'CancellationModal:REASON_SELECTOR'
  },
  CancelComment: {
    id: 'CancellationModal:EXTRA_REASON',
    defaultMessage: 'CancellationModal:EXTRA_REASON'
  },
  CancelConfirmTitle: {
    id: 'CancellationModal:CANCEL_CONFIRM_TITLE',
    defaultMessage: 'CancellationModal:CANCEL_CONFIRM_TITLE'
  },
  CancelConfirmDesc: {
    id: 'CancellationModal:CANCEL_CONFIRM_DESC',
    defaultMessage: 'CancellationModal:CANCEL_CONFIRM_DESC'
  },
  CancelSuccess: {
    id: 'CancellationMessage:CANCEL_SUCCESS',
    defaultMessage: 'CancellationMessage:CANCEL_SUCCESS'
  },
  CancelError: {
    id: 'CancellationMessage:CANCEL_ERROR',
    defaultMessage: 'CancellationMessage:CANCEL_ERROR'
  }
}

export default messageKeys
