import React from 'react'
import { TextField } from '@mui/material'
import { useIntl } from 'react-intl'
import messageKeys from './messages'

type CancellationReasonFieldProps = {
  value: string
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
}

const CancellationCommentField = (props: CancellationReasonFieldProps) => {
  const { value, onChange } = props
  const intl = useIntl()
  return (
    <TextField
      fullWidth
      margin="normal"
      variant="standard"
      label={intl.formatMessage(messageKeys.CancelComment)}
      multiline
      minRows={3}
      maxRows={5}
      value={value}
      onChange={onChange}
    />
  )
}

export default CancellationCommentField
