export const messageKeys = {
  Title: {
    id: 'BookingDetails:TITLE',
    defaultMessage: 'BookingDetails:TITLE'
  },
  Header: {
    id: 'BookingDetails:HEADER',
    defaultMessage: 'BookingDetails:HEADER'
  },
  SubHeader: {
    id: 'BookingDetails:SUB_HEADER',
    defaultMessage: 'BookingDetails:SUB_HEADER'
  },
  SubHeaderRequest: {
    id: 'BookingDetails:SUB_HEADER_REQUEST',
    defaultMessage: 'BookingDetails:SUB_HEADER_REQUEST'
  },
  SubHeaderCm: {
    id: 'BookingDetails:SUB_HEADER_CM',
    defaultMessage: 'BookingDetails:SUB_HEADER_CM'
  },
  Adult: {
    id: 'BookingDetails:ADULT',
    defaultMessage: 'BookingDetails:ADULT'
  },
  AdutFromAge: {
    id: 'BookingDetails:ADULT_FROM_AGE',
    defaultMessage: 'BookingDetails:ADULT_FROM_AGE'
  },
  Child: {
    id: 'BookingDetails:CHILD',
    defaultMessage: 'BookingDetails:CHILD'
  },
  ChildAgeError: {
    id: 'BookingDetails:CHILD_AGE_ERROR',
    defaultMessage: 'BookingDetails:CHILD_AGE_ERROR'
  },
  ChildUpToAge: {
    id: 'BookingDetails:CHILD_UP_TO_AGE',
    defaultMessage: 'BookingDetails:CHILD_UP_TO_AGE'
  },
  ChildAgeRequired: {
    id: 'BookingDetails:CHILD_AGE_REQUIRED',
    defaultMessage: 'BookingDetails:CHILD_AGE_REQUIRED'
  },
  Baby: {
    id: 'BookingDetails:BABY',
    defaultMessage: 'BookingDetails:BABY'
  },
  BabyAgeError: {
    id: 'BookingDetails:BABY_AGE_ERROR',
    defaultMessage: 'BookingDetails:BABY_AGE_ERROR'
  },
  BabyUpToAge: {
    id: 'BookingDetails:BABY_UP_TO_AGE',
    defaultMessage: 'BookingDetails:BABY_UP_TO_AGE'
  },
  BabiesAgeRequired: {
    id: 'BookingDetails:BABY_AGE_REQUIRED',
    defaultMessage: 'BookingDetails:BABY_AGE_REQUIRED'
  },
  BabiesUnlimited: {
    id: 'BookingDetails:BABY_UNLIMITED',
    defaultMessage: 'BookingDetails:BABY_UNLIMITED'
  },
  OtherBookingInformationHeader: {
    id: 'BookingDetails:OTHER_BOOKING_INFO',
    defaultMessage: 'BookingDetails:OTHER_BOOKING_INFO'
  },
  OtherBookingInformationSubHeader: {
    id: 'BookingDetails:OTHER_BOOKING_INFO_SUB',
    defaultMessage: 'BookingDetails:OTHER_BOOKING_INFO_SUB'
  },
  OtherBookingInformationSubHeaderCM: {
    id: 'BookingDetails:OTHER_BOOKING_INFO_SUB_CM',
    defaultMessage: 'BookingDetails:OTHER_BOOKING_INFO_SUB_CM'
  },
  OtherBookingInformationSubHeaderRequest: {
    id: 'BookingDetails:OTHER_BOOKING_INFO_SUB_REQUEST',
    defaultMessage: 'BookingDetails:OTHER_BOOKING_INFO_SUB_REQUEST'
  },
  PhoneNumber: {
    id: 'BookingDetails:PHONE',
    defaultMessage: 'BookingDetails:PHONE'
  },
  FullAddress: {
    id: 'BookingDetails:FULL_ADDRESS',
    defaultMessage: 'BookingDetails:FULL_ADDRESS'
  },
  Comment: {
    id: 'BookingDetails:COMMENT',
    defaultMessage: 'BookingDetails:COMMENT'
  }
}
