import { Map } from 'immutable'
import { getSelectedProvider } from '../providerSelection/providerSelector'

export const getProviderConfigurationSelector = state => {
  return {
    providersConfig: state.getIn(['entities', 'providersConfig'], new Map())
  }
}

export const getGeneralConfigSelector = state => {
  const { selectedProvider } = getSelectedProvider(state)
  const provider = state.getIn(['entities', 'providers', selectedProvider])
  const integrationChannel = identifyIntegrationChannel(provider)
  return {
    providersConfig: state.getIn(['entities', 'providersConfig'], new Map()),
    integrationChannel
  }
}

const identifyIntegrationChannel = provider => {
  if (provider && provider.get('channelManager')) {
    return provider.get('channelManager')
  } else if (provider && provider.get('api')) {
    return 'API'
  } else {
    return 'Holidu will connect with me'
  }
}
