import React from 'react'
import {
  Box,
  Button,
  Typography,
  Link,
  TextField,
  Alert,
  Stack
} from '@mui/material'
import { FormattedMessage } from 'react-intl'
import { Link as RouterLink } from 'react-router-dom'
import { signUp } from '../../utils/awsCognitoClient'
import { PATH_CONFIRM, PATH_LOGIN } from '../../routes/PathConstants'
import { messageKeys as globalMessageKeys } from '../../globalMessages'
import { startTransaction } from '../../utils/apm'
import { messageKeys } from './messages'
import { translateError } from './AwsExceptionUtil'

class SignUpPage extends React.Component {
  state = {
    email: '',
    password1: '',
    password2: '',
    error: '',
    errors: {
      email: false,
      password1: false,
      password2: false
    }
  }

  cleanError = () => {
    this.setState({
      errors: {},
      error: ''
    })
  }

  onClickSignUp = async () => {
    const { email, errors, password1, password2 } = this.state

    if (!this.fieldsAreValid(password1, password2, errors, email)) {
      return
    }
    const transaction = startTransaction('SIGN_UP')
    try {
      await signUp(email, password1)
      transaction.end()
      this.props.history.replace(PATH_CONFIRM)
    } catch (err) {
      this.setState({
        error: translateError(err)
      })
      transaction.end()
    }
  }

  fieldsAreValid(password1, password2, errors, email) {
    this.setState({
      error:
        password1 !== password2 ? (
          <FormattedMessage {...messageKeys.PasswordsDontMatch} />
        ) : (
          ''
        ),
      errors: {
        ...errors,
        email: !email,
        password1: !password1 || password1.length < 8,
        password2: !password2 || password2.length < 8
      }
    })
    return (
      email &&
      password1 === password2 &&
      password1 &&
      password1.length >= 8 &&
      password2 &&
      password2.length >= 8
    )
  }

  onChangeEmail = event => {
    this.cleanError()
    this.setState({
      email: event.target.value,
      errors: { ...this.state.errors, email: !event.target.value }
    })
  }

  onChangePassword1 = event => {
    this.cleanError()
    this.setState({
      password1: event.target.value,
      errors: { ...this.state.errors, password1: !event.target.value }
    })
  }

  onChangePassword2 = event => {
    this.cleanError()
    this.setState({
      password2: event.target.value,
      errors: { ...this.state.errors, password2: !event.target.value }
    })
  }

  render() {
    const { email, error, errors, password1, password2 } = this.state

    return (
      <form>
        <Stack spacing={2} alignItems="center">
          <Typography variant="h6">
            <FormattedMessage {...messageKeys.SignUpHeader} />
          </Typography>
          <TextField
            error={errors.email}
            helperText={
              errors.email ? (
                <FormattedMessage {...globalMessageKeys.ErrorIsMandatory} />
              ) : (
                ''
              )
            }
            id="email"
            label="Email Address"
            margin="normal"
            type="email"
            fullWidth
            size="small"
            required
            value={email || ''}
            variant="outlined"
            onChange={this.onChangeEmail}
          />
          <TextField
            error={errors.password1}
            helperText={
              errors.password1 ? (
                <FormattedMessage {...globalMessageKeys.ErrorIsMandatory} />
              ) : (
                <FormattedMessage {...messageKeys.PasswordRequirements} />
              )
            }
            id="password1"
            label="Password"
            margin="normal"
            type="password"
            fullWidth
            size="small"
            required
            value={password1 || ''}
            variant="outlined"
            onChange={this.onChangePassword1}
          />
          <TextField
            error={errors.password2}
            helperText={
              errors.password2 ? (
                <FormattedMessage {...globalMessageKeys.ErrorIsMandatory} />
              ) : (
                ''
              )
            }
            id="password2"
            label="Repeat Password"
            margin="normal"
            type="password"
            fullWidth
            size="small"
            required
            value={password2 || ''}
            variant="outlined"
            onChange={this.onChangePassword2}
          />
          {this.state.error ? <Alert severity="error">{error}</Alert> : null}

          <Button
            color="primary"
            mt={2}
            variant="contained"
            fullWidth
            onClick={this.onClickSignUp}
          >
            <FormattedMessage {...messageKeys.SignUpButton} />
          </Button>

          <Box>
            <FormattedMessage {...messageKeys.AlreadyHaveAccount} />
            &nbsp;
            <Link component={RouterLink} to={PATH_LOGIN}>
              <FormattedMessage {...messageKeys.LoginHere} />
            </Link>
          </Box>
        </Stack>
      </form>
    )
  }
}

export default SignUpPage
