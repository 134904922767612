import React from 'react'
import { Button } from '@mui/material'
import useGetProvider from '../../hooks/useGetProvider'
import { getSelectedProvider } from '../../localStorage'
import ContactSupportIcon from '@mui/icons-material/ContactSupport'
import { useIntl } from 'react-intl'
import { messageKeys } from './messages'
import { getContactLink } from '../../utils/support'
import Analytics from '../../utils/analytics'
import { HEADER_CLICK_CONTACT } from '../../utils/analytics/events'

const ContactUs = (): JSX.Element => {
  const selectedProvider = getSelectedProvider()
  const intl = useIntl()
  const { data } = useGetProvider(selectedProvider)
  if (selectedProvider && data?.buzDevResponsible) {
    return (
      <Button
        sx={{ whiteSpace: 'nowrap' }}
        onClick={() => {
          Analytics.trackEvent(HEADER_CLICK_CONTACT)
        }}
        href={getContactLink(selectedProvider, data.buzDevResponsible)}
        target="_blank"
        color="inherit"
        variant="text"
        startIcon={<ContactSupportIcon />}
      >
        {intl.formatMessage(messageKeys.ContactUs)}
      </Button>
    )
  }
  return <></>
}

export default ContactUs
