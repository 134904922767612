import React from 'react'
import { TableContainer, Stack, Tooltip, IconButton } from '@mui/material'
import CloudDownloadIcon from '@mui/icons-material/CloudDownload'
import AttachmentIcon from '@mui/icons-material/Attachment'
import moment from 'moment'
import { FormattedMessage, useIntl } from 'react-intl'
import downloadFile from '../../utils/downloadFile'
import { DATE_FORMAT, DATE_TIME_FORMAT } from '../../utils/dateUtil'
import { messageKeys } from './messages'
import { Invoice, InvoiceAggregation } from './types'
import { DataTable } from '@holidu/pui-components/dist'

const getMonth = (date: string) => {
  return moment(date).format('MMMM YYYY')
}

type ReferenceProps = {
  aggregation: InvoiceAggregation
}

const Reference = ({ aggregation }: ReferenceProps): JSX.Element => {
  if (aggregation.cancelled) {
    return (
      <Stack>
        <FormattedMessage {...messageKeys.CANCELLED_AT} />
        <span>
          {moment(aggregation.cancellationDate).format(DATE_TIME_FORMAT)}
        </span>
      </Stack>
    )
  } else if (aggregation.originalAggregationId) {
    const correctedInv = aggregation.linkedInvoices.find(inv => {
      return inv.type === 'REVENUE_AGGREGATION'
    })
    return correctedInv ? (
      <Stack>
        <FormattedMessage {...messageKeys.CORRECTED_AT} />
        <span>{moment(correctedInv.correctedAt).format(DATE_TIME_FORMAT)}</span>
      </Stack>
    ) : (
      <>-</>
    )
  } else {
    return <>-</>
  }
}

type InvoicesContentProps = {
  data: InvoiceAggregation[]
}
const InvoicesContent = ({ data }: InvoicesContentProps): JSX.Element => {
  const intl = useIntl()

  const handleDownload = (invoice: Invoice) => () => {
    const path = `/bookings/invoice/${invoice.id}`
    const fileName = `Holidu_Invoice_${invoice.id}_${invoice.invoiceNumber}.pdf`
    downloadFile(path, fileName, 'application/pdf')
  }

  const handleDownloadAttachemnt = (invoice: Invoice) => () => {
    const path = `/bookings/invoice/attachment/${invoice.id}`
    const fileName = `Holidu_Invoice_${invoice.id}_${invoice.invoiceNumber}_attachment.csv`
    downloadFile(path, fileName, 'text/csv')
  }

  const columns = [
    {
      id: 'startDateInclusive',
      label: intl.formatMessage(messageKeys.INVOICE_MONTH),
      renderCell: (startDateInclusive: string) => getMonth(startDateInclusive)
    },
    {
      id: 'aggregationType',
      label: intl.formatMessage(messageKeys.TYPE)
    },
    {
      id: 'aggregationReference',
      label: intl.formatMessage(messageKeys.REFERENCE),
      renderCell: (k: any, agg: InvoiceAggregation) => (
        <Reference aggregation={agg} />
      )
    },
    {
      id: 'aggregationDate',
      label: intl.formatMessage(messageKeys.CREATION_DATE),
      renderCell: (aggregationDate: string) =>
        moment(aggregationDate).format(DATE_FORMAT)
    },
    {
      id: 'amount',
      label: intl.formatMessage(messageKeys.AMOUNT),
      renderCell: (amount: number) => <>&euro; {`${amount}`}</>,
      cellProps: { align: 'right' as 'right' }
    },
    {
      id: 'downloads',
      label: intl.formatMessage(messageKeys.DOWNLOADS),
      renderCell: (d: any, agg: InvoiceAggregation) => (
        <Stack direction="row" flexWrap={'nowrap'} spacing={2}>
          {agg.linkedInvoices.map(inv => {
            const invType = inv.type
            const text =
              invType === 'REVENUE_AGGREGATION_CANCELLATION'
                ? intl.formatMessage(messageKeys.DOWNLOAD_CANCEL_INVOICE)
                : intl.formatMessage(messageKeys.DOWNLOAD_INVOICE)

            const attachmentText =
              invType === 'REVENUE_AGGREGATION_CANCELLATION'
                ? intl.formatMessage(
                    messageKeys.DOWNLOAD_CANCEL_INVOICE_ATTACHMENT
                  )
                : intl.formatMessage(messageKeys.DOWNLOAD_INVOICE_ATTACHMENT)

            return (
              <div>
                <Tooltip title={text}>
                  <IconButton
                    size="medium"
                    onClick={handleDownload(inv)}
                    color="primary"
                    sx={{
                      border: theme =>
                        `1px solid ${theme.palette.primary.main}`,
                      marginRight: 1.5
                    }}
                  >
                    <CloudDownloadIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title={attachmentText}>
                  <IconButton
                    size="medium"
                    onClick={handleDownloadAttachemnt(inv)}
                    color="primary"
                    sx={{
                      border: theme => `1px solid ${theme.palette.primary.main}`
                    }}
                  >
                    <AttachmentIcon />
                  </IconButton>
                </Tooltip>
              </div>
            )
          })}
        </Stack>
      )
    }
  ]

  return (
    <TableContainer
      sx={{
        overflowX: 'scroll',
        border: theme => `1px solid ${theme.palette.grey[300]}`,
        borderRadius: '10px',
        marginTop: 2
      }}
    >
      <DataTable
        tableProps={{
          sx: {
            minWidth: 650
          }
        }}
        thCellProps={{
          sx: {
            backgroundColor: theme => theme.palette.grey[50]
          }
        }}
        rowKey="id"
        columns={columns}
        data={data || []}
      />
    </TableContainer>
  )
}

export default InvoicesContent
