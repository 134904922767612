import { Section } from '@holidu/pui-components/dist'
import { Box, Button, Typography } from '@mui/material'
import CancelIcon from '@mui/icons-material/Cancel'
import moment from 'moment'
import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import messageKeys from './messages'
import { BookingInfo } from './types'
import CancelBookingDialog from './CancelBookingDialog'

const checkIfBookingIsCancellable = (checkin?: string) => {
  if (checkin) {
    // Should be cancallable on or before 6th of next month
    const cancellableTill = moment(checkin).add(1, 'months').set('date', 6)
    return moment().isSameOrBefore(cancellableTill)
  }
  return false
}

type CancelBookingSectionProps = {
  bookingInfo: BookingInfo
}

const CancelBookingSection = (
  props: CancelBookingSectionProps
): JSX.Element => {
  const { bookingInfo } = props
  const intl = useIntl()
  const [openCancelBooking, setOpenCancelBooking] = useState<boolean>(false)
  const handleCancelBookingClick = () => {
    setOpenCancelBooking(true)
  }
  const handleCancelBookingClose = () => {
    setOpenCancelBooking(false)
  }

  if (bookingInfo.holiduPayment || bookingInfo.cancelledAt) {
    return <></>
  }

  const isBookingCancellable = checkIfBookingIsCancellable(bookingInfo.checkin)

  return (
    <Section title={intl.formatMessage(messageKeys.CancelButton)}>
      {!isBookingCancellable && (
        <Typography variant="body2">
          {intl.formatMessage(messageKeys.CancellationExpired)}
        </Typography>
      )}
      <Box display="flex" mt={2} flexDirection="row-reverse">
        <Button
          variant="contained"
          color="error"
          startIcon={<CancelIcon />}
          disabled={!isBookingCancellable}
          onClick={handleCancelBookingClick}
        >
          {intl.formatMessage(messageKeys.CancelButton)}
        </Button>
      </Box>

      {openCancelBooking && bookingInfo.id && (
        <CancelBookingDialog
          bookingId={bookingInfo.id}
          onClose={handleCancelBookingClose}
        />
      )}
    </Section>
  )
}

export default CancelBookingSection
