import React from 'react'
import {
  TableContainer,
  Stack,
  Tooltip,
  IconButton,
  Alert
} from '@mui/material'
import CloudDownloadIcon from '@mui/icons-material/CloudDownload'
import moment from 'moment'
import { FormattedMessage, useIntl } from 'react-intl'
import downloadFile from '../../utils/downloadFile'
import { DATE_FORMAT } from '../../utils/dateUtil'
import { messageKeys } from './messages'
import { NonHopaInvoiceAggregation } from './types'
import { DataTable } from '@holidu/pui-components/dist'
import { useSelector } from 'react-redux'
import { getSelectedProvider } from '../../store/providerSelection/providerSelector'
import Section from '../../components/Section'

type NonHopaInvoicesContentProps = {
  data: NonHopaInvoiceAggregation[]
}
const NonHopaInvoicesContent = ({
  data
}: NonHopaInvoicesContentProps): JSX.Element => {
  const { selectedProvider } = useSelector(getSelectedProvider)
  const intl = useIntl()

  const handleDownload = (folder: string, file: string) => () => {
    const encodedFolderName = encodeURIComponent(folder)
    const encodedFileName = encodeURIComponent(file)
    const path = `/invoice/non-hopa/download/${selectedProvider}?folder=${encodedFolderName}&file=${encodedFileName}`
    const fileName = `${file}.pdf`
    downloadFile(path, fileName, 'application/pdf')
  }

  const columns = [
    {
      id: 'date',
      label: intl.formatMessage(messageKeys.DATE),
      renderCell: (date: string) => moment(date).format(DATE_FORMAT)
    },
    {
      id: 'aggregationType',
      label: intl.formatMessage(messageKeys.TYPE)
    },
    {
      id: 'downloads',
      label: intl.formatMessage(messageKeys.DOWNLOADS),
      renderCell: (d: any, agg: NonHopaInvoiceAggregation) => (
        <Stack direction="row" flexWrap={'nowrap'} spacing={2}>
          {agg.files.map(file => {
            return (
              <div>
                <Tooltip title={file}>
                  <IconButton
                    size="medium"
                    onClick={handleDownload(agg.folder, file)}
                    color="primary"
                    sx={{
                      border: theme =>
                        `1px solid ${theme.palette.primary.main}`,
                      marginRight: 1.5
                    }}
                  >
                    <CloudDownloadIcon />
                  </IconButton>
                </Tooltip>
              </div>
            )
          })}
        </Stack>
      )
    }
  ]

  return (
    <Section>
      <Alert severity="info">
        <FormattedMessage {...messageKeys.INFO} />
      </Alert>
      <TableContainer
        sx={{
          overflowX: 'scroll',
          border: theme => `1px solid ${theme.palette.grey[300]}`,
          borderRadius: '10px',
          marginTop: 2
        }}
      >
        <DataTable
          tableProps={{
            sx: {
              minWidth: 650
            }
          }}
          thCellProps={{
            sx: {
              backgroundColor: theme => theme.palette.grey[50]
            }
          }}
          rowKey="id"
          columns={columns}
          data={data || []}
        />
      </TableContainer>
    </Section>
  )
}

export default NonHopaInvoicesContent
