import { useSnackbar as useNotistackSnackBar } from 'notistack'
import { useIntl } from 'react-intl'
import { messageKeys as keys } from '../globalMessages'

export const useSnackbar = page => {
  const { enqueueSnackbar } = useNotistackSnackBar()
  const intl = useIntl()
  const showSuccessSnackbar = () => {
    enqueueSnackbar(
      intl.formatMessage(keys.Success, {
        page
      }),
      {
        variant: 'success'
      }
    )
  }

  const showErrorSnackbar = error => {
    enqueueSnackbar(
      intl.formatMessage(keys.Error, {
        error
      }),
      { variant: 'error' }
    )
  }

  return { showSuccessSnackbar, showErrorSnackbar }
}
