import React from 'react'
import { LabelValue, Tray } from '@holidu/pui-components/dist'
import { Box, Grid, Typography } from '@mui/material'
import messageKeys from './messages'
import { useIntl } from 'react-intl'
import moment from 'moment'
import { DATE_TIME_FORMAT } from '../../utils/dateUtil'

type BookingStatusProps = {
  status?: string
  cancelledAt?: string
  cancellationReason?: string
}

const BookingStatusTray = (props: BookingStatusProps) => {
  const { status = '', cancelledAt, cancellationReason } = props
  const intl = useIntl()

  if (status === 'CANCELLED') {
    return (
      <Tray color="error" type="outlined">
        <Typography variant="subtitle2">
          {intl.formatMessage(messageKeys.StatusCancelled)}
        </Typography>
        <Box pt={2}>
          <Grid container>
            <Grid item xs={12} md={6}>
              <LabelValue
                label={intl.formatMessage(messageKeys.CancelledAt)}
                value={
                  cancelledAt
                    ? moment(cancelledAt).format(DATE_TIME_FORMAT)
                    : '--'
                }
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <LabelValue
                label={intl.formatMessage(messageKeys.CancellationReason)}
                value={
                  cancellationReason
                    ? intl.formatMessage({
                        id: `CancellationReason:${cancellationReason}`,
                        defaultMessage: `CancellationReason:${cancellationReason}`
                      })
                    : '--'
                }
              />
            </Grid>
          </Grid>
        </Box>
      </Tray>
    )
  }
  if (status === 'CONFIRMED') {
    return (
      <Tray color="success" type="outlined">
        <Typography variant="subtitle2">
          {intl.formatMessage(messageKeys.StatusConfirmed)}
        </Typography>
      </Tray>
    )
  }
  return (
    <Tray color="grey" type="outlined">
      <Typography variant="subtitle2">{status}</Typography>
    </Tray>
  )
}

export default BookingStatusTray
