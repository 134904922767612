import { fromJS } from 'immutable'
import { applyMiddleware, createStore } from 'redux'
import { queryMiddleware } from 'redux-query-immutable'
import { composeWithDevTools } from 'redux-devtools-extension'
import thunk from 'redux-thunk'
import superagentInterface from 'redux-query-immutable-interface-superagent'
import rootReducer from './reducer'

const configureStore = initialStore => {
  const middleware = [
    queryMiddleware(
      superagentInterface,
      state => state.get('queries'),
      state => state.get('entities')
    ),
    thunk
  ]

  const enhancer = applyMiddleware(...middleware)

  if (process.env.NODE_ENV === 'development') {
    // Should not even be loaded in production
    return createStore(
      rootReducer,
      fromJS(initialStore),
      composeWithDevTools(enhancer)
    )
  } else {
    return createStore(rootReducer, fromJS(initialStore), enhancer)
  }
}

export default configureStore
