import React, { useEffect, useState } from 'react'
import {
  Checkbox,
  Stack,
  Grid,
  Link,
  FormControlLabel,
  Typography
} from '@mui/material'
import { FormattedMessage, injectIntl } from 'react-intl'
import { messageKeys } from '../messages'
import { messageKeys as globalMessageKeys } from '../../../globalMessages'
import { useIntl } from 'react-intl'

const CommercialAgreementForm = (props: any): JSX.Element => {
  const {
    commercialAgreementSigned,
    commission,
    updateCommercialAgreementSignature,
    errors,
    disabled
  } = props

  const EN_T_C =
    'https://holidu.s3.eu-west-1.amazonaws.com/live/231123_Holidu_Provider_T%26Cs_ENG.pdf'
  const DE_T_C =
    'https://holidu.s3.eu-west-1.amazonaws.com/live/231122_Holidu_Provider_T%26Cs_German_final.pdf'

  const intl = useIntl()
  const [commercialAgreementSignedData, setCommercialAgreementSignedData] =
    useState(false)
  const locale = localStorage.getItem('locale') || 'en-GB'

  const handleTCChange = (e: any) => {
    setCommercialAgreementSignedData(e.target.checked)
    let newSignature = {
      commercialAgreementSigned: e.target.checked,
      commission: commission
    }

    updateCommercialAgreementSignature(newSignature)
  }

  useEffect(() => {
    if (commercialAgreementSigned) {
      setCommercialAgreementSignedData(commercialAgreementSigned)
    }
  }, [commercialAgreementSigned])

  return (
    <Grid item xs={12} lg={12} xl={12}>
      <Stack spacing={2}>
        <Grid xs={12} lg={12} item>
          <FormattedMessage
            {...messageKeys.CommercialAgreementTCDescription}
            values={{
              link: (
                <Link
                  href={locale === 'de-DE' ? DE_T_C : EN_T_C}
                  target="_blank"
                >
                  <FormattedMessage {...messageKeys.HolidusTC} />
                </Link>
              ),
              commission: commission
            }}
          />
        </Grid>
        <Grid xs={12} lg={12} item>
          <FormControlLabel
            control={
              <Checkbox
                name={'commercialAgreementSigned'}
                checked={commercialAgreementSignedData}
                disabled={disabled}
                onChange={handleTCChange}
              />
            }
            label={
              <FormattedMessage
                {...messageKeys.CommercialAgreementTCCheckbox}
              />
            }
          />
        </Grid>
        {errors && errors['commercialAgreementSigned'] && (
          <Grid xs={12} lg={12} item>
            <Typography component="span" variant="subtitle2" color="error">
              {intl.formatMessage(globalMessageKeys.ErrorIsMandatory)}
            </Typography>
          </Grid>
        )}
      </Stack>
    </Grid>
  )
}

export default injectIntl(CommercialAgreementForm)
