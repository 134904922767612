import React from 'react'
import { MenuItem, TextField } from '@mui/material'
import { useIntl } from 'react-intl'
import messageKeys from '../../messages'
import { HERO_IMAGE_FILTER_OPTIONS } from '../../utils'

type ImageLabelFilterProps = {
  value: string
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
}

const ImageLabelFilter = (props: ImageLabelFilterProps): JSX.Element => {
  const { value, onChange } = props
  const intl = useIntl()
  return (
    <TextField
      label={intl.formatMessage(messageKeys.ImageLabelFilter)}
      margin="dense"
      name="imageLabel"
      variant="standard"
      sx={{
        width: 240
      }}
      value={value}
      onChange={onChange}
      select
    >
      <MenuItem value="">
        {intl.formatMessage(messageKeys.ImageLabelFilterAll)}
      </MenuItem>
      {HERO_IMAGE_FILTER_OPTIONS.map(option => (
        <MenuItem key={option} value={option}>
          {option}
        </MenuItem>
      ))}
    </TextField>
  )
}

export default ImageLabelFilter
