import React from 'react'
import moment from 'moment'
import {
  Box,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material'
import { useIntl } from 'react-intl'
import { DATE_FORMAT } from '../../utils/dateUtil'
import messageKeys from './messages'
import { ProviderPayoutSchedule } from './types'
import get from 'lodash/get'
import { Section, Tray } from '@holidu/pui-components/dist'
import { getAmountCurrencyText } from '../../utils/currencyUtil'

const getTotal = (providerPayoutSchedule: ProviderPayoutSchedule[]) => {
  const totalAmount = providerPayoutSchedule.reduce(
    (total, item) => total + get(item, 'amount', 0),
    0
  )
  return getAmountCurrencyText(
    totalAmount,
    get(providerPayoutSchedule, [0, 'currency'])
  )
}

type PayoutScheduleSectionProps = {
  providerPayoutSchedule: ProviderPayoutSchedule[]
}
const PayoutScheduleSection = (
  props: PayoutScheduleSectionProps
): JSX.Element => {
  const { providerPayoutSchedule } = props
  const intl = useIntl()

  return (
    <Section title={intl.formatMessage(messageKeys.PayoutSchedule)}>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                {intl.formatMessage(messageKeys.PayoutScheduleType)}
              </TableCell>
              <TableCell>
                {intl.formatMessage(messageKeys.PayoutScheduleDate)}
              </TableCell>
              <TableCell>
                {intl.formatMessage(messageKeys.PayoutScheduleStatus)}
              </TableCell>
              <TableCell align="right">
                {intl.formatMessage(messageKeys.PayoutScheduleAmount)}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {providerPayoutSchedule.length ? (
              <>
                {providerPayoutSchedule.map(item => {
                  return (
                    <TableRow key={item.id}>
                      <TableCell>{item.type}</TableCell>
                      <TableCell>
                        {moment(item.dueDate).format(DATE_FORMAT)}
                      </TableCell>
                      <TableCell>{item.status}</TableCell>
                      <TableCell align="right">
                        {getAmountCurrencyText(item.amount, item.currency)}
                      </TableCell>
                    </TableRow>
                  )
                })}
              </>
            ) : (
              <TableRow>
                <TableCell colSpan={4} align="center">
                  {intl.formatMessage(messageKeys.PayoutScheduleNoProvider)}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Box pt={2}>
        <Tray color="primary">
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="subtitle2">
              {intl.formatMessage(messageKeys.PayoutScheduleTotal)}
            </Typography>
            <Typography variant="subtitle2">
              {getTotal(providerPayoutSchedule)}
            </Typography>
          </Stack>
        </Tray>
      </Box>
    </Section>
  )
}

export default PayoutScheduleSection
