const messageKeys = {
  Title: {
    id: 'LivePropertyKeys:TITLE',
    defaultMessage: 'LivePropertyKeys:TITLE'
  },
  SearchText: {
    id: 'LivePropertyKeys:SEARCH_TEXT',
    defaultMessage: 'LivePropertyKeys:SEARCH_TEXT'
  },
  ImageLabelFilter: {
    id: 'LivePropertyKeys:IMAGE_LABEL_FILTER',
    defaultMessage: 'LivePropertyKeys:IMAGE_LABEL_FILTER'
  },
  ImageLabelFilterAll: {
    id: 'LivePropertyKeys:IMAGE_LABEL_FILTER_ALL',
    defaultMessage: 'LivePropertyKeys:IMAGE_LABEL_FILTER_ALL'
  },
  ApartmentStatusFilter: {
    id: 'LivePropertyKeys:APARTMENT_STATUS_FILTER',
    defaultMessage: 'LivePropertyKeys:APARTMENT_STATUS_FILTER'
  },
  ApartmentStatusFilterAll: {
    id: 'LivePropertyKeys:APARTMENT_STATUS_FILTER_ALL',
    defaultMessage: 'LivePropertyKeys:APARTMENT_STATUS_FILTER_ALL'
  },
  ApartmentFilterHeroImageContent: {
    id: 'LivePropertyKeys:APARTMENT_FILTER_HERO_IMAGE_CONTENT',
    defaultMessage: 'LivePropertyKeys:APARTMENT_FILTER_HERO_IMAGE_CONTENT'
  },
  ApartmentFilterHeroImageAll: {
    id: 'LivePropertyKeys:APARTMENT_FILTER_HERO_IMAGE_CONTENT_ALL',
    defaultMessage: 'LivePropertyKeys:APARTMENT_FILTER_HERO_IMAGE_CONTENT_ALL'
  },
  ApartmentFilterLowBookabiltyScore: {
    id: 'LivePropertyKeys:APARTMENT_FILTER_LOW_BOOKABILTY_SCORE',
    defaultMessage: 'LivePropertyKeys:APARTMENT_FILTER_LOW_BOOKABILTY_SCORE'
  },
  ApartmentFilterLowPhotoScore: {
    id: 'LivePropertyKeys:APARTMENT_FILTER_LOW_PHOTO_SCORE',
    defaultMessage: 'LivePropertyKeys:APARTMENT_FILTER_LOW_PHOTO_SCORE'
  },
  Sort: {
    id: 'LivePropertyKeys:SORT',
    defaultMessage: 'LivePropertyKeys:SORT'
  },
  SortPriceAsc: {
    id: 'LivePropertyKeys:SORT_PRICE_ASC',
    defaultMessage: 'LivePropertyKeys:SORT_PRICE_ASC'
  },
  SortPriceDesc: {
    id: 'LivePropertyKeys:SORT_PRICE_DESC',
    defaultMessage: 'LivePropertyKeys:SORT_PRICE_DESC'
  },
  SortHoliduScoreAsc: {
    id: 'LivePropertyKeys:SORT_HOLIDU_SCORE_ASC',
    defaultMessage: 'LivePropertyKeys:SORT_HOLIDU_SCORE_ASC'
  },
  SortHoliduScoreDesc: {
    id: 'LivePropertyKeys:SORT_HOLIDU_SCORE_DESC',
    defaultMessage: 'LivePropertyKeys:SORT_HOLIDU_SCORE_DESC'
  },
  SortLastUpdatedAsc: {
    id: 'LivePropertyKeys:SORT_LAST_UPDATED_ASC',
    defaultMessage: 'LivePropertyKeys:SORT_LAST_UPDATED_ASC'
  },
  SortLastUpdatedDesc: {
    id: 'LivePropertyKeys:SORT_LAST_UPDATED_DESC',
    defaultMessage: 'LivePropertyKeys:SORT_LAST_UPDATED_DESC'
  },
  TotalCount: {
    id: 'LivePropertyKeys:TOTAL_COUNT',
    defaultMessage: 'LivePropertyKeys:TOTAL_COUNT'
  },
  DownloadCsv: {
    id: 'LivePropertyKeys:DOWNLOAD_CSV',
    defaultMessage: 'LivePropertyKeys:DOWNLOAD_CSV'
  },
  DownloadCsvLimit: {
    id: 'LivePropertyKeys:DOWNLOAD_CSV_LIMIT',
    defaultMessage: 'LivePropertyKeys:DOWNLOAD_CSV_LIMIT'
  },
  ApartmentId: {
    id: 'LivePropertyKeys:APARTMENT_ID',
    defaultMessage: 'LivePropertyKeys:APARTMENT_ID'
  },
  ApartmentHoliduId: {
    id: 'LivePropertyKeys:APARTMENT_HOLIDU_ID',
    defaultMessage: 'LivePropertyKeys:APARTMENT_HOLIDU_ID'
  },
  ApartmentType: {
    id: 'LivePropertyKeys:APARTMENT_TYPE',
    defaultMessage: 'LivePropertyKeys:APARTMENT_TYPE'
  },
  ApartmentPersons: {
    id: 'LivePropertyKeys:APARTMENT_PERSONS',
    defaultMessage: 'LivePropertyKeys:APARTMENT_PERSONS'
  },
  ApartmentRooms: {
    id: 'LivePropertyKeys:APARTMENT_ROOMS',
    defaultMessage: 'LivePropertyKeys:APARTMENT_ROOMS'
  },
  ApartmentSize: {
    id: 'LivePropertyKeys:APARTMENT_SIZE',
    defaultMessage: 'LivePropertyKeys:APARTMENT_SIZE'
  },
  ApartmentPrice: {
    id: 'LivePropertyKeys:APARTMENT_PRICE',
    defaultMessage: 'LivePropertyKeys:APARTMENT_PRICE'
  },
  ApartmentPerNight: {
    id: 'LivePropertyKeys:APARTMENT_PER_NIGHT',
    defaultMessage: 'LivePropertyKeys:APARTMENT_PER_NIGHT'
  },
  ApartmentPriceNotAvailaible: {
    id: 'LivePropertyKeys:APARTMENT_PRICE_NOT_AVAILABLE',
    defaultMessage: 'LivePropertyKeys:APARTMENT_PRICE_NOT_AVAILABLE'
  },
  ApartmentPhotos: {
    id: 'LivePropertyKeys:APARTMENT_PHOTOS',
    defaultMessage: 'LivePropertyKeys:APARTMENT_PHOTOS'
  },
  ApartmentShowOnHolidu: {
    id: 'LivePropertyKeys:SHOW_ON_HOLIDU',
    defaultMessage: 'LivePropertyKeys:SHOW_ON_HOLIDU'
  },
  ApartmentHoliduScore: {
    id: 'LivePropertyKeys:APARTMENT_HOLIDU_SCORE',
    defaultMessage: 'LivePropertyKeys:APARTMENT_HOLIDU_SCORE'
  },
  EmptyPropertySubtitle: {
    id: 'LivePropertyKeys:EMPTY_PROPERTY_SUBTITLE',
    defaultMessage: 'LivePropertyKeys:EMPTY_PROPERTY_SUBTITLE'
  },
  DownloadCsvSuccess: {
    id: 'LivePropertyKeys:DOWNLOAD_CSV_SUCCESS',
    defaultMessage: 'LivePropertyKeys:DOWNLOAD_CSV_SUCCESS'
  },
  DownloadCsvError: {
    id: 'LivePropertyKeys:DOWNLOAD_CSV_ERROR',
    defaultMessage: 'LivePropertyKeys:DOWNLOAD_CSV_ERROR: {message}'
  },
  DisplayLimit: {
    id: 'LivePropertyKeys:DISPLAY_LIMIT',
    defaultMessage: 'LivePropertyKeys:DISPLAY_LIMIT'
  },
  InactiveApartmentsAlert: {
    id: 'LivePropertyKeys:INACTIVE_APARTMENTS_ALERT',
    defaultMessage: 'LivePropertyKeys:INACTIVE_APARTMENTS_ALERT: { count }'
  },
  InactiveApartmentsButton: {
    id: 'LivePropertyKeys:INACTIVE_APARTMENTS_BUTTON',
    defaultMessage: 'LivePropertyKeys:INACTIVE_APARTMENTS_BUTTON'
  }
}

export default messageKeys
