import React, { useEffect, useReducer } from 'react'
import { Button, Checkbox, FormControlLabel, Stack } from '@mui/material'
import SaveIcon from '@mui/icons-material/Save'
import { FormattedMessage } from 'react-intl'
import { Map } from 'immutable'

import { messageKeys as globalMessageKeys } from '../../globalMessages'
import { useSnackbar } from '../../utils/snackBar'
import Section from '../../components/Section'
import { messageKeys } from './messages'

import CustomerInformation from './Form/CustomerInformation'
import GuestCategoriesBaby from './Form/GuestCategoriesBaby'
import GuestCategoriesChild from './Form/GuestCategoriesChild'
import { reducer } from './reducer'
import Analytics from '../../utils/analytics'
import { BOOKING_CONFIGURATION_SAVE } from '../../utils/analytics/events'

const BookingConfigurationPage = props => {
  const bookingDetails = props.bookingsDetail
    .get(props.selectedProvider, Map({}))
    .toJSON()

  const kidsAreSupported = bookingDetails => {
    const adultAgeIsNotZeroAndItsNotBecauseOfBabies =
      bookingDetails.adultFromAge &&
      bookingDetails.adultFromAge !== 0 &&
      !bookingDetails.babiesSupported
    const babiesAndKidsArePresent = !!bookingDetails.kidsFromAge

    return adultAgeIsNotZeroAndItsNotBecauseOfBabies || babiesAndKidsArePresent
  }

  const calculateKidsUpToAge = bookingDetails => {
    if (kidsAreSupported(bookingDetails)) {
      return Number(bookingDetails.adultFromAge) - 1
    }
    return ''
  }

  const calculateBabiesUpToAge = bookingDetails => {
    if (bookingDetails.babiesSupported) {
      if (bookingDetails.kidsFromAge) {
        return Number(bookingDetails.kidsFromAge) - 1
      } else {
        return Number(bookingDetails.adultFromAge) - 1
      }
    }
    return ''
  }

  const { showSuccessSnackbar, showErrorSnackbar } = useSnackbar(
    <FormattedMessage {...messageKeys.Title} />
  )
  const [state, dispatch] = useReducer(reducer, {
    kidsSupported: false,
    kidsUpToAge: 0,
    kidsAgeRequired: false,
    babiesSupported: false,
    babiesUpToAge: 0,
    babiesAgeRequired: false,
    babiesUnlimited: false,
    phoneNumberRequired: false,
    fullAddressRequired: false,
    commentRequired: false,
    errors: {},
    hasChanges: false,
    updateBookingConfigurationDetails: props.updateBookingConfigurationDetails,
    showSuccessSnackbar,
    showErrorSnackbar
  })

  useEffect(() => {
    dispatch({
      type: 'init',
      payload: {
        kidsSupported: kidsAreSupported(bookingDetails),
        kidsUpToAge: calculateKidsUpToAge(bookingDetails),
        kidsAgeRequired: bookingDetails.kidsAgesRequired,
        babiesSupported: bookingDetails.babiesSupported,
        babiesUpToAge: calculateBabiesUpToAge(bookingDetails),
        babiesAgeRequired: bookingDetails.babiesAgesRequired,
        babiesUnlimited: bookingDetails.unlimitedBabies,
        phoneNumberRequired: bookingDetails.phoneNumberRequired,
        fullAddressRequired: bookingDetails.fullAddressRequired,
        commentRequired: bookingDetails.commentRequired
      }
    })
  }, [props.selectedProvider]) //eslint-disable-line

  const validateForm = () => {
    const newErrors = {}
    if (state.kidsSupported) {
      if (!state.kidsUpToAge) {
        newErrors.kidsUpToAge = (
          <FormattedMessage {...globalMessageKeys.ErrorIsMandatory} />
        )
      } else if (
        state.kidsSupported &&
        (state.kidsUpToAge < 1 || state.kidsUpToAge > 25)
      ) {
        newErrors.kidsUpToAge = (
          <FormattedMessage {...messageKeys.ChildAgeError} />
        )
      }
    }
    if (state.babiesSupported) {
      if (!state.babiesUpToAge) {
        newErrors.babiesUpToAge = (
          <FormattedMessage {...globalMessageKeys.ErrorIsMandatory} />
        )
      } else if (
        state.babiesUpToAge < 1 ||
        state.babiesUpToAge > 10 ||
        (!!state.kidsUpToAge &&
          Number(state.kidsUpToAge) <= Number(state.babiesUpToAge))
      ) {
        newErrors.babiesUpToAge = (
          <FormattedMessage {...messageKeys.BabyAgeError} />
        )
      }
    }
    return Object.entries(newErrors).length > 0 ? newErrors : null
  }

  const saveForm = () => {
    Analytics.trackEvent(BOOKING_CONFIGURATION_SAVE)
    const newErrors = validateForm()
    if (newErrors) {
      dispatch({ type: 'errors', payload: newErrors })
      return
    }
    dispatch({ type: 'saveBookingDetails' })
  }

  const disableAgesFields =
    bookingDetails.guestAdditionalInfoSource &&
    bookingDetails.guestAdditionalInfoSource !== 'subProvider'
  const disableAgesRequiredFields =
    bookingDetails.guestFieldsSource &&
    bookingDetails.guestFieldsSource !== 'subProvider'

  return (
    <Stack spacing={4}>
      <Section
        title={<FormattedMessage {...messageKeys.Header} />}
        subtitle={
          bookingDetails.guestAdditionalInfoSource === 'default' ? (
            <FormattedMessage {...messageKeys.SubHeaderCm} />
          ) : bookingDetails.guestAdditionalInfoSource === 'request' ? (
            <FormattedMessage {...messageKeys.SubHeaderRequest} />
          ) : (
            <FormattedMessage {...messageKeys.SubHeader} />
          )
        }
      >
        <Stack spacing={2}>
          <FormControlLabel
            control={<Checkbox name="ADULT" checked disabled />}
            label={<FormattedMessage {...messageKeys.Adult} />}
          />
          <GuestCategoriesChild
            {...state}
            disableAgesFields={disableAgesFields}
            disableAgesRequiredFields={disableAgesRequiredFields}
            dispatch={dispatch}
          />
          <GuestCategoriesBaby
            {...state}
            disableAgesFields={disableAgesFields}
            disableAgesRequiredFields={disableAgesRequiredFields}
            dispatch={dispatch}
          />
        </Stack>
      </Section>
      <CustomerInformation
        {...state}
        bookingDetails={bookingDetails}
        dispatch={dispatch}
      />
      <Button
        color="primary"
        size="large"
        disabled={!state.hasChanges}
        startIcon={<SaveIcon />}
        variant="contained"
        onClick={saveForm}
      >
        <FormattedMessage {...globalMessageKeys.SaveButton} />
      </Button>
    </Stack>
  )
}

export default BookingConfigurationPage
