import React, { useEffect } from 'react'
import { Redirect } from 'react-router-dom'
import { clearLocalStorage } from '../../localStorage'
import Analytics from '../../utils/analytics'
import { isUserAuthenticated } from '../../utils/awsCognitoClient'
import { getTargetUrlWithCurrentLocation } from '../../utils/redirectUrl'
import PageViewWrapper from '../PageViewWrapper'

const AWSCognitoAuthenticatedFilter = ({
  children,
  redirectWhenNotAuthenticated
}) => {
  const isAuthenticated = isUserAuthenticated()

  useEffect(() => {
    if (!isAuthenticated) {
      clearLocalStorage()
      Analytics.reset()
    } else {
      Analytics.init()
    }
  }, [isAuthenticated])
  if (isAuthenticated) {
    return <PageViewWrapper>{children}</PageViewWrapper>
  }

  const redirectToLoginWithRedirectUrl = getTargetUrlWithCurrentLocation(
    redirectWhenNotAuthenticated
  )

  return <Redirect to={redirectToLoginWithRedirectUrl} />
}

export default AWSCognitoAuthenticatedFilter
