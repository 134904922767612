import React from 'react'
import { useIntl } from 'react-intl'
import messageKeys from '../messages'
import { PropertyDetail } from '../types'
import Section from '../../../components/Section'
import { Box, Chip, Stack } from '@mui/material'

import millify from 'millify'
import LearnMoreLink from '../../../components/LearnMoreLink'
import SubScore from './Subscore'
import Opportunities from './Opportunities'
import { APARTMENT_DETAIL_CLICK_PHOTO_SCORE_LEARN_MORE } from '../../../utils/analytics/events'
import Analytics from '../../../utils/analytics'
import LinearScore from '../../../components/Score/LinearScore'
import { getScoreColor } from '../../../utils/scoreUtil'

const MAX_AVAILABLE_PHOTOS = 15
const MAX_AVG_RESOLUTION = 1000000

type PhotoScoreSectionProps = {
  data: PropertyDetail
}

const LEARN_MORE_LINK =
  'https://holidu4.zendesk.com/hc/en-us/articles/7756619687965'

const PhotoScoreSection = (props: PhotoScoreSectionProps): JSX.Element => {
  const { data } = props
  const intl = useIntl()

  const handleLearnMoreClick = () => {
    Analytics.trackEvent(APARTMENT_DETAIL_CLICK_PHOTO_SCORE_LEARN_MORE)
  }

  let downloadedPhotoRate =
    (data.availablePhotos / (data.availablePhotos + data.photosWithErrors)) *
    100
  if (!Number.isFinite(downloadedPhotoRate)) {
    downloadedPhotoRate = 0
  }
  return (
    <Section
      sx={{ height: '100%' }}
      title={intl.formatMessage(messageKeys.APARTMENT_PHOTO_SCORE)}
      actions={<LearnMoreLink href={LEARN_MORE_LINK} onClick={handleLearnMoreClick} />}
    >
      <Stack
        spacing={2}
        sx={{
          minHeight: 240
        }}
      >
        <LinearScore
          sx={theme => ({
            height: theme.spacing(3),
            color: getScoreColor(data.photoScore)
          })}
          value={data.photoScore}
          TypographyProps={{
            variant: "h5"
          }}
        />
        <SubScore
          label={intl.formatMessage(messageKeys.AVAILABLE_PHOTOS)}
          value={data.availablePhotos}
          progressValue={(data.availablePhotos / MAX_AVAILABLE_PHOTOS) * 100}
        />
        <SubScore
          label={intl.formatMessage(messageKeys.DOWNLOADED_PHOTO_RATE)}
          value={`${downloadedPhotoRate}%`}
          progressValue={downloadedPhotoRate}
        />
        <SubScore
          label={intl.formatMessage(messageKeys.AVG_RESOLUTION)}
          value={millify(data.avgResolution)}
          progressValue={(data.avgResolution / MAX_AVG_RESOLUTION) * 100}
        />
        <SubScore
          label={intl.formatMessage(messageKeys.MAIN_IMAGE_CONTENT)}
          value={
            <Box width="100%" textAlign="right">
              <Chip
                size="small"
                label={data.mainPhotoStatus}
                color={
                  data.mainPhotoStatus === 'GOOD'
                    ? 'success'
                    : data.mainPhotoStatus === 'BAD'
                    ? 'error'
                    : 'default'
                }
              />
            </Box>
          }
        />
      </Stack>
      {!!data.photoScoreOpportunities?.length && (
        <Box>
          <Opportunities values={data.photoScoreOpportunities} />
        </Box>
      )}
    </Section>
  )
}

export default PhotoScoreSection
