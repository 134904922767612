import React from 'react'
import { Box, Paper, Skeleton, Typography } from '@mui/material'
import green from '@mui/material/colors/green'
import red from '@mui/material/colors/red'
import orange from '@mui/material/colors/orange'
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Cell,
  ResponsiveContainer
} from 'recharts'
import { FormattedMessage } from 'react-intl'
import { messageKeys } from '../messages'

enum ChartDataItemCategory {
  LOW = 'LOW',
  MEDIUM = 'MEDIUM',
  HIGH = 'HIGH'
}

type ChartDataItem = { name: keyof typeof ChartDataItemCategory; value: number }

export type ChartData = ChartDataItem[]

type ApartmentStatChartProps = {
  chartData: ChartData,
  isLoading: boolean
}

export const generateChartData = (data: number[]): ChartData => {
  const total: ChartData = [
    {
      name: 'LOW',
      value: 0
    },
    {
      name: 'MEDIUM',
      value: 0
    },
    {
      name: 'HIGH',
      value: 0
    }
  ]
  return data.reduce((total, d, index) => {
    if (index <= 2) {
      total[0].value += d
    } else if (index <= 6) {
      total[1].value += d
    } else {
      total[2].value += d
    }
    return total
  }, total)
}

const FILL_COLORS = [red[600], orange[600], green[600]]
const STROKE_COLORS = [red[800], orange[800], green[800]]

const CustomTooltip = ({
  active,
  payload,
  label
}: {
  active: any
  payload: any
  label: any
}) => {
  if (active && payload && payload.length) {
    return (
      <Paper variant="outlined" sx={{ padding: 2 }}>
        <Typography variant="caption" component="p">
          <strong>
            <FormattedMessage {...messageKeys.statsTooltipRange} />
            {': '}
          </strong>
          {label}
        </Typography>
        <Typography variant="caption" component="p">
          <strong>
            <FormattedMessage {...messageKeys.statsTooltipApartments} />
            {': '}
          </strong>
          {payload[0].value}
        </Typography>
      </Paper>
    )
  }

  return null
}

const ApartmentStatChart = (props: ApartmentStatChartProps) => {
  const { chartData, isLoading } = props

  if (isLoading) {
    return (
      <Skeleton
        variant="rectangular"
        width="100%"
        height="100%"
        sx={{
          minHeight: 260
        }}
      />
    )
  }
  if (chartData) {

    return (
      <Box>
        <Box height="160px" width="100%" minHeight={200}>
          <ResponsiveContainer width="100%" height="100%">
            <BarChart
              data={chartData}
              margin={{ top: 20, right: 0, left: 0, bottom: 0 }}
            >
              <XAxis
                dataKey="name"
                type="category"
                interval={0}
                fontSize={10}
                height={16}
              />
              <YAxis
                type="number"
                fontSize={10}
                // display="none"
                domain={[0, (dataMax: number) => dataMax]}
              />
              <Tooltip
                // @ts-ignore
                content={<CustomTooltip />}
              />
              <Bar dataKey="value" isAnimationActive={false}>
                {chartData.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={FILL_COLORS[index]}
                    stroke={STROKE_COLORS[index]}
                  />
                ))}
              </Bar>
            </BarChart>
          </ResponsiveContainer>
        </Box>
      </Box>
    )
  }
  return null
}

export default ApartmentStatChart
