const sanitizeData = obj => {
  try {
    return JSON.parse(
      JSON.stringify(obj, (key, value) => {
        if (typeof value === 'string') {
          return value.trim()
        }
        return value
      })
    )
  } catch (err) {
    console.log(err)
  }
  return obj
}

export default sanitizeData
