import React from 'react'
import { ThemeProvider, CssBaseline, IconButton, Box } from '@mui/material'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'

import { Provider } from 'react-redux'
import lodash from 'lodash'
import { Route, Switch } from 'react-router-dom'
import { Provider as ReduxQueryProvider } from 'redux-query-immutable-react'
import { Redirect } from 'react-router'
import { SnackbarProvider } from 'notistack'
import CloseIcon from '@mui/icons-material/Close'
import { PuiTheme } from '@holidu/pui-components/dist'
import AWSCognitoAuthenticatedRoute from '../../routes/AWSCognitoAuthenticatedFilter'
import LanguageProvider from '../LanguageProvider'
import ConfirmPage from '../AuthPages/ConfirmPage'
import SignUpPage from '../AuthPages/SignUpPage'
import SignInPage from '../AuthPages/SignInPage'
import ResetPasswordPage from '../AuthPages/ResetPasswordPage'
import ChangePasswordPage from '../AuthPages/ChangePasswordPage'
import ChangeTempPasswordPage from '../AuthPages/ChangeTempPasswordPage'

import configureStore from '../../store/configureStore'
import { loadLocale, saveLocale } from '../../localStorage'
import { DEFAULT_LOCALE, setMomentLocale } from '../../../i18n'
import Routes from '../../routes/Routes'
import { AuthLayout } from '../AuthPages/AuthLayout'
import {
  PATH_CHANGE_TEMP_PASSWORD,
  PATH_CONFIRM,
  PATH_CONFIRM_SIGNUP,
  PATH_DASHBOARD,
  PATH_LOGIN,
  PATH_NEW_PASSWORD,
  PATH_RESET_PASSWORD,
  PATH_SIGNUP,
  PATH_CODE_EXPIRED,
  PATH_CODE_INVALID,
  PATH_VERSION
} from '../../routes/PathConstants'
import ConfirmSignupPage from '../Registration/ConfirmSignupPage'
import CodeExpired from '../Registration/CodeExpired'
import CodeInvalid from '../Registration/CodeInvalid'
import { initAxios } from '../../utils/axiosUtil'
import { BackendUrlEditor } from '@holidu/pui-components'
import env from '../../env'
import { red } from '@mui/material/colors'
import PageVersion from '../StaticPages/PageVersion'
import StaticLayout from '../../layouts/StaticLayout'

initAxios()
const reactQueryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false
    }
  }
})

const initialStore = {
  locale: loadLocale() || DEFAULT_LOCALE,
  entities: {},
  queries: {}
}

const store = configureStore(initialStore)
store.subscribe(
  lodash.throttle(() => {
    saveLocale(store.getState().get('locale'))
    setMomentLocale(store.getState().get('locale'))
  }, 1000)
)

const getQueries = state => state.get('queries')

const notistackRef = React.createRef()
const onClickDismiss = key => () => {
  notistackRef.current.closeSnackbar(key)
}

const App = ({ messages }) => {
  const isIE = navigator.userAgent.indexOf('MSIE') !== -1
  if (isIE) {
    return (
      <h4 align="center">
        Browser is not supported. Please use either of Chrome, Firefox or
        Safari.
      </h4>
    )
  } else {
    return (
      <Provider store={store}>
        <LanguageProvider messages={messages}>
          <ReduxQueryProvider queriesSelector={getQueries}>
            <QueryClientProvider client={reactQueryClient}>
              <ThemeProvider theme={PuiTheme}>
                <CssBaseline />
                <SnackbarProvider
                  ref={notistackRef}
                  action={key => (
                    <IconButton onClick={onClickDismiss(key)}>
                      <CloseIcon />
                    </IconButton>
                  )}
                  anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                >
                  <Switch>
                    <Route path="/" exact>
                      <Redirect to={PATH_DASHBOARD} />
                    </Route>
                    <Route
                      path={PATH_VERSION}
                      exact
                      render={() => (
                        <StaticLayout>
                          <PageVersion />
                        </StaticLayout>
                      )}
                    />
                    <Route
                      path={PATH_LOGIN}
                      render={props => (
                        <AuthLayout>
                          <SignInPage {...props} />
                        </AuthLayout>
                      )}
                      exact
                    />
                    <Route
                      path={PATH_SIGNUP}
                      render={props => (
                        <AuthLayout>
                          <SignUpPage {...props} />
                        </AuthLayout>
                      )}
                      exact
                    />
                    <Route
                      path={PATH_CONFIRM}
                      render={props => (
                        <AuthLayout>
                          <ConfirmPage {...props} />
                        </AuthLayout>
                      )}
                      exact
                    />
                    <Route
                      path={PATH_RESET_PASSWORD}
                      render={() => (
                        <AuthLayout>
                          <ResetPasswordPage />
                        </AuthLayout>
                      )}
                      exact
                    />
                    <Route
                      path={PATH_CHANGE_TEMP_PASSWORD + ':email'}
                      render={() => (
                        <AuthLayout>
                          <ChangeTempPasswordPage />
                        </AuthLayout>
                      )}
                    />
                    <Route
                      path={PATH_NEW_PASSWORD + ':email'}
                      render={() => (
                        <AuthLayout>
                          <ChangePasswordPage />
                        </AuthLayout>
                      )}
                    />
                    <Route
                      component={ConfirmSignupPage}
                      path={PATH_CONFIRM_SIGNUP}
                    />
                    <Route
                      path={PATH_CODE_EXPIRED}
                      render={() => (
                        <AuthLayout>
                          <CodeExpired />
                        </AuthLayout>
                      )}
                    />
                    <Route
                      path={PATH_CODE_INVALID}
                      render={() => (
                        <AuthLayout>
                          <CodeInvalid />
                        </AuthLayout>
                      )}
                    />
                    <AWSCognitoAuthenticatedRoute
                      redirectWhenNotAuthenticated={PATH_LOGIN}
                    >
                      <Routes />
                    </AWSCognitoAuthenticatedRoute>
                  </Switch>
                </SnackbarProvider>
                {env.TARGET_ENV === 'dev' && (
                  <Box
                    width="300px"
                    bgcolor={red[600]}
                    color="white"
                    position="fixed"
                    bottom="0px"
                    left="0px"
                    zIndex="1201"
                  >
                    <BackendUrlEditor
                      appEnv={env.TARGET_ENV}
                      appBackend={env.BACKEND}
                    />
                  </Box>
                )}
              </ThemeProvider>
            </QueryClientProvider>
          </ReduxQueryProvider>
        </LanguageProvider>
      </Provider>
    )
  }
}

export default App
