import { Map } from 'immutable'
import env from '../../env'
import { currentUserIdToken } from '../../utils/awsCognitoClient'
import sanitizeData from '../../utils/sanitizeData'

export const updateGeneralConfigurationRequestWithLogo = payload => {
  let formData = new FormData()
  formData.append(
    'providerDto',
    new Blob([JSON.stringify(sanitizeData(payload.provider))], {
      type: 'application/json'
    })
  )
  formData.append(
    'configuration',
    new Blob([JSON.stringify(payload.configuration)], {
      type: 'application/json'
    })
  )
  formData.append('logo', payload.logoFile)
  return {
    url: `${env.BACKEND}/provider/generalConfigurationWithLogo?provider=${payload.selectedProvider}`,
    body: formData,
    transform: response => {
      return {
        providers: response.provider,
        providersConfig: response.configuration
      }
    },
    update: {
      providers: (prev, next) => {
        return prev.set(payload.selectedProvider, next)
      },
      providersConfig: (prev, next) => {
        return prev.set(payload.selectedProvider, next)
      }
    },
    options: {
      method: 'PUT',
      headers: {
        Authorization: 'Bearer ' + currentUserIdToken()
      }
    }
  }
}

export const updateGeneralConfigurationRequest = payload => {
  return {
    url: `${env.BACKEND}/provider/generalConfiguration?provider=${payload.selectedProvider}`,
    body: sanitizeData(payload),
    transform: response => {
      return {
        providers: response.provider,
        providersConfig: response.configuration
      }
    },
    update: {
      providers: (prev, next) => {
        return prev.set(payload.selectedProvider, next)
      },
      providersConfig: (prev, next) => {
        return prev.set(payload.selectedProvider, next)
      }
    },
    options: {
      method: 'PUT',
      headers: {
        Authorization: 'Bearer ' + currentUserIdToken()
      }
    }
  }
}

export const getProviderConfigurationRequest = props => ({
  url: `${env.BACKEND}/provider/configuration?provider=${props.selectedProvider}`,
  transform: response => {
    return {
      providersConfig: response
    }
  },
  update: {
    providersConfig: (prev, next) => {
      if (!prev) {
        return Map({ [props.selectedProvider]: next })
      } else {
        return prev.set(props.selectedProvider, next)
      }
    }
  },
  options: {
    headers: {
      Authorization: 'Bearer ' + currentUserIdToken()
    }
  }
})

export const updateProviderConfigurationRequest = payload => {
  return {
    url: `${env.BACKEND}/provider/configuration?provider=${payload.selectedProvider}`,
    body: sanitizeData(payload),
    transform: response => ({
      providersConfig: response
    }),
    update: {
      providersConfig: (prev, next) => {
        return prev.set(payload.selectedProvider, next)
      }
    },
    options: {
      method: 'PUT',
      headers: {
        Authorization: 'Bearer ' + currentUserIdToken()
      }
    }
  }
}
