import React from 'react'
import { Typography, Button, Stack } from '@mui/material'
import { FormattedMessage } from 'react-intl'
import { Link } from 'react-router-dom'

import { messageKeys } from './messages'

const CodeInvalid = () => {
  return (
    <Stack spacing={2} alignItems="center">
      <Typography variant="h5" gutterBottom>
        <FormattedMessage {...messageKeys.VerificationErrorHeader} />
      </Typography>
      <Typography variant="body2">
        <FormattedMessage {...messageKeys.NotAuthorizedExceptionMessage} />
      </Typography>
      <Button
        component={Link}
        to="/auth/login"
        variant="contained"
        fullWidth
      >
        <FormattedMessage {...messageKeys.LoginButton} />
      </Button>
    </Stack>
  )
}

export default CodeInvalid
