import { init as initApm } from '@elastic/apm-rum'
import env from '../env'

export const apm = initApm({
  serviceName: 'partner-portal-frontend',
  serverUrl: env.APM_SERVER,
  serviceVersion: env.APM_VERSION,
  distributedTracingOrigins: [env.BACKEND],
  transactionSampleRate: 1.0,
  active: false,
  pageLoadTransactionName: window.location.pathname
})

export const startTransaction = transactionName => {
  if (apm.isEnabled()) {
    const transaction = apm.startTransaction(transactionName, 'custom', {
      managed: true
    })
    const span = apm.startSpan(transactionName)
    const id = transaction.addTask()
    return {
      end: () => {
        transaction.removeTask(id)
        span.end()
        transaction.end()
      }
    }
  }
  return {
    end: () => {}
  }
}
