import React from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { connectRequest } from 'redux-query-immutable-react'

import { Page, PageContent, PageHeader } from '@holidu/pui-components/dist'
import { Typography } from '@mui/material'
import { FormattedMessage } from 'react-intl'
import { messageKeys } from './messages'

import { getSelectedProvider } from '../../store/providerSelection/providerSelector'
import { getProviderSelector } from '../../store/provider/providerSelector'
import { getProviderRequest } from '../../store/provider/providerRequest'
import { RequestsAreDone } from '../RequestsAreDone'
import RegulatoryReportingPage from './RegulatoryReportingPage'
import { updateCompany } from '../../store/company/actions'
import { getCompanySelector } from '../../store/company/companySelector'
import { getCompanyRequest } from '../../store/company/companyRequest'

const RegulatoryReporting = props => {
  return (
    <Page>
      <PageHeader
        sx={{
          borderBottom: 'none'
        }}
      >
        <Typography variant="h6">
          <FormattedMessage {...messageKeys.Title} />
        </Typography>
      </PageHeader>
      <PageContent px={3} pb={8}>
        <RegulatoryReportingPage
          selectedProvider={props.selectedProvider}
          companies={props.companies}
          updateRegulatoryReportingData={props.updateCompany}
        />
      </PageContent>
    </Page>
  )
}

const mapDispatchToProps = (dispatch, props) => ({
  updateCompany: (data, onSuccess, onError) => {
    dispatch(
      updateCompany(
        { ...data, selectedProvider: props.selectedProvider },
        onSuccess,
        onError
      )
    )
  }
})

export default compose(
  connect(getSelectedProvider),
  connect(getProviderSelector),
  connect(getCompanySelector, mapDispatchToProps),
  connectRequest(getProviderRequest),
  connectRequest(getCompanyRequest),
  RequestsAreDone
)(RegulatoryReporting)
