import axios, { AxiosError } from 'axios'
import env from '../env'
import { currentUserIdToken } from './awsCognitoClient'

export const initAxios = () => {
  axios.defaults.baseURL = env.BACKEND
  axios.interceptors.request.use(
    function (config) {
      return {
        ...config,
        headers: {
          ...config.headers,
          Authorization: 'Bearer ' + currentUserIdToken()
        }
      }
    },
    function (error) {
      return Promise.reject(error)
    }
  )
}

export const getErrorMessage = (err: AxiosError): string => {
  let message = ''
  if (err?.message) {
    message += `${err.message}`
  } else if (err?.code) {
    message += `${err.code}`
  }
  const responseData: any = err?.response?.data
  if (responseData?.message) {
    message += `: ${responseData.message}`
  }
  return message
}
