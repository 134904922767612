import React, { useEffect, useState } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import {
  Avatar,
  Button,
  IconButton,
  MenuItem,
  MenuList,
  Stack,
  useMediaQuery,
  useTheme,
  Popover,
  InputBase,
  Divider
} from '@mui/material'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import SearchIcon from '@mui/icons-material/Search'
import { getSelectedProvider } from '../../store/providerSelection/providerSelector'
import { getUserSelector } from '../../store/user/userSelector'
import { saveSelectedProvider } from '../../localStorage'
import { useIntl } from 'react-intl'
import { messageKeys } from './messages'
import Analytics from '../../utils/analytics'

type ProviderSelectionMenuProps = {
  selectedProvider: string
  size?: 'small' | 'medium' | 'large'
  user: any
}

const ProviderSelectionMenu = (props: ProviderSelectionMenuProps) => {
  const { selectedProvider, size, user } = props
  const theme = useTheme()
  const intl = useIntl()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const attachedProviders: string[] = user.get('attachedProviders', []).toJS() // TODO: get rid of the goddamn ImmutableJS!
  const [searchQuery, setSearchQuery] = useState<string>('')
  const [open, setOpen] = React.useState(false)
  const anchorRef = React.useRef<HTMLButtonElement>(null)
  const searchInputRef = React.useRef<HTMLInputElement>(null)

  useEffect(() => {
    searchInputRef.current?.focus()
  }, [searchQuery])

  const handleSearchQueryChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value)
  }

  const handleToggleMenu = () => {
    setOpen(prevOpen => !prevOpen)
  }

  const handleCloseMenu = (event: Event | React.SyntheticEvent) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return
    }
    setOpen(false)
  }

  const searchResults = searchQuery
    ? attachedProviders.filter((provider: string) =>
        provider.match(new RegExp(searchQuery, 'i'))
      )
    : attachedProviders

  const handleProviderSelection = (provider: string) => (event: any) => {
    Analytics.trackEvent('PROVIDER_CHANGE', provider)
    saveSelectedProvider(provider)
    handleCloseMenu(event)
    window.location.replace('/')
  }

  if (user && user.size) {
    return (
      <>
        {isMobile && size === 'small' && selectedProvider ? (
          <IconButton
            size="small"
            data-ga-label="Provider"
            onClick={handleToggleMenu}
          >
            <Avatar
              sx={{
                cursor: 'pointer',
                bgcolor: theme.palette.secondary.main
              }}
            >
              {selectedProvider.charAt(0)}
            </Avatar>
          </IconButton>
        ) : (
          <Button
            ref={anchorRef}
            size={size}
            color="inherit"
            endIcon={<ArrowDropDownIcon />}
            aria-haspopup="true"
            aria-owns={open ? 'menu-provider-selection' : undefined}
            aria-controls={open ? 'composition-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            onClick={handleToggleMenu}
          >
            {selectedProvider || intl.formatMessage(messageKeys.SelectProvider)}
          </Button>
        )}
        <Popover
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          onClose={handleCloseMenu}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left'
          }}
        >
          <Stack>
            <Stack
              direction={'row'}
              alignItems={'center'}
              pl={1.5}
              pr={2}
              py={1}
            >
              <SearchIcon sx={{ mr: 1 }} color="primary" />
              <InputBase
                autoFocus
                inputRef={searchInputRef}
                sx={{ flex: 1 }}
                placeholder={intl.formatMessage(messageKeys.SearchPlaceholder)}
                onChange={handleSearchQueryChange}
              />
            </Stack>
            <Divider />
            <MenuList
              sx={{ maxHeight: 'calc(100vh - 100px)', overflowY: 'scroll' }}
            >
              {(searchResults.length &&
                searchResults.map((provider: string) => (
                  <MenuItem
                    dense
                    key={provider}
                    selected={provider === selectedProvider}
                    onClick={handleProviderSelection(provider)}
                  >
                    {provider}
                  </MenuItem>
                ))) || (
                <MenuItem disabled>
                  {intl.formatMessage(messageKeys.NoProviders)}
                </MenuItem>
              )}
            </MenuList>
          </Stack>
        </Popover>
      </>
    )
  }
  return null
}

export default compose(
  connect(getUserSelector),
  connect(getSelectedProvider)
)(ProviderSelectionMenu)
