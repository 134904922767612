import React from 'react'
import { Redirect } from 'react-router'
import { connectRequest } from 'redux-query-immutable-react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { getUserSelector } from '../../store/user/userSelector'
import { getUserRequest } from '../../store/user/userRequest'
import {
  getProviderSelector,
  getOneProviderSelector
} from '../../store/provider/providerSelector'
import { getProviderRequest } from '../../store/provider/providerRequest'
import Page500 from '../../containers/StaticPages/Page500'
import Loader from '../../components/Loader'
import StaticLayout from '../../layouts/StaticLayout'
import { getQueriesStatus } from '../../store/query/querySelector'
import {
  PATH_FINALIZE_REGISTRATION,
  PATH_FINALIZE_REGISTRATION_THANK_YOU
} from '../PathConstants'
import { allFieldsAreFilled, userFieldsAreFilled } from './utils'

const FinalizeRegistrationFilter = props => {
  const { children, provider, providers, user } = props

  const currentProvider =
    provider.size > 0 ? provider : providers.valueSeq().first()

  if (props.backendIsDown) {
    return <Page500 />
  }
  if (user.size < 1 || providers.size < 1) {
    return (
      <StaticLayout>
        <Loader />
      </StaticLayout>
    )
  }

  if (allFieldsAreFilled(user, currentProvider)) {
    return <React.Fragment>{children}</React.Fragment>
  } else if (userFieldsAreFilled(user)) {
    return <Redirect to={PATH_FINALIZE_REGISTRATION_THANK_YOU} push />
  }
  return <Redirect to={PATH_FINALIZE_REGISTRATION} push />
}

export default compose(
  connect(getUserSelector),
  connect(getProviderSelector),
  connect(getOneProviderSelector),
  connect(getQueriesStatus),
  connectRequest(getUserRequest),
  connectRequest(getProviderRequest)
)(FinalizeRegistrationFilter)
