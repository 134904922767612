import React from 'react'
import { useIntl } from 'react-intl'
import messageKeys from '../messages'
import { PropertyDetail } from '../types'
import Section from '../../../components/Section'
import { Box, Stack } from '@mui/material'
import LearnMoreLink from '../../../components/LearnMoreLink'
import SubScore from './Subscore'
import Opportunities from './Opportunities'
import Analytics from '../../../utils/analytics'
import { APARTMENT_DETAIL_CLICK_BOOKABILITY_SCORE_LEARN_MORE } from '../../../utils/analytics/events'
import LinearScore from '../../../components/Score/LinearScore'
import { getScoreColor } from '../../../utils/scoreUtil'

const MAX_BOOKABLE_DATES = 150

type BookabilityScoreSectionProps = {
  data: PropertyDetail
}

const LEARN_MORE_LINK =
  'https://holidu4.zendesk.com/hc/en-us/articles/7756537173021'

const BookabilityScoreSection = (
  props: BookabilityScoreSectionProps
): JSX.Element => {
  const { data } = props
  const intl = useIntl()

  const handleLearnMoreClick = () => {
    Analytics.trackEvent(APARTMENT_DETAIL_CLICK_BOOKABILITY_SCORE_LEARN_MORE)
  }

  return (
    <Section
      sx={{ height: '100%' }}
      title={intl.formatMessage(messageKeys.APARTMENT_BOOKABILITY_SCORE)}
      actions={
        <LearnMoreLink href={LEARN_MORE_LINK} onClick={handleLearnMoreClick} />
      }
    >
      <Stack
        spacing={2}
        sx={{
          minHeight: 240
        }}
      >
        <LinearScore
          sx={theme => ({
            height: theme.spacing(3),
            color: getScoreColor(data.bookabilityScore)
          })}
          value={data.bookabilityScore}
          TypographyProps={{
            variant: "h5"
          }}
        />
        <SubScore
          label={intl.formatMessage(messageKeys.BOOKABLE_DATES)}
          value={data.bookableDates}
          progressValue={(data.bookableDates / MAX_BOOKABLE_DATES) * 100}
        />
        <SubScore
          label={intl.formatMessage(messageKeys.FLEXIBILTY)}
          value={`${data.flexibility || 0}%`}
          progressValue={data.flexibility}
        />
      </Stack>
      {!!data.bookabilityScoreOpportunities?.length && (
        <Box>
          <Opportunities values={data.bookabilityScoreOpportunities} />
        </Box>
      )}
    </Section>
  )
}

export default BookabilityScoreSection
