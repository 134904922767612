import React, { useEffect, useState } from 'react'
import { Typography } from '@mui/material'

const PageVersion = () => {
  const [value, setValue] = useState<string>('')
  useEffect(() => {
    fetch(process.env.PUBLIC_URL + '/static/gitInfo.txt')
      .then(result => {
        return result.text()
      })
      .then(text => {
        setValue(text)
      })
  }, [])
  return <Typography>{value}</Typography>
}

export default PageVersion
