import React from 'react'
import { TextField } from '@mui/material'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import { messageKeys as globalMessageKeys } from '../../../globalMessages'

const ValidationTextField = props => {
  const {
    error,
    helperText,
    onChange,
    required,
    validations,
    value,
    ...restProps
  } = props

  const validate = e => {
    const { value } = e.target
    let errorReason = null
    if (!value) {
      if (required) {
        errorReason = 'required'
      }
    } else if (typeof validations === 'object') {
      try {
        for (let vKey in validations) {
          if (
            validations[vKey] &&
            typeof validations[vKey].validator === 'function'
          ) {
            const bValid = validations[vKey].validator(value)
            if (!bValid) {
              errorReason = vKey
            }
            break
          }
        }
      } catch (err) {
        console.warn(err)
      }
    }

    return errorReason
  }
  const handleChange = e => {
    let errorReason = validate(e)
    if (typeof onChange === 'function') {
      onChange(e, errorReason)
    }
  }

  let condHelperText
  if (error) {
    if (error === 'required') {
      condHelperText = (
        <FormattedMessage {...globalMessageKeys.ErrorIsMandatory} />
      )
    } else if (validations[error].message) {
      condHelperText = validations[error].message
    }
  }

  condHelperText = condHelperText || helperText

  return (
    <TextField
      value={value}
      error={Boolean(error)}
      required={required}
      helperText={condHelperText}
      onChange={handleChange}
      {...restProps}
    />
  )
}

ValidationTextField.propTypes = {
  value: PropTypes.any.isRequired,
  error: PropTypes.string,
  helperText: PropTypes.any,
  validations: PropTypes.shape({
    [PropTypes.string]: PropTypes.shape({
      validator: PropTypes.func,
      message: PropTypes.any
    })
  }),
  onChange: PropTypes.func
}

export default ValidationTextField
