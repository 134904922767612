import React, { useState } from 'react'
import {
  Button,
  TextField,
  Typography,
  Stack,
  Alert,
  Box,
  Link
} from '@mui/material'
import { useIntl } from 'react-intl'
import isObject from 'lodash/isObject'
import isString from 'lodash/isString'
import {
  sendVerificationCode,
  resendVerification
} from '../../utils/awsCognitoClient'
import { PATH_CONFIRM } from '../../routes/PathConstants'
import { messageKeys } from './messages'
import { useHistory } from 'react-router'

type ApiError = {
  code: string
  message: string
}

const ResetPasswordPage = () => {
  const intl = useIntl()
  const history = useHistory()
  const [email, setEmail] = useState('')
  const [error, setError] = useState<string | ApiError>('')

  const onClickSubmit = async () => {
    if (!email || !!error) {
      setError('Invalid email')
      return
    }
    try {
      await sendVerificationCode(email)
    } catch (err) {
      console.log(err)
      setError(err as ApiError)
      return
    }
    history.replace('/auth/new-password/' + email)
  }

  const handleResendVerificationEmail = async () => {
    try {
      await resendVerification(email)
      history.replace(PATH_CONFIRM)
    } catch (err) {
      console.log('err', err)
    }
  }

  let errorMessage
  if (isObject(error)) {
    errorMessage =
      error.code === 'InvalidParameterException' ? (
        <Box>
          <Typography>
            {intl.formatMessage(messageKeys.UserNotConfirmed)}
          </Typography>
          <Box>
            <Link
              component="button"
              underline="hover"
              sx={{ padding: 0, marginTop: 2 }}
              onClick={handleResendVerificationEmail}
            >
              <Typography>
                <strong>
                  {intl.formatMessage(messageKeys.ResendVerificationLink)}
                </strong>
              </Typography>
            </Link>
          </Box>
        </Box>
      ) : (
        error.message
      )
  } else if (isString(error)) {
    errorMessage = error
  } else {
    errorMessage = null
  }

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target
    setEmail(value)
    setError('')
  }

  return (
    <form>
      <Stack spacing={2} alignItems="center">
        <Typography variant="body1">
          {intl.formatMessage(messageKeys.RessetPassHeader)}
        </Typography>
        <TextField
          id="email"
          label="Email Address"
          required
          size="small"
          fullWidth
          margin="normal"
          value={email}
          onChange={onChange}
        />

        {errorMessage && <Alert severity="error">{errorMessage}</Alert>}

        <Button
          color="primary"
          variant="contained"
          fullWidth
          disabled={!!error}
          onClick={onClickSubmit}
        >
          {intl.formatMessage(messageKeys.RessetPassButton)}
        </Button>
      </Stack>
    </form>
  )
}

export default ResetPasswordPage
