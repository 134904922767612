import React from 'react'
import {
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  SelectChangeEvent
} from '@mui/material'
import messageKeys from './messages'
import { useIntl } from 'react-intl'
import Loader from '../../../components/Loader'
import useGetBookingCancellationReasons from './useGetCancellationReasons'

type CancellationReasonFieldProps = {
  value: string
  onChange: (e: SelectChangeEvent) => void
}

const CancellationReasonField = (props: CancellationReasonFieldProps) => {
  const { value, onChange } = props
  const intl = useIntl()
  const { data, isLoading, isFetching } = useGetBookingCancellationReasons()
  return (
    <FormControl size="small" variant="standard" required fullWidth>
      <InputLabel id="cancellation-reason" style={{ background: '#fff' }}>
        {intl.formatMessage(messageKeys.CancelReason)}
      </InputLabel>
      <Select
        labelId="cancellation-reason"
        value={value}
        onChange={onChange}
        renderValue={value => {
          return intl.formatMessage({
            id: `CancellationReason:${value}`,
            defaultMessage: `CancellationReason:${value}`
          })
        }}
      >
        {data &&
          data.map(option => (
            <MenuItem key={option} value={option}>
              {intl.formatMessage({
                id: `CancellationReason:${option}`,
                defaultMessage: `CancellationReason:${option}`
              })}
            </MenuItem>
          ))}
      </Select>
      {(isLoading || isFetching) && <Loader />}
    </FormControl>
  )
}

export default CancellationReasonField
