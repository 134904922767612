import React from 'react'
import { Stack } from '@mui/material'
import { FormattedMessage } from 'react-intl'
import { messageKeys } from '../messages'
import Section from '../../../components/Section'
import ValidationTextField from '../../../components/Fields/ValidationTextField'

const HoliduAppearance = props => {
  const { data, errors, onChange } = props

  return (
    <Section
      title={<FormattedMessage {...messageKeys.Header} />}
      subtitle={<FormattedMessage {...messageKeys.SubHeader} />}
    >
      <Stack spacing={4}>
        {/* Company Name */}
        <ValidationTextField
          name="name"
          error={errors.name}
          label={<FormattedMessage {...messageKeys.CompanyName} />}
          value={data.name || ''}
          fullWidth
          required
          onChange={onChange}
        />
        {/* Company Shortname */}
        <ValidationTextField
          name="shortName"
          error={errors.shortName}
          label={<FormattedMessage {...messageKeys.ShortCompanyName} />}
          value={data.shortName || ''}
          fullWidth
          required
          onChange={onChange}
        />
      </Stack>
    </Section>
  )
}

export default HoliduAppearance
