import { mutateAsync } from 'redux-query-immutable'
import {
  updateGeneralConfigurationRequest,
  updateGeneralConfigurationRequestWithLogo,
  updateProviderConfigurationRequest
} from './providerConfigurationRequest'

export const updateGeneralConfigurationWithLogo = (
  data,
  onSuccess = () => {},
  onError = () => {}
) => {
  return dispatch => {
    return dispatch(
      mutateAsync(updateGeneralConfigurationRequestWithLogo(data))
    ).then(response => {
      if (response.status === 200) {
        onSuccess(response)
      } else {
        onError(response)
      }
    })
  }
}

export const updateGeneralConfiguration = (
  data,
  onSuccess = () => {},
  onError = () => {}
) => {
  return dispatch => {
    return dispatch(mutateAsync(updateGeneralConfigurationRequest(data))).then(
      response => {
        if (response.status === 200) {
          onSuccess(response)
        } else {
          onError(response)
        }
      }
    )
  }
}

export const updateProviderConfiguration = (
  data,
  onSuccess = () => {},
  onError = () => {}
) => {
  return dispatch => {
    return dispatch(mutateAsync(updateProviderConfigurationRequest(data))).then(
      response => {
        if (response.status === 200) {
          onSuccess(response)
        } else {
          onError(response)
        }
      }
    )
  }
}
