import React from 'react'
import { Booking } from './types'
import { DataTable } from '@holidu/pui-components/dist'
import { useHistory } from 'react-router'
import useBookingsColumns from './useBookingsColumns'

type BookingsTableProps = {
  data: Booking[]
}
const BookingsTable = (props: BookingsTableProps): JSX.Element => {
  const { data } = props
  const history = useHistory()
  const columns = useBookingsColumns()
  const handleRowClick = (data: Booking) => {
    history.push(`/inventory/booking?id=${data.bookingId}`)
  }

  return (
    <DataTable
      thCellProps={{
        sx: {
          backgroundColor: theme => theme.palette.grey[50]
        }
      }}
      bodyRowProps={{
        hover: true
      }}
      onRowClick={handleRowClick}
      rowKey="bookingId"
      columns={columns}
      data={data || []}
    />
  )
}

export default BookingsTable
