import { Map as ImmutableMap } from 'immutable'

export const getBookingConfigurationDetailsSelector = state => {
  return {
    bookingsDetail: state.getIn(
      ['entities', 'bookingsDetail'],
      new ImmutableMap()
    )
  }
}
