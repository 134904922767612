import React from 'react'
import QueryUtils from 'query-string'
import AxiosErrorAlert from '../../components/AxiosErrorAlert'
import Loader from '../../components/Loader'
import ErrorBanner from '../../components/ErrorBanner'
import BookingDetailHeader from './BookingDetailHeader'
import { Page, PageContent, PageHeader } from '@holidu/pui-components/dist'
import useGetBookingDetail from './useGetBookingDetail'
import { Grid, Stack } from '@mui/material'
import BookingStatusTray from './BookingStatusTray'
import BookingInfoSection from './BookingInfoSection'
import CustomerInfoSection from './CustomerInfoSection'
import CancellationPolicySection from './CancellationPolicySection'
import BookingReceiptSection from './BookingReceiptSection'
import CommissionBreakdownSection from './CommissionBreakdownSection'
import PayoutScheduleSection from './PayoutScheduleSection'
import CancelBookingSection from './CancelBookingSection'

const BookingDetailPage = (): JSX.Element => {
  const query = QueryUtils.parse(window.location.search)
  const { data, isLoading, isFetching, isError, error } = useGetBookingDetail(
    query.id as string
  )

  if (isError) {
    return <AxiosErrorAlert error={error} />
  }

  return (
    <Page>
      <PageHeader
        sx={{
          borderBottom: 'none'
        }}
      >
        <BookingDetailHeader
          bookingInfo={data?.booking}
          invoices={data?.invoices}
        />
      </PageHeader>
      <PageContent px={3} pb={8}>
        {data && (
          <>
            {data?.errors &&
              data?.errors.map(error => (
                <ErrorBanner
                  key={error.error}
                  message={error.error}
                  detailedMessage={error.message}
                />
              ))}
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <BookingStatusTray
                  status={data.booking?.status}
                  cancelledAt={data.booking?.cancelledAt}
                  cancellationReason={data.booking?.cancellationReason}
                />
              </Grid>

              <Grid item xs={12} md={8}>
                <Stack spacing={4}>
                  {data.booking && (
                    <>
                      <BookingInfoSection bookingInfo={data.booking} />
                      <CancellationPolicySection bookingInfo={data.booking} />
                    </>
                  )}

                  {data.booking?.holiduPayment &&
                    data.providerPayoutSchedule && (
                      <PayoutScheduleSection
                        providerPayoutSchedule={data.providerPayoutSchedule}
                      />
                    )}

                  {data.receipt && (
                    <BookingReceiptSection receipt={data.receipt} />
                  )}
                  {data.commissionBreakdown && (
                    <CommissionBreakdownSection
                      commissionBreakdown={data.commissionBreakdown}
                      isHoliduPayment={data.booking?.holiduPayment}
                    />
                  )}
                  {data.booking && (
                    <CancelBookingSection bookingInfo={data.booking} />
                  )}
                </Stack>
              </Grid>
              <Grid item xs={12} md={4}>
                {data.customer && (
                  <CustomerInfoSection customerInfo={data.customer} />
                )}
              </Grid>
            </Grid>
          </>
        )}
        {(isLoading || isFetching) && <Loader />}
      </PageContent>
    </Page>
  )
}

export default BookingDetailPage
