export const messageKeys = {
  Title: {
    id: 'KnowYourClientVerfication:TITLE',
    defaultMessage: 'KnowYourClientVerfication:TITLE'
  },
  Header: {
    id: 'KnowYourClientVerfication:HEADER',
    defaultMessage: 'KnowYourClientVerfication:HEADER'
  },
  SubHeader: {
    id: 'KnowYourClientVerfication:SUB_HEADER',
    defaultMessage: 'KnowYourClientVerfication:SUB_HEADER'
  },
  ContentDescription: {
    id: 'KnowYourClientVerfication:CONTENT_DESCRIPTION',
    defaultMessage: 'KnowYourClientVerfication:CONTENT_DESCRIPTION'
  },
  StepsIdentity: {
    id: 'KnowYourClientVerfication:STEPS_IDENTITY',
    defaultMessage: 'KnowYourClientVerfication:STEPS_IDENTITY'
  },
  StepsCompany: {
    id: 'KnowYourClientVerfication:STEPS_COMPANY',
    defaultMessage: 'KnowYourClientVerfication:STEPS_COMPANY'
  },
  StepsBank: {
    id: 'KnowYourClientVerfication:STEPS_BANK',
    defaultMessage: 'KnowYourClientVerfication:STEPS_BANK'
  },
  StepsPersonalId: {
    id: 'KnowYourClientVerfication:STEPS_PERSONAL_ID',
    defaultMessage: 'KnowYourClientVerfication:STEPS_PERSONAL_ID'
  },
  GoToVerficationProcess: {
    id: 'KnowYourClientVerfication:BUTTON_GO_TO_VERIFICATION',
    defaultMessage: 'KnowYourClientVerfication:BUTTON_GO_TO_VERIFICATION'
  },
  Loading: {
    id: 'KnowYourClientVerfication:BUTTON_LOADING',
    defaultMessage: 'KnowYourClientVerfication:BUTTON_LOADING'
  },
  FailedToGetAdyenLink: {
    id: 'KnowYourClientVerfication:FAILED_TO_GET_ADYEN_LINK',
    defaultMessage: 'KnowYourClientVerfication:FAILED_TO_GET_ADYEN_LINK'
  },
  KycGeneralInfo: {
    id: 'KnowYourClientVerfication:KYC_GENERAL_INFO',
    defaultMessage: 'KnowYourClientVerfication:KYC_GENERAL_INFO'
  },
  VideoComingSoon: {
    id: 'KnowYourClientVerfication:VIDEO_COMMING_SOON',
    defaultMessage: 'KnowYourClientVerfication:VIDEO_COMMING_SOON'
  },
  OverallStatus: {
    id: 'KnowYourClientVerfication:OVERALL_STATUS',
    defaultMessage: 'KnowYourClientVerfication:OVERALL_STATUS'
  },
  KycStatusRequestFailed: {
    id: 'KnowYourClientVerfication:KYC_STATUS_REQUEST_FAILED',
    defaultMessage: 'KnowYourClientVerfication:KYC_STATUS_REQUEST_FAILED'
  },
  AdditionalInformation: {
    id: 'KnowYourClientVerfication:ADDITIONAL_INFORMATION',
    defaultMessage: 'KnowYourClientVerfication:ADDITIONAL_INFORMATION'
  },
  TrustLevelTier: {
    id: 'KnowYourClientVerfication:TRUST_LEVEL_TIER',
    defaultMessage: 'KnowYourClientVerfication:TRUST_LEVEL_TIER'
  },
  Accountholder: {
    id: 'KnowYourClientVerfication:ACCOUNT_HOLDER',
    defaultMessage: 'KnowYourClientVerfication:ACCOUNT_HOLDER'
  },
  AccountholderInfo: {
    id: 'KnowYourClientVerfication:ACCOUNT_HOLDER_INFO',
    defaultMessage: 'KnowYourClientVerfication:ACCOUNT_HOLDER_INFO'
  },
  Company: {
    id: 'KnowYourClientVerfication:COMPANY',
    defaultMessage: 'KnowYourClientVerfication:COMPANY'
  },
  Shareholders: {
    id: 'KnowYourClientVerfication:SHARE_HOLDERS',
    defaultMessage: 'KnowYourClientVerfication:SHARE_HOLDERS'
  },
  ShareholdersInfo: {
    id: 'KnowYourClientVerfication:SHARE_HOLDER_INFO',
    defaultMessage: 'KnowYourClientVerfication:SHARE_HOLDER_INFO'
  },
  Name: {
    id: 'KnowYourClientVerfication:NAME',
    defaultMessage: 'KnowYourClientVerfication:NAME'
  },
  Identity: {
    id: 'KnowYourClientVerfication:IDENTITY',
    defaultMessage: 'KnowYourClientVerfication:IDENTITY'
  },
  Passport: {
    id: 'KnowYourClientVerfication:PASSPORT',
    defaultMessage: 'KnowYourClientVerfication:PASSPORT'
  },
  Bankaccount: {
    id: 'KnowYourClientVerfication:BANK_ACOUNT',
    defaultMessage: 'KnowYourClientVerfication:BANK_ACOUNT'
  },
  Bankaccountname: {
    id: 'KnowYourClientVerfication:BANK_ACOUNT_NAME',
    defaultMessage: 'KnowYourClientVerfication:BANK_ACOUNT_NAME'
  },
  BankaccountInfo: {
    id: 'KnowYourClientVerfication:BANK_ACOUNT_INFO',
    defaultMessage: 'KnowYourClientVerfication:BANK_ACOUNT_INFO'
  }
}
