import React from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { connectRequest } from 'redux-query-immutable-react'
import { withTransaction } from '@elastic/apm-rum-react'
import { FormattedMessage } from 'react-intl'

import { Grid, Typography } from '@mui/material'
import { getGeneralConfigSelector } from '../../store/providerConfiguration/providerConfigurationSelector'
import { getCompanySelector } from '../../store/company/companySelector'
import { getProviderConfigurationRequest } from '../../store/providerConfiguration/providerConfigurationRequest'
import { getSelectedProvider } from '../../store/providerSelection/providerSelector'
import { getCompanyRequest } from '../../store/company/companyRequest'
import { getUserRequest } from '../../store/user/userRequest'
import { getUserSelector } from '../../store/user/userSelector'
import { getProviderSelector } from '../../store/provider/providerSelector'
import { getProviderRequest } from '../../store/provider/providerRequest'

import Page from '../../components/Page'
import PageContent from '../../components/Page/PageContent'
import { getAllChannelManagerConfigurationRequest } from '../../store/channelManager/requests'
import { getAllChannelManagerConfigsSelector } from '../../store/channelManager/selectors'
import OnboardingPage from './OnBoarding/OnboardingPage'
import { messageKeys } from './messages'
import { getKycStatusSelector } from '../../store/kyc/kycSelectors'
import { getFetchKycStatusRequest } from '../../store/kyc/kycRequests'
import { getBookingConfigurationDetailsSelector } from '../../store/bookingConfigurationDetails/bookingDetailsSelector'
import { getBookingConfigurationDetailsRequest } from '../../store/bookingConfigurationDetails/bookingDetailsRequest'

function Dashboard(props) {
  const { channelManagerConfigs, user } = props

  return (
    <Page>
      <PageContent pb={6}>
        <Grid justify="space-between" spacing={6} container>
          <Grid item>
            <Typography display="inline" variant="h5">
              <FormattedMessage
                {...messageKeys.Greatings}
                values={{ user: user.get('firstName') }}
              />
            </Typography>
          </Grid>
        </Grid>
        <OnboardingPage
          companies={props.companies}
          integrationChannel={props.integrationChannel}
          providersConfig={props.providersConfig}
          providers={props.providers}
          selectedProvider={props.selectedProvider}
          kycStatus={props.kycStatus}
          channelManagerConfigs={channelManagerConfigs}
          providersFrontendConfig={props.bookingsDetail}
        />
      </PageContent>
    </Page>
  )
}

export default compose(
  connect(getSelectedProvider),
  connect(getGeneralConfigSelector),
  connect(getCompanySelector),
  connect(getUserSelector),
  connect(getProviderSelector),
  connect(getAllChannelManagerConfigsSelector),
  connect(getKycStatusSelector),
  connect(getBookingConfigurationDetailsSelector),
  connectRequest(getBookingConfigurationDetailsRequest),
  connectRequest(getProviderConfigurationRequest),
  connectRequest(getCompanyRequest),
  connectRequest(getUserRequest),
  connectRequest(getProviderRequest),
  connectRequest(getAllChannelManagerConfigurationRequest),
  connectRequest(getFetchKycStatusRequest),
  withTransaction('Dashboard', 'custom')
)(Dashboard)
