import React from 'react'
import { Stack, Typography } from '@mui/material'
import { useIntl } from 'react-intl'
import messageKeys from '../messages'
import { getAmountCurrencyText } from '../../../utils/currencyUtil'

type PriceProps = {
  value?: number
}

const Price = (props: PriceProps): JSX.Element => {
  const { value } = props
  const intl = useIntl()
  if (typeof value === 'number') {
    return (
      <Stack direction="row" alignItems="flex-end" spacing={1}>
        <Typography
          variant="body1"
          component="span"
          sx={{
            fontWeight: 'bold'
          }}
          lineHeight="normal"
        >
          {getAmountCurrencyText(value, 'EUR', 0)}
        </Typography>
        <Typography variant="caption" sx={{ lineHeight: 1.5 }} noWrap>
          / {intl.formatMessage(messageKeys.ApartmentPerNight)}
        </Typography>
      </Stack>
    )
  }
  return (
    <Typography
      variant="caption"
      sx={theme => ({ color: theme.palette.warning.main })}
    >
      {intl.formatMessage(messageKeys.ApartmentPriceNotAvailaible)}
    </Typography>
  )
}

export default Price
