import axios from 'axios'
import { useQuery } from '@tanstack/react-query'
import { BookingDetail } from './types'
import QueryKeys from '../../utils/queryKeys'

const useGetBookingDetail = (id: string) => {
  return useQuery<any, Error, BookingDetail>(
    [QueryKeys.BookingDetail, { query: { id } }],
    async () => {
      const { data } = await axios.get(`/bookings/${id}`)
      return data
    },
    { keepPreviousData: false, retry: false }
  )
}

export default useGetBookingDetail
