import React, { useEffect, useState } from 'react'
import {
  TextField,
  MenuItem,
  Box,
  Stack,
  Paper,
  Collapse,
  InputAdornment,
  Divider
} from '@mui/material'

import SearchIcon from '@mui/icons-material/Search'
import { FormattedMessage, useIntl } from 'react-intl'

import messageKeys from './messages'
import { messageKeys as globalMessages } from '../../globalMessages'

import { FilterQueryType, SortOrder } from './types'
import CircleIcon from '@mui/icons-material/Circle'
import { BOOKING_STATUS } from './constants'
import FilterButton from '../../components/FilterButton'
import QuerySearchField from '../../components/QueryFields/QuerySearchField'
import { LabelValue } from '@holidu/pui-components/dist'
import Analytics from '../../utils/analytics'
import {
  BOOKINGS_HIDE_FILTERS,
  BOOKINGS_SHOW_FILTERS
} from '../../utils/analytics/events'
import DateRangeFilter, {
  DateRangeChange
} from '../../components/DateRangeFilter'

export type FilterChange = { name: string; value: string }

export type SortChange = {
  sortBy: string
  sortOrder: SortOrder
}

type BookingFiltersProps = Omit<FilterQueryType, 'page' | 'size'> & {
  totalCount?: number
  onFilterChange: ({ name, value }: FilterChange) => void
  onBookingDateFilterChange: (dateRangeChange: DateRangeChange) => void
  onSortChange: ({ sortBy, sortOrder }: SortChange) => void
}

const STATUS_FILTER_OPTIONS = [
  {
    color: BOOKING_STATUS.CONFIRMED.color,
    value: BOOKING_STATUS.CONFIRMED.text
  },
  {
    color: BOOKING_STATUS.CANCELLED.color,
    value: BOOKING_STATUS.CANCELLED.text
  }
]

const BookingsFilter = (props: BookingFiltersProps): JSX.Element => {
  const {
    searchText,
    transactionId,
    status,
    arrivalDate,
    bookedAtFrom,
    bookedAtTo,
    sortBy,
    sortOrder,
    totalCount,
    onFilterChange,
    onBookingDateFilterChange,
    onSortChange
  } = props

  const [show, setShow] = useState<boolean>(true)
  const [isFiltersApplied, setIsFilterApplied] = useState<boolean>(false)
  const intl = useIntl()

  useEffect(() => {
    let isFilterApplied = false
    if (status || arrivalDate || bookedAtFrom || bookedAtTo) {
      isFilterApplied = true
    } else if (sortBy !== 'BOOKED_AT' || sortOrder !== 'DESC') {
      isFilterApplied = true
    }
    setIsFilterApplied(isFilterApplied)
    if (isFilterApplied) {
      setShow(true)
    }
  }, [status, arrivalDate, bookedAtFrom, bookedAtTo, sortBy, sortOrder])

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target
    onFilterChange({ name, value })
  }

  const handleSortChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target
    const [sortBy, sortOrder] = value.split('.')
    onSortChange({ sortBy, sortOrder: sortOrder as SortOrder })
  }

  const handleFilterButtonClick = (event: React.MouseEvent<unknown>) => {
    if (show) {
      Analytics.trackEvent(BOOKINGS_HIDE_FILTERS)
    } else {
      Analytics.trackEvent(BOOKINGS_SHOW_FILTERS)
    }
    setShow(!show)
  }

  const BigSearch = (
    <QuerySearchField
      placeholder={intl.formatMessage(messageKeys.FilterBigSearch)}
      margin="dense"
      name="searchText"
      size="small"
      sx={{
        width: 360,
        flexGrow: 1
      }}
      startAdornment={
        <InputAdornment position="start">
          <SearchIcon />
        </InputAdornment>
      }
      value={searchText || ''}
      onChange={handleChange}
    />
  )

  const TransactionId = (
    <QuerySearchField
      placeholder={intl.formatMessage(messageKeys.FilterTransactionId)}
      margin="dense"
      name="transactionId"
      size="small"
      sx={{
        width: 360,
        flexGrow: 1
      }}
      startAdornment={
        <InputAdornment position="start">
          <SearchIcon />
        </InputAdornment>
      }
      value={transactionId || ''}
      onChange={handleChange}
    />
  )

  const BookingStatuses = (
    <Box>
      <TextField
        sx={{
          width: 200
        }}
        label={intl.formatMessage(messageKeys.FilterBookingStatus)}
        margin="dense"
        name="status"
        size="small"
        value={status || ''}
        variant="standard"
        select
        onChange={handleChange}
      >
        <MenuItem value="">
          {intl.formatMessage(messageKeys.FilterBookingStatusAll)}
        </MenuItem>
        {STATUS_FILTER_OPTIONS.map(option => (
          <MenuItem key={option.value} value={option.value}>
            <Stack direction="row" alignItems="center" spacing={1}>
              <CircleIcon
                fontSize="small"
                color={option.color as 'success' | 'error'}
              />
              <span>{option.value}</span>
            </Stack>
          </MenuItem>
        ))}
      </TextField>
    </Box>
  )

  const ArrivalDateFilter = (
    <Box>
      <TextField
        sx={{
          width: 200
        }}
        label={intl.formatMessage(messageKeys.FilterArrivalDate)}
        margin="dense"
        name="arrivalDate"
        size="small"
        variant="standard"
        value={arrivalDate || ''}
        select
        onChange={handleChange}
      >
        <MenuItem value="">
          <FormattedMessage {...messageKeys.FilterArrivalDateAll} />
        </MenuItem>
        <MenuItem value="FUTURE">
          <FormattedMessage {...messageKeys.FilterArrivalDateFuture} />
        </MenuItem>
        <MenuItem value="PAST">
          <FormattedMessage {...messageKeys.FilterArrivalDatePast} />
        </MenuItem>
      </TextField>
    </Box>
  )

  const BookingDateRangeFilter = (
    <DateRangeFilter
      label={intl.formatMessage(messageKeys.FilterBookingDateRange)}
      from={bookedAtFrom}
      to={bookedAtTo}
      onChange={onBookingDateFilterChange}
      allowBeforeDays={[
        {
          key: 'TODAY',
          label: intl.formatMessage(globalMessages.TODAY),
          subtract: 0
        },
        {
          key: 'LAST_7_DAYS',
          label: intl.formatMessage(globalMessages.LAST_N_DAYS, {
            n: 7
          }),
          subtract: 6
        },
        {
          key: 'LAST_30_DAYS',
          label: intl.formatMessage(globalMessages.LAST_N_DAYS, {
            n: 30
          }),
          subtract: 29
        }
      ]}
      fromDateText={intl.formatMessage(globalMessages.FROM_DATE)}
      toDateText={intl.formatMessage(globalMessages.TO_DATE)}
      clearText={intl.formatMessage(globalMessages.CLEAR)}
      applyText={intl.formatMessage(globalMessages.APPLY)}
    />
  )

  const Sort = (
    <Box>
      <TextField
        select
        sx={{
          width: 360
        }}
        label={intl.formatMessage(messageKeys.FilterSort)}
        name="sort"
        margin="dense"
        size="small"
        variant="standard"
        value={`${sortBy}.${sortOrder}`}
        onChange={handleSortChange}
      >
        <MenuItem value="BOOKED_AT.ASC">
          <FormattedMessage {...messageKeys.FilterSortBookingDateAsc} />
        </MenuItem>
        <MenuItem value="BOOKED_AT.DESC">
          <FormattedMessage {...messageKeys.FilterSortBookingDateDesc} />
        </MenuItem>
        <MenuItem value="CHECKIN.ASC">
          <FormattedMessage {...messageKeys.FilterSortArrivalDateAsc} />
        </MenuItem>
        <MenuItem value="CHECKIN.DESC">
          <FormattedMessage {...messageKeys.FilterSortArrivalDateDesc} />
        </MenuItem>
      </TextField>
    </Box>
  )

  return (
    <Paper
      variant="outlined"
      sx={{
        width: '100%',
        mb: 2,
        p: 2
      }}
    >
      <Box display="flex" alignItems="center" gap={2} flexWrap="wrap">
        {BigSearch}
        {TransactionId}
        <Divider orientation="vertical" flexItem />
        <LabelValue
          label={intl.formatMessage(globalMessages.TOTAL_COUNT)}
          value={totalCount || 0}
        />
        <Divider orientation="vertical" flexItem />
        <FilterButton
          open={show}
          showBadge={isFiltersApplied && !show}
          onClick={handleFilterButtonClick}
        />
      </Box>
      <Collapse in={show}>
        <Box mt={1} display="flex" alignItems="center" gap={2} flexWrap="wrap">
          {BookingStatuses}
          {ArrivalDateFilter}
          {BookingDateRangeFilter}
          <Box flexGrow={1} />
          {Sort}
        </Box>
      </Collapse>
    </Paper>
  )
}

export default BookingsFilter
