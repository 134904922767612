import React from 'react'
import { FormattedMessage } from 'react-intl'

import { messageKeys as integrationDetailsKeys } from '../../IntegrationBasics/messages'
import { messageKeys as billingDetailsKeys } from '../../BillingInformation/messages'
import { messageKeys as customerServiceDetailsKeys } from '../../CustomerService/messages'
import { messageKeys as customerPaymentKeys } from '../../CustomerPayments/messages'
import { messageKeys as cancellationPolicyKeys } from '../../CancellationPolicy/messages'
import { messageKeys as regulatoryReportingKeys } from '../../RegulatoryReporting/messages'
import { messageKeys as commercialAgreementKeys } from '../../CommercialAgreement/messages'

export function getMissingFieldsForIntegrationBasics(provider, providerConfig) {
  let missingFieldsForIntegrationBasics = []
  if (provider && !provider.get('propertiesCount')) {
    missingFieldsForIntegrationBasics.push(
      <FormattedMessage {...integrationDetailsKeys.NumberOfProperties} />
    )
  }
  if (providerConfig && !providerConfig.get('name')) {
    missingFieldsForIntegrationBasics.push(
      <FormattedMessage {...integrationDetailsKeys.CompanyName} />
    )
  }
  if (providerConfig && !providerConfig.get('shortName')) {
    missingFieldsForIntegrationBasics.push(
      <FormattedMessage {...integrationDetailsKeys.ShortCompanyName} />
    )
  }
  if (providerConfig && !providerConfig.get('logopath')) {
    missingFieldsForIntegrationBasics.push(
      <FormattedMessage {...integrationDetailsKeys.LogoTitle} />
    )
  }
  return missingFieldsForIntegrationBasics
}

export function countMissingFieldsForIntegrationBasics(
  provider,
  providerConfig
) {
  return getMissingFieldsForIntegrationBasics(provider, providerConfig).length
}

export function getMissingFieldsForCustomerService(provider, providerConfig) {
  let missingFieldsForCustomerService = []
  if (provider && !provider.get('bookingNotificationEmail')) {
    missingFieldsForCustomerService.push(
      <FormattedMessage
        {...customerServiceDetailsKeys.BookingNotificationEmail}
      />
    )
  }
  if (providerConfig && !providerConfig.get('customerServiceEmail')) {
    missingFieldsForCustomerService.push(
      <FormattedMessage {...customerServiceDetailsKeys.CustomerServiceEmail} />
    )
  }
  if (providerConfig && !providerConfig.get('customerServicePhone')) {
    missingFieldsForCustomerService.push(
      <FormattedMessage {...customerServiceDetailsKeys.CustomerServicePhone} />
    )
  }
  return missingFieldsForCustomerService
}

export function getMissingFieldsForRegulatoryReporting(company) {
  let missingFieldsForRegulatoryReporting = []
  if (
    company &&
    company.get('entityType') === 'legal_business_entity' &&
    !company.get('registrationNumber')
  ) {
    missingFieldsForRegulatoryReporting.push(
      <FormattedMessage {...regulatoryReportingKeys.RegistrationNumber} />
    )
  }
  if (
    company &&
    company.get('entityType') === 'individual_entity' &&
    !company.get('birthDate')
  ) {
    missingFieldsForRegulatoryReporting.push(
      <FormattedMessage {...regulatoryReportingKeys.BirthDate} />
    )
  }
  if (company && !company.get('entityType')) {
    missingFieldsForRegulatoryReporting.push(
      <FormattedMessage {...regulatoryReportingKeys.BusinessType} />
    )
  }
  if (
    company &&
    !company.get('taxId') &&
    company.get('entityType') !== 'state_or_public_entity'
  ) {
    missingFieldsForRegulatoryReporting.push(
      <FormattedMessage {...regulatoryReportingKeys.TaxId} />
    )
  }
  return missingFieldsForRegulatoryReporting
}

export function countMissingFieldsForCustomerService(provider, providerConfig) {
  return getMissingFieldsForCustomerService(provider, providerConfig).length
}

export function getMissingFieldsForCustomerPaymentOptions(providerConfig) {
  let missingFieldsForCustomerPaymentOptions = []
  if (
    providerConfig &&
    !providerConfig.get('holiduHandlesPayments') &&
    providerConfig.get('paymentMethodConfigSource') !== 'request' &&
    ((providerConfig && !providerConfig.get('paymentMethods')) ||
      (providerConfig && !providerConfig.get('paymentMethods').size > 0))
  ) {
    missingFieldsForCustomerPaymentOptions.push(
      <FormattedMessage {...customerPaymentKeys.PaymentOptionsHeader} />
    )
  }
  return missingFieldsForCustomerPaymentOptions
}

export function countMissingFieldsForCustomerPaymentOptions(providerConfig) {
  return getMissingFieldsForCustomerPaymentOptions(providerConfig).length
}

export function getMissingFieldsForCancellationPolicy(providerConfig) {
  let missingFieldsForCancellationPolicy = []

  if (providerConfig && !providerConfig.get('cancellationPolicy')) {
    missingFieldsForCancellationPolicy.push(
      <FormattedMessage {...cancellationPolicyKeys.Title} />
    )
  }
  return missingFieldsForCancellationPolicy
}

export function countMissingFieldsForCancellationPolicy(providerConfig) {
  return getMissingFieldsForCancellationPolicy(providerConfig).length
}

export function getMissingFieldsForBillingInformation(company) {
  let missingFieldsForBillingInformation = []
  if (company && !company.get('legalCompanyName')) {
    missingFieldsForBillingInformation.push(
      <FormattedMessage {...billingDetailsKeys.LegalName} />
    )
  }
  if (company && !company.get('street')) {
    missingFieldsForBillingInformation.push(
      <FormattedMessage {...billingDetailsKeys.BillingAddress} />
    )
  }
  if (company && !company.get('number')) {
    missingFieldsForBillingInformation.push(
      <FormattedMessage {...billingDetailsKeys.BillingAddressHouseNumber} />
    )
  }
  if (company && !company.get('zip')) {
    missingFieldsForBillingInformation.push(
      <FormattedMessage {...billingDetailsKeys.BillingAddressZip} />
    )
  }
  if (company && !company.get('city')) {
    missingFieldsForBillingInformation.push(
      <FormattedMessage {...billingDetailsKeys.BillingAddressCity} />
    )
  }
  if (company && !company.get('country')) {
    missingFieldsForBillingInformation.push(
      <FormattedMessage {...billingDetailsKeys.BillingAddressCountry} />
    )
  }
  if (company && !company.get('invoiceRecipients')) {
    missingFieldsForBillingInformation.push(
      <FormattedMessage {...billingDetailsKeys.InvoiceRecipients} />
    )
  }
  if (company && !company.get('contactLanguage')) {
    missingFieldsForBillingInformation.push(
      <FormattedMessage {...billingDetailsKeys.ContactLanguage} />
    )
  }
  return missingFieldsForBillingInformation
}

export function countMissingFieldsForBillingInformation(company) {
  return getMissingFieldsForBillingInformation(company).length
}

export function countMissingFieldsForRegulatoryReporting(company) {
  return getMissingFieldsForRegulatoryReporting(company).length
}

export function checkKycApproved(kycStatus) {
  if (kycStatus.get('adyenOverallStatus') !== 'APPROVED') {
    return 1
  }
}

export function getMissingFieldsForCommercialAgreement(company, provider) {
  if (!company || company.get('holiduPaymentsEnabled')) {
    return []
  }

  let missingFieldsForCommercialAgreement = []
  if (company && !company.get('legalCompanyName')) {
    missingFieldsForCommercialAgreement.push(
      <FormattedMessage {...billingDetailsKeys.LegalName} />
    )
  }
  if (company && !company.get('street')) {
    missingFieldsForCommercialAgreement.push(
      <FormattedMessage {...billingDetailsKeys.BillingAddress} />
    )
  }
  if (company && !company.get('number')) {
    missingFieldsForCommercialAgreement.push(
      <FormattedMessage {...billingDetailsKeys.BillingAddressHouseNumber} />
    )
  }
  if (company && !company.get('zip')) {
    missingFieldsForCommercialAgreement.push(
      <FormattedMessage {...billingDetailsKeys.BillingAddressZip} />
    )
  }
  if (company && !company.get('city')) {
    missingFieldsForCommercialAgreement.push(
      <FormattedMessage {...billingDetailsKeys.BillingAddressCity} />
    )
  }
  if (company && !company.get('country')) {
    missingFieldsForCommercialAgreement.push(
      <FormattedMessage {...billingDetailsKeys.BillingAddressCountry} />
    )
  }
  if (company && !company.get('bankName')) {
    missingFieldsForCommercialAgreement.push(
      <FormattedMessage {...commercialAgreementKeys.BankName} />
    )
  }
  if (company && !company.get('sepaLocation')) {
    missingFieldsForCommercialAgreement.push(
      <FormattedMessage {...commercialAgreementKeys.Location} />
    )
  }
  if (company && !company.get('sepaChargeDate')) {
    missingFieldsForCommercialAgreement.push(
      <FormattedMessage {...commercialAgreementKeys.Date} />
    )
  }
  if (company && !company.get('sepaChargeConsent')) {
    missingFieldsForCommercialAgreement.push(
      <FormattedMessage {...commercialAgreementKeys.SepaTitle} />
    )
  }
  if (company && !company.get('iban')) {
    missingFieldsForCommercialAgreement.push(
      <FormattedMessage {...commercialAgreementKeys.Iban} />
    )
  }
  if (company && !company.get('bic')) {
    missingFieldsForCommercialAgreement.push(
      <FormattedMessage {...commercialAgreementKeys.Bic} />
    )
  }
  return missingFieldsForCommercialAgreement
}

export function countMissingFieldsForCommercialAgreement(company, provider) {
  return getMissingFieldsForCommercialAgreement(company, provider).length
}
