import React, { useState } from 'react'
import {
  Page,
  PageContent,
  PageHeader,
  ValidationAlert
} from '@holidu/pui-components/dist'
import useGetPropertyDetail from './useGetPropertyDetail'
import { useSelector } from 'react-redux'
import { getSelectedProvider } from '../../store/providerSelection/providerSelector'
import ErrorPageContent from '../../components/ErrorPage/ErrorPageContent'
import QueryUtils from 'query-string'
import ApartmentSection from './components/ApartmentSection'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Stack,
  Typography
} from '@mui/material'
import MapIcon from '@mui/icons-material/Map'
import LaunchIcon from '@mui/icons-material/Launch'
import Loader from '../../components/Loader'
import { useIntl } from 'react-intl'
import messageKeys from './messages'
import BookabilityScoreSection from './components/BookabilityScoreSection'
import PhotoScoreSection from './components/PhotoScoreSection'
import PropertyStatusChip from '../../components/PropertyStatusChip'
import useDeleteProperty from './useDeleteProperty'
import { messageKeys as globalMessageKeys } from '../../globalMessages'
import DeleteIcon from '@mui/icons-material/Delete'
import Analytics from '../../utils/analytics'
import {
  APARTMENT_DETAIL_CLICK_DELETE,
  APARTMENT_DETAIL_CLICK_DELETE_NO,
  APARTMENT_DETAIL_CLICK_DELETE_YES,
  APARTMENT_DETAIL_CLICK_SHOW_ON_HOLIDU,
  APARTMENT_DETAIL_CLICK_SHOW_ON_MAP
} from '../../utils/analytics/events'
import SubSection from '../../components/Section/SubSection'

const MAX_NUMBER_OF_VALIDATION_ERRORS = 3

const PropertyDetail = (): JSX.Element => {
  const { selectedProvider } = useSelector(getSelectedProvider)
  const intl = useIntl()
  const query = QueryUtils.parse(window.location.search)

  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState<boolean>(false)

  const { isLoading, isFetching, isError, error, data, refetch } =
    useGetPropertyDetail(selectedProvider, query.apartmentId as string)

  const { isLoading: isDeleteLoading, mutateAsync: mutateAsyncDelete } =
    useDeleteProperty(selectedProvider, query.apartmentId as string)

  const handleDelete = () => {
    Analytics.trackEvent(APARTMENT_DETAIL_CLICK_DELETE)
    setConfirmDeleteOpen(true)
  }

  const onNo = () => {
    Analytics.trackEvent(APARTMENT_DETAIL_CLICK_DELETE_NO)
    setConfirmDeleteOpen(false)
  }
  const onYes = async () => {
    Analytics.trackEvent(APARTMENT_DETAIL_CLICK_DELETE_YES)
    try {
      await mutateAsyncDelete()
      refetch()
    } catch (err) {}
    setConfirmDeleteOpen(false)
  }

  const handleLinkClick = (eventName: string) => () => {
    Analytics.trackEvent(eventName)
  }

  return (
    <Page>
      <PageHeader
        sx={{
          borderBottom: 'none'
        }}
      >
        {data && (
          <Box display="flex" width="100%" flexWrap="wrap">
            <Stack flexGrow="1" direction="row" alignItems="center" spacing={2}>
              <Typography variant="h6">
                {intl.formatMessage(messageKeys.APARTMENT)}:{' '}
                {data?.externalId || '-'}
              </Typography>
              <PropertyStatusChip
                status={data.status}
                size="small"
                label={data.status}
              />
            </Stack>
            <Stack
              direction="row"
              spacing={2}
              alignItems="center"
              justifyContent="flex-end"
            >
              {data.location && (
                <Button
                  startIcon={<MapIcon />}
                  target="_blank"
                  color="primary"
                  rel="noopener noreferrer"
                  onClick={handleLinkClick(APARTMENT_DETAIL_CLICK_SHOW_ON_MAP)}
                  href={`https://www.google.com/maps/search/?api=1&query=${data.location.latitude},${data.location.longitude}`}
                >
                  {intl.formatMessage(messageKeys.SHOW_ON_MAP)}
                </Button>
              )}
              <Button
                href={`https://www.holidu.com/d/${data.apartmentId}?currency=EUR`}
                target="_blank"
                startIcon={<LaunchIcon />}
                onClick={handleLinkClick(APARTMENT_DETAIL_CLICK_SHOW_ON_HOLIDU)}
              >
                {intl.formatMessage(messageKeys.SHOW_ON_HOLIDU)}
              </Button>
            </Stack>
          </Box>
        )}
      </PageHeader>
      <PageContent px={3} pb={8}>
        {isError ? (
          <ErrorPageContent status={error.message} />
        ) : (
          <>
            {data && (
              <Box>
                <Grid container spacing={2}>
                  {Boolean(data.validationErrorMessages?.length) && (
                    <Grid item xs={12}>
                      <ValidationAlert
                        title={intl.formatMessage(
                          messageKeys.VALIDATION_ERROR_HEADER
                        )}
                        maxDefaultItems={MAX_NUMBER_OF_VALIDATION_ERRORS}
                        validations={data.validationErrorMessages.map(
                          vError => {
                            return {
                              title: intl.formatMessage({
                                id: `PropertyDetail:VERROR_${vError}_TITLE`,
                                defaultMessage: `PropertyDetail:VERROR_${vError}_TITLE`
                              }),
                              description: intl.formatMessage({
                                id: `PropertyDetail:VERROR_${vError}_DESC`,
                                defaultMessage: `PropertyDetail:VERROR_${vError}_DESC`
                              })
                            }
                          }
                        )}
                        showMoreText={intl.formatMessage(
                          messageKeys.VALIDATION_ERROR_SHOW_MORE,
                          {
                            value:
                              data.validationErrorMessages.length -
                              MAX_NUMBER_OF_VALIDATION_ERRORS
                          }
                        )}
                        showLessText={intl.formatMessage(
                          messageKeys.VALIDATION_ERROR_SHOW_LESS
                        )}
                      />
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <ApartmentSection data={data} />
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <BookabilityScoreSection data={data} />
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <PhotoScoreSection data={data} />
                  </Grid>
                  {data.status !== 'DELETED' && (
                    <Grid item xs={12}>
                      <SubSection
                        title={intl.formatMessage(messageKeys.DELETE_PROPERTY)}
                      >
                        <Typography variant="body2">
                          {intl.formatMessage(messageKeys.DELETE_PROPERTY_DESC)}
                        </Typography>
                        <Box display="flex" mt={2} flexDirection="row-reverse">
                          <Button
                            variant="contained"
                            color="error"
                            startIcon={<DeleteIcon />}
                            onClick={handleDelete}
                          >
                            {intl.formatMessage(messageKeys.DELETE_PROPERTY)}
                          </Button>
                        </Box>
                      </SubSection>
                    </Grid>
                  )}
                </Grid>
              </Box>
            )}
          </>
        )}
        <Dialog open={confirmDeleteOpen}>
          <DialogTitle>
            {intl.formatMessage(messageKeys.DELETE_PROPERTY_CONFIRM_TITLE)}
          </DialogTitle>
          <DialogContent>
            {intl.formatMessage(messageKeys.DELETE_PROPERTY_CONFIRM_DESC)}
          </DialogContent>
          <DialogActions>
            <Button onClick={onNo} color="primary">
              {intl.formatMessage(globalMessageKeys.No)}
            </Button>
            <Button
              autoFocus
              onClick={onYes}
              variant="contained"
              color="primary"
            >
              {intl.formatMessage(globalMessageKeys.Yes)}
            </Button>
          </DialogActions>
        </Dialog>
        {(isLoading || isFetching || isDeleteLoading) && <Loader />}
      </PageContent>
    </Page>
  )
}

export default PropertyDetail
