import * as React from 'react'
import PropTypes from 'prop-types'
import Button from '@mui/material/Button'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import DialogTitle from '@mui/material/DialogTitle'
import Dialog from '@mui/material/Dialog'
import {
  DialogActions,
  DialogContent,
  Link,
  ListItemIcon,
  Stack,
  Typography
} from '@mui/material'
import { styled } from '@mui/material/styles'
import { FormattedMessage } from 'react-intl'
import { messageKeys } from '../messages'
import { OnboardingContents } from './utils'
import { Link as RouterLink } from 'react-router-dom'
import { messageKeys as commercialAgreementKeys } from '../../CommercialAgreement/messages'

const ImageDiv = styled('div')(({ theme }) => ({
  flexGrow: 1,
  display: 'flex',
  [theme.breakpoints.down('md')]: {
    display: 'none'
  }
}))

const StepsDiv = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    width: '60%'
  }
}))

const OnboardingGuideDialog = props => {
  const { onClose, open, showKycStep, showSignCommercialAgreement } = props
  return (
    <Dialog scroll="body" maxWidth="xl" disableEscapeKeyDown open={open}>
      <DialogTitle>
        <FormattedMessage {...messageKeys.OnboardingGuideHeader} />
      </DialogTitle>
      <DialogContent>
        <Typography variant="body2">
          <FormattedMessage {...messageKeys.OnboardingGuideSubHeader} />
        </Typography>
        <Stack direction="row" mt={2}>
          <StepsDiv>
            <List sx={{ pt: 0 }}>
              {Object.keys(OnboardingContents).map(key => {
                const item = OnboardingContents[key]
                let formattedMessageValues = null

                if (key === 'KYC' && !showKycStep) {
                  return null
                }
                if (key === 'SIGN' && showSignCommercialAgreement) {
                  return null
                }
                if (
                  key === 'SIGN_COMMERCIAL_AGREEMENT' &&
                  !showSignCommercialAgreement
                ) {
                  return null
                } else if (key === 'SIGN_COMMERCIAL_AGREEMENT') {
                  formattedMessageValues = {
                    link: (
                      <Link
                        component={RouterLink}
                        to={'/configuration/commercialAgreement'}
                      >
                        <FormattedMessage
                          {...commercialAgreementKeys.CommercialAgreement}
                        />
                      </Link>
                    )
                  }
                }

                return (
                  <ListItem
                    disablePadding
                    key={key}
                    sx={{ alignItems: 'flex-start' }}
                  >
                    <ListItemIcon sx={{ minWidth: 40, mt: '7px' }}>
                      {item.icon}
                    </ListItemIcon>
                    <ListItemText disableTypography>
                      <Typography variant="subtitle1">
                        <strong>
                          <FormattedMessage {...item.label} />
                        </strong>
                      </Typography>
                      <Typography variant="body2">
                        <FormattedMessage
                          {...item.desc}
                          values={formattedMessageValues}
                        />
                      </Typography>
                    </ListItemText>
                  </ListItem>
                )
              })}
            </List>
          </StepsDiv>
          <ImageDiv>
            <img
              style={{ width: '100%' }}
              alt="Onboarding"
              src="/static/img/vectors/EmptyProperties.svg"
            />
          </ImageDiv>
        </Stack>
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'flex-start', pl: 4, pb: 2 }}>
        <Button variant="contained" onClick={onClose}>
          <FormattedMessage {...messageKeys.OnboardingGuideUnderstood} />
        </Button>
      </DialogActions>
    </Dialog>
  )
}

OnboardingGuideDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool,
  showKycStep: PropTypes.bool
}

OnboardingGuideDialog.defaultProps = {
  open: false,
  showKycStep: false
}

export default OnboardingGuideDialog
