import { mutateAsync } from 'redux-query-immutable'
import { updateUserRequest } from './userRequest'

export const updateUser = (data, onSuccess = () => {}, onError = () => {}) => {
  return dispatch => {
    return dispatch(mutateAsync(updateUserRequest(data))).then(response => {
      if (response.status === 200) {
        onSuccess(response)
      } else {
        onError(response)
      }
    })
  }
}
