export const messageKeys = {
  Title: {
    id: 'CancellationPolicy:TITLE',
    defaultMessage: 'CancellationPolicy:TITLE'
  },
  CancellationHeader: {
    id: 'CancellationPolicy:CANCELLATION_HEADER',
    defaultMessage: 'CancellationPolicy:CANCELLATION_HEADER'
  },
  CancellationSubHeader: {
    id: 'CancellationPolicy:CANCELLATION_SUB_HEADER',
    defaultMessage: 'CancellationPolicy:CANCELLATION_SUB_HEADER'
  },
  CancellationRequestSubHeader: {
    id: 'CancellationPolicy:CANCELLATION_REQUEST_SUB_HEADER',
    defaultMessage: 'CancellationPolicy:CANCELLATION_REQUEST_SUB_HEADER'
  },
  CancellationCmSubHeader: {
    id: 'CancellationPolicy:CANCELLATION_CM_SUB_HEADER',
    defaultMessage: 'CancellationPolicy:CANCELLATION_CM_SUB_HEADER'
  },
  CancellationFree: {
    id: 'CancellationPolicy:CANCELLATION_FREE',
    defaultMessage: 'CancellationPolicy:CANCELLATION_FREE'
  },
  CancellationFlexible: {
    id: 'CancellationPolicy:CANCELLATION_FLEXIBLE',
    defaultMessage: 'CancellationPolicy:CANCELLATION_FLEXIBLE'
  },
  CancellationModerate: {
    id: 'CancellationPolicy:CANCELLATION_MODERATE',
    defaultMessage: 'CancellationPolicy:CANCELLATION_MODERATE'
  },
  CancellationStrict: {
    id: 'CancellationPolicy:CANCELLATION_STRICT',
    defaultMessage: 'CancellationPolicy:CANCELLATION_STRICT'
  },
  CancellationCustom: {
    id: 'CancellationPolicy:CANCELLATION_CUSTOM',
    defaultMessage: 'CancellationPolicy:CANCELLATION_CUSTOM'
  },
  CancellationStrictWarning: {
    id: 'CancellationPolicy:CANCELLATION_STRICT_WARNING',
    defaultMessage: 'CancellationPolicy:CANCELLATION_STRICT_WARNING'
  },
  CancellationFlexibleV1: {
    id: 'CancellationPolicy:CANCELLATION_FLEXIBLE_V1',
    defaultMessage: 'CancellationPolicy:CANCELLATION_FLEXIBLE_V1'
  },
  CancellationModerateV1: {
    id: 'CancellationPolicy:CANCELLATION_MODERATE_V1',
    defaultMessage: 'CancellationPolicy:CANCELLATION_MODERATE_V1'
  },
  CancellationFirmV1: {
    id: 'CancellationPolicy:CANCELLATION_FIRM_V1',
    defaultMessage: 'CancellationPolicy:CANCELLATION_FIRM_V1'
  },
  CancellationStrictV1: {
    id: 'CancellationPolicy:CANCELLATION_STRICT_V1',
    defaultMessage: 'CancellationPolicy:CANCELLATION_STRICT_V1'
  },
  CancellationDeprecated: {
    id: 'CancellationPolicy:CANCELLATION_DEPRECATED',
    defaultMessage: 'CancellationPolicy:CANCELLATION_DEPRECATED'
  },
  CANCEL_FLEXIBLE_LABEL: {
    id: 'CancellationPolicy:CANCEL_FLEXIBLE_LABEL',
    defaultMessage: 'CancellationPolicy:CANCEL_FLEXIBLE_LABEL'
  },
  CANCEL_FLEXIBLE_DESC: {
    id: 'CancellationPolicy:CANCEL_FLEXIBLE_DESC',
    defaultMessage: 'CancellationPolicy:CANCEL_FLEXIBLE_DESC'
  },
  CANCEL_MODERATE_LABEL: {
    id: 'CancellationPolicy:CANCEL_MODERATE_LABEL',
    defaultMessage: 'CancellationPolicy:CANCEL_MODERATE_LABEL'
  },
  CANCEL_MODERATE_DESC: {
    id: 'CancellationPolicy:CANCEL_MODERATE_DESC',
    defaultMessage: 'CancellationPolicy:CANCEL_MODERATE_DESC'
  },
  CANCEL_FIRM_LABEL: {
    id: 'CancellationPolicy:CANCEL_FIRM_LABEL',
    defaultMessage: 'CancellationPolicy:CANCEL_FIRM_LABEL'
  },
  CANCEL_FIRM_DESC: {
    id: 'CancellationPolicy:CANCEL_FIRM_DESC',
    defaultMessage: 'CancellationPolicy:CANCEL_FIRM_DESC'
  },
  CANCEL_STRICT_LABEL: {
    id: 'CancellationPolicy:CANCEL_STRICT_LABEL',
    defaultMessage: 'CancellationPolicy:CANCEL_STRICT_LABEL'
  },
  CANCEL_STRICT_DESC: {
    id: 'CancellationPolicy:CANCEL_STRICT_DESC',
    defaultMessage: 'CancellationPolicy:CANCEL_STRICT_DESC'
  },
  CUSTOM_LABEL: {
    id: 'CancellationPolicy:CUSTOM_LABEL',
    defaultMessage: 'CancellationPolicy:CUSTOM_LABEL'
  },
  CUSTOM_DESC: {
    id: 'CancellationPolicy:CUSTOM_DESC',
    defaultMessage: 'CancellationPolicy:CUSTOM_DESC'
  },
  DEPRECATED_LABEL: {
    id: 'CancellationPolicy:DEPRECATED_LABEL',
    defaultMessage: 'CancellationPolicy:DEPRECATED_LABEL'
  },
  DEPRECATED_DESC: {
    id: 'CancellationPolicy:DEPRECATED_DESC',
    defaultMessage: 'CancellationPolicy:DEPRECATED_DESC'
  },
  ContactHolidu: {
    id: 'CancellationPolicy:CONTACT_HOLIDU',
    defaultMessage: 'CancellationPolicy:CONTACT_HOLIDU'
  },
  PaymentRatesAlignOption: {
    id: 'CancellationPolicy:ALIGN_OPTION',
    defaultMessage: 'CancellationPolicy:ALIGN_OPTION'
  },
  PaymentRatesCustomOption: {
    id: 'CancellationPolicy:CUSTOM_OPTION',
    defaultMessage: 'CancellationPolicy:CUSTOM_OPTION'
  },
  PaymentRatesHead: {
    id: 'CustomerPayments:PAYMENT_RATES_HEADER',
    defaultMessage: 'CustomerPayments:PAYMENT_RATES_HEADER'
  },
  PaymentRatesSubHead: {
    id: 'CustomerPayments:PAYMENT_RATES_SUB_HEADER',
    defaultMessage: 'CustomerPayments:PAYMENT_RATES_SUB_HEADER'
  },
  PaymentRatesRequestHead: {
    id: 'CustomerPayments:PAYMENT_RATES_REQUEST_SUB_HEADER',
    defaultMessage: 'CustomerPayments:PAYMENT_RATES_REQUEST_SUB_HEADER'
  },
  PaymentRatesCmSubHead: {
    id: 'CustomerPayments:PAYMENT_RATES_CM_SUB_HEADER',
    defaultMessage: 'CustomerPayments:PAYMENT_RATES_CM_SUB_HEADER'
  },
  FirstRate: {
    id: 'CustomerPayments:FIRST_RATE',
    defaultMessage: 'CustomerPayments:FIRST_RATE'
  },
  Rate: {
    id: 'CustomerPayments:RATE',
    defaultMessage: 'CustomerPayments:RATE'
  },
  RatesMustSumTo100: {
    id: 'CustomerPayments:RATES_MUST_SUM_100',
    defaultMessage: 'CustomerPayments:RATES_MUST_SUM_100'
  },
  SomeOfPaymentRatesFieldsAreEmpty: {
    id: 'CustomerPayments:SOME_FIELDS_ARE_EMPTY',
    defaultMessage: 'CustomerPayments:SOME_FIELDS_ARE_EMPTY'
  },
  DaysBefore: {
    id: 'CustomerPayments:DAYS_BEFORE',
    defaultMessage: 'CustomerPayments:DAYS_BEFORE'
  },
  PercentOfBooking: {
    id: 'CustomerPayments:PERCENT_OF_BOOKING',
    defaultMessage: 'CustomerPayments:PERCENT_OF_BOOKING'
  },
  AddMore: {
    id: 'CustomerPayments:ADD_MORE_PAYMENT_RATES',
    defaultMessage: 'CustomerPayments:ADD_MORE_PAYMENT_RATES'
  }
}
