import React from 'react'
import { VerificationStatus } from '../types'
import { STATUS_COLORS } from '../utils'
import CheckCircle from '@mui/icons-material/CheckCircle'
import CancelRoundedIcon from '@mui/icons-material/CancelRounded'
import CircleIcon from '@mui/icons-material/Circle'
import WatchLaterIcon from '@mui/icons-material/WatchLater'

type StatusIconProps = {
  status: VerificationStatus
}

const StatusIcon = (props: StatusIconProps): JSX.Element | null => {
  const iconColor = STATUS_COLORS[props.status]

  switch (iconColor) {
    case 'success':
      return <CheckCircle color={iconColor} />
    case 'info':
      return <CircleIcon color={iconColor} />
    case 'warning':
      return <WatchLaterIcon color={iconColor} />
    case 'error':
      return <CancelRoundedIcon color={iconColor} />
    default:
      return null
  }
}

export default StatusIcon
