import React from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { connectRequest } from 'redux-query-immutable-react'

import { getProviderRequest } from '../../store/provider/providerRequest'
import { getProviderConfigurationRequest } from '../../store/providerConfiguration/providerConfigurationRequest'
import { getGeneralConfigSelector } from '../../store/providerConfiguration/providerConfigurationSelector'
import { updateGeneralConfiguration } from '../../store/providerConfiguration/actions'
import { getSelectedProvider } from '../../store/providerSelection/providerSelector'

import { RequestsAreDone } from '../RequestsAreDone'
import { getProviderSelector } from '../../store/provider/providerSelector'
import { getMissingFieldsForCustomerService } from '../Dashboard/OnBoarding/RequiredFieldsFetcher'
import InformationMissingError from '../../components/InformationMissingError'

import CustomerSerivcePage from './CustomerSerivcePage'
import { Page, PageContent, PageHeader } from '@holidu/pui-components/dist'
import { messageKeys } from './messages'
import { FormattedMessage } from 'react-intl'
import { Typography } from '@mui/material'

const CustomerService = props => {
  const { providers, providersConfig, selectedProvider } = props
  const provider = providers.get(selectedProvider)
  const providerConfig = providersConfig.get(selectedProvider)
  const csFields = getMissingFieldsForCustomerService(provider, providerConfig)

  return (
    <Page>
      <PageHeader
        sx={{
          borderBottom: 'none'
        }}
      >
        <Typography variant="h6">
          <FormattedMessage {...messageKeys.Title} />
        </Typography>
      </PageHeader>
      <PageContent px={3} pb={8}>
        {provider && provider.get('status') !== 'LIVE' && csFields.length ? (
          <InformationMissingError />
        ) : null}
        <CustomerSerivcePage
          providers={props.providers}
          providersConfig={props.providersConfig}
          selectedProvider={props.selectedProvider}
          updateCustomerServiceData={props.updateCustomerServiceData}
        />
      </PageContent>
    </Page>
  )
}

const mapDispatchToProps = (dispatch, props) => ({
  updateCustomerServiceData: (data, onSuccess, onError) => {
    dispatch(
      updateGeneralConfiguration(
        { ...data, selectedProvider: props.selectedProvider },
        onSuccess,
        onError
      )
    )
  }
})

export default compose(
  connect(getSelectedProvider),
  connect(getGeneralConfigSelector, mapDispatchToProps),
  connect(getProviderSelector),
  connectRequest(getProviderRequest),
  connectRequest(getProviderConfigurationRequest),
  RequestsAreDone
)(CustomerService)
