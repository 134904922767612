import React, { useEffect, useState } from 'react'
import queryString from 'query-string'
import { confirmUserSignUp } from '../../utils/awsCognitoClient'
import {
  PATH_LOGIN,
  PATH_CODE_EXPIRED,
  PATH_CODE_INVALID
} from '../../routes/PathConstants'
import Loader from '../../components/Loader'
import { useHistory, useLocation } from 'react-router'
import { Alert, AlertTitle } from '@mui/material'

const ConfirmSignupPage = () => {
  // eslint-disable-next-line
  const [errorMessage, setErrorMessage] = useState('')
  const history = useHistory()
  const location = useLocation()

  useEffect(() => {
    const code =
      getFieldFromQueryString('code', location.search) === undefined
        ? 0
        : getFieldFromQueryString('code', location.search)

    const username =
      getFieldFromQueryString('username', location.search) === undefined
        ? 0
        : getFieldFromQueryString('username', location.search)

    confirmUserSignUp(
      username,
      code,
      () => {
        history.push(PATH_LOGIN + '?verified=true')
      },
      (err: any) => {
        if (err.name === 'ExpiredCodeException') {
          history.push(PATH_CODE_EXPIRED)
        } else if (err.name === 'NotAuthorizedException') {
          history.push(PATH_CODE_INVALID)
        } else {
          setErrorMessage(err?.message || 'Error')
        }
      }
    )
  }, []) // eslint-disable-line

  if (errorMessage) {
    return (
      <Alert severity="error">
        <AlertTitle>Error</AlertTitle>
        {errorMessage}
      </Alert>
    )
  }
  return <Loader text="Confirming user" />
}

const getFieldFromQueryString = (field: string, search: string) =>
  queryString.parse(search)[field]

export default ConfirmSignupPage
