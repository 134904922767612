import { List } from 'immutable'

export const getChannelManagersSelector = state => ({
  channelManagers: state.get('entities').get('channelManagers', new List())
})

export const getAllChannelManagerConfigsSelector = state => ({
  channelManagerConfigs: state
    .get('entities')
    .get('channelManagerConfigs', new List())
})
