import React from 'react'
import {
  Grid,
  Stack,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  Button
} from '@mui/material'
import { Box } from '@mui/system'
import { FormattedMessage } from 'react-intl'
import { messageKeys as finalizeMessageKeys } from '../messages'

const SelectCmModal = props => {
  const { channelManagerConfigs, onClose, onSelect, open } = props

  const handleClick = e => {
    onSelect(e.currentTarget.dataset.cm)
    onClose()
  }

  return (
    <Dialog maxWidth="lg" fullWidth open={open} onClose={onClose}>
      <DialogTitle>
        <FormattedMessage {...finalizeMessageKeys.SELECT_CHANNEL_MANAGER} />
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          {/* Integration Channel */}
          {channelManagerConfigs.map((cm, index) => (
            <Grid key={index} xs={12} md={4} lg={3} item>
              <Button
                color="primary"
                sx={{ justifyContent: 'flex-start', textTransform: 'unset' }}
                variant="outlined"
                fullWidth
                data-cm={cm.get('identifier')}
                onClick={handleClick}
              >
                <Stack direction="row" spacing={2} alignItems="center">
                  <Box
                    display="flex"
                    alignItems="center"
                    minHeight={48}
                    minWidth={48}
                  >
                    <img
                      style={{
                        maxHeight: '48px',
                        maxWidth: '48px'
                      }}
                      alt="Logo"
                      src={cm.get('logoPath') || '/static/img/empty.jpg'}
                    />
                  </Box>
                  <Typography noWrap>
                    {cm.get('name') || cm.get('identifier')}
                  </Typography>
                </Stack>
              </Button>
            </Grid>
          ))}
        </Grid>
      </DialogContent>
    </Dialog>
  )
}

export default SelectCmModal
