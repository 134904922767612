import React from 'react'
import LinearProgress, {
  linearProgressClasses,
  LinearProgressProps
} from '@mui/material/LinearProgress'
import { styled } from '@mui/material/styles'
import { getScoreColor, getScoreText } from '../../utils/scoreUtil'
import { Box, Stack, Typography, TypographyProps } from '@mui/material'

const LinearScoreProgress = styled(LinearProgress)(({ theme, value }) => ({
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[300]
  },
  [`& .${linearProgressClasses.bar}`]: {
    backgroundColor: value ? getScoreColor(value) : theme.palette.grey[300]
  }
}))

type LinearScoreProps = LinearProgressProps & {
  value?: number
  TypographyProps?: TypographyProps
}

const LinearScore = (props: LinearScoreProps): JSX.Element => {
  const { value = 0, TypographyProps, ...restProps } = props
  return (
    <Stack
      alignItems="center"
      direction="row"
      sx={{
        width: '100%'
      }}
      color={getScoreColor(value)}
    >
      <Typography
        variant="subtitle1"
        color="inherit"
        component="span"
        {...TypographyProps}
      >
        {getScoreText(value)}
      </Typography>
      <Box width="100%" ml={1}>
        <LinearScoreProgress
          variant="determinate"
          value={value}
          {...restProps}
        />
      </Box>
    </Stack>
  )
}

export default LinearScore
