import React, { useEffect, useState } from 'react'
import {
  Button,
  Typography,
  TextField,
  Box,
  Stack,
  Alert,
  Link
} from '@mui/material'
import { FormattedMessage } from 'react-intl'
import queryString from 'query-string'
import { Link as RouterLink } from 'react-router-dom'
import { signIn, resendVerification } from '../../utils/awsCognitoClient'
import {
  PATH_CHANGE_TEMP_PASSWORD,
  PATH_DASHBOARD,
  PATH_RESET_PASSWORD,
  PATH_SIGNUP,
  PATH_CONFIRM
} from '../../routes/PathConstants'
import { clearLocalStorage } from '../../localStorage'
import { startTransaction } from '../../utils/apm'
import { getRedirectUrl } from '../../utils/redirectUrl'
import { messageKeys } from './messages'
import Analytics from '../../utils/analytics'

const SignInPage = props => {
  const [state, setState] = useState({
    email: '',
    password: '',
    error: '',
    errorCode: '',
    verified: false
  })

  useEffect(() => {
    clearLocalStorage()
    Analytics.reset()
  }, [])

  useEffect(() => {
    if (getFieldFromQueryString('verified', props.location.search) === 'true') {
      setState(state => ({
        ...state,
        verified: true
      }))
    }
  }, [props.location.search])

  const cleanError = () => {
    setState({
      ...state,
      error: ''
    })
  }

  const onClickSignIn = async () => {
    const { email, password } = state
    cleanError()
    const transaction = startTransaction('SIGN_IN')
    try {
      await signIn(email, password, () =>
        props.history.replace(PATH_CHANGE_TEMP_PASSWORD + email)
      )
      transaction.end()
      const redirectUrl = getRedirectUrl()
      if (redirectUrl) {
        props.history.replace(redirectUrl)
      } else {
        props.history.replace(PATH_DASHBOARD)
      }
    } catch (err) {
      setState({
        ...state,
        errorCode: err.code,
        error: err.message
      })
      transaction.end()
    }
  }

  const handleResendVerificationEmail = async () => {
    const { email, password } = state
    try {
      await resendVerification(email, password)
      props.history.replace(PATH_CONFIRM)
    } catch (err) {
      console.log('err', err)
    }
  }

  const handleChange = event => {
    const { id, value } = event.target
    cleanError()
    setState({
      ...state,
      [id]: id === 'email' ? value.trim() : value
    })
  }

  const handleKeyDown = event => {
    if (event.keyCode === 13) {
      onClickSignIn()
    }
  }

  return (
    <form>
      <Stack spacing={2} alignItems="center">
        <Typography variant="h6">
          <FormattedMessage {...messageKeys.SignInHeader} />
        </Typography>

        {state.verified && (
          <Alert variant="outlined" severity="success">
            <FormattedMessage {...messageKeys.UserConfirmed} />
          </Alert>
        )}

        <TextField
          id="email"
          label="Email Address"
          value={state.email}
          variant="outlined"
          fullWidth
          required
          onKeyDown={handleKeyDown}
          onChange={handleChange}
        />

        <TextField
          id="password"
          label="Password"
          type="password"
          value={state.password}
          fullWidth
          required
          onKeyDown={handleKeyDown}
          onChange={handleChange}
        />

        {state.error ? (
          <div>
            {['UserNotConfirmedException'].includes(state.errorCode) ? (
              <Alert severity="error">
                <FormattedMessage {...messageKeys.UserNotConfirmed} />
                <span
                  style={{
                    display: 'block',
                    color: '#1976d2',
                    fontWeight: '700',
                    cursor: 'pointer'
                  }}
                  onClick={handleResendVerificationEmail}
                >
                  <FormattedMessage {...messageKeys.ResendVerificationLink} />
                </span>
              </Alert>
            ) : (
              <Alert severity="error">{state.error}</Alert>
            )}
          </div>
        ) : null}

        <Button
          color="primary"
          mb={2}
          variant="contained"
          fullWidth
          onClick={onClickSignIn}
        >
          <FormattedMessage {...messageKeys.SignInButton} />
        </Button>

        <Link component={RouterLink} to={PATH_RESET_PASSWORD}>
          <FormattedMessage {...messageKeys.ForgotPassLink} />
        </Link>
        <Box>
          <FormattedMessage {...messageKeys.IfYouDoNotHaveAccountYouCan} />
          &nbsp;
          <Link component={RouterLink} to={PATH_SIGNUP}>
            <FormattedMessage {...messageKeys.SignUpHereButton} />
          </Link>
        </Box>
      </Stack>
    </form>
  )
}

const getFieldFromQueryString = (field, search) =>
  queryString.parse(search)[field]

export default SignInPage
