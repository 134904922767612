import React from 'react'
import { Typography, Button, TextField, Stack } from '@mui/material'
import { FormattedMessage } from 'react-intl'

import { resendVerification } from '../../utils/awsCognitoClient'
import { PATH_CONFIRM } from '../../routes/PathConstants'
import { startTransaction } from '../../utils/apm'
import { messageKeys } from './messages'
import { useHistory } from 'react-router'

const CodeExpired = () => {
  const history = useHistory()
  const [state, setState] = React.useState({
    email: ''
  })

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState({
      ...state,
      [event.target.id]: event.target.value
    })
  }

  const handleResendVerificationEmail = async () => {
    const { email } = state
    const transaction = startTransaction('ResendVerification#Resend')
    try {
      await resendVerification(email)
      transaction.end()
      history.push(PATH_CONFIRM)
    } catch (err) {
      console.log('err', err)
      transaction.end()
    }
  }

  return (
    <Stack spacing={2} alignItems="center">
      <Typography variant="h5" gutterBottom>
        <FormattedMessage {...messageKeys.VerificationErrorHeader} />
      </Typography>
      <Typography variant="body2">
        <FormattedMessage {...messageKeys.ExpirationCodeExceptionMessage} />
      </Typography>
      <TextField
        id="email"
        size="small"
        label="Email Address"
        margin="normal"
        value={state.email}
        variant="outlined"
        fullWidth
        required
        onChange={handleChange}
      />
      <Button
        variant="contained"
        color="primary"
        fullWidth
        onClick={handleResendVerificationEmail}
      >
        <FormattedMessage {...messageKeys.ResendVerificationButton} />
      </Button>
    </Stack>
  )
}

export default CodeExpired
