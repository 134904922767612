import axios from 'axios'
import { useMutation } from '@tanstack/react-query'
import { messageKeys as globalMessageKeys } from '../globalMessages'  
import { useIntl } from 'react-intl'
import { useSnackbar } from 'notistack'

const useGetValidateVat = () => {
  const { enqueueSnackbar } = useSnackbar()
  const intl = useIntl()
  return useMutation<any, Error>(
    async (data) => {
      return await axios.post('/vat/validate', data)
    },
    {
      onError: error => {
        enqueueSnackbar(
          intl.formatMessage(globalMessageKeys.Error, {
            error: error.message
          }),
          {
            variant: 'error'
          }
        )
      },
    }
  )
}

export default useGetValidateVat
