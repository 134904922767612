export const messageKeys = {
  Greatings: {
    id: 'OnboardingPage:GREATINGS',
    defaultMessage: 'OnboardingPage:GREATINGS {user}'
  },
  Header: {
    id: 'OnboardingPage:HEADER',
    defaultMessage: 'OnboardingPage:HEADER'
  },
  SubHeader: {
    id: 'OnboardingPage:SUB_HEADER',
    defaultMessage: 'OnboardingPage:SUB_HEADER'
  },
  FieldMissing: {
    id: 'OnboardingPage:MISSING_FIELD',
    defaultMessage: 'OnboardingPage:MISSING_FIELD {field}'
  },
  connectionDetailsHeading: {
    id: 'OnboardingPage:CONNECTION_DETAILS_HEADING',
    defaultMessage: 'OnboardingPage:CONNECTION_DETAILS_HEADING'
  },
  integrationChannelHeading: {
    id: 'OnboardingPage:INTEGRATION_CHANNEL_HEADING',
    defaultMessage: 'OnboardingPage:INTEGRATION_CHANNEL_HEADING'
  },
  integrationChannelHoliduWillContact: {
    id: 'OnboardingPage:INTEGRATION_CHANNEL_HOLIDU_WILL_CONTACT',
    defaultMessage: 'OnboardingPage:INTEGRATION_CHANNEL_HOLIDU_WILL_CONTACT'
  },
  integrationChannelApi: {
    id: 'OnboardingPage:INTEGRATION_CHANNEL_API',
    defaultMessage: 'OnboardingPage:INTEGRATION_CHANNEL_API'
  },
  yourIdentifierHeading: {
    id: 'OnboardingPage:YOUR_IDENTIFIER',
    defaultMessage: 'OnboardingPage:YOUR_IDENTIFIER'
  },
  contactHoliduHeading: {
    id: 'OnboardingPage:CONTACT_HOLIDU',
    defaultMessage: 'OnboardingPage:CONTACT_HOLIDU'
  },
  contactMe: {
    id: 'OnboardingPage:BIZ_DEV_CONTACT_ME',
    defaultMessage: 'OnboardingPage:BIZ_DEV_CONTACT_ME'
  },
  scheduleAppointment: {
    id: 'OnboardingPage:BIZ_DEV_SCHEDULE_APPOINTMENT',
    defaultMessage: 'OnboardingPage:BIZ_DEV_SCHEDULE_APPOINTMENT'
  },
  // Dashboard Stats Card
  statsActiveProperties: {
    id: 'Dashboard:STATS_ACTIVE_PROPERTIES',
    defaultMessage: 'Dashboard:STATS_ACTIVE_PROPERTIES'
  },
  statsActivePropertiesLink: {
    id: 'Dashboard:STATS_ACTIVE_PROPERTIES_LINK',
    defaultMessage: 'Dashboard:STATS_ACTIVE_PROPERTIES_LINK'
  },
  statsInactiveProperties: {
    id: 'Dashboard:STATS_INACTIVE_PROPERTIES',
    defaultMessage: 'Dashboard:STATS_INACTIVE_PROPERTIES'
  },
  statsInactivePropertiesLink: {
    id: 'Dashboard:STATS_INACTIVE_PROPERTIES_LINK',
    defaultMessage: 'Dashboard:STATS_INACTIVE_PROPERTIES_LINK'
  },
  statsDeletedProperties: {
    id: 'Dashboard:STATS_DELETED_PROPERTIES',
    defaultMessage: 'Dashboard:STATS_DELETED_PROPERTIES'
  },
  statsDeletedPropertiesLink: {
    id: 'Dashboard:STATS_DELETED_PROPERTIES_LINK',
    defaultMessage: 'Dashboard:STATS_DELETED_PROPERTIES_LINK'
  },
  statsBadHeroImageProperties: {
    id: 'Dashboard:STATS_BAD_HERO_IMAGE_PROPERTIES',
    defaultMessage: 'Dashboard:STATS_BAD_HERO_IMAGE_PROPERTIES'
  },
  statsBadHeroImagePropertiesLink: {
    id: 'Dashboard:STATS_BAD_HERO_IMAGE_PROPERTIES_LINK',
    defaultMessage: 'Dashboard:STATS_BAD_HERO_IMAGE_PROPERTIES_LINK'
  },
  statsAverageHoliduScore: {
    id: 'Dashboard:STATS_AVERAGE_HOLIDU_SCORE',
    defaultMessage: 'Dashboard:STATS_AVERAGE_HOLIDU_SCORE'
  },
  statsHoliduScoreComponents: {
    id: 'Dashboard:STATS_HOLIDU_SCORE_COMPONENTS',
    defaultMessage: 'Dashboard:STATS_HOLIDU_SCORE_COMPONENTS'
  },
  statsAveragePhotoScore: {
    id: 'Dashboard:STATS_AVERAGE_PHOTO_SCORE',
    defaultMessage: 'Dashboard:STATS_AVERAGE_PHOTO_SCORE'
  },
  statsLowPhotoScore: {
    id: 'Dashboard:STATS_LOW_PHOTO_SCORE',
    defaultMessage: 'Dashboard:STATS_LOW_PHOTO_SCORE'
  },
  statsAverageBookabilityScore: {
    id: 'Dashboard:STATS_AVERAGE_BOOKABILITY_SCORE',
    defaultMessage: 'Dashboard:STATS_AVERAGE_BOOKABILITY_SCORE'
  },
  statsLowBookabilityScore: {
    id: 'Dashboard:STATS_LOW_BOOKABILITY_SCORE',
    defaultMessage: 'Dashboard:STATS_LOW_BOOKABILITY_SCORE'
  },
  statsTooltipRange: {
    id: 'Dashboard:STATS_TOOLTIP_RANGE',
    defaultMessage: 'Dashboard:STATS_TOOLTIP_RANGE'
  },
  statsTooltipApartments: {
    id: 'Dashboard:STATS_TOOLTIP_APARTMENTS',
    defaultMessage: 'Dashboard:STATS_TOOLTIP_APARTMENTS'
  },
  statsVisitAparments: {
    id: 'Dashboard:STATS_VISIT_APARTMENTS',
    defaultMessage: 'Dashboard:STATS_VISIT_APARTMENTS'
  },
  // MissingInfo
  InformationMissingTitle: {
    id: 'Dashboard:INFO_MISSING_TITLE',
    defaultMessage: 'Dashboard:INFO_MISSING_TITLE'
  },
  InformationMissingError: {
    id: 'Dashboard:INFO_MISSING_ERROR',
    defaultMessage: 'Dashboard:INFO_MISSING_ERROR'
  },
  // MissingCancellationPolicy
  CpMissingTitle: {
    id: 'Dashboard:CP_MISSING_TITLE',
    defaultMessage: 'Dashboard:CP_MISSING_TITLE'
  },
  CpMissingError: {
    id: 'Dashboard:CP_MISSING_ERROR',
    defaultMessage: 'Dashboard:CP_MISSING_ERROR'
  },
  // Integration Complete
  IntegrationCompletedHeader: {
    id: 'Dashboard:INTEGRATION_COMPLETED_HEADER',
    defaultMessage: 'Dashboard:INTEGRATION_COMPLETED_HEADER'
  },
  // Integration Complete Message 1
  IntegrationCompletedMessage1Header: {
    id: 'Dashboard:INTEGRATION_COMPLETED_MESSAGE1_HEADER',
    defaultMessage: 'Dashboard:INTEGRATION_COMPLETED_MESSAGE1_HEADER'
  },
  // Integration Complete Message 2
  IntegrationCompletedMessage2Header: {
    id: 'Dashboard:INTEGRATION_COMPLETED_MESSAGE2_HEADER',
    defaultMessage: 'Dashboard:INTEGRATION_COMPLETED_MESSAGE2_HEADER'
  },
  IntegrationCompletedMessage2Option1: {
    id: 'Dashboard:INTEGRATION_COMPLETED_MESSAGE2_OPTION1',
    defaultMessage: 'Dashboard:INTEGRATION_COMPLETED_MESSAGE2_OPTION1'
  },
  IntegrationCompletedMessage2Option2: {
    id: 'Dashboard:INTEGRATION_COMPLETED_MESSAGE2_OPTION2',
    defaultMessage: 'Dashboard:INTEGRATION_COMPLETED_MESSAGE2_OPTION2'
  },
  IntegrationCompletedMessage2Option3: {
    id: 'Dashboard:INTEGRATION_COMPLETED_MESSAGE2_OPTION3',
    defaultMessage: 'Dashboard:INTEGRATION_COMPLETED_MESSAGE2_OPTION3'
  },
  // Integration Complete FAQ
  IntegrationCompletedFAQMessage: {
    id: 'Dashboard:INTEGRATION_COMPLETED_FAQ_MESSAGE',
    defaultMessage: 'Dashboard:INTEGRATION_COMPLETED_FAQ_MESSAGE'
  },
  IntegrationCompletedFAQLink: {
    id: 'Dashboard:INTEGRATION_COMPLETED_FAQ_LINK',
    defaultMessage: 'Dashboard:INTEGRATION_COMPLETED_FAQ_LINK'
  },
  // Configuration Complete
  ConfigurationCompletedHeader: {
    id: 'Dashboard:CONFIGURATION_COMPLETED_HEADER',
    defaultMessage: 'Dashboard:CONFIGURATION_COMPLETED_HEADER'
  },
  // Configuration Complete Message 1
  ConfigurationCompletedMessage1Header: {
    id: 'Dashboard:CONFIGURATION_COMPLETED_MESSAGE1_HEADER',
    defaultMessage: 'Dashboard:CONFIGURATION_COMPLETED_MESSAGE1_HEADER'
  },
  ConfigurationCompletedMessage1Option1: {
    id: 'Dashboard:CONFIGURATION_COMPLETED_MESSAGE1_OPTION1',
    defaultMessage: 'Dashboard:CONFIGURATION_COMPLETED_MESSAGE1_OPTION1'
  },
  ConfigurationCompletedMessage1Option2: {
    id: 'Dashboard:CONFIGURATION_COMPLETED_MESSAGE1_OPTION2',
    defaultMessage: 'Dashboard:CONFIGURATION_COMPLETED_MESSAGE1_OPTION2'
  },
  // Configuration Complete FAQ
  ConfigurationCompletedFAQMessage: {
    id: 'Dashboard:CONFIGURATION_COMPLETED_FAQ_MESSAGE',
    defaultMessage: 'Dashboard:CONFIGURATION_COMPLETED_FAQ_MESSAGE'
  },
  ConfigurationCompletedFAQLink: {
    id: 'Dashboard:CONFIGURATION_COMPLETED_FAQ_LINK',
    defaultMessage: 'Dashboard:CONFIGURATION_COMPLETED_FAQ_LINK'
  },

  // Onboarding
  OnboardingGuideHeader: {
    id: 'OnboardingGuide:HEADER',
    defaultMessage: 'OnboardingGuide:HEADER'
  },
  OnboardingGuideSubHeader: {
    id: 'OnboardingGuide:SUBHEADER',
    defaultMessage: 'OnboardingGuide:SUBHEADER'
  },
  OnboardingGuideUnderstood: {
    id: 'OnboardingGuide:UNDERSTOOD',
    defaultMessage: 'OnboardingGuide:UNDERSTOOD'
  },
  OnboardingStepSign_Label: {
    id: 'OnboardingStep:SIGN_LABEL',
    defaultMessage: 'OnboardingStep:SIGN_LABEL'
  },
  OnboardingStepSign_Desc: {
    id: 'OnboardingStep:SIGN_DESC',
    defaultMessage: 'OnboardingStep:SIGN_DESC'
  },
  OnboardingStepSignCommercialAgreement_Label: {
    id: 'OnboardingStep:SIGN_COMMERCIAL_AGREEMENT_LABEL',
    defaultMessage: 'OnboardingStep:SIGN_COMMERCIAL_AGREEMENT_LABEL'
  },
  OnboardingStepSignCommercialAgreement_Desc: {
    id: 'OnboardingStep:SIGN_COMMERCIAL_AGREEMENT_DESC',
    defaultMessage: 'OnboardingStep:SIGN_COMMERCIAL_AGREEMENT_DESC'
  },
  OnboardingStepEnableCM_Label: {
    id: 'OnboardingStep:Enable_CM_LABEL',
    defaultMessage: 'OnboardingStep:Enable_CM_LABEL'
  },
  OnboardingStepEnableCM_Desc: {
    id: 'OnboardingStep:Enable_CM_DESC',
    defaultMessage: 'OnboardingStep:Enable_CM_DESC'
  },
  OnboardingStepKyc_Label: {
    id: 'OnboardingStep:KYC_LABEL',
    defaultMessage: 'OnboardingStep:KYC_LABEL'
  },
  OnboardingStepKyc_Desc: {
    id: 'OnboardingStep:KYC_DESC',
    defaultMessage: 'OnboardingStep:KYC_DESC'
  },
  OnboardingStepConfiguration_Label: {
    id: 'OnboardingStep:CONFIG_LABEL',
    defaultMessage: 'OnboardingStep:CONFIG_LABEL'
  },
  OnboardingStepConfiguration_Desc: {
    id: 'OnboardingStep:CONFIG_DESC',
    defaultMessage: 'OnboardingStep:CONFIG_DESC'
  },
  OnboardingStepTestBooking_Label: {
    id: 'OnboardingStep:TEST_BOOKING_LABEL',
    defaultMessage: 'OnboardingStep:TEST_BOOKING_LABEL'
  },
  OnboardingStepTestBooking_Desc: {
    id: 'OnboardingStep:TEST_BOOKING_DESC',
    defaultMessage: 'OnboardingStep:TEST_BOOKING_DESC'
  },
  OnboardingStepReady_Label: {
    id: 'OnboardingStep:READY_LABEL',
    defaultMessage: 'OnboardingStep:READY_LABEL'
  },
  OnboardingStepReady_Desc: {
    id: 'OnboardingStep:READY_DESC',
    defaultMessage: 'OnboardingStep:READY_DESC'
  },
  OnboardingStepIssues: {
    id: 'OnboardingStep:ISSUES',
    defaultMessage: 'OnboardingStep:ISSUES'
  },
  OnboardingStepGood: {
    id: 'OnboardingStep:GOOD',
    defaultMessage: 'OnboardingStep:GOOD'
  },
  OnboardingStepHoliduPaymentLink: {
    id: 'OnboardingStep:HOLIDU_PAYMENT_LINK',
    defaultMessage: 'OnboardingStep:HOLIDU_PAYMENT_LINK'
  }
}
