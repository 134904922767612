import React from 'react'
import {
  Button,
  ButtonGroup,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Popover,
  Tooltip,
  Typography
} from '@mui/material'
import moment from 'moment'
import CloudDownloadIcon from '@mui/icons-material/CloudDownload'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'

import { useIntl } from 'react-intl'
import downloadFile from '../../utils/downloadFile'
import { DATE_TIME_FORMAT } from '../../utils/dateUtil'
import messageKeys from './messages'
import { Invoice } from './types'

type DownloadInvoiceProps = {
  invoices: Invoice[]
}

const DownloadInvoice = (props: DownloadInvoiceProps): JSX.Element => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)
  const intl = useIntl()
  const { invoices } = props
  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(e.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleDownload = (item: Invoice) => () => {
    const path = `/bookings/invoice/${item.id}`
    const fileName = `invoice_${item.invoiceNumber}.pdf`
    downloadFile(path, fileName, 'application/pdf')
  }

  const getTypeText = (type?: Invoice['type']) => {
    if (type && messageKeys[`INVOICE_TYPE_${type}`]) {
      return intl.formatMessage(messageKeys[`INVOICE_TYPE_${type}`])
    }
    return type
  }

  if (invoices.length) {
    const firstInvoice = invoices[0]
    return (
      <>
        <ButtonGroup
          size="small"
          variant="contained"
          color="primary"
          aria-label="download invoice"
        >
          <Tooltip title={intl.formatMessage(messageKeys.DownloadInvoice)}>
            <Button
              startIcon={<CloudDownloadIcon />}
              onClick={handleDownload(firstInvoice)}
            >
              {`${intl.formatMessage(messageKeys.Invoice)} ${
                firstInvoice.invoiceNumber
              }`}
            </Button>
          </Tooltip>
          {invoices.length > 1 && (
            <Tooltip title={intl.formatMessage(messageKeys.ShowAllInvoices)}>
              <Button
                aria-controls="downloadMenu"
                aria-haspopup="true"
                onClick={handleClick}
              >
                <ArrowDropDownIcon />
              </Button>
            </Tooltip>
          )}
        </ButtonGroup>
        {invoices.length > 1 && (
          <Popover
            id="downloadMenu"
            anchorEl={anchorEl}
            keepMounted
            elevation={8}
            open={Boolean(anchorEl)}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left'
            }}
            onClose={handleClose}
          >
            <List>
              {invoices.map(item => {
                return (
                  <ListItemButton
                    invoices-ga-label="Download invoice"
                    key={item.id}
                    onClick={handleDownload(item)}
                  >
                    <ListItemIcon>
                      <CloudDownloadIcon />
                    </ListItemIcon>
                    <ListItemText>
                      <Typography variant="body1">{`${intl.formatMessage(
                        messageKeys.Invoice
                      )} ${item.invoiceNumber}`}</Typography>
                      <Typography variant="body2" color="textSecondary">
                        {getTypeText(item.type)}
                      </Typography>
                      <Typography variant="body2" color="textSecondary">
                        {moment(item.createdAt).format(DATE_TIME_FORMAT)}
                      </Typography>
                    </ListItemText>
                  </ListItemButton>
                )
              })}
            </List>
          </Popover>
        )}
      </>
    )
  }

  return <></>
}

export default DownloadInvoice
