import React, { useEffect } from 'react'
import CommercialAgreementForm from '../../CommercialAgreement/Form/CommercialAgreementForm'
import useGetCommissionForProviderRequest from '../../../store/provider/useGetProviderCommission'

const StepCommercialAgreement = props => {
  const { dispatch, errors, formData } = props
  const { data: countryCommission } = useGetCommissionForProviderRequest(
    formData.country || 'none'
  )
  const HOLIDU_HOPA_COMMISSION = 1.5

  const onChange = commercialAgreementSignature => {
    dispatch({
      type: 'field',
      field: 'commercialAgreementSigned',
      value: commercialAgreementSignature.commercialAgreementSigned
    })
  }

  useEffect(() => {
    const isHopa =
      formData.isHoliduPaymentEnabled ||
      (formData.selectedChannelManager &&
        formData.selectedChannelManager.get('paymentType') ===
          'HOLIDU_PAYMENTS_ONLY')

    const newCommissionValue =
      Number(countryCommission) + (isHopa ? HOLIDU_HOPA_COMMISSION : 0)
    if (formData.commission !== newCommissionValue) {
      dispatch({
        type: 'field',
        field: 'commission',
        value: Number(countryCommission) + (isHopa ? HOLIDU_HOPA_COMMISSION : 0)
      })
    }
  }, [countryCommission, formData]) //eslint-disable-line

  return (
    <CommercialAgreementForm
      commercialAgreementSigned={formData.commercialAgreementSigned}
      commission={formData.commission}
      updateCommercialAgreementSignature={onChange}
      errors={errors}
      disabled={false}
    />
  )
}

export default StepCommercialAgreement
