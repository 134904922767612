import React from 'react'
import {
  Checkbox,
  FormControlLabel,
  Grid,
  Link,
  MenuItem,
  Radio,
  RadioGroup
} from '@mui/material'
import { FormattedMessage } from 'react-intl'
import countryList from 'react-select-country-list'

import { messageKeys as integrationDetailsKeys } from '../../IntegrationBasics/messages'
import { messageKeys as billingAddressMessageKeys } from '../../BillingInformation/messages'
import { messageKeys as globalMessageKeys } from '../../../globalMessages'
import ValidationTextField from '../../../components/Fields/ValidationTextField'
import { validateVAT, validateZip } from '../../../utils/validation'
import ValidationHint from '../../../components/Fields/ValidationHint'
import { VatUtil, ZipUtil } from '@holidu/pui-utils/dist'
import VatValidationErrorDialog from '../../../widgets/VatValidationErrorDialog'
import { messageKeys } from '../messages'

const StepIntegrationDetailsNotSigned = props => {
  const {
    dispatch,
    errors,
    formData,
    vatValidationError,
    onVatValidationErrorClose
  } = props

  const handleChange = (e, errorReason) => {
    const { name, value } = e.target
    dispatch({
      type: 'field',
      field: name,
      value
    })
    if (name === 'country') {
      dispatch({
        type: 'field',
        field: 'zip',
        value: null
      })
      dispatch({
        type: 'field',
        field: 'vatNumber',
        value: null
      })
      dispatch({
        type: 'field',
        field: 'withoutVat',
        value: !VatUtil.isValidCountry(value)
      })
    }
    dispatch({
      type: 'error',
      field: name,
      value: errorReason
    })
  }

  const handleWithoutVatChange = e => {
    dispatch({
      type: 'field',
      field: 'withoutVat',
      value: e.target.checked
    })
    dispatch({
      type: 'field',
      field: 'vatNumber',
      value: null
    })
  }

  const handleHoliduPaymentChange = e => {
    dispatch({
      type: 'field',
      field: 'isHoliduPaymentEnabled',
      value: e.target.value === 'true'
    })
  }

  return (
    <>
      <Grid item xs={12} spacing={4} container>
        {/* Legal Company Name */}
        <Grid xs={12} item>
          <ValidationTextField
            error={errors.legalName}
            name="legalName"
            label={
              <FormattedMessage {...billingAddressMessageKeys.LegalName} />
            }
            value={formData.legalName || ''}
            fullWidth
            required
            onChange={handleChange}
          />
        </Grid>
        {/* Billing Address */}
        <Grid xs={12} lg={8} item>
          <ValidationTextField
            error={errors.street}
            name="street"
            label={
              <FormattedMessage {...billingAddressMessageKeys.BillingAddress} />
            }
            value={formData.street || ''}
            fullWidth
            required
            onChange={handleChange}
          />
        </Grid>
        {/* House Number */}
        <Grid xs={12} lg={4} item>
          <ValidationTextField
            error={errors.number}
            name="number"
            label={
              <FormattedMessage
                {...billingAddressMessageKeys.BillingAddressHouseNumber}
              />
            }
            value={formData.number || ''}
            fullWidth
            required
            onChange={handleChange}
          />
        </Grid>
        {/* City */}
        <Grid xs={12} lg={3} item>
          <ValidationTextField
            name="city"
            error={errors.city}
            label={
              <FormattedMessage
                {...billingAddressMessageKeys.BillingAddressCity}
              />
            }
            value={formData.city || ''}
            fullWidth
            required
            onChange={handleChange}
          />
        </Grid>
        <Grid xs={12} lg={3} item>
          <ValidationTextField
            name="country"
            error={errors.country}
            label={
              <FormattedMessage
                {...billingAddressMessageKeys.BillingAddressCountry}
              />
            }
            value={formData.country || ''}
            fullWidth
            required
            select
            onChange={handleChange}
          >
            <MenuItem value="" disabled>
              <FormattedMessage {...globalMessageKeys.Select} />
            </MenuItem>
            {countryList()
              .getData()
              .map((country, index) => (
                <MenuItem key={index} value={country.value}>
                  {country.label}
                </MenuItem>
              ))}
          </ValidationTextField>
        </Grid>
        {/* ZIP Code */}
        <Grid xs={12} lg={2} item>
          <ValidationTextField
            error={errors.zip}
            name="zip"
            label={
              <FormattedMessage
                {...billingAddressMessageKeys.BillingAddressZip}
              />
            }
            value={formData.zip || ''}
            disabled={!formData.country}
            fullWidth
            required
            validations={{
              zipValidation: {
                validator: zip => validateZip(formData.country, zip),
                message: (
                  <FormattedMessage
                    {...globalMessageKeys.ErrorInvalidZipCode}
                  />
                )
              }
            }}
            onChange={handleChange}
          />
          {ZipUtil.isValidCountry(formData.country) && (
            <ValidationHint>
              <FormattedMessage {...globalMessageKeys.ForExample} />
              :&nbsp;{ZipUtil.getZipSample(formData.country)}
            </ValidationHint>
          )}
        </Grid>
        <Grid xs={12} lg={4} item>
          <ValidationTextField
            error={errors.vatNumber}
            disabled={
              !formData.country ||
              !VatUtil.isValidCountry(formData.country) ||
              formData.withoutVat
            }
            name="vatNumber"
            label={
              <FormattedMessage
                {...billingAddressMessageKeys.BillingVatNumber}
              />
            }
            value={formData.vatNumber || ''}
            required={
              VatUtil.isValidCountry(formData.country) && !formData.withoutVat
            }
            fullWidth
            validations={{
              vatValidation: {
                validator: vatNumber =>
                  validateVAT(formData.country, vatNumber),
                message: (
                  <FormattedMessage
                    {...globalMessageKeys.ErrorInvalidVatNumber}
                  />
                )
              }
            }}
            onChange={handleChange}
          />
          {VatUtil.isValidCountry(formData.country) && (
            <>
              <ValidationHint>
                <FormattedMessage {...globalMessageKeys.ForExample} />
                :&nbsp;{VatUtil.getVatSample(formData.country)}
              </ValidationHint>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formData.withoutVat}
                    onChange={handleWithoutVatChange}
                  />
                }
                label={<FormattedMessage {...messageKeys.WITHOUT_VAT} />}
              />
            </>
          )}
        </Grid>
        {/* Company Name */}
        <Grid xs={12} item>
          <ValidationTextField
            error={errors.companyName}
            name="companyName"
            label={<FormattedMessage {...integrationDetailsKeys.CompanyName} />}
            value={formData.companyName || ''}
            fullWidth
            required
            onChange={handleChange}
          />
        </Grid>
        {/* Property Location */}
        <Grid xs={12} item>
          <ValidationTextField
            name="locationOfProperties"
            error={errors.locationOfProperties}
            label={
              <FormattedMessage
                {...integrationDetailsKeys.LocationOfProperties}
              />
            }
            value={formData.locationOfProperties || ''}
            fullWidth
            required
            onChange={handleChange}
          />
        </Grid>
        {/* Properties Count */}
        <Grid xs={12} item>
          <ValidationTextField
            error={errors.propertiesCount}
            name="propertiesCount"
            label={
              <FormattedMessage
                {...integrationDetailsKeys.NumberOfProperties}
              />
            }
            type="number"
            value={formData.propertiesCount || ''}
            fullWidth
            required
            onChange={handleChange}
          />
        </Grid>
        {/* Holidu Payment */}
        {formData.selectedChannelManager &&
          formData.selectedChannelManager.get('paymentType') ===
            'HOLIDU_PAYMENTS_ALLOWED' && (
            <Grid xs={12} item>
              <FormattedMessage {...messageKeys.HoliduPaymentEnabled} />
              <RadioGroup
                aria-labelledby="hopa-radio-group-label"
                value={String(formData.isHoliduPaymentEnabled)}
                onChange={handleHoliduPaymentChange}
              >
                <FormControlLabel
                  value="true"
                  control={<Radio />}
                  label={
                    <FormattedMessage
                      {...messageKeys.HoliduPaymentConsent}
                      values={{
                        link: (
                          <Link
                            href="https://mailchi.mp/holidu/hopa"
                            target="_blank"
                          >
                            <FormattedMessage
                              {...messageKeys.HoliduPaymentInfoLink}
                            />
                          </Link>
                        )
                      }}
                    />
                  }
                />
                <FormControlLabel
                  value="false"
                  control={<Radio />}
                  label={
                    <FormattedMessage {...messageKeys.HoliduPaymentRefusal} />
                  }
                />
              </RadioGroup>
            </Grid>
          )}
      </Grid>
      <VatValidationErrorDialog
        vatValidationError={vatValidationError}
        onVatValidationErrorClose={onVatValidationErrorClose}
      />
    </>
  )
}

export default StepIntegrationDetailsNotSigned
