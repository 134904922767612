import React, { useState } from 'react'
import {
  Stack,
  Alert,
  TextField,
  Button,
  Typography,
  TextFieldProps
} from '@mui/material'
import { useIntl } from 'react-intl'
import { signIn } from '../../utils/awsCognitoClient'
import { PATH_DASHBOARD } from '../../routes/PathConstants'
import { messageKeys } from './messages'
import { useHistory, useParams } from 'react-router'

type ParamTypes = {
  email: string
}

const CommonTextFieldProps: TextFieldProps = {
  margin: 'dense',
  size: 'small',
  fullWidth: true,
  required: true
}

const ChangeTempPasswordPage = () => {
  const intl = useIntl()
  const history = useHistory()
  let { email } = useParams<ParamTypes>()

  const [form, setForm] = useState({
    tempPassword: '',
    password1: '',
    password2: '',
    error: ''
  })

  const onSubmit = async () => {
    if (form.password1 !== form.password2) {
      setForm({
        ...form,
        error: intl.formatMessage(messageKeys.PasswordsDontMatch)
      })
      return
    }
    try {
      await signIn(email, form.tempPassword, null, form.password1)
      history.replace(PATH_DASHBOARD)
    } catch (err) {
      setForm({ ...form, error: (err as any)?.message as string })
    }
  }

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target
    setForm({
      ...form,
      error: '',
      [name]: value
    })
  }

  return (
    <form>
      <Stack spacing={2} alignItems="center">
        <Typography variant="h6">
          {intl.formatMessage(messageKeys.ChangePassTitle)}
        </Typography>
        <TextField
          name="tempPassword"
          label={intl.formatMessage(messageKeys.TemporaryPassword)}
          type="password"
          value={form.tempPassword}
          onChange={onChange}
          {...CommonTextFieldProps}
        />
        <TextField
          name="password1"
          label={intl.formatMessage(messageKeys.NewPassword)}
          type="password"
          value={form.password1}
          onChange={onChange}
          {...CommonTextFieldProps}
        />
        <TextField
          name="password2"
          label={intl.formatMessage(messageKeys.RepeatPassword)}
          type="password"
          value={form.password2}
          onChange={onChange}
          {...CommonTextFieldProps}
        />

        {form.error ? <Alert severity="error">{form.error}</Alert> : null}

        <Button
          color="primary"
          variant="contained"
          fullWidth
          onClick={onSubmit}
        >
          {intl.formatMessage(messageKeys.ChangePassButton)}
        </Button>
      </Stack>
    </form>
  )
}

export default ChangeTempPasswordPage
