import { Grid, Paper, styled, Box, Stack, Typography } from '@mui/material'
import React from 'react'
import LanguageMenu from '../Header/LanguageMenu'

export const AuthLayout = ({ children }) => {
  return (
    <Box>
      <BackGround top={0} left={0} right={0} bottom={0} position="absolute" />
      <Grid container display="flex" alignItems="center" minHeight="100vh">
        <Grid lg={4} md={5} sm={12} xs={12} item>
          <Box display="flex" alignItems="center" justifyContent="center">
            <Paper
              variant="outlined"
              sx={{
                width: 360,
                maxWidth: 'calc(100vw - 10px)',
                p: 2,
                opacity: 0.9
              }}
            >
              <Stack spacing={1} alignItems="center">
                <img
                  alt="Holidu logo"
                  src="/static/img/Logo.png"
                  style={{ maxWidth: '50%' }}
                />
                <Typography variant="h6" color="primary" gutterBottom>
                  Partner Portal
                </Typography>
              </Stack>
              {children}
              <Box display="flex" justifyContent="center" mt={2}>
                <LanguageMenu />
              </Box>
            </Paper>
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}

const BackGround = styled(Box)({
  backgroundImage: "url('/static/img/PPHome.jpg')",
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat'
})
