import QueryUtils from 'query-string'
import env from '../../env'
import { currentUserIdToken } from '../../utils/awsCognitoClient'

export const fetchPayouts = (selectedProvider, force = true) => {
  const { bookingId, fromDate, tab, untilDate } = QueryUtils.parse(
    window.location.search
  )

  const query = {
    bookingId,
    status: tab === 'PAID' ? 'PAID' : ['NEW', 'PENDING'],
    fromDate: tab === 'PAID' ? fromDate : null,
    untilDate
  }

  const queryStr = QueryUtils.stringify(query, {
    skipNull: true,
    skipEmptyString: true
  })
  return {
    url: `${env.BACKEND}/payouts/${selectedProvider}?${queryStr}`,
    transform: response => ({
      payouts: response
    }),
    update: {
      payouts: (prev, next) => next
    },
    options: {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + currentUserIdToken()
      }
    },
    force
  }
}
