import axios from 'axios'
import { useMutation } from '@tanstack/react-query'
import { useSnackbar } from 'notistack'
import { useIntl } from 'react-intl'
import messageKeys from './messages'
import sanitizeData from '../../../utils/sanitizeData'

const usePostCancelBooking = (bookingId: number) => {
  const { enqueueSnackbar } = useSnackbar()
  const intl = useIntl()
  return useMutation<any, Error, any>(
    async (payload: any) => {
      return await axios.post(
        `/bookings/${bookingId}/cancel`,
        sanitizeData(payload)
      )
    },
    {
      onError: error => {
        enqueueSnackbar(
          intl.formatMessage(messageKeys.CancelError, {
            message: error.message
          }),
          {
            variant: 'error'
          }
        )
      },
      onSuccess: () => {
        enqueueSnackbar(intl.formatMessage(messageKeys.CancelSuccess), {
          variant: 'success'
        })
      }
    }
  )
}

export default usePostCancelBooking
