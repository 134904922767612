import React from 'react'
import PropTypes from 'prop-types'
import { Box, Paper, Typography, styled } from '@mui/material'

const Header = styled(Box)(({ theme }) => ({
  padding: theme.spacing(1, 1.5),
  backgroundColor: theme.palette.grey['100'],
  borderBottom: `1px solid ${theme.palette.divider}`
}))

const SubSection = props => {
  const { actions, title } = props

  return (
    <Paper variant="outlined">
      {Boolean(title || actions) && (
        <Header>
          <Box sx={{ flexGrow: 1 }}>
            <Typography
              variant="subtitle2"
              component="span"
              color="inherit"
              sx={{
                fontWeight: 'bold'
              }}
            >
              {title}
            </Typography>
          </Box>
          <Box>{actions}</Box>
        </Header>
      )}
      <Box
        sx={{
          p: 1.5
        }}
      >
        {props.children}
      </Box>
    </Paper>
  )
}

SubSection.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  title: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  subtitle: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  actions: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
}

export default SubSection
