import {
  DataTable,
  DataTableColumnProp,
  Section
} from '@holidu/pui-components/dist'
import { Stack, Typography } from '@mui/material'
import React from 'react'
import { useIntl } from 'react-intl'
import { messageKeys } from '../messages'
import { ShareholderKycStatus } from '../types'
import StatusIcon from './StatusIcon'

type ShareholderKycSectionProps = {
  shareholderKycStatuses?: ShareholderKycStatus[]
}

function ShareholderKycSection(props: ShareholderKycSectionProps) {
  const { shareholderKycStatuses } = props

  const intl = useIntl()

  const columns: DataTableColumnProp[] = [
    {
      id: 'shareholderName',
      label: intl.formatMessage(messageKeys.Name)
    },
    {
      id: 'shareHolderIdentity',
      label: intl.formatMessage(messageKeys.Identity),
      renderCell: (data, shareholder: ShareholderKycStatus) => (
        <Stack direction="row" alignItems={'center'} spacing={1}>
          <StatusIcon status={shareholder.identityVerificationStatus} />
          <Typography variant="body2">
            {shareholder.identityVerificationStatus}
          </Typography>
        </Stack>
      )
    },
    {
      id: 'shareholderPassport',
      label: intl.formatMessage(messageKeys.Passport),
      renderCell: (data, shareholder: ShareholderKycStatus) => (
        <Stack direction="row" alignItems={'center'} spacing={1}>
          <StatusIcon status={shareholder.passportVerificationStatus} />
          <Typography variant="body2">
            {shareholder.passportVerificationStatus}
          </Typography>
        </Stack>
      )
    }
  ]

  return (
    <Section title={intl.formatMessage(messageKeys.Shareholders)}>
      {shareholderKycStatuses && (
        <DataTable
          data={shareholderKycStatuses}
          columns={columns}
          tdCellProps={{ sx: { borderBottomWidth: 0 } }}
        />
      )}
    </Section>
  )
}

export default ShareholderKycSection
