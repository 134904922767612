import React from 'react'
import { Route, Switch } from 'react-router-dom'
import MainLayout from '../layouts/MainLayout'
import StaticLayout from '../layouts/StaticLayout'
import Page404 from '../containers/StaticPages/Page404'
import FinalizeRegistrationPageContainer from '../containers/Registration/FinalizeRegistrationPageContainer'
import FinalizeRegistrationThankYouPage from '../containers/Registration/FinalizeRegistrationThankYouPage'
import FinalizeRegistrationFilter from './FinalizeRegistrationFilter'

import {
  PATH_FINALIZE_REGISTRATION,
  PATH_FINALIZE_REGISTRATION_THANK_YOU
} from './PathConstants'
import { routesWithDashboardLayout, routesWithStaticPageLayout } from './index'

const childRoutes = (Layout, routes) => {
  return routes.map(({ children, component: Component, path, title }, index) =>
    children ? (
      // Route item with children
      children.map(({ component: Component, path, title }, index) => (
        <Route
          key={index}
          path={path}
          render={props => (
            <Layout title={title}>
              <Component {...props} />
            </Layout>
          )}
          exact
        />
      ))
    ) : (
      // Route item without children
      <Route
        key={index}
        path={path}
        render={props => (
          <Layout title={title}>
            <Component {...props} />
          </Layout>
        )}
        exact
      />
    )
  )
}

const Routes = () => (
  <Switch>
    <Route
      path={PATH_FINALIZE_REGISTRATION}
      render={props => (
        <StaticLayout>
          <FinalizeRegistrationPageContainer {...props} />
        </StaticLayout>
      )}
      exact
    />
    <Route
      path={PATH_FINALIZE_REGISTRATION_THANK_YOU}
      render={props => <FinalizeRegistrationThankYouPage {...props} />}
    />
    <FinalizeRegistrationFilter>
      <Switch>
        {childRoutes(MainLayout, routesWithDashboardLayout)}
        {childRoutes(StaticLayout, routesWithStaticPageLayout)}
        <Route
          render={props => (
            <MainLayout>
              <Page404 />
            </MainLayout>
          )}
        />
      </Switch>
    </FinalizeRegistrationFilter>
  </Switch>
)

export default Routes
