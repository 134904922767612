import React from 'react'
import { Chip } from '@mui/material'
import { DataTableColumnProp } from '@holidu/pui-components/dist'
import * as CONSTANTS from './constants'
import get from 'lodash/get'
import messageKeys from './messages'
import { default as bookingDetailMesageKeys } from '../BookingDetail/messages'
import { useIntl } from 'react-intl'
import moment from 'moment'
import { DATE_FORMAT } from '../../utils/dateUtil'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

const useBookingsColumns = (): DataTableColumnProp[] => {
  const intl = useIntl()
  return [
    {
      id: 'bookedAt',
      label: intl.formatMessage(messageKeys.BookedOn),
      renderCell: data => {
        return moment(data).format(DATE_FORMAT)
      }
    },

    {
      id: 'checkin',
      label: intl.formatMessage(bookingDetailMesageKeys.CheckInDate),
      renderCell: data => {
        return moment(data).format(DATE_FORMAT)
      }
    },
    {
      id: 'checkout',
      label: intl.formatMessage(bookingDetailMesageKeys.CheckOutDate),
      renderCell: data => {
        return moment(data).format(DATE_FORMAT)
      }
    },
    {
      id: 'transactionId',
      label: intl.formatMessage(bookingDetailMesageKeys.TransactionId)
    },
    {
      id: 'bookingId',
      label: intl.formatMessage(bookingDetailMesageKeys.BookingId)
    },
    {
      id: 'customerEmail',
      label: intl.formatMessage(bookingDetailMesageKeys.Email)
    },
    {
      id: 'status',
      label: intl.formatMessage(messageKeys.Status),
      renderCell: data => {
        return (
          <Chip
            sx={{
              cursor: 'inherit'
            }}
            size="small"
            color={get(CONSTANTS.BOOKING_STATUS, [data, 'color'], 'default')}
            label={get(CONSTANTS.BOOKING_STATUS, [data, 'text'], data)}
          />
        )
      }
    },
    {
      id: 'action',
      renderCell: () => {
        return (
          <KeyboardArrowRightIcon />
        )
      }
    },
  ]
}

export default useBookingsColumns
